import {useDispatch, useSelector} from "react-redux";
import {Nav} from "./Nav";
import {useEffect} from "react";
import {teamActions} from "../_store";
import {ProductNav} from "./ProductNav";
import {useParams, useSearchParams} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import PieChart from "./PieChart";

const Dashboard = () => {

    const teams = useSelector(x => x.teams)
    const dispatch = useDispatch()
    const {id} = useParams()
    const [searchParams] = useSearchParams()
    const plan = searchParams.get("plan")

// Inside Dashboard component
    const pieData1 = {
        labels: ['Completed', 'Started', 'Not Started'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    const pieData2 = {
        labels: ['Completed', 'Started', 'Not Started'],
        datasets: [
            {
                data: [200, 150, 50],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
    };

    useEffect(() => {
        dispatch(teamActions.getAll())
    },[])

    return (
        <>
            {/*{isOpen && <Modal />}*/}

            <ToastContainer />
            <div className="min-h-full">
              <ProductNav id={id} plan={plan} />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
                    </div>
                </header>

                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <h1 className="text-4xl text-[#a70000]">Assessment Statistics</h1>
                                <br/>

                                {/*Put Pie Chart here*/}

                                <div className="flex flex-col md:flex-row items-center justify-around h-full">
                                    <div className="w-full md:w-1/2 h-1/2 md:h-full p-4">
                                        <h2 className="text-xl text-center mb-4">Team 1</h2>
                                        <PieChart data={pieData1} options={pieOptions} />
                                    </div>
                                    <div className="w-full md:w-1/2 h-1/2 md:h-full p-4">
                                        <h2 className="text-xl text-center mb-4">Team 2</h2>
                                        <PieChart data={pieData2} options={pieOptions} />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
 }

 export default Dashboard



//  <div className="grid grid-cols-4 gap-4">
//     <div className="report ">
//     <h1 className="h1">
//     Teams
//     </h1>
//
// <br/>
// <div>
//     <h2 className="text-4xl"> <strong>0</strong> </h2>
//     <p><strong>+0</strong></p>
//     <span>last month</span>
// </div>
// </div>
// <div className="report">
// <h1 className="h1">
// Members
// </h1>
//
// <br/>
// <div>
// <h2 className="text-4xl"> <strong>0</strong> </h2>
// <p><strong>+0</strong></p>
// <span>last month</span>
// </div>
// </div>
// <div className="report">
// <h1 className="h1">
// Started
// </h1>
// <br/>
// <div>
// <h2 className="text-4xl"> <strong>0</strong> </h2>
// <p><strong>+0</strong></p>
// <span>last month</span>
// </div>
// </div>
// <div className="report ">
// <h1 className="h1">
// Completed
// </h1>
//
// <br/>
// <div>
// <h2 className="text-4xl"> <strong>0</strong> </h2>
// <p><strong>+0</strong></p>
// <span>last month</span>
// </div>
// </div>
// </div>