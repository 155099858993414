import {useDispatch, useSelector} from 'react-redux';

import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router";
import {progressActions} from "../../_store";
import CopyrightLogo from "../../games/copyright-logo.jpg";

const CompletedGameModalThree = () => {
    const dispatch = useDispatch();
    const {currentModalStep} = useSelector(x => x.games)
    const {progress} = useSelector(x => x.progress)
    const {user} = useSelector(x=> x.auth)
    const navigate = useNavigate()

    const currentActualEEScore = progress.progress && progress.progress.actual_ee_score + 100
    const currentActualETScore = progress.progress && progress.progress.actual_et_score + 100
    const currentActualBudgetScore = progress.progress && progress.progress.actual_budget_score + 100

    const getEffectivenessAssessment = () => {
        if (currentActualEEScore >= 208 && currentActualEEScore < 247){
            return <p>
                Well done! You are an expert at managing Employee Effectiveness during the change process.
                Successful change relies on the participation and effort of individuals to accomplish tasks and activities. In order for individuals to effectively contribute, it is important for them to have a clear understanding of their expectations, the necessary resources and support to fulfil those expectations, and a willingness to be involved in the change process. Throughout the change process, it is important to manage the well-being of employees and recognise their efforts and successes.
                Your score suggests that you may be proficient in designing and implementing activities that are tailored to the specific Employee Effectiveness needs for large-scale and complex change with highly diverse stakeholders. This leads to effective management of human resource capacity, capability, competency development, role and deliverable clarity, health and wellness, stakeholder engagement, and recognition during the change process.
            </p>
        }else if (currentActualEEScore >= 172 && currentActualEEScore < 208){
           return  <p>
               Successful change relies on the participation and effort of individuals to accomplish tasks and activities. In order for individuals to effectively contribute, it is important for them to have a clear understanding of their expectations, the necessary resources and support to fulfil those expectations, and a willingness to be involved in the change process. Throughout the change process, it is important to manage the well-being and participation of employees. This includes managing human resource capacity, capability, competency development, role and deliverable clarity, health and wellness, stakeholder engagement, and recognition in the change process.
               Your score suggests that you have a thorough understanding of, and experience in Employee Effectiveness management during the change process. A recommendation for further development is to enhance your Change Management skills and ability to refine and/or develop change initiatives and tailor them more specifically to the needs of highly diverse stakeholders, to more complex change deliverables, and to the phases within the change process.
           </p>
        }else if(currentActualEEScore >= 137 && currentActualEEScore < 172) {
            return <p>
                Successful change relies on the participation and effort of individuals to accomplish tasks and activities. In order for individuals to effectively contribute, it is important for them to have a clear understanding of their expectations, the necessary resources and support to fulfil those expectations, and a willingness to be involved in the change process. Throughout the change process, it is important to manage the well-being and participation of employees. This includes managing human resource capacity, capability, competency development, role and deliverable clarity, health and wellness, stakeholder engagement, and recognition during the change process.
                Your score suggests that you have some knowledge and experience in managing Employee Effectiveness during the change process. As a next step for your development, it is recommended to focus on enhancing the practical application of your Change Management skills to include more diverse tools and interventions that will address the needs of larger and more complex stakeholder groups and Employee Effectiveness issues.
            </p>
        }else if(currentActualEEScore >= 104 && currentActualEEScore < 137 ){
          return  <p>
              Successful change relies on the participation and effort of individuals to accomplish tasks and activities. In order for individuals to effectively contribute, it is important for them to have a clear understanding of their expectations, the necessary resources and support to fulfil those expectations, and a willingness to be involved in the change process. Throughout the change process, it is important to manage the well-being of employees and recognise their efforts and successes.
              Your score suggests a theoretical understanding of the importance of managing Employee Effectiveness during the change process. As a next step in your development journey, it is recommended to focus on the practical application of your Change Management skills to enhance your ability to manage human resource capacity, capability development, and role clarity, as well as promote employee health and wellness, stakeholder engagement, and recognition.
          </p>
        }else if (currentActualEEScore >= 57 && currentActualEEScore < 104 ) {
            return <p>
                Successful change relies on the participation and effort of individuals to accomplish tasks and activities. In order for individuals to effectively contribute, it is important for them to have a clear understanding of their expectations, the necessary resources and support to fulfil those expectations, and a willingness to be involved in the change process. Throughout the change process, it is important to manage the well-being of employees and recognise their efforts and successes.
                Your score suggests that you have some knowledge of the importance of successfully managing Employee Effectiveness during the change process. As a next step, more learning and development are required to deepen your Change Management knowledge and practical application of skills for enhancing human resource capacity, capability, competency development, role and deliverable clarity, health and wellness, stakeholder engagement, and recognition.
            </p>
        }else if (currentActualEEScore >= 0  && currentActualEEScore < 57 ) {
            return <p>
                Successful change relies on the participation and effort of individuals to accomplish tasks and activities. In order for individuals to effectively contribute, it is important for them to have a clear understanding of their expectations, the necessary resources and support to fulfil those expectations, and a willingness to be involved in the change process. Throughout the change process, it is essential to prioritise the well-being of employees and recognise their efforts and successes.
                Your score suggests that there is a need for further learning and development in understanding the importance of Employee Effectiveness in Change Management. This includes how to effectively support human resource capacity, capability, competency development, role and deliverable clarity, health and wellness, stakeholder engagement, and recognition during the change process.
            </p>
        }
    }

    const getTimeAssessment = () => {
        if (currentActualETScore >= 197 && currentActualETScore < 230){
            return <p>
                Well done! You are an expert at managing project timelines during the change process. Effective management of project timelines is crucial in ensuring the timely completion of tasks and maintaining the overall progress of the change project. This not only helps to avoid delays and additional expenses but also contributes to the successful implementation of the change.
                Your score suggests that you know how to manage the impact of different activities, issues and risks on the overall change project deadline. You most likely consider the strategic interdependence of each decision, stakeholder engagement, or intervention that was planned. You know how to focus on what needs to be delivered, clearly communicate the requirements to stakeholders, regularly monitor the progress and make adjustments where necessary.
            </p>
        }

        else if(currentActualETScore >= 168 && currentActualETScore < 197) {
            return <p>
                Effective management of project timelines is crucial in ensuring the timely completion of tasks and maintaining the overall progress of the change project. This not only helps to avoid delays and additional expenses but also contributes to the successful implementation of the change.
                Good time management includes clear communication on stakeholder requirements and expectations, effective risk management, and regular progress monitoring with adjustments or corrective action where necessary.
                Your score suggests that you know how to manage project timelines during a change process well. A recommendation for further development is to refine your tracking and management of the impact of different activities, interventions, and issues on the overall project deadline. Explore the option of more diverse tools to manage the interdependence of decisions, stakeholder engagements, interventions, and strategic challenges on planned timelines.
            </p>
        }

        else if(currentActualETScore >= 138 && currentActualETScore < 168) {
            return <p>
                Effective management of project timelines is crucial in ensuring the timely completion of tasks and maintaining the overall progress of the change project. This not only helps to avoid delays and additional expenses but also contributes to the successful implementation of the change.
                Each decision, stakeholder engagement, or action taken during the project affects the timelines. Good time management includes clear communication on stakeholder requirements and expectations, effective risk management, and regular progress monitoring with adjustments or corrective action where necessary.
                Your score suggests that you have some knowledge and experience in project time management during the change process. As a next step for your development, it is recommended to focus on enhancing the practical application of your skills through learning about more tools and methods to support effective time management for more complex change projects.
            </p>
        }

        else if(currentActualETScore >= 108 && currentActualETScore < 138 ){
            return <p>
                Effective management of project timelines is crucial in ensuring the timely completion of tasks and maintaining the overall progress of the change project. This not only helps to avoid delays and additional expenses but also contributes to the successful implementation of the change.
                Each decision, stakeholder engagement, or action taken during the project affects the timelines. Good time management includes clear communication on stakeholder requirements and expectations, effective risk management, and regular progress monitoring with adjustments or corrective action where necessary.
                Your score suggests that you have a theoretical understanding of project time management during the change process. There seems to be a need for further learning and development with regard to practical application in this area. This includes the practical application of skills to plan and manage the time available for tasks, effectively drive task completion within planned timelines, and assess the overall impact on the project deadline. This will help you to optimise the use of time and prioritise activities that are essential to the change project's success and avoid implementing inappropriate activities or wasting time on unnecessary tasks.
            </p>
        }else if (currentActualETScore >= 69 && currentActualETScore < 108 ) {
            return <p>
                Effective management of project timelines is crucial in ensuring the timely completion of tasks and maintaining the overall progress of the change project. This not only helps to avoid delays and additional expenses but also contributes to the successful implementation of the change.
                Each decision, stakeholder engagement, or action taken during the project affects the timelines. Good time management includes clear communication on stakeholder requirements and expectations, effective risk management, and regular progress monitoring with adjustments or corrective action where necessary.
                Your score suggests that you have some knowledge of project time management during the change process. There seems to be a need for further learning and development in this area. This includes gaining more knowledge and practical application of skills to plan and manage the time available for tasks, effectively drive task completion within planned timelines, and assess the overall impact on the project deadline. This will help you to optimise the use of time and prioritise activities that are essential to the change project's success and avoid implementing inappropriate activities or wasting time on unnecessary tasks.
            </p>
        }else if (currentActualETScore >= 0  && currentActualETScore < 69 ) {
            return <p>
                Effective management of project timelines is crucial in ensuring the timely completion of tasks and maintaining the overall progress of the change project. This not only helps to avoid delays and additional expenses but also contributes to the successful implementation of the change.
                Each decision, stakeholder engagement, or action taken during the project affects the timelines. Good time management includes clear communication on stakeholder requirements and expectations, effective risk management, and regular progress monitoring with adjustments or corrective action where necessary.
                Your score suggests that you probably have limited knowledge and experience in project time management during the change process, and there is a need for further learning and development in this area. This includes the practical application of skills to plan and manage the time available for tasks, effectively drive task completion within planned timelines, and assess the overall impact on the project deadline.
            </p>
        }
    }

    const getBudgetAssessment = () => {
        if (currentActualBudgetScore >= 185 && currentActualBudgetScore < 220){
            return <p>
                Well done! You are an expert at managing change project budgets. Effective management of change budgets is crucial in ensuring that the change project stays within the allocated financial resources and avoids overspending. This can prevent delays and additional costs that can negatively impact the project's success.
                Your score suggests that you know how to manage the impact of different activities, issues, and risks on the overall change budget. You most likely consider the strategic interdependence of each decision, stakeholder engagement, intervention, potential risks, and project timelines on the budget. You probably present budget information well and should be able to assess variables and scenarios, such as forecasting. A high level of business acumen and the ability to anticipate and plan for potential financial challenges and related decision-making are core skills for strategic change role players.
            </p>
        }else if (currentActualBudgetScore >= 159 && currentActualBudgetScore < 185){
            return <p>
                Effective management of change budgets is crucial in ensuring that the change project stays within the allocated financial resources and avoids overspending. This can prevent delays and additional costs that can negatively impact the project's success.
                Your score indicates that you know how to manage change budgets well. A recommendation for further development is to refine your budget management skills. This includes considering the strategic interdependence of decisions, stakeholder engagement, interventions, potential risks, and project timelines on the budget. Additionally, explore enhancing your business acumen, different methods of presenting budget information, and evaluating variables and scenarios, such as forecasting. This will enable you to anticipate and plan for potential financial challenges and make informed decisions, which is a core skill for strategic change role players.
            </p>
        }else if(currentActualBudgetScore >= 132 && currentActualBudgetScore < 159) {
            return <p>
                Effective management of change budgets is crucial in ensuring that the change project stays within the allocated financial resources and avoids overspending.
                This can prevent delays and additional costs that can negatively impact the project's success. When making decisions, engaging with stakeholders, or planning interventions, it is important to consider their financial impact on the budget. Additionally, potential risks or issues that may affect the budget should be closely monitored and addressed promptly.
                Your score suggests that you have some knowledge and experience in budget management during the change process. As a next step for your development, it is recommended to focus on enhancing the practical application of your skills to manage change budgets for more diverse and complex change projects and the impact of the interdependence of each decision, stakeholder engagement, intervention, and risk on the budget.
            </p>
        }else if(currentActualBudgetScore >= 105 && currentActualBudgetScore < 132 ){
            return <p>
                Effective management of change budgets is crucial in ensuring that the change project stays within the allocated financial resources and avoids overspending. This can prevent delays and additional costs that can negatively impact the project's success. When making decisions, engaging with stakeholders, or planning interventions, it is important to consider their financial impact on the budget. Additionally, potential risks or issues that may affect the budget should be closely monitored and addressed promptly.
                Your score suggests that you have an understanding of the significance of project budget management during the change process. It is recommended that you place additional emphasis on the practical application of managing a change budget effectively. This includes developing a comprehensive budget plan, consistently monitoring expenses, and being prepared to make adjustments as necessary to ensure that the project stays within financial constraints. By focusing on these key elements, you will be better equipped to successfully manage change budgets.
            </p>

        }else if (currentActualBudgetScore >= 70 && currentActualBudgetScore < 105 ) {
            return <p>
                Effective management of change budgets is crucial in ensuring that the change project stays within the allocated financial resources and avoids overspending. This can prevent delays and additional costs that can negatively impact the project's success. When making decisions, engaging with stakeholders, or planning interventions, it is important to consider their financial impact on the budget. Additionally, potential risks or issues that may affect the budget should be closely monitored and addressed promptly.
                Your score suggests that you probably have some knowledge of budget management during the change process and there is a need for further learning and development in this area. The next step to deepen and enhance your change budget management knowledge and practical application of skills could include focussing on creating a detailed budget plan, regularly monitoring expenses, and being prepared to make adjustments to the budget as needed to ensure the financial success of a change initiative.
            </p>

        }else if (currentActualBudgetScore >= 0  && currentActualBudgetScore < 70 ) {
            return <p>
                Effective management of change budgets is crucial in ensuring that the change project stays within the allocated financial resources and avoids overspending. This can prevent delays and additional costs that can negatively impact the project's success. When making decisions, engaging with stakeholders, or planning interventions, it is important to consider their financial impact on the budget. Additionally, potential risks or issues that may affect the budget should be closely monitored and addressed promptly.
                Your score suggests that you probably have limited knowledge and experience in budget management during the change process and there is a need for further learning and development in this area. This includes creating a detailed budget plan, regularly monitoring expenses, and being prepared to make adjustments to the budget as needed to ensure the financial success of the change.
            </p>
        }
    }

    return (
        <>
            <ToastContainer />
            <aside className='modal-container'>
                <div className='modal mt-6'>
                    <form method="POST">
                        {/*<Confetti />*/}

                        <h1 className="mb-2 mt-6">
                            <span className="font-bold mb-3">
                                 Explanation of your individual category scores:
                            </span>
                        </h1>

                        <p className="font-semibold text-lg">Employee Effectiveness = { currentActualEEScore}</p>
                        <p className="text-xs semi">
                            {getEffectivenessAssessment()}
                        </p>

                        <p className="font-semibold text-lg"> Time = { currentActualETScore}</p>
                        <p className="text-xs semi">
                            {getTimeAssessment()}
                        </p>

                        <p className="font-semibold text-lg"> Budget = { currentActualBudgetScore}</p>
                        <p className="text-xs">
                            {getBudgetAssessment()}
                        </p>

                        <img src={CopyrightLogo} width={100} className="float-left mt-5"  />

                        <div>
                            <div className='btn-container'>
                                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                                    <button
                                        onClick={() => {
                                            // navigate(-1)
                                            dispatch(progressActions.setGameCompletedStatusText("overall-results-card"))

                                        }}
                                        type="button"
                                        className="mr-3 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                        data-bs-dismiss="modal">
                                        NEXT
                                    </button>
                                </div>
                            </div>
                        </div>


                    </form>
                </div>
            </aside>

        </>
    )
}
export default CompletedGameModalThree


