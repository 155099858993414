import {DeleteButton, EditButton, LinkButton} from "./button/Buttons";
import React, {useRef, useState} from "react";
import ContentEditable from "react-contenteditable";

export const SingleStep = ({step}) => {

    const contentEditable = React.createRef()
    const orderEditable = React.createRef()
    const text = useRef('');
    const orderText = useRef('');

    const handleChange = evt => {
        text.current = evt.target.value;
    };

    const handleOrderNumberChange = evt => {
        orderText.current = evt.target.value;
    }

    return (
        <div className={`w-full h-64 flex flex-col justify-between dark:bg-gray-800 ${step.step_enabled ? "bg-white" : "bg-red-300"}  dark:border-gray-700 rounded-lg border border-gray-400 mb-6 py-5 px-4`}>

            <div>
                <h3 className="text-gray-800 dark:text-gray-100 leading-7 font-semibold w-11/12">
                    {/*<textarea defaultValue={step.phase_step_name} >*/}
                    {/*</textarea>*/}

                    <ContentEditable
                        innerRef={contentEditable}
                        html={step.phase_step_name} // innerHTML of the editable div
                        disabled={false}       // use true to disable editing
                        onChange={handleChange} // handle innerHTML change
                        tagName='article' // Use a custom HTML tag (uses a div by default)
                    />

                </h3>
            </div>

            <div>
                <div className="mb-3 flex items-center">
                    <div className="p-1 bg-gray-800 dark:bg-gray-100 rounded text-yellow-500">
                        {/*<div className="p-2">*/}

                            <ContentEditable
                                innerRef={orderEditable}
                                html={`${step.order}`} // innerHTML of the editable div
                                disabled={false}       // use true to disable editing
                                onChange={handleOrderNumberChange} // handle innerHTML change
                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                className="p-3"
                            />
                            {/*{step.order}*/}
                        {/*</div>*/}
                        {/*<svg xmlns="http://www.w3.org/2000/svg"*/}
                        {/*     className="icon icon-tabler icon-tabler-star" width={16} height={16}*/}
                        {/*     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"*/}
                        {/*     strokeLinecap="round" strokeLinejoin="round">*/}
                        {/*    <path stroke="none" d="M0 0h24v24H0z"/>*/}
                        {/*    <path*/}
                        {/*        d="M12 17.75l-6.172 3.245 1.179-6.873-4.993-4.867 6.9-1.002L12 2l3.086 6.253 6.9 1.002-4.993 4.867 1.179 6.873z"/>*/}
                        {/*</svg>*/}
                    </div>
                </div>
                <div className="flex items-center justify-between text-gray-800 dark:text-gray-100">
                    {/*<p className="text-sm">March 28, 2020</p>*/}
                    <p className="text-sm">{step.phase_name}</p>

                    <div className="grid grid-cols-3 gap-1">
                        <div className="w-8 h-8 rounded-full bg-gray-800 text-white dark:bg-gray-100 dark:text-gray-800 flex items-center justify-center">
                            <a href={`/super-admin/edit-step/${step.phase_step_id}`}>
                                <EditButton />

                            </a>
                        </div>

                        <div
                            onClick={() => {
                                alert("hello")
                            }}
                            className="w-8 h-8 rounded-full bg-gray-800 text-white dark:bg-gray-100 dark:text-gray-800 flex items-center justify-center">
                            <DeleteButton />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
