import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'progress';
const initialState = createInitialState();
const extraActions = createExtraActions();
const reducers = createReducers();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const progressActions = { ...slice.actions, ...extraActions };
export const progressReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        list:  [],
        progress: {},
        nextStep: {},
        progressOrder: 0,
        ee_score: 0,
        et_score: 0,
        budget_score: 0,
        actual_ee_score: 0,
        actual_et_score: 0,
        actual_budget_score: 0,
        gameCompleted: false,
        isCorrect: false,
        scoresIndicator :{},
        gameCompletedStatus: "",
        watchedVideo: false,
        kcTries: {}
    }
}

function createExtraActions() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/progress`;

    return {
        getAll       :  getAll(),
        addProgress  :  addProgress(),
        getProgress  :  getProgress(),
        getKCTries   :  getKCTries(),
        getNextStep  :  getNextStep(),
        clearProgress:  clearProgress(),
        updateProgress: updateProgress()
    }

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${url}`)
        );
    }

    function addProgress() {
        return createAsyncThunk(
            `${name}/addProgress`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function getProgress() {
        return createAsyncThunk(
            `${name}/getProgress`,
            async (body ) => await fetchWrapper.post(`${url}/${body.user}`, body )
        )
    }

    function getKCTries() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/progress/kc-tries`;

        return createAsyncThunk(
            `${name}/getKCTries`,
            async (body ) => await fetchWrapper.post(`${url}/${body.user}`, body )
        )
    }

    function getNextStep() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/progress/next-step`;

        return createAsyncThunk(
            `${name}/getNextStep`,
            async (body) => await fetchWrapper.post(`${url}/${body.order}`, body )
        )
    }

    function clearProgress() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/clear-progress`;

        return createAsyncThunk(
            `${name}/clearProgress`,
            async (body) => await fetchWrapper.post(`${url}`, body )
        )
    }

    function updateProgress() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/progress/update/user`;

        return createAsyncThunk(
            `${name}/updateProgress`,
            async (body) => await fetchWrapper.patch(`${url}`, body )
        )
    }
}

function createReducers() {
    return {
        setProgressOrder,
        setEEScore,
        setETScore,
        setBudgetScore,
        setOriginalEEScore,
        setOriginalETScore,
        setOriginalBudgetScore,
        setActualEEScore,
        setActualETScore,
        setActualBudgetScore,
        setGameCompleteStatus,
        setScoreIndicator,
        setGameCompletedStatusText,
        setKCTries
    }

    function setProgressOrder(state, action) {
        state.progressOrder = action.payload
    }

    function setETScore(state, action) {
        const oldEtScore = state.et_score
        state.et_score = oldEtScore + action.payload
    }

    function setEEScore(state, action) {
        const oldEeScore  = state.ee_score
        state.ee_score = oldEeScore + action.payload
    }

    function setBudgetScore(state, action) {
        const oldBudgetScore = state.budget_score
        state.budget_score = oldBudgetScore + action.payload
    }

    function setOriginalETScore (state, action){
        state.et_score = action.payload
    }

    function setOriginalEEScore (state, action){
        state.ee_score = action.payload
    }

    function setOriginalBudgetScore (state, action){
        state.budget_score = action.payload
    }

    function setActualEEScore (state, action) {
        state.actual_ee_score = state.payload
    }

    function setActualETScore (state, action){
        state.actual_et_score = state.payload
    }

    function setActualBudgetScore (state, action) {
        state.actual_budget_score = state.payload
    }

    function setGameCompleteStatus (state, action){
        state.gameCompleted = action.payload
    }

    function setScoreIndicator (state, action){
        state.scoresIndicator = action.payload
    }

    function setGameCompletedStatusText(state, action){
        state.gameCompletedStatus = action.payload
    }

    function setKCTries(state, action){
        state.kcTries = action.payload
    }

}

function createExtraReducers() {
    return {
        ...getAll(),
        ...addProgress(),
        ...getProgress(),
        ...getKCTries(),
        ...getNextStep(),
        ...clearProgress(),
        ...updateProgress()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.list = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload
            },
            [rejected]: (state, action) => {
                state.list = { error: action.error };
            }
        };
    }

    function addProgress() {
        const { pending, fulfilled, rejected } = extraActions.addProgress;
        return {
            [pending]: (state) => {
                state.progress = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.progress = action.payload;
            },
            [rejected]: (state, action) => {
                state.progress = { error: action.error };
            }
        }
    }

    function clearProgress() {
        const { pending, fulfilled, rejected } = extraActions.clearProgress;
        return {
            [pending]: (state) => {
                // state.progress = { loading: true };
            },
            [fulfilled]: (state, action) => {
                // state.progress = action.payload;
            },
            [rejected]: (state, action) => {
                // state.progress = { error: action.error };
            }
        }
    }

    function updateProgress() {
        const { pending, fulfilled, rejected } = extraActions.updateProgress;
        return {
            [pending]: (state) => {
                // state.progress = { loading: true };
            },
            [fulfilled]: (state, action) => {
                // state.progress = action.payload;
            },
            [rejected]: (state, action) => {
                // state.progress = { error: action.error };
            }
        }
    }

    function getProgress() {
        const { pending, fulfilled, rejected } = extraActions.getProgress;
        return {
            [pending]: (state) => {
                state.progress = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.progress = action.payload;
            },
            [rejected]: (state, action) => {
                state.progress = { error: action.error };
            }
        }
    }

    function getKCTries() {
        const { pending, fulfilled, rejected } = extraActions.getKCTries;
        return {
            [pending]: (state) => {
                state.kcTries = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.kcTries = action.payload;
                console.log("payload", action.payload)
            },
            [rejected]: (state, action) => {
                state.kcTries = { error: action.error };
            }
        }
    }

    function getNextStep() {
        const { pending, fulfilled, rejected } = extraActions.getNextStep;
        return {
            [pending]: (state) => {
                state.nextStep = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.nextStep = action.payload;
                console.log("Next Step from progress", action.payload)

            },
            [rejected]: (state, action) => {
                state.nextStep = { error: action.error };
            }
        }
    }
}
