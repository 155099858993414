
import {Fragment, useEffect, useState} from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {saleActions} from "../_store";
import {useParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

const product = {
    name: 'Basic Tee 6-Pack ',
    price: 'ZAR 100',
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    imageSrc: 'https://via.placeholder.com/250x450',
    imageAlt: 'management game.',
    colors: [
        { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
    ],
    sizes: [
        { name: 'Game', inStock: true },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PaypalPayment() {
    const {sale} = useSelector(x => x.sales.sale)
    const [open, setOpen] = useState(true)
    const [selectedColor, setSelectedColor] = useState(product.colors[0])
    const [selectedSize, setSelectedSize] = useState(product.sizes[2])
    const [expired, setExpired] = useState(false)
    const dispatch = useDispatch()
    const {token} = useParams()

    console.log("sale", sale)

    useEffect(() => {
        dispatch(saleActions.getSaleDetails(token)).then((response)=> {
            if (response.type === "sales/getSaleDetail/rejected"){
                setExpired(true)
                toast.error("OOps, payment link expired!")
                return
            }
        })
    }, [])

    return (
        <>
            {/*<ToastContainer limit={1} />*/}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                                enterTo="opacity-100 translate-y-0 md:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            >
                                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                    <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                        <button
                                            type="button"
                                            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            {/*<XMarkIcon className="h-6 w-6" aria-hidden="true" />*/}
                                        </button>

                                        <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                                            <div className="aspect-w-2 aspect-h-3 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                                <img src={product.imageSrc} alt={product.imageAlt} className="object-cover object-center" />
                                            </div>
                                            <div className="sm:col-span-8 lg:col-span-7">
                                                <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{sale && sale.items[0].name}</h2>

                                                <section aria-labelledby="information-heading" className="mt-2">
                                                    <h3 id="information-heading" className="sr-only">
                                                        Product information
                                                    </h3>

                                                    <p className="text-2xl text-gray-900">{sale && sale.formatted_total}</p>

                                                    {/* Reviews */}
                                                    <div className="mt-6">
                                                        <h4 className="sr-only">Reviews</h4>
                                                        <div className="flex items-center">

                                                            <p className="sr-only">{product.rating} out of 5 stars</p>
                                                            <a href="#" className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                                                {product.reviewCount} reviews
                                                            </a>
                                                        </div>
                                                    </div>
                                                </section>

                                                <section aria-labelledby="options-heading" className="mt-10">
                                                    <h3 id="options-heading" className="sr-only">
                                                        Product options
                                                    </h3>

                                                    <form action="https://www.payfast.co.za/eng/process" >
                                                        {/* Colors */}
                                                        <div>
                                                            <h4 className="text-sm font-medium text-gray-900">Region</h4>

                                                        </div>

                                                        {/* Sizes */}
                                                        <div className="mt-10">
                                                            <div className="flex items-center justify-between">
                                                                <h4 className="text-sm font-medium text-gray-900">Type</h4>
                                                                {/*<a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">*/}
                                                                {/*    Size guide*/}
                                                                {/*</a>*/}
                                                            </div>

                                                            <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">
                                                                <RadioGroup.Label className="sr-only"> Choose a size </RadioGroup.Label>
                                                                <div className="grid grid-cols-4 gap-4">
                                                                    {product.sizes.map((size) => (
                                                                        <RadioGroup.Option
                                                                            key={size.name}
                                                                            value={size}
                                                                            disabled={!size.inStock}
                                                                            className={({ active }) =>
                                                                                classNames(
                                                                                    size.inStock
                                                                                        ? 'bg-white shadow-sm text-gray-900 cursor-pointer'
                                                                                        : 'bg-gray-50 text-gray-200 cursor-not-allowed',
                                                                                    active ? 'ring-2 ring-indigo-500' : '',
                                                                                    'group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                                                                )
                                                                            }
                                                                        >
                                                                            {({ active, checked }) => (
                                                                                <>
                                                                                    <RadioGroup.Label as="span">{size.name}</RadioGroup.Label>
                                                                                    {size.inStock ? (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active ? 'border' : 'border-2',
                                                                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                                                                'pointer-events-none absolute -inset-px rounded-md'
                                                                                            )}
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    ) : (
                                                                                        <span
                                                                                            aria-hidden="true"
                                                                                            className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                                                                                        >
                                            <svg
                                                className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="none"
                                                stroke="currentColor"
                                            >
                                              <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                            </svg>
                                          </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </RadioGroup.Option>
                                                                    ))}
                                                                </div>
                                                            </RadioGroup>
                                                        </div>

                                                        <button
                                                            disabled={expired}
                                                            type="submit"
                                                            className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-[#a70000] py-3 px-8 text-base font-medium text-white hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-[#a70000] focus:ring-offset-2"
                                                        >
                                                            {expired ? "Payment Link expired!" : "Make Payment" }
                                                        </button>

                                                        <input type="hidden" name="merchant_id" value="21013235" />
                                                        <input type="hidden" name="merchant_key" value="zcx350nyoedit" />
                                                        <input type="hidden" name="amount" value={sale && sale.total} />
                                                        <input type="hidden" name="item_name" value={sale && sale.items[0].name} />

                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>

    )
}
