import {useDispatch, useSelector} from 'react-redux';
import { closeModal } from '../features/modal/modalSlice';
import {promiseToast} from "../_helpers/alerts";
import {confirmModalActions, licenseActions, teamActions} from "../_store";
import {useNavigate, useSearchParams} from "react-router-dom";

const Modal = ({paramId}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {emailContent, currentTeamId} = useSelector(x => x.teams)
    const {license} = useSelector(x => x.auth.user)
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")

    const team_id = id != null ? id : currentTeamId

    const sendEmails = () =>{
      promiseToast("Sending", "Sending emails!")
      dispatch(teamActions.sendEmails(
          {
              license: license,
              team_id: team_id,
              scheduled: false,
              scheduled_at: null
          }))


      setTimeout(() => {
          const id = paramId ? paramId : license
          dispatch(confirmModalActions.closeModal())
        navigate(`/assessments/${id}`)
      },2000)
  }

  const scheduleEmails = () =>{
      dispatch(confirmModalActions.closeModal())

      dispatch(teamActions.setLicensedEmails(null))
      dispatch(teamActions.setIsCompanyInfoCompleted(false))
      dispatch(teamActions.setIsTeamInfoCompleted(false))
      dispatch(teamActions.setIsEmailInfoCompleted(false))
      dispatch(teamActions.setMemberEmailsCompleted(false))
      dispatch(licenseActions.setLicensedEmails(null))

      setTimeout(() => {
        navigate(`/assessments/add-schedule/${team_id}`)
      },500)
  }

  return (
    <aside className='modal-container'>
      <div className='small-modal'>
        <div>

          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button type="button"
                      className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      data-modal-toggle="popup-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                {/*<span className="sr-only">Close modal</span>*/}
              </button>
              <div className="p-6 text-center">
                <svg aria-hidden="true" className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" fill="none"
                     stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Start sending email to invitees</h3>
                <button
                    onClick={() => {
                        sendEmails()
                    }}
                    data-modal-toggle="popup-modal" type="button"
                        className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                  Yes, Send now
                </button>
                <button
                    onClick={() => {
                        scheduleEmails()
                    }}
                    data-modal-toggle="popup-modal" type="button"
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                  Schedule emails
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </aside>
  )
}
export default Modal;


