import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'phases';
const initialState = createInitialState();
// const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
// const slice = createSlice({ name, initialState,reducers, extraReducers });
const slice = createSlice({ name, initialState, extraReducers });

// exports
export const phaseActions = { ...slice.actions, ...extraActions };
export const phasesReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        list:  [],
        phase: {},
        phases:[]
    }
}

function createExtraActions() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/phases`;

    return {
        getAll: getAll(),
        addPhase: addPhase(),
    };


    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${url}`)
        );
    }

    function addPhase() {
        return createAsyncThunk(
            `${name}/addPhase`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

}

function createExtraReducers() {
    return {
        ...getAll(),
        ...addPhase()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.list = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload
            },
            [rejected]: (state, action) => {
                state.list = { error: action.error };
            }
        };
    }

    function addPhase() {
        const { pending, fulfilled, rejected } = extraActions.addPhase;
        return {
            [pending]: (state) => {
                state.phase = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.phase = action.payload;
            },
            [rejected]: (state, action) => {
                state.phase = { error: action.error };
            }
        };
    }

}
