import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {planActions, saleActions, subscriptionActions} from "../../../_store";
import {SuperAdminNav} from "../SuperAdminNav";
import {ToastContainer} from "react-toastify";
import ExtendExpiryDateModal from "../ExtendExpiryDateModal";
import {SingleSubscription} from "./SingleSubscription";
import { FaRegFrownOpen } from 'react-icons/fa';

function Subscriptions() {

    const dispatch = useDispatch()
    const {isExpiryDateModal} = useSelector(x => x.sales )
    const {subscriptions} = useSelector(x => x.subscriptions )
    console.log("Subscriptions", subscriptions)

    const [query, setQuery] = useState("desc")
    const [fromDateRange, setFromDateRange] = useState()
    const [toDateRange, setToDateRange] = useState()

    useEffect(() => {
        dispatch(subscriptionActions.getSubscriptions({toDate: toDateRange, fromDate: fromDateRange, order:query }))
    },[])

    return (

        <>
            <ToastContainer />

            {isExpiryDateModal && <ExtendExpiryDateModal />}

            {/*{isOpen && <Modal />}*/}
            <div className="min-h-full">
                <SuperAdminNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Subscriptions</h1>
                    </div>
                </header>

                <main className="w-full">

                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">
                        <nav className="ml-8 rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href="/super-admin/sales" className="text-grey-600">Dashboard</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">Subscriptions</li>
                            </ol>
                        </nav>
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="w-full sm:px-6">
                                    <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
                                        <div className="sm:flex items-center justify-between">
                                            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">List</p>
                                        </div>
                                    </div>



                                    <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">

                                        {subscriptions.subscriptions && subscriptions.subscriptions.length > 0 &&
                                        <>

                                            <select onChange={(e) => {
                                                setQuery(e.target.value)
                                                dispatch(subscriptionActions.getSubscriptions({order: e.target.value}))

                                            }}>
                                                <option value="asc">Ascending</option>
                                                <option value="desc">Descending</option>
                                                <option value="expired">Expired</option>
                                                <option value="not-expired">Not Expired</option>
                                            </select>

                                            <label className="label ml-5">From:</label>
                                            <input type="date" placeholder="from" className="rounded ml-5"
                                                   onChange={(e) => {
                                                       setFromDateRange(e.target.value)
                                                   }}
                                            />

                                            <label className="label ml-5 mr-5">To:</label>
                                            <input type="date" placeholder="to" className="rounded mx-auto"
                                                   onChange={(e) => {
                                                       setToDateRange(e.target.value)
                                                   }}
                                            />

                                            <input type="submit"
                                                   className="ml-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"
                                                   onClick={() => {
                                                       dispatch(subscriptionActions.getSubscriptions({toDate: toDateRange, fromDate: fromDateRange, order:query }))
                                                   }} />
                                        </>

                                        }

                                        <br/>
                                        <br/>

                                        {subscriptions.subscriptions && subscriptions.subscriptions.length === 0 &&
                                        <div className="flex flex-col items-center justify-center h-full space-y-4">
                                            <FaRegFrownOpen className="w-12 h-12 text-gray-700" />
                                            <h2 className="text-2xl font-semibold text-gray-700">No Subscriptions!</h2>
                                        </div>
                                        }

                                        {  subscriptions.subscriptions && subscriptions.subscriptions.length > 0 &&

                                            <>
                                        <input type="text" placeholder="search" className="w-1/2 rounded mx-auto"/>
                                            <table className="w-full whitespace-nowrap">
                                            <thead>
                                            <tr className="h-16 w-full text-sm leading-none text-gray-800">
                                            <th className="font-normal text-left pl-4">No.</th>
                                            <th className="font-normal text-left pl-4">Admin email</th>
                                            <th className="font-normal text-left pl-4">Product Plan</th>
                                            <th className="font-normal text-left pl-4">No. of Licenses</th>
                                            <th className="font-normal text-left pl-4">Expiry date</th>
                                            <th className="font-normal text-left pl-20">Date</th>
                                            <th className="font-normal text-left pl-16">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="w-full">

                                        {  subscriptions.subscriptions && subscriptions.subscriptions.length > 0 && subscriptions?.subscriptions.map((sub, index) =>
                                            <SingleSubscription
                                            key={sub.id}
                                            number={index+1}
                                            subscription={sub}
                                            />
                                            )}

                                            </tbody>
                                            </table>
                                        </>
                                        }


                                    </div>

                                    <br/>
                                    <br/>
                                    <br/>

                                </div>

                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </>

    );
}

export default Subscriptions
