import {useDispatch, useSelector} from "react-redux";
import {saleActions, subscriptionActions} from "../../_store";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {promiseToast} from "../../_helpers/alerts";
import {useNavigate} from "react-router-dom";

const ExtendExpiryDateModal = () => {

    const dispatch = useDispatch()
    const {subscription} = useSelector((x) => x.subscriptions)
    console.log("subscription extend ", subscription)
    const navigate = useNavigate()
    const [days, setDays] = useState(0)

    const extendExpiry = () => {
        console.log("subscription from extend expiry", subscription)
        dispatch(subscriptionActions.extend({days, id:subscription.sub_id})).then(()=> {
            toast.info("Successfully extended subscription")
            dispatch(subscriptionActions.getSubscriptions({}))
        })
    }



    return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <h4>Extend Expiry Date</h4>

                        <br/>
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Extend by how many days?
                            </label>
                            <input
                                onChange={(e) => {
                                    setDays(Number(e.target.value))
                                }}
                                name="days"
                                type="number"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                    </div>
                    <div className="bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse sm:px-6">



                        <button
                            onClick={()=> {
                                extendExpiry()

                            }}
                            type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-[#a70000] px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-[#a70000] focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            Extend date</button>


                        <button
                            onClick={()=> {
                                dispatch(saleActions.closeExtendExpiryDateModal())
                            }}
                            type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

)
}
export default ExtendExpiryDateModal


