import {useDispatch, useSelector} from "react-redux";
// import {logout} from "../features/user/userSlice";
import TeamSetup from "./TeamSetUp";
import Modal from '../Modal'
import {Nav} from "../Nav";

const EditTeam = () => {
    const dispatch = useDispatch()
    // const { isOpen } = useSelector((store) => store.modal);

    return (
        <>
            {/*{isOpen && <Modal />}*/}

            <div className="min-h-full">
                <Nav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Team Setup</h1>
                    </div>
                </header>
                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <TeamSetup edit={true} />

                            </div>
                        </div>
                    </div>
                </main>
                <br/>
                <br/>
            </div>
        </>
    )
}

export default EditTeam