import {LockClosedIcon} from "@heroicons/react/solid";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {authActions} from "../../_store";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {AdminLogin} from "./AdminLogin";
import {AssessmentLogin} from "./AssessmentLogin";
import {toast, ToastContainer} from "react-toastify";
import logo from "../../Images/logo.png";

export const SuperAdminLogin = () => {

    const dispatch = useDispatch()
    const {adminUser} = useSelector(x => x.auth)
    const auth = useSelector(x => x.auth)
    const authError = useSelector(x => x.auth.error)
    const {authorized, setAuthorized} = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        // redirect to home if already logged in
        if (adminUser && adminUser.isAdmin) {
            navigate('/super-admin/dashboard');
        }

        if (adminUser && !adminUser.isAdmin){
             toast.info("You are not authorized to access this page!")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, adminUser]);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('email is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email, password }) {
        return dispatch(authActions.adminLogin({ email, password }));
    }

    return (
       <>
           <ToastContainer />
           <div className="wrapper">
               <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                   <div className="max-w-md w-full space-y-8">
                       <div>
                           {/*<img*/}
                           {/*    className="mx-auto h-12 w-auto"*/}
                           {/*    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"*/}
                           {/*    alt="Workflow"*/}
                           {/*/>   */}

                           <img
                               className="mx-auto h-12 w-auto"
                               src={logo}
                               alt="logo"
                           />

                           {/*<img*/}
                           {/*    className="mx-auto h-12 w-auto"*/}
                           {/*    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"*/}
                           {/*    alt="Workflow"*/}
                           {/*/>*/}
                           <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Super Admin</h2>
                       </div>

                       <div>
                           <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6" action="#" method="POST">
                               {/*{error && <Error>{error}</Error>}*/}
                               <input type="hidden" name="remember" defaultValue="true"/>
                               <div className="rounded-md shadow-sm -space-y-px">
                                   <div>
                                       <label htmlFor="email-address" className="sr-only">
                                           Email address
                                       </label>
                                       <input
                                           id="email-address"
                                           name="email"
                                           type="email"
                                           autoComplete="email"
                                           required
                                           // className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                           className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#a70000] focus:border-[#a70000] focus:z-10 sm:text-sm"
                                           placeholder="Email address"
                                           {...register('email')}
                                       />
                                       <div className="invalid-feedback">{errors.email?.message}</div>

                                   </div>

                                   <div>
                                       <label htmlFor="password" className="sr-only">
                                           Password
                                       </label>
                                       <input
                                           id="password"
                                           name="password"
                                           type="password"
                                           autoComplete="current-password"
                                           required
                                           // className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                           className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-[#a70000] focus:border-[#a70000] focus:z-10 sm:text-sm"
                                           placeholder="Password"
                                           {...register('password')}
                                       />
                                       <div className="invalid-feedback">{errors.password?.message}</div>

                                   </div>

                                   <div className="text-red-900">
                                       {authError &&
                                       <div className="alert alert-danger mt-1 mb-0">{authError.message}</div>
                                       }
                                   </div>

                               </div>

                               <div className="flex items-center justify-between">
                                   <div className="flex items-center">
                                       <input
                                           id="remember-me"
                                           name="remember-me"
                                           type="checkbox"
                                           className="h-4 w-4 text-indigo-600 focus:ring-[#a70000] border-gray-300 rounded"
                                       />


                                       <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                           Remember me
                                       </label>
                                   </div>

                                   <div className="text-sm">
                                       <a href="/forgot-password" className="font-medium text-[#a70000] hover:text-indigo-500">
                                           Forgot your password?
                                       </a>
                                   </div>
                               </div>

                               <div>
                                   <button
                                       type="submit"
                                       // className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                       className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"
                                   >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                </span>
                                       Sign in
                                   </button>

                                   {/*{authError &&*/}
                                   {/*<div className="alert alert-danger mt-3 mb-0">{authError.message}</div>*/}
                                   {/*}*/}
                               </div>
                           </form>
                       </div>

                   </div>
               </div>
           </div>
       </>
    )

}