import {useDispatch, useSelector} from "react-redux";
import {SuperAdminNav} from "../SuperAdminNav";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {SpinningButton} from "../../button/Buttons";
import {planActions, saleActions, userActions} from "../../../_store";
import {toast, ToastContainer} from "react-toastify";
import {promiseToast} from "../../../_helpers/alerts";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router-dom";
import {UserInfo} from "./UserInfo";
import ConfirmSaleModal from "../ConfirmSaleModal";

const NewSale = () => {
  const dispatch = useDispatch()
  const {checkoutUser, createUser, users}    = useSelector(x => x.users)
  const {sales}    = useSelector(x => x.sales.sales)
  console.log("sales", sales)
  const {plans}    = useSelector(x => x.plans.list)
  const {isOpen}  = useSelector( x => x.sales)
  const {sale} = useSelector(x => x.sales.sale)
  const navigate = useNavigate()
  const [user, setUser] = useState({})
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [currency, setCurrency] = useState("ZAR")
  const [region, setRegion] = useState({})
  const [regionPrice, setRegionPrice] = useState({})
  const [override, setOverride] = useState(false)
  const [emailInvoice, setEmailInvoice] = useState(true)
  const [plan, setPlan] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [clientCompany, setClientCompany] = useState("")
  const {invoiceDetails} = useSelector( x => x.sales)

  const formatter =
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency
      })

  const validationSchema = Yup.object().shape({
    // user: Yup.object().required('User is required'),
    email: Yup.object().required('User is required'),
    qty: Yup.string().required('Number of licenses is required'),
  })
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, control } = useForm(formOptions);
  const { errors, isSubmitting } = formState
  const userOptions =  sales?.map((item) =>{
    return { email: item.email, value: item.user, label: `${item.email}`}
  })

  const planOptions =  plans && plans.map((item) =>{
    return { europe_price: item.price_per_person_europe,
      price: item.price_per_person,
      value: item.id,
      label: `${item.name}`}
  })

  const regionOptions =  [
    { value: "ZAR", label: "SADC"},
    { value: "EUR", label: "EUROPE"},
    { value: "USD", label: "US"},
    { value: "GHS", label: "GHANA"},
  ]

  useEffect(() => {
    dispatch(saleActions.getAll())
    dispatch(userActions.getAll())
    dispatch(planActions.getAll())
    setPaymentMethod("payfast")
  }, [])

  function onSubmit({ qty, user}) {

    const items = [{
      id: plan.value && plan.value,
      name: plan.value && plan.label,
      qty,
      email: clientEmail
    }]

    return dispatch(saleActions.addSale({items, email: clientEmail, user: user.value, emailInvoice, invoiceDetails }))
        .then((response)=> {
          console.log("response", response)
          if (response.type === "sales/addSale/rejected"){
            toast.error("OOps, problem saving plan!")
            return
          }
          promiseToast("Saving plan", "invoice successfully created!")

          setTimeout(()=> {
            navigate(0)
          },2000)
        })
  }

  return (
      <>
        <ToastContainer />

        {isOpen && <ConfirmSaleModal
            formatter={formatter}
            price={price}
            plan={plan}
            qty={quantity}
            user={user}
            emailInvoice={emailInvoice}
            paymentMode={paymentMethod}
            email={clientEmail}
        />}

        <div className="min-h-full">
          <SuperAdminNav />
          <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-gray-900">Create Invoice</h1>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

              <nav className="rounded-md w-full">
                <ol className="list-reset flex">
                  <li><a href="/super-admin/sales" className="text-blue-600 hover:text-blue-700">Invoices</a></li>
                  <li><span className="text-gray-500 mx-2">/</span></li>
                  <li className="text-gray-500">Create Invoice</li>
                </ol>
              </nav>

              <div className="px-4 py-6 sm:px-0">
                {/*<div>Payment link: </div>*/}
                <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">
                  <div className="grid grid-cols-1 gap-4">

                    {/*<UserInfo />*/}

                    <div>
                      <form  onSubmit={handleSubmit(onSubmit)}  method="POST">

                        <div className="shadow overflow-hidden sm:rounded-md">

                          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                            <div className="grid grid-cols-2">
                              <div>Buy Plan</div>
                              <div><small><i>Total:</i></small>
                                {/*<span className="  ml-2 text-red-500">GHS</span>*/}
                                <span className="ml-2 font-bold  text-red-500">{formatter.format(price*quantity)}</span>
                              </div>

                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Client's email
                              </label>

                              <input
                                  {...register("email")}
                                  type="text"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  onChange={(e)=> {
                                    setClientEmail(e.target.value)
                                  }}
                              />

                              {errors.email && <span className="text-red-500">This field is required</span>}

                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Client's company
                              </label>

                              <input
                                  {...register("company")}
                                  type="text"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  onChange={(e)=> {
                                    setClientCompany(e.target.value)
                                  }}
                              />

                              {errors.email && <span className="text-red-500">This field is required</span>}

                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="first-name" className=" mb-2 block text-sm font-medium text-gray-700">
                                Plan
                              </label>

                              <Controller
                                  {...register('plan')}
                                  name="plan"
                                  control={control}
                                  render={({ field }) => <Select
                                      {...field}
                                      options={planOptions}
                                      onChange={(value)=> {
                                        setRegionPrice(value)
                                        setPlan(value)
                                        if (currency === "ZAR"){
                                          setPrice(regionPrice.price)
                                        }else{
                                          setPrice(regionPrice.europe_price)
                                        }
                                      }}
                                  />}
                              />
                              {errors.plan && <span className="text-red-500">This field is required</span>}

                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                How many Licenses?
                              </label>
                              <input
                                  {...register("qty")}
                                  type="number"
                                  min={1}
                                  defaultValue={1}
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  onChange={(e)=> {
                                    setQuantity(Number(e.target.value))
                                  }}
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="first-name" className=" mb-2 block text-sm font-medium text-gray-700">
                                Client's region
                              </label>

                              <Controller
                                  {...register('region')}
                                  name="region"
                                  control={control}
                                  render={({ field }) => <Select
                                      {...field}
                                      options={regionOptions}
                                      onChange={(value)=> {
                                        setCurrency(value.value)
                                        if (value.value === "ZAR"){
                                          setPrice(regionPrice.price)
                                        }else if (value.value === "EUR"){
                                          setPrice(regionPrice.europe_price)
                                        }
                                      }}

                                  />}
                              />
                              {errors.region && <span className="text-red-500">This field is required</span>}

                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Product plan price
                              </label>
                              <input

                                  onChange={(e)=> {
                                    setPrice(e.target.value)
                                  }}
                                  disabled={!override}
                                  // value={price}
                                  type="number"
                                  className={`${override && 'border-2 border-black'} mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                              />
                              <br/>
                              <div>
                                <label><i> Override price? </i> </label>
                                <input checked={override} type="checkbox" {...register("override_price")} className="ml-2" onChange={ e => setOverride(!override)} />
                              </div>

                              <br/>
                              <div className="grid grid-cols-3">
                                {currency === "ZAR" &&
                                <>
                                  <div>
                                    <label><i> Payfast  </i> </label>
                                    <input  type="radio" {...register("payment_mode")}
                                            disabled
                                            checked
                                            className="ml-2 accent-pink-500 focus:accent-pink-500" value="payfast"
                                            onChange={(e) => {
                                              setPaymentMethod(e.target.value)
                                            }}
                                    />
                                  </div>

                                </>

                                }

                                {currency !== "ZAR" &&
                                <div>
                                  <label><i> Paypal </i> </label>
                                  <input type="radio" {...register("payment_mode")} className="ml-2" value="paypal"

                                         onChange={(e) => {
                                           setPaymentMethod(e.target.value)
                                         }}
                                  />
                                </div>

                                }


                              </div>

                              <br/>

                              <div>
                                <label><i> Email invoice? </i> </label>
                                <input checked={emailInvoice} type="checkbox" {...register("email_invoice")}
                                       className="ml-2 accent-pink-500 bg-[#a70000]" onChange={ e => setEmailInvoice(!emailInvoice)} />
                              </div>

                            </div>

                          </div>

                          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                onClick={() => {
                                  dispatch(saleActions.openModal())
                                }}
                                type="button"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                              Confirm
                            </button>


                          </div>
                        </div>
                      </form>

                    </div>
                  </div>

                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </main>
          <br/>
          <br/>
        </div>
      </>
  )
}

export default NewSale