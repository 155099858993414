import {useDispatch, useSelector} from 'react-redux';
import {gameActions, progressActions, questionActions, videoActions} from "../_store";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useSearchParams} from "react-router-dom"
import {useEffect, useState} from "react";
import Video from "./Video";
import CopyrightLogo from "../games/copyright-logo.jpg";

const InfoButtonModal = () => {
    const dispatch = useDispatch();
    const {currentIntroVideo} = useSelector(x => x.videos)
    const [currentMedia, setCurrentMedia] = useState("business-objective")
    const {completed,isModalOpen} = useSelector(x=> x.videos)
    const { progress, nextStep,progressOrder} = useSelector(x => x.progress)
    const validationSchema = Yup.object().shape({
        // answer: string().required('answer is required')
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit } = useForm(formOptions);

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [ ])

    function onSubmit() {

    }

    return (
        <>
            <aside className='modal-container' onLoad={() => dispatch(videoActions.setCompleted(false))}>
                <div className='large-modal'>
                    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                        <div>

                            <br/>

                            <h2 className="mb-2 underline italic font-bold">
                                {currentIntroVideo === "business-objective" &&
                                "BUSINESS OBJECTIVE VIDEO"
                                }

                                {currentIntroVideo === "business-case" &&
                                "BUSINESS CASE VIDEO"
                                }

                                {currentIntroVideo === "business-case-image" &&
                                "BUSINESS CASE SUMMARY"
                                }

                                {currentIntroVideo === "how-to-play" &&
                                "HOW TO PLAY"
                                }

                                {currentIntroVideo === "how-to-play" &&
                                // <Video url={"https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo+4+-+How+to+Play+the+Game.mp4_1080_H264"} />
                                <Video url={"https://changeverve-bucket.s3.eu-west-2.amazonaws.com/How+to+Play+the+Game+Mar+2023.mp4_1080_H264"} />
                                }

                                {/*{currentMedia === "how-to-play" &&*/}
                                {/*    <img src={"https://changeverve-bucket.s3.eu-west-2.amazonaws.com/Business-Case%5B935%5D.png"}/>*/}
                                {/*}*/}

                            </h2>

                            {currentIntroVideo === "business-objective" &&
                            <div>
                                { <Video url={"https://changeverve-bucket.s3.eu-west-2.amazonaws.com/Video-Objectives.mp4"} />}
                            </div>
                            }

                            {currentIntroVideo === "business-case" &&
                            <div>
                                { <Video url={"https://changeverve-bucket.s3.eu-west-2.amazonaws.com/Business-Case-Video.mp4"} />}
                            </div>
                            }


                            {currentIntroVideo === "business-case-image" &&
                            // <img src={"https://changeverve-bucket.s3.eu-west-2.amazonaws.com/Business-Case%5B935%5D.png"} width={`778`}/>
                            <img src={"https://changeverve-bucket.s3.eu-west-2.amazonaws.com/Business-Case%5B935%5D.png"} width={`950`}/>
                            }

                            <img src={CopyrightLogo} width={100} className="float-left mt-5"  />

                            <div className='btn-container'>

                                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                                    <button
                                        type="button"
                                        className="mr-3 inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            dispatch(gameActions.setInfoButtonModalOpened(false));
                                        }}>
                                        CLOSE
                                    </button>

                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </aside>
        </>
    )
};
export default InfoButtonModal

