import logo from "../../Images/logo.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {useLocation} from "react-router";
import {authActions} from "../../_store";

export default function ChangePassword() {
    const [password, setPassword] = useState("");
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');

    const [confirmPassword, setConfirmPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password === confirmPassword) {
            dispatch(authActions.changePassword({token, password})).then(()=> {
                navigate("/login"); // navigate to dashboard after password change
            })
        } else {
            alert("Passwords do not match");
        }
    }

    return (
        <div className="wrapper">
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <img className="mx-auto h-12 w-auto" src={logo} alt="logo" />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            Change password
                        </h2>
                    </div>
                    <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="password">Password</label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Type password"
                                />
                            </div>

                            <div>
                                <label htmlFor="confirm-password">Confirm password</label>
                                <input
                                    id="confirm-password"
                                    name="confirm-password"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Confirm password"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                                Change password
                            </button>
                        </div>
                    </form>

                    <div className="text-sm">
                        <a href="/login" className="font-medium text-[#a70000]">
                            Return to login
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
