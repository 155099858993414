import {NavLink} from "react-router-dom";
import React from "react";

export const EmptySingleStep = ({columnStepsCount, number, game_id}) => {
    return (
        <div className="border-dotted border-2 border-black w-60 h-60 rounded">
            <div className="flex items-center justify-center my-10">
                <NavLink
                    className="inline-flex  px-6 py-3 bg-gray-800 dark:bg-gray-100 rounded"
                    to={`/super-admin/new-step?column=${number}&step=${columnStepsCount+1}&game_id=${game_id}`}>
                    <p className="text-sm font-medium leading-none text-white">Add Step</p>
                </NavLink>
            </div>
        </div>
    )
}