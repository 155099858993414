import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes.js'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {questionActions} from "../../_store";
const style = {
    height: '12rem',
    width: '12rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    color: 'white',
    padding: '1rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    float: 'left',
}
let localSelectedValues = []

export const Dustbin = ({id}) => {
    const dispatch = useDispatch()
    const {selectedQuizValues} = useSelector( x => x.questions)
    const [name, setName] = useState("")
    const [hasDropped, setHasDropped] = useState(false)
    const [values, setValues] = useState({})
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.BOX,
        hover: (item, monitor) => {
            // this function is called when an item is hovered
            // you can use the 'item.id' to get the target id
            console.log(item.id);

        },
        drop(_item, monitor){
            const didDrop = monitor.didDrop()
            const dropCoordinates = monitor.getClientOffset();
            console.log("Drop coordinates monitor", dropCoordinates)

            console.log("monitor", drop)
            console.log("canDrop monitor", canDrop)
            console.log("isOver monitor", isOver)
            console.log("didDrop monitor", didDrop)
            console.log("item id",id)

            const droppedItem = monitor.getItem();
            console.log("dropped Item monitor",droppedItem);

            setName(_item.name)
            setHasDropped(true)

            setValues({
                ...values,
                name: _item.name,
                id
                // id:monitor.getHandlerId()
            })

            const index = localSelectedValues.findIndex(object => {
                return object.id === monitor.getHandlerId();
            })

            if (index !== -1) {
                localSelectedValues[index].name = _item.name;

            }else{
                localSelectedValues.push({name: _item.name, id: monitor.getHandlerId()})
            }

            // dispatch(questionActions.setSelectedValues({id: monitor.getHandlerId(), name: _item.name}))
            dispatch(questionActions.setSelectedValues({id, name: _item.name}))

        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [setHasDropped])
    const isActive = canDrop && isOver
    let backgroundColor = '#222'
    if (isActive) {
        backgroundColor = 'darkgreen'
    } else if (canDrop) {
        backgroundColor = 'darkkhaki'
    }

    console.log("values", localSelectedValues)
    console.log("selected quiz values", selectedQuizValues)

    return (
        <>
            {hasDropped &&
            <div
                className="text-sm flex justify-center items-center"
                ref={drop} data-testid={`dustbin`} style={{ height:`100px`,
                backgroundColor: `#a70000`, color:`#fff` }}>
                {name}
            </div>
            }

            {!hasDropped &&
            <div className="flex justify-center items-center" data-testid="dustbin" ref={drop}
                 style={{ textAlign: `center`,  height: `100px`, padding: "6px", border: `2px dotted #000`}}>
                <span style={{textAlign: `center`}}>{hasDropped ? <span>{name}</span> : "Drag your answer here"}</span>
            </div>
            }
        </>
    )
}
