import * as Yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import {Controller, useForm} from "react-hook-form"
import {SpinningButton} from "../button/Buttons"
import {ModalAlertDark} from "../Alert/ModalAlert"
import {toast} from "react-toastify"
import {promiseToast} from "../../_helpers/alerts"
import Select from "react-select";
import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {teamActions, industryActions, functionActions} from '../../_store';

export const CompanyInfo = () => {
    const dispatch = useDispatch();

    const user = useSelector( x => x.auth)
    const teams = useSelector(x => x.teams );
    const industries = useSelector(x => x.industries.industries.data)
    const functions  = useSelector(x=> x.functions.functions.data)
    const teamError = useSelector(x => x.error);

    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required('company name is required'),
        company_size: Yup.string().required('company size is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(()=>{
        dispatch(industryActions.getAll())
        dispatch(functionActions.getAll())
    }, [])

    const industryOptions = useMemo(() => industries?.map((item) => ({ value: item.id, label: item.name})), [industries])

    const functionOptions = useMemo(() => functions?.map((item) => ({ value: item.id, label: item.name })), [functions])

    const onSubmit = ({ company_name, company_industry, company_function, company_size }) => {
        const companyObject = ({
            company_name,
            industry: company_industry.value,
            company_function,
            company_size
        })
        console.log("company object", companyObject)
        dispatch(teamActions.setCompanyObject(companyObject))
        console.log("company object 2", teams)

        dispatch(teamActions.setIsCompanyInfoCompleted(true))
        return promiseToast("Saving company details", "Successfully added your company!")

    }

    const displayModal = false

    return (
        <>
            {
                displayModal &&
                <ModalAlertDark message="Are you sure?"
                                description="You will only be able to edit after saving Team and Email section" />
            }

            { !teams.companyInfoCompleted &&
            <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={handleSubmit(onSubmit)} method="POST">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                            <div className="col-span-6">
                                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                    Name
                                </label>
                                <input
                                    placeholder="Type  company name"
                                    disabled={teams.companyInfoCompleted}
                                    type="text"
                                    name="name"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    {...register('company_name')}
                                />
                            </div>

                            <div className="col-span-6">
                                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                    Industry
                                </label>

                                <Controller
                                    {...register('company_industry')}
                                    name="company_industry"
                                    control={control}
                                    render={({ field }) => <Select
                                        {...field}
                                        options={industryOptions}
                                    />}
                                />
                            </div>

                            <div className="col-span-6">
                                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                    Function
                                </label>

                                <Controller
                                    {...register('company_function')}
                                    name="company_function"
                                    control={control}
                                    render={({ field }) => <Select
                                        {...field}
                                        options={functionOptions}
                                    />}
                                />

                            </div>

                            <div className="col-span-6">
                                <label htmlFor="companySize" className="block text-sm font-medium text-gray-700">
                                    Company Size
                                </label>
                                <select
                                    disabled={teams.companyInfoCompleted}
                                    {...register('company_size')}
                                    name="companySize"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    <option>100 - 499</option>
                                    <option>500 - 999</option>
                                    <option>1,000 - 2,499</option>
                                    <option>3,000 - over</option>
                                </select>
                            </div>

                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                            <button
                                disabled={teams.companyInfoCompleted}
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                                {isSubmitting ? <SpinningButton />
                                    : teams.companyInfoCompleted ? "Saved" : "Save" }
                            </button>

                            {teamError &&
                            <div className="alert alert-danger mt-3 mb-0">{teamError.message}</div>
                            }
                        </div>
                    </div>
                </form>
            </div>
            }

        </>

    )
}