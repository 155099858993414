import {  createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'confirm-modal';
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports
export const confirmModalActions = { ...slice.actions };
export const confirmModalReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        isOpen: false
    }
}

function createReducers() {
    return {
        openModal,
        closeModal
    };

    function openModal (state, action){
        state.isOpen = true;
    }

    function closeModal (state, action){
        state.isOpen = false;
    }
}

