import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice
const name = 'sales';
const initialState = createInitialState();
const extraActions = createExtraActions();
const reducers = createReducers();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const saleActions = { ...slice.actions, ...extraActions };
export const salesReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        sale: {},
        sales: {},
        checkout: null,
        createSale: false,
        isOpen: false,
        isExpiryDateModal: false,
        invoiceDetails: {},
        verified: false
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/checkout`;

    return {
        getAll: getAll(),
        getSale: getSale(),
        getSaleDetails: getSaleDetails(),
        regenerateLink: regenerateLink(),
        addSale: addSale(),
        addPaypalSale: addPaypalSale(),
        checkTransactionStatus: checkTransactionStatus(),

    };

    function getAll() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/sales`;
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(baseUrl)
        );
    }

    function getSale() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/sales`;
        return createAsyncThunk(
            `${name}/getSale`,
            async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }

    function getSaleDetails() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/sales/details`;
        return createAsyncThunk(
            `${name}/getSaleDetail`,
            async (token) => await fetchWrapper.get(`${baseUrl}/${token}`)
        );
    }

    function regenerateLink() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/sales/details/regenerate-link`;
        return createAsyncThunk(
            `${name}/regenerateLink`,
            async (token) => await fetchWrapper.post(`${baseUrl}/${token}`)
        );
    }

    function addSale() {
        return createAsyncThunk(
            `${name}/addSale`,
            async (body) => await fetchWrapper.post(baseUrl,body)
        )
    }

    function addPaypalSale() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/pay`;

        return createAsyncThunk(
            `${name}/addPaypalSale`,
            async (body) => await fetchWrapper.post(baseUrl,body)
        )
    }

    function checkTransactionStatus() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/sales/check`;
        return createAsyncThunk(
            `${name}/checkTransactionStatus`,
            async (transaction_id) => await fetchWrapper.get(`${baseUrl}/${transaction_id}`)
        );
    }
}



function createReducers() {
    return {
        setCheckoutSale,
        setCreateSale,
        openModal,
        closeModal,
        openExtendExpiryDateModal,
        closeExtendExpiryDateModal,
        setInvoiceDetails

    }

    function setCheckoutSale(state, action){
        state.checkoutSale = action.payload
    }

    function setCreateSale(state, action){
        state.createSale = action.payload
    }

    function openModal (state, action){
        state.isOpen = true;
    }

    function closeModal (state, action){
        state.isOpen = false;
    }

    function openExtendExpiryDateModal (state, action){
        state.isExpiryDateModal = true;
    }

    function closeExtendExpiryDateModal (state, action){
        state.isExpiryDateModal = false;
    }

    function setInvoiceDetails (state, action){
        state.invoiceDetails = action.payload;
    }

}

function createExtraReducers() {
    return {
        ...getAll(),
        ...getSale(),
        ...getSaleDetails(),
        ...addSale(),
        ...addPaypalSale(),
        ...regenerateLink(),
        ...getTransactionStatus()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.sales = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.sales = action.payload;
            },
            [rejected]: (state, action) => {
                state.sales = { error: action.error };
            }
        };
    }

    function getSale() {
        const { pending, fulfilled, rejected } = extraActions.getSale;
        return {
            [pending]: (state) => {
                state.sale = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.sale = action.payload;
            },
            [rejected]: (state, action) => {
                state.sale = { error: action.error };
            }
        };
    }

    function getSaleDetails() {
        const { pending, fulfilled, rejected } = extraActions.getSaleDetails;
        return {
            [pending]: (state) => {
                state.sale = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.sale = action.payload;
            },
            [rejected]: (state, action) => {
                state.sale = { error: action.error };
            }
        };
    }

    function regenerateLink() {
        const { pending, fulfilled, rejected } = extraActions.regenerateLink;
        return {
            [pending]: (state) => {
                state.sale = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.sale = action.payload;
            },
            [rejected]: (state, action) => {
                state.sale = { error: action.error };
            }
        };
    }

    function addSale() {
        const { pending, fulfilled, rejected } = extraActions.addSale;
        return {
            [pending]: (state) => {
                state.sale = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.sale = action.payload;
            },
            [rejected]: (state, action) => {
                state.sale = { error: action.error };
            }
        };
    }

    function addPaypalSale() {
        const { pending, fulfilled, rejected } = extraActions.addPaypalSale;
        return {
            [pending]: (state) => {
                state.sale = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.sale = action.payload;
            },
            [rejected]: (state, action) => {
                state.sale = { error: action.error };
            }
        };
    }

    function getTransactionStatus() {
        const { pending, fulfilled, rejected } = extraActions.checkTransactionStatus;
        return {
            [pending]: (state) => {
                state.verified = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.verified = action.payload;
            },
            [rejected]: (state, action) => {
                state.verified = { error: action.error };
            }
        };
    }

}
