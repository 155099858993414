import {Navigate, Outlet} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '../../_helpers';

export { AdminPrivateRoute };

function AdminPrivateRoute({ children }) {
    const { adminUser } = useSelector(x => x.auth);

    console.log("auth user", adminUser)

    if ( !adminUser || !adminUser.isAdmin) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/admin" state={{ from: history.location }} />
    }

    return <Outlet />
}