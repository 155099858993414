import React, { useEffect, useState } from "react";
import { authActions, teamActions } from "../_store";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { promiseToast } from "../_helpers/alerts";
import Pagination from "react-js-pagination";
import * as XLSX from "xlsx";

export const CoordinatorTeamUsers = () => {
    const dispatch = useDispatch()
    const teams = useSelector(x => x.teams );
    const teamList = teams?.teamMembers?.data
    const params = useParams();
    const [resend, setResent] = useState(false)
    const [activePage, setActivePage] = useState(1);
    const itemsCountPerPage = 10;
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);

    const [searchTerm, setSearchTerm] = useState('');

    const getPercentage = (team) => {
        return  ( Number(team.order/52 * 100))
    }

    useEffect(() => {
        dispatch(teamActions.getTeamMembers(params.team_id));
    }, []);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };


    const filterTeamList = () => {
        return teamList?.filter((user) => {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();

            switch (searchCriteria) {
                case 'invited':
                    return user.isInvited === (searchTerm === 'true');
                case 'started':
                    return user.isStarted === (searchTerm === 'true');
                case 'completed':
                    return user.completed === (searchTerm === 'true');
                case 'progress':
                    return getPercentage(user).toString().toLowerCase().includes(lowerCaseSearchTerm);
                case 'email':
                default:
                    return user.email.toLowerCase().includes(lowerCaseSearchTerm);
            }
        });
    };


    const [searchCriteria, setSearchCriteria] = useState('email');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchCriteria = (e) => {
        setSearchCriteria(e.target.value);
    };

    const paginatedTeamList = () => {
        const filteredList = filterTeamList();
        const start = (activePage - 1) * itemsCountPerPage;
        const end = start + itemsCountPerPage;
        return filteredList?.slice(start, end);
    };

    const [page, setPage] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(teamActions.getTeamMembers(params.team_id,page));
            setLoading(false);
        };
        fetchData();
    }, [page]);

    const downloadResults = (user_id, license_id, game_id) => {
        const baseURL =
            window.location.hostname === 'localhost'
                ? `http://localhost:5001`
                : 'https://backend.changeverveacademy.com';

        const url = `${baseURL}/api/v1/download-results?user_id=${user_id}&license_id=${license_id}&game_id=${game_id}`;
        window.location.href = url;
    };

    const resendEmail = (team_id, license_id, user_id) => {
        promiseToast('sending', 'Successfully sent!')
        dispatch(teamActions.resendEmail({team_id, license_id, user_id}))
        setResent(true)
        alert("Successfully resent email!")
    }

    const exportToExcel = () => {
        const fileName = "team_members.xlsx";

        const filteredTeamList = filterTeamList().map((user) => {
            return {
                email: user.email,
                coordinator: user.contact_email,
                invited : user.isInvited ? "Yes" : "No",
                started: user.isStarted ? "Yes" : "No",
                completed: user.completed ? "Yes" : "No",
                date: user.created_at,
                EE_score: actual_ee_score(user),
                ET_score: actual_et_score(user),
                budget_score: actual_budget_score(user),
                percentage: getPercentage(user),
            };
        });

        const ws = XLSX.utils.json_to_sheet(filteredTeamList);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Team Members");
        XLSX.writeFile(wb, fileName);
    };

    const actual_ee_score = (team) => team?.actual_ee_score !== undefined ? 100 + team.actual_ee_score : 100;
    const actual_et_score = (team) =>team?.actual_et_score !== undefined ? 100 + team.actual_et_score : 100;
    const actual_budget_score = (team) => team?.actual_budget_score !== undefined ? 100 + team.actual_budget_score : 100;
    return (

        loading ? (
                <div className="flex justify-center items-center min-h-screen">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>

                    <div className="min-h-full">
                        <header className="bg-white shadow">
                            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                                <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
                                <a
                                    onClick={() => {
                                        dispatch(authActions.logout())
                                    }}
                                    href="#" className="block px-4 py-2  font-bold float-right mt-0 pt-0 "
                                    role="menuitem" tabIndex="-1" id="user-menu-item-2">Sign out</a>

                            </div>
                        </header>

                        <main>
                            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

                                <nav className="ml-8 rounded-md w-full">
                                    <ol className="list-reset flex">
                                        <a
                                            onClick={() => navigate(-1)}
                                            className="text-[#a70000] hover:text-blue-700 cursor-pointer">
                                            Dashboard
                                        </a>
                                        <li><span className="text-gray-500 mx-2">/</span></li>
                                        <li className="text-gray-500">Team Members</li>
                                    </ol>
                                </nav>

                                <div className="px-4 py-6 sm:px-0">
                                    <h1 className="text-4xl">Members</h1>
                                    <br/>

                                    <br />

                                    {teamList && teamList.length > 0 ? (

                                    <div className=" p-6">

                                        <button
                                            onClick={exportToExcel}
                                            className="bg-[#a70000] text-white font-bold py-2 px-4 rounded"
                                        >
                                            Export to Excel
                                        </button>

                                        <p className={"pb-2"}></p>

                                        <div className="flex items-center">
                                            <select
                                                className="w-40 py-2 px-4 mr-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-200 focus:border-transparent shadow-md"
                                                value={searchCriteria}
                                                onChange={handleSearchCriteria}
                                            >
                                                <option value="email">Email</option>
                                                <option value="invited">Invited</option>
                                                <option value="started">Started</option>
                                                <option value="completed">Completed</option>
                                                <option value="progress">Progress</option>
                                            </select>

                                            {searchCriteria === 'invited' || searchCriteria === 'started' || searchCriteria === 'completed' ? (
                                                <div className="flex items-center">
                                                    <input
                                                        id="searchCheckbox"
                                                        type="checkbox"
                                                        className="hidden"
                                                        checked={searchTerm === 'true'}
                                                        onChange={(e) => setSearchTerm(e.target.checked ? 'true' : 'false')}
                                                    />
                                                    <label htmlFor="searchCheckbox" className="flex items-center cursor-pointer">
        <span className="w-5 h-5 inline-block mr-2 border-2 rounded border-gray-300">
          {searchTerm === 'true' && (
              <svg className="fill-current w-4 h-4 text-blue-600" viewBox="0 0 20 20">
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
              </svg>
          )}
        </span>
                                                        <span className="text-gray-700">{searchCriteria}</span>
                                                    </label>
                                                </div>
                                            ) : (
                                                <input
                                                    type={searchCriteria === 'progress' ? 'number' : 'text'}
                                                    className="w-full py-2 px-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-200 focus:border-transparent shadow-md"
                                                    placeholder={`Search by ${searchCriteria}`}
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            )}
                                        </div>



                                        <br/>
                                        <br/>
                                        <div className="w-full flex flex-col">
                                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div className="shadow-md overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-slate-800">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                                                                >
                                                                    #
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                                                    Email
                                                                </th>

                                                                <th scope="col" className="px-6 py-3 text-left text-xs font medium text-white uppercase tracking-wider">
                                                                    Invited
                                                                </th>

                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                                                    Started
                                                                </th>

                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                                                    Completed
                                                                </th>

                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                                                    Date Created
                                                                </th>

                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                                                    Progress
                                                                </th>

                                                                {/*<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">*/}
                                                                {/*    Scores*/}
                                                                {/*</th>*/}

                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                                                                >
                                                                    Action
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                            {paginatedTeamList()?.map((user,index) => (

                                                                <tr key={user.id}>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                        {index + 1}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        {user.email}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        {user.isInvited ? "Yes": "No"}

                                                                    </td>

                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        {user.isStarted ? "Yes": "No"}

                                                                    </td>

                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        {user.completed ? "Yes": "No"}

                                                                    </td>

                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        {user.created_at}
                                                                    </td>

                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        {getPercentage(user) + "%"}
                                                                    </td>

                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div>
                                                                            <a
                                                                                onClick={() => {
                                                                                    resendEmail(user?.id, user?.userLicense, user?.user )
                                                                                } }
                                                                                className="focus:outline-none pr-15 cursor-pointer">
                                                                                Resend invitation
                                                                            </a>
                                                                        </div>

                                                                        {user.completed &&
                                                                        <div className="mt-3">
                                                                            <a
                                                                                onClick={() => {
                                                                                    if(user.completed){
                                                                                        downloadResults(user.user,user.userLicense, user.game)
                                                                                    }
                                                                                }}
                                                                                className="focus:outline-none pr-15 cursor-pointer">
                                                                                Download results
                                                                            </a>
                                                                        </div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={teamList?.length || 0}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                itemClass="inline-block mx-1"
                                                linkClass="block w-8 h-8 leading-8 text-center text-gray-600 border border-gray-300 rounded hover:bg-gray-200 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#a70000] focus:border-transparent"
                                                activeLinkClass="bg-[#a70000] text-white border-transparent"
                                            />
                                        </div>
                                    </div>
                                    ) : (

                                        <div className="flex flex-col items-center justify-center mt-10 space-y-4">
                                            <div className="flex items-center space-x-2">
                                                <svg
                                                    className="w-8 h-8 text-[#a70000]"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4 4a2 2 0 0 1 2-2h4.586A2 2 0 0 1 12 2.586L15.414 6A2 2 0 0 1 16 8.414V16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8.414a1 1 0 0 0-.293-.707L12.293 3.293A1 1 0 0 0 11.586 3H8z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                <p className="text-xl font-semibold text-gray-800">No team members yet!</p>
                                            </div>
                                            <a
                                                href={`/coordinator-team-setup/${params.team_id}`}
                                                className="inline-flex items-center px-4 py-2 bg-[#a70000] border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-[#990000] focus:outline-none focus:ring-2 focus:ring-[#a70000] focus:ring-opacity-50"
                                            >
                                                <svg
                                                    className="w-5 h-5 mr-2"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm3-11a1 1 0 1 1-2 0V7H9a1 1 0 1 1 0-2h2V4a1 1 0 1 1 2 0v1h2a1 1 0 1 1 0 2h-2v1z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                Add New
                                            </a>
                                        </div>

                                    )}
                                </div>


                            </div>
                        </main>
                    </div>

                    <div className="flex justify-center mt-8">
                        <div className="flex justify-center mt-8">
                        </div>
                    </div>
                </>
            )

    );
};
