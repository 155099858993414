import React, {useEffect} from "react";
import './index.css';
import Login from "./components/auth/Login";
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import ForgotPassword from "./components/auth/ForgotPassword";
import Dashboard from "./components/Dashboard";
import AssessmentIntroPage from "./components/AssessmentIntroPage";
import {PrivateRoute} from "./components/team/PrivateRoute";
import NewTeam from "./components/team/NewTeam";
import Assessments from "./components/Assessments";
import {Videos} from "./components/Videos";
import TeamAssessment from "./components/team/TeamAssessment";
import ProfilePage from "./components/ProfilePage";
import Game from "./components/Game";
import {StoreHome} from "./components/StoreHome";
import {AddEmail} from "./components/team/AddEmail";
import EditTeam from "./components/team/EditTeam";
import {SuperAdminLogin} from "./components/auth/SuperAdminLogin";
import {AdminPrivateRoute} from "./components/team/AdminPrivateRoute";
import SuperAdminDashboard from "./components/superAdmin/SuperAdminDashboard";
import Games from "./components/superAdmin/Games/Games";
import Steps from "./components/superAdmin/Steps";
import Plans from "./components/superAdmin/Plans";
import NewPlan from "./components/superAdmin/NewPlan";
import NewGame from "./components/superAdmin/Games/NewGame";
import NewStep from "./components/superAdmin/Steps/NewStep";
import NewPhase from "./components/superAdmin/Phase/NewPhase";
import {AddSchedule} from "./components/team/AddSchedule";
import NewQuestion from "./components/superAdmin/Questions/NewQuestion";
import Questions from "./components/superAdmin/Questions/Questions";
import NewVideo from "./components/superAdmin/NewVideo";
import Products from "./components/Products";
import NewSale from "./components/superAdmin/Sales/NewSale";
import Sales from "./components/superAdmin/Sales/Sales";
import AppLogout from "./Utilities/AppLogout";
import {ProductsIndex} from "./components/Product/ProductsIndex";
import PaymentPage from "./components/PaymentPage";
import Subscriptions from "./components/superAdmin/Subscriptions/Subscriptions";
import {AssessmentTeams} from "./components/team/AssessmentTeams";
import PaypalPayment from "./components/PaypalPayment";
import EditStep from "./components/superAdmin/Steps/EditStep";
import Interactive from "./components/superAdmin/Interactive";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {List} from "./components/List";
import {SubGame} from "./components/Subgame/matchingGameDeprecated/SubGame";
import {BoxExample} from "./components/Subgame/BoxExample";
import EditQuestion from "./components/superAdmin/Questions/EditQuestion";
import SubGames from "./components/superAdmin/SubGames/Games";
import NewSubGame from "./components/superAdmin/SubGames/NewSubGame";
import EditGame from "./components/superAdmin/SubGames/EditGame";
import {EmailRegister} from "./components/auth/EmailRegister";
import ChangePassword from "./components/auth/ChangePassword";
import {CoordinatorDashboard} from "./components/CoordinatorDashboard";
import {CoordinatorTeamSetUp} from "./components/CoordinatorTeamSetUp";
import {CoordinatorTeamUsers} from "./components/CoordinatorTeamUsers";
import ReturnURLPage from "./components/ReturnURLPage";
import CancelURLPage from "./components/CancelURLPage";
import NotFoundPage from "./components/NotFoundPage";
import TransactionStatusPage from "./components/TransactionStatusPage";



function App() {


  return (
      <Router>
          <Routes>
              <Route path="/" element={<Login/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/invitee-register/:user" element={<EmailRegister/>}/>
              <Route path="/store" element={<StoreHome/>}/>
              <Route exact path="/admin" element={<SuperAdminLogin/>}/>
              <Route exact path="/card-payments/:token" element={<PaymentPage/>}/>
              <Route exact path="/paypal-payments/:token" element={<PaypalPayment/>}/>
              <Route exact path="/sub-game" element={<SubGame />}/>
              <Route exact path="/box-example" element={<BoxExample />}/>
              <Route exact path="/return-url/:token" element={<ReturnURLPage />}/>
              <Route exact path="/cancel-url" element={<CancelURLPage />}/>
              <Route exact path="/transaction-status" element={<TransactionStatusPage />}/>

              {/*<DndProvider backend={HTML5Backend}>*/}

              <Route element={<AdminPrivateRoute />}>
                  <Route path="/super-admin/dashboard" element={<SuperAdminDashboard/>}/>
                  <Route path="/super-admin/new-sale" element={<NewSale/>}/>
                  <Route path="/super-admin/sales" element={<Sales />}/>
                  <Route path="/super-admin/subscriptions" element={<Subscriptions />}/>
                  <Route path="/super-admin/plans" element={<Plans/>}/>
                  <Route path="/super-admin/videos" element={<Videos/>}/>
                  <Route path="/super-admin/media" element={<Videos/>}/>
                  <Route path="/super-admin/new-video" element={<NewVideo/>}/>
                  <Route path="/super-admin/new-media" element={<NewVideo/>}/>
                  <Route path="/super-admin/new-plan" element={<NewPlan/>}/>
                  <Route path="/super-admin/questions" element={<Questions/>}/>
                  <Route path="/super-admin/edit-question/:id" element={<EditQuestion/>}/>
                  <Route path="/super-admin/new-question" element={<NewQuestion/>}/>
                  <Route path="/super-admin/games" element={<Games/>}/>
                  <Route path="/super-admin/new-game" element={<NewGame/>}/>
                  <Route path="/super-admin/steps/:id" element={<Steps/>}/>
                  <Route path="/super-admin/new-step" element={<NewStep/>}/>
                  <Route path="/super-admin/edit-step/:id" element={<EditStep/>}/>
                  <Route path="/super-admin/sub-games" element={<SubGames/>}/>
                  <Route path="/super-admin/new-sub-game" element={<NewSubGame/>}/>
                  <Route path="/super-admin/edit-sub-game/:id" element={<EditGame/>}/>

                  {/*<Route path="/super-admin/edit-step/:id" element={<EditStep/>}/>*/}
                  <Route path="/super-admin/new-phase" element={<NewPhase/>}/>
                      {/* Here, render a component that uses DND inside it */}
                      <Route path="/super-admin/interactive" element={<List/>}/>

              </Route>

              {/*</DndProvider>*/}

              <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
              <Route exact path="/change-password" element={<ChangePassword/>}/>


              <Route element={<PrivateRoute />}>
                  <Route exact path="/coordinator-dashboard/:id" element={<CoordinatorDashboard/>}/>
                  <Route exact path="/assessments/:id" element={<Assessments />} />
                  <Route path='/products' element={<ProductsIndex />} />
                  <Route exact path="/coordinator-team-setup/:team_id" element={<CoordinatorTeamSetUp/>}/>
                  <Route exact path="/coordinator-team-users/:team_id" element={<CoordinatorTeamUsers/>}/>
                  <Route exact path="/assessment-intro" element={<AssessmentIntroPage/>}/>
                  <Route exact path="/assessment/teams" element={<AssessmentTeams/>}/>
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/dashboard/:id' element={<Dashboard />} />
                  <Route path='/profile' element={<ProfilePage />} />
                  {/*<Route path='/new-team' element={<NewTeam />} />*/}
                  <Route path='/new-team/:license_id' element={<NewTeam />} />
                  <Route path='/edit-team/:id' element={<EditTeam />} />
                  {/*<Route path='/assessments' element={<TeamAssessment />} />*/}
                  <Route path='/assessments/add-email/:license_id' element={<AddEmail />} />
                  <Route path='/assessments/add-schedule/:id' element={<AddSchedule />} />
                  <Route path='/game-board/:id' element={<Game />} />
                  <Route exact path="/assessments/members/:id" element={<TeamAssessment />} />
                  {/*<Route exact path="/assessments/:id" element={<TeamAssessment />} />*/}
                  <Route path='/videos' element={<Videos />} />
                  <Route path='/videos' element={<Videos />} />
                  <Route path='/videos' element={<Videos />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
          </Routes>
      </Router>
  );
}

export default App;
