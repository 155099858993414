import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {gameActions, questionActions, videoActions} from "../../../_store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../../_helpers/alerts";
import {EditorState, convertToRaw} from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, {useEffect, useState} from "react";
import {SpinningButton} from "../../button/Buttons";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
// import { convertToHTML } from 'draft-convert';


export const GameInfo = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {videos} = useSelector(x => x.videos.list)

    const videoOptions = videos && videos
        .map((item) =>{
            return { value: item.id, label: item.name}
        })

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    )
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        no_of_phases: Yup.string().required('Phase count is required'),
        no_of_rows: Yup.string().required('Row count is required'),
        no_of_columns: Yup.string().required('Column count is required'),
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState

        const onSubmit = async ({name, description, instruction, no_of_phases, no_of_rows, no_of_columns, media, enabled}) => {
            return dispatch(gameActions.addGame({
                name, description, instruction, no_of_phases, no_of_rows, no_of_columns, media, enabled
            })).then((response) => {
                if (response.type === "games/addGame/rejected"){
                    toast.error("OOps!, Problem saving game!")
                    return
                }
                promiseToast("Saving game", "Added a new game!")

                setTimeout(() => {
                    navigate("/super-admin/games")
                }, 2000)
            })
        }

        useEffect(() => {
            dispatch(videoActions.getAll())
        }, [])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}  method="POST" encType="multipart/form-data">
                <div className="shadow overflow-hidden sm:rounded-md">

                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <input
                                {...register("name")}
                                name="name"
                                type="text"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <textarea
                                rows="4"
                                name={"description"}
                                {...register("description")}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Instruction
                            </label>
                            <div>

                            </div>
                            <textarea
                                rows="5"
                                name={"instruction"}
                                {...register("instruction")}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="grid grid-cols-3 gap-1">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    No. of phases
                                </label>
                                <div>

                                </div>
                                <input
                                    type="number"
                                    name={"no_of_phases"}
                                    {...register("no_of_phases")}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.no_of_phases && <span className="text-red-500">Number of phases is required!</span>}
                            </div>

                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    No. of rows
                                </label>
                                <div>

                                </div>
                                <input
                                    type="number"
                                    name={"no_of_rows"}
                                    {...register("no_of_rows")}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.no_of_rows && <span className="text-red-500">Row count is required!</span>}

                            </div>

                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    No. of columns
                                </label>
                                <div>

                                </div>
                                <input
                                    type="number"
                                    name={"no_of_columns"}
                                    {...register("no_of_columns")}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.no_of_columns && <span className="text-red-500">Column count is required!</span>}

                            </div>

                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Attach game board design (<small><i>You can add media on Media page</i></small>)
                            </label>
                            <Controller
                                {...register('media')}
                                name="media"
                                control={control}
                                render={({ field }) => <Select
                                    {...field}
                                    options={videoOptions}
                                />}
                            />

                            {errors.media && <span className="text-red-500">This field is required</span>}

                        </div>


                        {/*<div className="col-span-6 sm:col-span-3">*/}
                        {/*    <label htmlFor="name" className="block text-sm font-medium text-gray-700">*/}
                        {/*        Instructions*/}
                        {/*    </label>*/}

                        {/*<div className="border-2 border-gray-200">*/}
                        {/*    <Editor*/}
                        {/*        editorState={editorState}*/}
                        {/*        onEditorStateChange={setEditorState}*/}
                        {/*        toolbarClassName="toolbarClassName"*/}
                        {/*        wrapperClassName="wrapperClassName"*/}
                        {/*        editorClassName="editorClassName"*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*</div>*/}


                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Enabled
                            </label>

                            <select
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                name={"enabled"}
                                {...register("enabled")}
                            >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </select>

                        </div>

                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                            {isSubmitting ? <SpinningButton />  : "Add Game" }
                        </button>

                            {/*<button*/}
                            {/*    type="submit"*/}
                            {/*    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*/}
                            {/*    Add Game*/}
                            {/*</button>*/}
                    </div>
                </div>
            </form>

        </>
    )
}