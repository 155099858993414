import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {gameActions, planActions, modalActions, phaseActions} from "../../../_store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../../_helpers/alerts";
import {useNavigate, useParams} from "react-router";
import {useEffect} from "react";


export const PhaseInfo = () => {
    const dispatch = useDispatch();

    // const user = useSelector( x => x.auth)
    // const teams = useSelector(x => x.teams )
    // const teamError = useSelector(x => x.error)
    const games = useSelector(x => x.games );
    const gameList = games.list.games
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        description: Yup.string().required('Description is required'),
        background: Yup.string().required('Background is required'),
        order: Yup.string().required('Order is required'),
        // game: Yup.string().required('Game is required'),
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ name, description, background, order }) {

        return dispatch(phaseActions.addPhase({ name,description, background, order }))
            .then((response)=> {
                if (response.type === "phases/addPhase/rejected"){
                    toast.error("OOps!, Problem saving phase!")
                    return
                }
                promiseToast("Saving phase", "You have Successfully added a new phase!")

                setTimeout(() => {
                    navigate("/super-admin/games")
                }, 2000)

            });
    }

    const gameOptions = gameList && gameList.map((item) =>{
        return { value: item.id, label: item.name}
    })

    useEffect(() => {
        dispatch(gameActions.getAll())
    },[])

    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)}  method="POST">

                <div className="shadow overflow-hidden sm:rounded-md">

                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <input
                                {...register("name")}
                                type="text"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <textarea
                                {...register("description")}
                                 name={"description"}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Background
                            </label>
                            <select
                                {...register("background")}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">

                                <option value={"Board-Design-1"}>Board Design 1</option>
                                <option value={"Board-Design-2"}>Board Design 2</option>
                                <option value={"Board-Design-3"}>Board Design 3</option>
                            </select>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Order
                            </label>
                            <input
                                name="order"
                                {...register("order")}
                                type="number"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Add Phase
                        </button>
                    </div>
                </div>
            </form>

        </>
    )
}