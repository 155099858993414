import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice
const name = 'subgames';
const initialState = createInitialState();
const extraActions = createExtraActions();
const reducers = createReducers();
const extraReducers = createExtraReducers();

const slice = createSlice({ name, initialState,reducers, extraReducers });

// exports
export const subgameActions = { ...slice.actions, ...extraActions };
export const subgamesReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        subgames: {},
        subgame: {},

    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/sub-games`;

    return {
        getAll: getAll(),
        addSubGame: addSubGame(),
        getSubGame: getSubGame(),
    }

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(baseUrl)
        );
    }

    function addSubGame() {
        return createAsyncThunk(
            `${name}/addSubGame`,
            async (body) => await fetchWrapper.post(baseUrl,body)
        );
    }

    function getSubGame() {
        return createAsyncThunk(
            `${name}/getSubGame`,
            async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }
}

function createReducers() {
    return {
        setAnswer,
        setQuestion,
        setCorrect,
        openModal,
        closeModal
    };

    function setAnswer (state, action){
        state.answer = action.payload;
    }

    function openModal (state, action){
        state.isModalOpen = true
    }

    function closeModal (state, action){
        state.isModalOpen = false
    }

    function setQuestion (state, action){
        localStorage.setItem("question", action.payload )
    }

    function setCorrect(state, action) {
        state.isCorrect = action.payload
    }

}

function createExtraReducers() {
    return {
        ...getAll(),
        ...addSubGame(),
        ...getSubGame(),
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.subgames = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subgames = action.payload;
            },
            [rejected]: (state, action) => {
                state.subgames = { error: action.error };
                console.log("questions error", action.error )
            }
        };
    }

    function addSubGame() {
        const { pending, fulfilled, rejected } = extraActions.addSubGame;
        return {
            [pending]: (state) => {
                state.subgame = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subgame = action.payload;
            },
            [rejected]: (state, action) => {
                state.subgame = { error: action.error };
                console.log("questions error", action.error )
            }
        };
    }

    function getSubGame() {
        const { pending, fulfilled, rejected } = extraActions.getSubGame
        return {
            [pending]: (state) => {
                state.subgame = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subgame = action.payload;
            },
            [rejected]: (state, action) => {
                state.subgame = { error: action.error };
                console.log("question error", action.error )
            }
        };
    }
}
