import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
import {act} from "@testing-library/react";

// create slice

const name = 'teams';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,reducers, extraReducers });

// exports
export const teamActions = { ...slice.actions, ...extraActions };
export const teamsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list:  [],
        company: {},
        teams: {},
        teamEmails: [],
        team: {},
        currentTeamId: "",
        email: {},
        memberEmail: {},
        companyInfoCompleted: (localStorage.getItem('companyInfoCompleted') || false),
        teamInfoCompleted: false,
        emailInfoCompleted: false,
        memberEmailsCompleted: false,
        isEmailDelimiterSelected: false,
        emailContent: {
            subject: "",
            content: "",
            signOff: ""
        },
        isEmailScheduled: false,
        licensedEmails : [],
        teamMembers: [],
        count: 0,
        clickedTeamId: localStorage.getItem('team_id'),
        teamObject: {},
        companyObject: JSON.parse(localStorage.getItem("companyObject"))
    }
}

function createReducers() {
    return {
        setMemberEmail,
        setIsEmailDelimiterSelected,
        setEmailSubject,
        setEmailContent,
        setSignOff,
        setIsEmailScheduled,
        setLicensedEmails,
        setIsCompanyInfoCompleted,
        setIsTeamInfoCompleted,
        setIsEmailInfoCompleted,
        setMemberEmailsCompleted,
        setCurrentTeamId,
        setClickedTeamId,
        setCompanyObject,
        setTeamObject,
        setTeamEmails
    };

    function setMemberEmail(state, action){
        state.memberEmail = action.payload
    }

    function setIsEmailDelimiterSelected(state, action){
        state.isEmailDelimiterSelected = action.payload
    }

    function setEmailSubject(state, action){
        state.emailContent.subject = action.payload
    }

    function setEmailContent(state, action){
        state.emailContent.content = action.payload
    }

    function setSignOff(state, action){
        state.emailContent.signOff = action.payload
    }

    function setIsEmailScheduled (state, action){
        state.emailContent.signOff = action.payload
    }

    function setLicensedEmails (state, action){
        state.licensedEmails = action.payload
    }

    function setIsEmailInfoCompleted (state, action){
        state.emailInfoCompleted = action.payload
    }

    function setIsCompanyInfoCompleted (state, action){
        state.companyInfoCompleted = action.payload
        localStorage.setItem('companyInfoCompleted', "true");


    }

    function setIsTeamInfoCompleted (state, action){
        state.teamInfoCompleted = action.payload
    }

    function setMemberEmailsCompleted (state, action){
        state.memberEmailsCompleted = action.payload
    }

    function setCurrentTeamId (state, action){
        state.currentTeamId = action.payload
    }

    function setClickedTeamId (state, action){
        localStorage.setItem('team_id', action.payload);
        // state.currentTeamId = action.payload
    }

    function setCompanyObject (state, action){
        // state.companyObject = action.payload
        localStorage.setItem('companyObject', JSON.stringify(action.payload));
    }

    function setTeamObject (state, action) {
        state.teamObject = action.payload
        state.teamInfoCompleted = true
    }

    function setTeamEmails (state, action) {
        state.teamEmails.push(action.payload);
    }
}

function createExtraActions() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/teams`;

    return {
        getAll: getAll(),
        getTeams: getTeams(),
        getCoordinatorTeams: getCoordinatorTeams(),
        getTeam: getTeam(),
        getTeamMembers: getTeamMembers(),
        addCompany: addCompany(),
        addTeam: addTeam(),
        addEmail: addEmail(),
        resendEmail: resendEmail(),
        removeEmail: removeEmail(),
        addEmailInfo: addEmailInfo(),
        sendEmails: sendEmails(),
        getCount: getCount(),
        getTeamById: getTeamById(),
        addEmails: addEmails()
    };


    function getCount() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams/members/counts/`;
        return createAsyncThunk(
            `${name}/getCount`,
            async ({license}) => await fetchWrapper.get(`${url}/${license}`)
        );
    }

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async ({license}) => await fetchWrapper.get(`${url}/${license}`)
        );
    }

    function getCoordinatorTeams() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams/list`;
        return createAsyncThunk(
            `${name}/getCoordinatorTeams`,
            async ({user}) => await fetchWrapper.get(`${url}/${user}?coordinator=true`)
        );
    }

    function getTeams() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams/list`;
        return createAsyncThunk(
            `${name}/getTeams`,
            async ({user}) => await fetchWrapper.get(`${url}/${user}`)
        );
    }

    function getTeam() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams/single`;

        return createAsyncThunk(
            `${name}/getTeam`,
            async ({license}) => await fetchWrapper.get(`${url}/${license}`)
        );
    }

    function getTeamById() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams/single`;

        return createAsyncThunk(
            `${name}/getTeamById`,
            async ({team_id}) => await fetchWrapper.get(`${url}/${team_id}?type=id`)
        );
    }

    function getTeamMembers() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams/members`

        return createAsyncThunk(
            `${name}/getTeamMembers`,
            async (team) => await fetchWrapper.get(`${url}/${team}`)
        );
    }

    function addCompany() {
        return createAsyncThunk(
            `${name}/addCompany`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function addTeam() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams`;
        return createAsyncThunk(
            `${name}/addTeam`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function addEmail() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/emails`;
        return createAsyncThunk(
            `${name}/addEmail`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function addEmails() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/add-members`;
        return createAsyncThunk(
            `${name}/addEmails`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function removeEmail() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/emails`;
        return createAsyncThunk(
            `${name}/removeEmail`,
            async (body ) => await fetchWrapper.delete(`${url}`, body )
        );
    }

    function addEmailInfo() {
        return createAsyncThunk(
            `${name}/addEmailInfo`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function sendEmails() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams/send-invites`;

        return createAsyncThunk(
            `${name}/sendEmails`,
            async ({license, team_id, scheduled, scheduled_at} ) =>
                await fetchWrapper.post(`${url}/${team_id}`, {license, team_id, scheduled, scheduled_at} )
        );
    }

    function resendEmail() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/teams/resend-email`;

        return createAsyncThunk(
            `${name}/resendEmail`,
            async ({ team_id, user_id, license_id} ) =>
                await fetchWrapper.post(`${url}`, {team_id, user_id, license_id} )
        );

    }

}


function createExtraReducers() {
    return {
        ...getAll(),
        ...getTeams(),
        ...getCoordinatorTeams(),
        ...getById(),
        ...getTeam(),
        ...getTeamMembers(),
        ...addCompany(),
        ...addTeam(),
        ...addEmail(),
        ...removeEmail(),
        ...addEmailInfo(),
        ...saveEmails(),
        ...addEmails()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.teams = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload
            },
            [rejected]: (state, action) => {
                state.teams = { error: action.error };
            }
        };
    }

    function getTeams() {
        const { pending, fulfilled, rejected } = extraActions.getTeams;
        return {
            [pending]: (state) => {
                state.teams = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload
            },
            [rejected]: (state, action) => {
                state.teams = { error: action.error };
            }
        };
    }

    function getCoordinatorTeams() {
        const { pending, fulfilled, rejected } = extraActions.getCoordinatorTeams;
        return {
            [pending]: (state) => {
                state.teams = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload
            },
            [rejected]: (state, action) => {
                state.teams = { error: action.error };
            }
        };
    }

    function getById() {
        const { pending, fulfilled, rejected } = extraActions.getTeam;
        return {
            [pending]: (state) => {
                state.team = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.team = action.payload
            },
            [rejected]: (state, action) => {
                state.team = { error: action.error };
            }
        };
    }

    function getTeam() {
        const { pending, fulfilled, rejected } = extraActions.getTeamById;
        return {
            [pending]: (state) => {
                state.team = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.team = action.payload
            },
            [rejected]: (state, action) => {
                state.team = { error: action.error };
            }
        };
    }

    function getTeamMembers() {
        const { pending, fulfilled, rejected } = extraActions.getTeamMembers;
        return {
            [pending]: (state) => {
                state.teamMembers = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.teamMembers = action.payload
            },
            [rejected]: (state, action) => {
                state.teamMembers = { error: action.error };
            }
        };
    }

    function addCompany() {
        const { pending, fulfilled, rejected } = extraActions.addCompany;
        return {
            [pending]: (state) => {
                state.company = { loading: true };
                state.companyInfoCompleted = false
            },
            [fulfilled]: (state, action) => {
                state.company = action.payload;
                state.companyInfoCompleted = true
            },
            [rejected]: (state, action) => {
                state.company = { error: action.error };
                state.companyInfoCompleted = false
            }
        };
    }

    function addTeam() {
        const { pending, fulfilled, rejected } = extraActions.addTeam;
        return {
            [pending]: (state) => {
                state.teams = { loading: true };
                state.teamInfoCompleted = false
            },
            [fulfilled]: (state, action) => {
                state.teams = action.payload
                state.teamInfoCompleted = true
            },
            [rejected]: (state, action) => {
                state.teams = { error: action.error };
                state.teamInfoCompleted = false

            }
        };
    }

    function addEmail() {
        const { pending, fulfilled, rejected } = extraActions.addEmail;
        return {
            [pending]: (state) => {
                state.memberEmailsCompleted = false
            },
            [fulfilled]: (state, action) => {
                state.memberEmailsCompleted = true
            },
            [rejected]: (state, action) => {
                state.teams = { error: action.error };
                state.memberEmailsCompleted = false
            }
        };
    }

    function addEmails() {
        const { pending, fulfilled, rejected } = extraActions.addEmail;
        return {
            [pending]: (state) => {
                // state.memberEmailsCompleted = false
            },
            [fulfilled]: (state, action) => {
                // state.memberEmailsCompleted = true
            },
            [rejected]: (state, action) => {
                // state.teams = { error: action.error };
                // state.memberEmailsCompleted = false
            }
        };
    }

    function removeEmail() {
        const { pending, fulfilled, rejected } = extraActions.addEmail;
        return {
            [pending]: (state) => {
                state.teams = { loading: true };
            },
            [fulfilled]: (state, action) => {
                // state.teams = action.payload
            },
            [rejected]: (state, action) => {
                state.teams = { error: action.error };
            }
        };
    }

    function addEmailInfo() {
        const { pending, fulfilled, rejected } = extraActions.addEmailInfo
        return {
            [pending]: (state) => {
                state.teams = { loading: true };
                state.emailInfoCompleted = false
            },
            [fulfilled]: (state, action) => {
                state.teams = action.payload
                state.emailInfoCompleted = true
            },
            [rejected]: (state, action) => {
                state.teams = { error: action.error };
                state.emailInfoCompleted = false
            }
        }
    }

    function saveEmails() {
        const { pending, fulfilled, rejected } = extraActions.sendEmails
        return {
            [pending]: (state) => {
                state.teams = { loading: true };
                state.emailInfoCompleted = false
            },
            [fulfilled]: (state, action) => {
                state.emailInfoCompleted = true
            },
            [rejected]: (state, action) => {
                state.teams = { error: action.error };
                state.emailInfoCompleted = false
            }
        }
    }
}
