import {MemberEmails} from "./MemberEmails";
import {CompanyInfo} from "./CompanyInfo";
import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";
import {EmailInfo} from "./EmailInfo";
import {licenseActions} from "../../_store";
import {useDispatch, useSelector} from "react-redux";
import {Nav} from "../Nav";
import TeamSetup from "./TeamSetUp";
import {useSearchParams} from "react-router-dom";
import Modal from "../Modal";
import ConfirmModal from "../ConfirmModal";
import {ScheduleInfo} from "./ScheduleInfo";

export const AddSchedule = () => {

    const dispatch = useDispatch()
    const modal  = useSelector(x => x.modal )
    const confirmModal = useSelector(x => x.confirmModal)
    const {user} = useSelector(x => x.auth)
    const licenses = useSelector(x => x.licenses)
    const [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get("id")

    useEffect(() => {
        const license = user.license
        dispatch(licenseActions.getAll({license}))
    },[])

    return (
        <>
            <ToastContainer />
            {modal.isOpen && <Modal />}
            {confirmModal.isOpen && <ConfirmModal />}

            <div className="min-h-full">
                <Nav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Schedule Emails</h1>
                    </div>
                </header>
                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

                        <nav className="rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href="/assessments" className="text-blue-600 hover:text-blue-700">Assessments</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li><a href="/new-team" className="text-blue-600 hover:text-blue-700">New Team</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">Schedule Email</li>
                            </ol>
                        </nav>

                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="max-w-5xl content-center">
                                    <div className="hidden sm:block" aria-hidden="true">
                                        <div className="py-5">
                                            <div className="border-t border-gray-200" />
                                        </div>
                                    </div>
                                    <div className=" sm:mt-0">
                                        <div className="md:grid md:grid-cols-1 md:gap-6">
                                            <div className=" md:mt-0 md:col-span-2">
                                                <ScheduleInfo />

                                                <br/>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
                <br/>
                <br/>
            </div>


        </>
    )
}