import {useDispatch, useSelector} from 'react-redux';
import {gameActions, progressActions, questionActions, videoActions} from "../../_store";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useSearchParams} from "react-router-dom"
import {useEffect, useState} from "react";
import Video from "../Video";
import  IntroVideo from "../../Videos/Change Game Intro.mp4"
import CopyrightLogo from "../../games/copyright-logo.jpg"

const VideoModal = () => {
    const dispatch = useDispatch();
    const {currentModalStep} = useSelector(x => x.games)
    const {user} = useSelector(x=> x.auth)
    const {choiceOfAnswer} = useSelector(x=> x.games)
    const {completed,isModalOpen} = useSelector(x=> x.videos)
    const [searchParams] = useSearchParams()
    const game_id = searchParams.get("game_id")
    const { progress, nextStep,progressOrder} = useSelector(x => x.progress)
    const {isCorrect} = useSelector( x => x.questions)
    const navigate = useNavigate()

    const recapLink = progress.progress && progress.progress.link

    console.log("Current Modal Step", currentModalStep)

    const progressObject = progress.progress && progress.progress

    const correctEEScore     =   choiceOfAnswer?.ee_score
    const correctETScore     =   choiceOfAnswer?.et_score
    const correctBudgetScore =   choiceOfAnswer?.eb_score

    const currentActualEEScore     =  progressObject && Number(progressObject.actual_ee_score) + correctEEScore
    const currentActualETScore     =  progressObject && Number(progressObject.actual_et_score) + correctETScore
    const currentActualBudgetScore =  progressObject && Number(progressObject.actual_budget_score) + correctBudgetScore

    // const progressObject = progress.progress;
    // const { ee_score: correctEEScore, et_score: correctETScore, eb_score: correctBudgetScore } = choiceOfAnswer || {};
    //
    // const currentActualEEScore = progressObject ? Number(progressObject.actual_ee_score) + correctEEScore : undefined;
    // const currentActualETScore = progressObject ? Number(progressObject.actual_et_score) + correctETScore : undefined;
    // const currentActualBudgetScore = progressObject ? Number(progressObject.actual_budget_score) + correctBudgetScore : undefined;

    const validationSchema = Yup.object().shape({
        // answer: string().required('answer is required')
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const userLastStep =  nextStep.nextPhaseStep && nextStep.nextPhaseStep.id



    useEffect(() => {
        dispatch(videoActions.setCompleted(false))
    },[])

    const handleContextmenu = e => {
        e.preventDefault()
    }

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [ ])

    function onSubmit() {
        if (!completed){
            toast.info("You need to watch video to continue!")
            return
        }

        const nextProgressOrder = progressOrder
        dispatch(progressActions.getNextStep({order: nextProgressOrder}))
        const column = progress.progress && progress.progress.column
        dispatch(gameActions.closeVideoModal());
        toast.success("Good Job! You finished a block!")
        dispatch(progressActions.updateProgress({user: user.id}))
        dispatch(progressActions.getProgress({user: user.id}))
        dispatch(progressActions.getNextStep({order: nextProgressOrder}))

        setTimeout(() => {
            navigate(0)
        },1000)
            // })
    }

    return (
        <>
            <aside className='modal-container' onLoad={() => dispatch(videoActions.setCompleted(false))}>
                <div className='modal'>
                    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <h1 className="mb-2">VIDEO</h1>

                            <div>
                                {currentModalStep.link != null && currentModalStep.link.includes(".mp4") && <Video url={currentModalStep.link} />}
                            </div>

                            <img src={CopyrightLogo} width={100} className="float-left mt-5"  />

                            <div className='btn-container'>

                                {/*<div*/}
                                {/*    className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">*/}


                                {/*</div>*/}

                                    <div
                                    className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                                        <button
                                            onClick={() => {
                                                dispatch(gameActions.closeVideoModal())
                                                navigate(0)
                                            }}
                                            type="button"
                                            className="mr-3 inline-block px-6 py-2.5 bg-[#a70000] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out disabled:bg-slate-50"
                                            data-bs-dismiss="modal">
                                            CLOSE
                                        </button>



                                        {completed &&
                                        <button
                                            disabled={!completed}
                                            type="submit"
                                            className="mr-3 inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-800 hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out disabled:bg-slate-50"
                                            data-bs-dismiss="modal">
                                            NEXT
                                        </button>
                                    }

                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </aside>
        </>
    )
};
export default VideoModal


