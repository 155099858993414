import {useDispatch, useSelector} from "react-redux";
import {Nav} from "./Nav";
import {useEffect} from "react";
import {subscriptionActions, teamActions} from "../_store";
import "@videsk/tailwind-random-color"
import {ToastContainer} from "react-toastify";
import SessionEndedModal from "./superAdmin/SessionEndedModal";
import {ProductNav} from "./ProductNav";

const Products = () => {
    const {subscriptions} = useSelector(x => x.subscriptions.subscriptions)
    const {user,isLoggedOut} = useSelector(x => x.auth)
    const dispatch = useDispatch()
    console.log("subscriptions", subscriptions)

    useEffect(() => {
        dispatch(teamActions.getAll())
        dispatch(subscriptionActions.getAll({user: user.id}))
    },[])

    return (
        <>
            <ToastContainer limit={1} />
            {isLoggedOut && <SessionEndedModal/> }
            <div className="min-h-full">
              <ProductNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Purchased Products</h1>
                    </div>
                </header>

                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <h1 className="text-4xl">Products</h1>
                            <br/>

                            <div className="grid grid-cols-4 gap-1">
                                {subscriptions && subscriptions.map((sub) =>
                                        <div key={sub.id} className="border-4 border-dashed border-gray-200 rounded-lg h-96">
                                            <div>
                                                {/*<a href={`/dashboard/${sub.license}?plan=${sub.subscription_plan}`}>*/}
                                                <a href={`/assessments/${sub.license}`}>
                                                    <div className={`report  ${ sub.name.includes("Change Management") ? "bg-slate-700": "bg-slate-700"} hover:grow text-white`}>
                                                        <h1 className="h1">
                                                            {sub.name}
                                                        </h1>

                                                        <br/>
                                                        <div>
                                                            <h2 className="text-4xl"> <strong>{sub.quantity}</strong> </h2>
                                                            {/*<p><strong>3/{sub.quantity}Used</strong></p>*/}
                                                            <span>Licenses</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
 }

 export default Products