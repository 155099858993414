import {ToastContainer} from "react-toastify";
import SessionEndedModal from "../superAdmin/SessionEndedModal";
import {Nav} from "../Nav";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {authActions, planActions, teamActions} from "../../_store";
import {useNavigate} from "react-router";

export const AssessmentTeams = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authUser = useSelector(x => x.auth.user)
    const teams = useSelector(x => x.teams );
    console.log("clickedTeamId ", teams.clickedTeamId)
    const {plan} = useSelector(x => x.plans)
    const teamList = teams.list.teams
    const uniqueIds = []
    const uniqueTeams = teamList && teamList.filter(element => {
        const isDuplicate = uniqueIds.includes(element.id)
        if (!isDuplicate){
            uniqueIds.push(element.id)
            return true;
        }
        return false
    })
    console.log("Team list from teams assessment", teamList)

    useEffect(() => {
        dispatch(teamActions.getTeams({user: authUser.id}))
        dispatch(planActions.getById(authUser.subscription_plan))
    }, [])

    return (
        <>
            <ToastContainer limit={1} />

            <div className="min-h-full">
                {/*<Nav />*/}
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
                        <a
                            onClick={() => {
                                dispatch(authActions.logout())
                            }}
                            href="#" className="block px-4 py-2  font-bold float-right mt-0 pt-0 "
                            role="menuitem" tabIndex="-1" id="user-menu-item-2">Sign out</a>

                    </div>
                </header>

                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <h1 className="text-4xl">Invitations</h1>
                            <br/>

                            <div className="grid grid-cols-4 gap-1">
                                {uniqueTeams && uniqueTeams.map((tm) =>
                                    !tm.completed &&
                                    <div key={tm.id} className="border-4 border-dashed border-gray-200 rounded-lg h-96">
                                        <div>
                                            <a onClick={() => {
                                                dispatch(teamActions.setClickedTeamId(tm.team))
                                                setTimeout(() => {
                                                    navigate(`/assessment-intro?license_id=${tm.license}&game=${plan.game}`)
                                                },1000)
                                            }} >
                                                {/*<div className={`report bg-slate-700 hover:grow text-white`}>*/}
                                                <div className="report bg-[#a70000] hover:grow text-white">
                                                    <h1 className="h1">
                                                        {tm.teamName}
                                                    </h1>

                                                    <br/>
                                                    <br/>
                                                    <div>
                                                        <h2 className="text-4xl"> <strong>{tm.quantity}</strong> </h2>
                                                        <p><strong>  {tm.companyName}</strong></p>
                                                        <span>
                                                            <i> {tm.isStarted ? "Started" : "Not Started"} </i>
                                                        </span>
                                                    </div>

                                                </div>
                                            </a>
                                        </div>
                                    </div>


                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>

    )
}