import {  createSlice } from '@reduxjs/toolkit';

// create slice

const name = 'profile';
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports

export const profileActions = { ...slice.actions };
export const profileReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        isOpen: false
    }
}

function createReducers() {
    return {
        open,
        close
    };

    function open (state, action){
        if (state.isOpen){
            state.isOpen = false;
            return
        }

        state.isOpen = true
    }

    function close (state, action){
        state.isOpen = false;
    }
}

