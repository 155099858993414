import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SingleTile} from "../SingleTile";
import GreenIndicator from "../../../games/Yellow-Indicator-B.png";
import infoButton from "../../../games/Info-Button.png";
import DemoImage from "../../../games/resized-image-Promo.jpeg";
import AnimatedNumbers from "react-animated-numbers";
import {useNavigate} from "react-router";
import {AnimateNumber} from "../../AnimateNumber";
import {screens} from "../../../_helpers/screens";
import {gameActions, videoActions} from "../../../_store";
import "./css/custom.css"
import {GameTile} from "../assets";

export const Desktop1536X864 = ({
                                    columnOneSteps,
                                    columnTwoSteps,
                                    columnThreeSteps,
                                    columnFourSteps,
                                    columnFiveSteps,
                                    userLastStep,
                                    progressLastStep}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {phaseBackground}  = useSelector(x => x.games)
    const {nextStep,progress, et_score, ee_score, budget_score} = useSelector(x => x.progress)
    const progressObject     = progress.progress && progress.progress
    const currentEEScore     =   screens.desktopOne.eeIndicator + (progressObject.actual_ee_score || 0)
    const currentETScore     =   screens.desktopOne.etIndicator + (progressObject.actual_et_score || 0)
    const currentBudgetScore =   screens.desktopOne.budgetIndicator + (progressObject.actual_budget_score || 0)
    const {infoButtonModalOpened} = useSelector(x => x.games)
    const phaseName          = nextStep.nextPhaseStep && nextStep.nextPhaseStep.phase_name
    const description          = nextStep?.nextPhaseStep?.description
    const columnTwoLeft      = `452px`
    const columnThreeLeft      = `681px`

    console.log("Phase background", phaseBackground)

    return (
            <>
                <div className="container bg-white">
                    <div className="game-board">
                        <div>
                            <div>
                                {columnOneSteps && columnOneSteps.map((step, index)=>
                                    <div>
                                        {index === 0 &&
                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            progressLastStep={progressLastStep}
                                            divStyle={{ top: `105px` ,left: `60px`}}
                                            tileStyle={{width: `65%`, marginTop: `-5px` }}
                                            busStyle={{left:`-25px`, width: `50%`, top: `-13px` }}
                                            // busStyle={{left:`-15px`, width: `40%`, marginTop: `-13px` }}
                                            column={1}
                                            number={index}
                                            type={`changeLandscape`}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                                        />
                                        }

                                        {index === 1 &&
                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            progressLastStep={progressLastStep}
                                            divStyle={{top: `217px`, left: `45px`}}
                                            tileStyle={{width: `70%`, marginTop: `2px`}}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={1}
                                            number={index}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />

                                        }

                                        {index === 2 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            progressLastStep={progressLastStep}
                                            divStyle={{top: `355px`, left: `45px`}}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={1}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />

                                        }

                                        {index === 3 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{top: `490px`, left: `45px`}}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={1}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />
                                        }

                                    </div>
                                )}

                            </div>

                            <div className="static">

                                {columnTwoSteps && columnTwoSteps.map((step, index)=>
                                    <div>
                                        {index === 3 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `280px`, top: `85px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={2}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />

                                        }

                                        {index === 2 &&
                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `280px`, top: `220px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={2}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />
                                        }

                                        {index === 1 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `280px`, top: `355px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, top: `0px`}}
                                            column={2}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />
                                        }

                                        {index === 0 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `295px`, top: `505px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={2}
                                            type={`startUp`}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                                        />
                                        }

                                    </div>
                                )}

                            </div>

                            <div className="static">

                                {columnThreeSteps && columnThreeSteps.map((step, index)=>
                                    <div>
                                        {index === 0 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            progressLastStep={progressLastStep}
                                            divStyle={{ left: `520px`, top: `85px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            number={index}
                                            column={3}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />

                                        }

                                        {index === 1 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            progressLastStep={progressLastStep}
                                            divStyle={{ left: `520px`, top: `218px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={3}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />

                                        }

                                        {index === 2 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            progressLastStep={progressLastStep}
                                            divStyle={{ left: `520px`, top: `355px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            number={index}
                                            column={3}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />

                                        }

                                        {index === 3 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            progressLastStep={progressLastStep}
                                            divStyle={{ left: `520px`, top: `490px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            number={index}
                                            column={3}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />
                                        }

                                    </div>
                                )}

                            </div>

                            <div className="static">

                                {columnFourSteps && columnFourSteps.map((step, index)=>
                                    <div>
                                        {index === 3 &&
                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `760px`, top: `90px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={4}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}

                                        />
                                        }

                                        {index === 2 &&
                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            type={`implementationPhase`}
                                            divStyle={{ left: `780px`, top: `235px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={4}
                                            greenTickerStyle={{top: `-10px`,  left: `40px`, width: `18%`}}
                                        />

                                        }

                                        {index === 1 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `760px`, top: `355px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={4}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />

                                        }

                                        {index === 0 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `760px`, top: `490px` }}
                                            tileStyle={{width: `70%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={4}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                        />
                                        }

                                    </div>
                                )}

                            </div>

                            <div className="static">

                                {columnFiveSteps && columnFiveSteps.map((step, index)=>
                                    <div>
                                        {index === 0 &&
                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `1010px`, top: `89px` }}
                                            tileStyle={{width: `80%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={5}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                                        />
                                        }

                                        {index === 1 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `1010px`, top: `224px` }}
                                            tileStyle={{width: `80%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={5}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                                        />

                                        }

                                        {index === 2 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `1010px`, top: `355px` }}
                                            tileStyle={{width: `80%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={5}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                                        />

                                        }

                                        {index === 3 &&

                                        <SingleTile
                                            step={step}
                                            userLastStep={userLastStep}
                                            divStyle={{ left: `1010px`, top: `490px` }}
                                            tileStyle={{width: `80%`, marginTop: `2px` }}
                                            busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                                            column={5}
                                            greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                                        />
                                        }

                                    </div>
                                )}

                            </div>

                            <div style={{
                                border: `1px solid #000`,
                                width: `40px`,
                                position: `absolute`,
                                top: `645px`,
                                left: `405px`,
                                background: `#000`,
                                height: `27px` }}>
                                <p style={{color: `white`,
                                    marginTop: `-1px`,
                                    marginLeft: `4px`
                                }}>

                                    {/*<AnimateNumber number={!isNaN(progressObject.actual_ee_score)? progressObject.actual_ee_score : 100} />*/}
                                    {!isNaN(progressObject.actual_ee_score)? progressObject.actual_ee_score + 100 : 100}
                                    {/*<AnimateNumber number={cachedValue} />*/}

                                </p>

                            </div>

                            <div style={{
                                border: `1px solid #000`,
                                width: `40px`,
                                position: `absolute`, top: `645px`,
                                left: `767px`,
                                background: `#000`,
                                height: `27px` }}>
                                <p style={{color: `white`, marginTop: `-1px`, marginLeft: `4px`}}>
                                    {/*<AnimateNumber number={!isNaN(progressObject.actual_et_score)? progressObject.actual_et_score : 100} />*/}
                                    {!isNaN(progressObject.actual_et_score)? progressObject.actual_et_score + 100 : 100}
                                </p>
                            </div>

                            <div style={{
                                border: `1px solid #000`,
                                width: `40px`,
                                position: `absolute`, top: `645px`,
                                left: `1128px`,
                                background: `#000`,
                                height: `27px` }}>
                                <p style={{color: `white`, top: `-1px`, marginLeft: `4px`}}>
                                    {/*<AnimateNumber number={!isNaN(progressObject.actual_budget_score) ? progressObject.actual_budget_score : 100} />*/}
                                    {!isNaN(progressObject.actual_budget_score) ? progressObject.actual_budget_score + 100  : 100}
                                </p>
                            </div>

                            <div className={`absolute` } style={{top: `650px`, left: `${currentEEScore}px`}}>
                                <img src={GreenIndicator}   alt="indicator" />
                            </div>

                            <div className={`absolute` } style={{top: `650px`, left: `${currentETScore}px`}}>
                                <img src={GreenIndicator}  alt="indicator" />
                            </div>

                            <div className={`absolute` } style={{top: `650px`, left: `${currentBudgetScore}px`}}>
                                <img src={GreenIndicator}  alt="indicator" />
                            </div>


                            {/*<div className={`absolute` } style={{top: `645px`, left: `220px`}}*/}
                            {/*     onClick={ () => {*/}
                            {/*         dispatch(gameActions.setInfoButtonModalOpened(true))*/}
                            {/*     }}>*/}
                            {/*    <img src={infoButton}  alt="indicator" width="30"*/}
                            {/*         style={{*/}
                            {/*             position: `relative`,*/}
                            {/*             left: `-145px`*/}
                            {/*         }} />*/}
                            {/*</div>*/}

                        </div>
                        <img src={phaseBackground} width={1200} />
                    </div>

                    <div className="instructions" >
                        {/*<h4>Current Stage: <strong>{phaseName}</strong></h4>*/}
                        <br/>
                        <br/>
                        <br/>

                        {/*<p>Stop {description?.replace(/\D+/g, '')}: Change Management Plan</p>*/}
                        {/*<br/>*/}

                        <h2><strong>Information:</strong> </h2>
                        {/*<br/>*/}
                        <ol>
                            <li>
                                <button
                                    onClick={() =>{
                                        dispatch(videoActions.setCurrentIntroVideo("business-objective"))
                                        dispatch(gameActions.setInfoButtonModalOpened(true))
                                    }
                                    }
                                    className="exit-game w-full">Objectives of the Game</button>
                            </li>

                            <li>
                                <button onClick={() => {
                                    dispatch(videoActions.setCurrentIntroVideo("business-case-image"))
                                    dispatch(gameActions.setInfoButtonModalOpened(true))
                                }}
                                    className="exit-game w-full">Business Case Summary</button>
                            </li>

                            <li>
                                <button onClick={() => {
                                    dispatch(videoActions.setCurrentIntroVideo("business-case"))
                                    dispatch(gameActions.setInfoButtonModalOpened(true))
                                }}
                                        className="exit-game w-full">Business Case</button>
                            </li>

                            <li>
                                <button
                                    onClick={() => {
                                        dispatch(videoActions.setCurrentIntroVideo("how-to-play"))
                                        dispatch(gameActions.setInfoButtonModalOpened(true))
                                    }}
                                    className="exit-game w-full">How to play the Game</button>
                            </li>

                            <br/>

                            <li>

                                <button
                                    onClick={() =>
                                        navigate(-1)
                                    }
                                    className=" w-full bg-gray-300 text-black font-bold py-2 px-4 rounded">
                                    Exit game
                                </button>

                            </li>
                        </ol>

                        <br/>


                    </div>
                </div>
        </>

    )
}
