import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate, useParams} from "react-router"
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {toast, ToastContainer} from "react-toastify"
import {authActions, gameActions, progressActions, questionActions, stepActions, videoActions} from "../_store"
import {useSearchParams} from "react-router-dom";
import GameModal from "./superAdmin/GameModal";
import CorrectAnswerModal from "./superAdmin/CorrectAnswerModal";
import WrongAnswerModal from "./superAdmin/WrongAnswerModal";
import {IPadPro1024X1366} from "./GameScreens/IPadPro1024X1366/IPadPro1024X1366";
import GameMediaModal from "./superAdmin/GameMediaModal";
import VideoModal from "./superAdmin/VideoModal";
import VideoSeekModal from "./VideoSeekModal";
import CompletedGameModal from "./Modals/CompletedGameModal";
import HoverModal from "./HoverModal";
import {Render1920X1080} from "./GameScreens/Desktop1920X1080/Render1920X1080";
import {Render1536X864} from "./GameScreens/Desktop1536X864/Render1536X864";
import {screens} from "../_helpers/screens";
import {Render1366X768} from "./GameScreens/Desktop1366X768/Render1366X768";
import {Render1440X900} from "./GameScreens/Desktop1440X900/Render1440X900";
import {Render1280X720} from "./GameScreens/Desktop1280X720/Render1280X720";
import SessionEndedModal from "./superAdmin/SessionEndedModal";
import {SubGame} from "./Subgame/matchingGameDeprecated/SubGame";
import NotSupportedResolution, {NoSupportedResolution} from "./GameScreens/NoSupportedResolution";
import CompletedQuizModal from "./CompletedQuizModal";
import InfoButtonModal from "./InfoButtonModal";
import CompletedGameModalOne from "./Modals/CompletedGameModalOne";
import CompletedGameModalThree from "./Modals/CompletedGameModalThree";
import CompletedGameModalFour from "./Modals/CompletedGameModalFour";
import CompleteGameModal from "./Modals/CompleteGameModal"
import "../components/GameScreens/Desktop1536X864/css/custom.css"
import ZoomOutComponent from "./GameScreens/Desktop1536X864/ZoomOutComponent";


const { screen, innerWidth, innerHeight, document } = window;

const screenSize = `${screen.width} * ${screen.height}`;
const innerScreenSize = `${innerWidth} * ${innerHeight}`;

console.log("Inner Screen size", innerScreenSize);
console.log("Screen size", screenSize);

document.addEventListener("copy", (event) => {
    event.preventDefault();
});

document.addEventListener("paste", (event) => {
    event.preventDefault();
});


function Game() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {maxScreenClicked} = useSelector(x => x.videos)
    console.log("Max screen clicked", maxScreenClicked)

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    useEffect(() => {
        const handleResize = () => {
            if (!maxScreenClicked) {
                // window.location.reload();
            }
            dispatch(videoActions.setMaxScreenClicked(false))
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [maxScreenClicked, screen.width]);
    const [searchParams, setSearchParams] = useSearchParams()
    const game_id = searchParams.get("game_id")
    const steps = useSelector(x => x.steps)
    const games = useSelector(x => x.games)
    const {isModalOpen} = useSelector(x => x.videos)
    const questionModalOpen = useSelector(x => x.questions.isModalOpen)
    const {isCompletedQuizModalOpen, isQuizCorrect} = useSelector(x => x.questions)
    const questions = useSelector(x => x.videos)
    const {user, isLoggedOut} = useSelector(x => x.auth)
    const {
        progress,
        nextStep,
        progressOrder,
        et_score,
        gameCompleted,
        gameCompletedStatus
    } = useSelector(x => x.progress)
    const userLastStep = nextStep.nextPhaseStep && nextStep.nextPhaseStep.id
    const progressLastStep = progress.progress && progress.progress.phaseStep

    const stepsList = steps?.list?.steps;

    const filterByColumn = (column) => {
        return stepsList?.filter((element) => element.column === column) || [];
    }

    const columnOneSteps = filterByColumn(1);
    const columnTwoSteps = filterByColumn(2);
    const columnThreeSteps = filterByColumn(3);
    const columnFourSteps = filterByColumn(4);
    const columnFiveSteps = filterByColumn(5);
    const columnSixSteps = filterByColumn(6);
    const columnSevenSteps = filterByColumn(7);
    const columnEightSteps = filterByColumn(8);
    const columnNineSteps = filterByColumn(9);
    const columnTenSteps = filterByColumn(10);
    const columnElevenSteps = filterByColumn(11);
    const columnTwelveSteps = filterByColumn(12);
    const columnThirteenSteps = filterByColumn(13);

    const progressScores = progress?.progress
    const progressEEScore = progressScores?.ee_score
    const progressETScore = progressScores?.et_score
    const progressBudgetScore = progressScores?.budget_score
    const currentProgressOrder = progressScores?.order
    const answeredQuestionOnly = progressScores?.answered_question_only

    const fetchData = useCallback(() => {
        dispatch(stepActions.getStepsByGame(game_id));
        dispatch(progressActions.getProgress({user: user.id, game: game_id}));
        dispatch(progressActions.setGameCompleteStatus(false));
    }, [dispatch, game_id, progressActions, user.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const setOriginalScores = useMemo(() => {
        return () => {
            if (progressEEScore != null && progressETScore != null && progressBudgetScore != null) {
                dispatch(progressActions.setOriginalEEScore(progressEEScore));
                dispatch(progressActions.setOriginalETScore(progressETScore));
                dispatch(progressActions.setOriginalBudgetScore(progressBudgetScore));
            }
        }
    }, [dispatch, progressBudgetScore, progressETScore, progressEEScore]);

    useEffect(() => {
        setOriginalScores();
    }, [setOriginalScores, progressBudgetScore, progressETScore, progressEEScore]);

    useEffect(() => {

        const newOrder = answeredQuestionOnly ? progressOrder - 1 : progressOrder;

        dispatch(progressActions.getNextStep({order: newOrder})).then(() => {
            if (progress?.progress?.order === 52) {
                dispatch(progressActions.setGameCompleteStatus(true));
            }
        });

    }, [dispatch, progressOrder, progress.progress]);


    useEffect(() => {
        const isSafari = () => {
            return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        };

        const zoomOut = () => {

            // if (screen.width === screens.desktopThree.width ){
            //     document.body.style.zoom = '90%'; // Set the zoom level to 80% (zoom out)
            // }

            if (screen.width < screens.desktopOne.width) {
                if (isSafari()) {
                    document.body.style.transform = 'scale(0.8)'; // Set the scale to 80% (zoom out)
                    document.body.style.transformOrigin = '0 0'; // Set the transform origin to the top-left corner
                } else {

                    if(screen.width === screens.desktopThree.width){
                        document.body.style.zoom = '87%';
                        return
                    }

                    document.body.style.zoom = '90%';
                }
            }
        };
        zoomOut();
    }, []);

    return (

        <>
            {/*{ <SubGame />}*/}
            {questions && questionModalOpen && <SubGame/>}
            {isModalOpen && VideoSeekModal}
            {/*{isLoggedOut && <SessionEndedModal/>}*/}

            <ToastContainer/>

            {gameCompleted && <CompletedGameModal/>}
            {gameCompletedStatus === "scores-card" && <CompletedGameModalOne/>}
            {gameCompletedStatus === "explanation-card" && <CompletedGameModalThree/>}
            {gameCompletedStatus === "overall-results-card" && <CompletedGameModalFour/>}
            {gameCompletedStatus === "end-game" && <CompleteGameModal game_id={game_id}/>}

            {games.isModalOpen && <GameModal/>}

            {games.isMediaModalOpen && <GameMediaModal/>}

            {games.isCorrectResultModalOpen && <CorrectAnswerModal/>}

            {games.isWrongResultModalOpen && <WrongAnswerModal/>}

            {games.isVideoModalOpen && <VideoModal/>}

            {games.hoverModalOpen && <HoverModal/>}

            {isCompletedQuizModalOpen && <CompletedQuizModal/>}

            {games?.infoButtonModalOpened && <InfoButtonModal/>}

            {screen.width === screens.desktopFive.width || screen.width < screens.desktopFive.width ?

                <Render1280X720 columnOneSteps={columnOneSteps}
                                columnTwoSteps={columnTwoSteps}
                                columnThreeSteps={columnThreeSteps}
                                columnFourSteps={columnFourSteps}
                                columnFiveSteps={columnFiveSteps}
                                columnSixSteps={columnSixSteps}
                                columnSevenSteps={columnSevenSteps}
                                columnEightSteps={columnEightSteps}
                                columnNineSteps={columnNineSteps}
                                columnTenSteps={columnTenSteps}
                                columnElevenSteps={columnElevenSteps}
                                columnTwelveSteps={columnTwelveSteps}
                                columnThirteenSteps={columnThirteenSteps}
                                screens={screens}
                                width={screen.width}
                                height={screen.height}
                                progressLastStep={progressLastStep}
                                userLastStep={userLastStep}
                                currentProgressOrder={currentProgressOrder}/> :


                <Render1536X864 columnOneSteps={columnOneSteps}
                                columnTwoSteps={columnTwoSteps}
                                columnThreeSteps={columnThreeSteps}
                                columnFourSteps={columnFourSteps}
                                columnFiveSteps={columnFiveSteps}
                                columnSixSteps={columnSixSteps}
                                columnSevenSteps={columnSevenSteps}
                                columnEightSteps={columnEightSteps}
                                columnNineSteps={columnNineSteps}
                                columnTenSteps={columnTenSteps}
                                columnElevenSteps={columnElevenSteps}
                                columnTwelveSteps={columnTwelveSteps}
                                columnThirteenSteps={columnThirteenSteps}
                                screens={screens}
                                width={screen.width}
                                height={screen.height}
                                progressLastStep={progressLastStep}
                                userLastStep={userLastStep}
                                currentProgressOrder={currentProgressOrder}/>


            }

        </>
    )
}


export default Game;