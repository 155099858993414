import {CompanyInfo} from "./CompanyInfo";
import {TeamInfo} from "./TeamInfo";
import {EmailInfo} from "./EmailInfo";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../Modal";
import {toast, ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import {MemberEmails} from "./MemberEmails";
import {licenseActions} from "../../_store";
import {ScheduleInfo} from "./ScheduleInfo";
import {confirmAlert} from "react-confirm-alert";
import {useNavigate} from "react-router-dom";
import ConfirmModal from "../ConfirmModal";

export default function TeamSetup({edit}) {
    const modal  = useSelector(x => x.modal )
    const confirmModal = useSelector(x => x.confirmModal)
    const teams = useSelector(x => x.teams )
    const user  = useSelector(x => x.auth.user )
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {licensedEmails} = useSelector( x=> x.licenses)
    const [licenseQuantity, setLicenseQuantity] = useState(0)

    console.log("User from Team set up", user)
    console.log("License quantity", licenseQuantity)

    useEffect(() => {
        // const license = user.license
        dispatch(licenseActions.getAll({administrator: user?.id})).then((res) => {
            console.log("Res", res)
            setLicenseQuantity(res.payload.licenseQuantity)
        })
    },[])

    return (
        <>
            <ToastContainer />
            {modal.isOpen && <Modal />}
            {confirmModal.isOpen && <ConfirmModal />}

            {!teams.companyInfoCompleted &&
            <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Company</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                This information will be displayed publicly so be careful what you share.
                            </p>
                        </div>
                    </div>
                    <CompanyInfo/>
                </div>
            </div>
            }

            {!teams.teamInfoCompleted &&

            <div>
                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>
                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Team</h3>
                                <p className="mt-1 text-sm text-gray-600">Set up and team members for this assessment.</p>
                            </div>
                        </div>

                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <TeamInfo/>
                        </div>
                    </div>
                </div>
            </div>
            }

            <br/>
            <br/>
        </>
    )
}