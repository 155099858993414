import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '../_helpers';


// create slice
const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        adminUser: JSON.parse(localStorage.getItem('admin-user')),
        isAdmin: (localStorage.getItem('isAdmin')),
        isLoggedOut: false,
        error: null,
        isAdminLoginOpen: false,
        isAssessmentLoginOpen : false
    }
}

function createReducers() {
    return {
        logout,
        adminLogout,
        setLogout,
        openAdminLogin,
        openAssessmentLogin
    };

    function logout(state) {
        state.user = null;
        localStorage.removeItem('user');
        localStorage.removeItem('admin-user');
        localStorage.removeItem('isAdmin');
    }

    function adminLogout(state) {
        state.adminUser = null;
        localStorage.removeItem('admin-user');
        localStorage.removeItem('isAdmin');
    }

    function openAdminLogin(state){
        state.isAdminLoginOpen = true
        state.isAssessmentLoginOpen = false
        state.error = null
    }

    function openAssessmentLogin(state){
        state.isAdminLoginOpen = false
        state.isAssessmentLoginOpen = true
        state.error = null
    }

    function setLogout(state,action){
        state.isLoggedOut = action.payload
    }


    function clearErrors(state){
        state.error = null
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    const baseAPIUrl = `${process.env.REACT_APP_API_BASE_URL}`;

    return {
        login: login(),
        adminLogin: adminLogin(),
        assessmentLogin: assessmentLogin(),
        resetEmail: resetEmail(),
        changePassword: changePassword(),
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => await fetchWrapper.post(`${baseUrl}/login`, { email, password })
        );
    }

    function adminLogin() {
        return createAsyncThunk(
            `${name}/adminLogin`,
            async ({ email, password }) => await fetchWrapper.post(`${baseUrl}/admin-login`, { email, password })
        );
    }

    function assessmentLogin() {
        return createAsyncThunk(
            `${name}/assessmentLogin`,
            async ({ email, password }) => await fetchWrapper.post(`${baseUrl}/assessment-login`, { email, password })
        );
    }

    function resetEmail() {
        return createAsyncThunk(
            `${name}/resetEmail`,
            async ({ email }) => await fetchWrapper.post(`${baseAPIUrl}/reset-password-email`, { email })
        );
    }

    function changePassword() {
        return createAsyncThunk(
            `${name}/changePassword`,
            async ({ token, password }) => await fetchWrapper.post(`${baseAPIUrl}/change-password`, { token, password })
        );
    }
}

function createExtraReducers() {
    return {
        ...login(),
        ...adminLogin(),
        ...assessmentLogin(),
        ...resetEmail()
    };

    function login() {
        var { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                state.isLoggedOut = false
            },
            [rejected]: (state, action) => {

                if (action.error.message.includes("401")){
                    state.error = {message : "Username or password is incorrect!" }
                    return;
                }else if (action.error.message.includes("403")){
                    state.error = {message : "Account not activated or license has expired!" }
                    return
                }

                state.error = action.error;
                console.log("error object", action)
            }
        };
    }

    function adminLogin() {
        var { pending, fulfilled, rejected } = extraActions.adminLogin
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('admin-user', JSON.stringify(user));
                localStorage.setItem('isAdmin', "true");
                state.adminUser = user;
                state.isLoggedOut = false
            },
            [rejected]: (state, action) => {

                if (action.error.message.includes("401")){
                    state.error = {message : "Username or password is incorrect!" }
                    return;
                }else if (action.error.message.includes("403")){
                    state.error = {message : "Account not activated or license has expired!" }
                    return
                }

                state.error = action.error;
                console.log("error object", action)
            }
        };
    }

    function assessmentLogin() {
        const { pending, fulfilled, rejected } = extraActions.assessmentLogin;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
            },
            [rejected]: (state, action) => {

                if (action.error.message.includes("401")){
                    state.error = {message : "Username or password is incorrect!" }
                    return;
                }else if (action.error.message.includes("403")){
                    state.error = {message : "Account not activated or license has expired!" }
                    return
                }else if (action.error.message.includes("400")){
                    state.error = {message : "Account not activated or license has expired!" }
                    return
                }else if (action.error.message.includes("409")){
                    state.error = { message: "Resource already exist"}
                    return
                }

                state.error = action.error;
            }
        };
    }

    function resetEmail() {
        const { pending, fulfilled, rejected } = extraActions.resetEmail;
        return {
            [pending]: (state) => {

            },
            [fulfilled]: (state, action) => {

            },
            [rejected]: (state, action) => {

            }
        };
    }

}
