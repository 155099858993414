import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {confirmModalActions, gameActions, licenseActions, planActions, teamActions} from "../../_store";
import {modalActions} from "../../_store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../_helpers/alerts";
import {useNavigate, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {SpinningButton} from "../button/Buttons";


export const PlanInfo = () => {
    const dispatch = useDispatch();

    const games = useSelector(x => x.games );
    const gameList = games.list.games
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        price_per_person: Yup.string().required('Price is required'),
        duration_in_days: Yup.string().required('Duration is required'),
        // game: Yup.string().required('Game is required'),
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const [showWebsite, setShowWebsite] = useState(false)

    function onSubmit({ name, price_per_person,price_per_person_europe, duration_in_days, game }) {

        return dispatch(planActions.addPlan({ name,price_per_person, price_per_person_europe, duration_in_days, game, show_on_website:showWebsite }))
            .then((response)=> {
                if (response.type === "plans/addPlan/rejected"){
                    toast.error("OOps!, Problem saving plan!")
                    return
                }
                promiseToast("Saving plan", "You have Successfully added a new plan!")

                setTimeout(() => {
                    navigate("/super-admin/plans")
                }, 2000)

            });
    }

    const gameOptions = gameList && gameList.map((item) =>{
        return { value: item.id, label: item.name}
    })

    useEffect(() => {
        dispatch(gameActions.getAll())
    },[])

    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)}  method="POST">

                <div className="shadow overflow-hidden sm:rounded-md">

                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Game
                            </label>

                            <Controller
                                {...register('game')}
                                name="game"
                                control={control}
                                render={({ field }) => <Select
                                    {...field}
                                    options={gameOptions}
                                />}
                            />
                            {errors.game && <span className="text-red-500">This field is required</span>}

                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Plan Name
                            </label>
                            <input
                                {...register("name")}
                                type="text"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />

                            {errors.name && <span className="text-red-500">This field is required</span>}

                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Price Per License(rand) - SADC
                            </label>
                            <input
                                {...register("price_per_person")}
                                type="number"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.price_per_person && <span className="text-red-500">This field is required</span>}

                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Price Per License(euro) - Europe and Americas
                            </label>
                            <input
                                {...register("price_per_person_europe")}
                                type="number"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Duration(days)
                            </label>
                            <input
                                {...register("duration_in_days")}
                                type="number"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div>
                            <label><i> Show on website? </i> </label>
                            <input checked={showWebsite} type="checkbox" {...register("show_website")} className="ml-2" onChange={ e => setShowWebsite(!showWebsite)} />
                        </div>


                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            {/*<button*/}
                            {/*    type="submit"*/}
                            {/*    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*/}
                            {/*    Add Plan*/}
                            {/*</button>*/}

                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                            {isSubmitting ? <SpinningButton />  : "Add Plan" }
                        </button>
                    </div>
                </div>
            </form>

        </>
    )
}