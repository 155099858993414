import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { gameActions, videoActions } from "../../../_store";

const InstructionItem = ({ video, title, onClick }) => (
    <li>
        <button onClick={onClick} className="exit-game w-full">
            {title}
        </button>
    </li>
);

export const Instructions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleButtonClick = (video) => {
        dispatch(videoActions.setCurrentIntroVideo(video));
        dispatch(gameActions.setInfoButtonModalOpened(true));
    };

    return (
        <>
            <h3>
                <strong>Information:</strong>
            </h3>
            <br />
            <ol>
                <InstructionItem
                    video="business-objective"
                    title="Objectives of the Game"
                    onClick={() => handleButtonClick("business-objective")}
                />
                <InstructionItem
                    video="business-case-image"
                    title="Business Case Summary"
                    onClick={() => handleButtonClick("business-case-image")}
                />
                <InstructionItem
                    video="business-case"
                    title="Business Case"
                    onClick={() => handleButtonClick("business-case")}
                />
                <InstructionItem
                    video="how-to-play"
                    title="How to play the Game"
                    onClick={() => handleButtonClick("how-to-play")}
                />
                <br />
                <br />
                <li>
                    <button
                        onClick={() => navigate(-1)}
                        className="w-full bg-gray-300 text-black font-bold py-2 px-4 rounded"
                    >
                        Exit game
                    </button>
                </li>
            </ol>
            <br />
        </>
    );
};
