import {LockClosedIcon} from "@heroicons/react/solid";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {authActions, planActions} from "../../_store";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {SpinningButton} from "../button/Buttons";
import ReCAPTCHA from "react-google-recaptcha";

// Validation schema
const validationSchema = Yup.object().shape({
    email: Yup.string().required('email is required'),
});

// Form options
const formOptions = { resolver: yupResolver(validationSchema) };

export const AssessmentLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Use destructuring to get values from state
    const { user: authUser, error: authError } = useSelector(state => state.auth);
    const { game } = useSelector(state => state.plans.plan);
    const [value, setValue] = useState("")
    const [scriptLoaded, setScriptLoaded] = useState(true)

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://www.google.com/recaptcha/api.js';
    //     script.addEventListener('load', () => setScriptLoaded(true));
    //     document.body.appendChild(script);
    // }, []);

    useEffect(() => {
        if (authUser) {
            dispatch(planActions.getById(authUser.subscription_plan));
            if (game) {
                navigate(`/assessment/teams`);
            }
        }
    }, [navigate, authUser, game, dispatch]);

    const onSubmit = ({ email, password }) => {
        // if (value === undefined || value === '' || value === null) {
            // Handle missing recaptcha
            // alert("You need to complete the challenge first!")
            // return;
        // }
        return dispatch(authActions.assessmentLogin({ email, password }));
    };

    // Render input field
    const renderInputField = (name, type, placeholder) => (
        <div>
            <label htmlFor={name} className="sr-only">
                {placeholder}
            </label>
            <input
                id={name}
                name={name}
                type={type}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#a70000] focus:border-[#a70000] focus:z-10 sm:text-sm"
                placeholder={placeholder}
                {...register(name)}
            />
            <div className="invalid-feedback">{errors[name]?.message}</div>
        </div>
    );

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6" autoComplete="off">
                <input type="hidden" name="remember" defaultValue="true"/>

                {renderInputField('email', 'email', 'Email address')}
                {renderInputField('password', 'password', 'Password')}

                {/*{scriptLoaded &&*/}
                {/*<ReCAPTCHA*/}
                {/*    sitekey="6LdTVP8lAAAAAPcD8BcFfAydHTnEQQbUl0kfamX8"*/}
                {/*    onChange={value => setValue('recaptcha', value, { shouldValidate: true })}*/}
                {/*/>*/}
                {/*}*/}


                <div className="text-red-900 italic font-normal">
                    {authError && <div className="alert alert-danger mt-1 mb-0">{authError.message}</div>}
                </div>

                <div>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000]  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon className="h-5 w-5 text-white" aria-hidden="true"/>
            </span>
                        {isSubmitting ? <SpinningButton /> : "Start" }
                    </button>
                </div>
            </form>
        </div>
    )
}
