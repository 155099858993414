import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {teamActions, industryActions} from '../_store';
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {SpinningButton} from "./button/Buttons";


export const ProfileInfo = () => {

    const dispatch = useDispatch();

    const authSelector = useSelector( x => x.auth)
    const teams = useSelector(x => x.teams );
    const teamError = useSelector(x => x.error);
    let user = authSelector.user

    console.log("Auth", user)

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('email is required'),
        username: Yup.string().required('username is required'),
        firstName: Yup.string().required('first name is required'),
        lastName: Yup.string().required('last name is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email, username, firstName, lastName }) {
        return dispatch(teamActions.addCompany({ email, username, firstName, lastName }));
    }

    return (
        <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleSubmit(onSubmit)} method="POST">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                        <div className="col-span-6">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <input
                                disabled={true}
                                type="text"
                                name="email"
                                value={user.email}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                {...register('email')}
                            />
                        </div>

                        <div className="col-span-6">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                Username
                            </label>
                            <input
                                type="text"
                                name="email"
                                value={user.username}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                {...register('username')}
                            />
                        </div>

                        <div className="col-span-6">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                First Name
                            </label>
                            <input
                                type="text"
                                name="email"
                                value={user.firstName}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                {...register('firstName')}
                            />
                        </div>

                        <div className="col-span-6">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="lastName"
                                value={user.lastName}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                {...register('lastName')}
                            />
                        </div>

                        <div className="col-span-6">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                Phone Number
                            </label>
                            <input
                                type="text"
                                name="phoneNumber"
                                value={user.phoneNumber}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                {...register('phoneNumber')}
                            />
                        </div>


                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {isSubmitting ? <SpinningButton />
                                : teams.companyInfoCompleted ? "Saved" : "Update" }
                        </button>

                        {teamError &&
                        <div className="alert alert-danger mt-3 mb-0">{teamError.message}</div>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}