import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SingleTile} from "./SingleTile";
import GreenIndicator from "../../../games/Yellow-Indicator-B.png";
import AnimatedNumbers from "react-animated-numbers";
import {useNavigate} from "react-router"
import SecondBoardDesign from "../../../games/Board-Design-Frame-Phase-3-B.png"
import {screens} from "../../../_helpers/screens";
import {gameActions} from "../../../_store";
import infoButton from "../../../games/Info-Button.png";
import {Instructions} from "./Instructions";

export const Desktop1280X720ScreenTwo = ({columnSixSteps, columnSevenSteps, columnEightSteps,
                                           columnNineSteps,columnTenSteps,
                                           userLastStep, progressLastStep}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {phaseBackground} = useSelector(x => x.games)
  const {nextStep,progress, et_score, ee_score, budget_score} = useSelector(x => x.progress)
  const currentEEScore     =   screens.desktopFive.eeIndicator + progress.progress.ee_score
  const currentETScore     =   screens.desktopFive.etIndicator + progress.progress.et_score
  const currentBudgetScore =   screens.desktopFive.budgetIndicator + progress.progress.budget_score
  const progressObject     = progress.progress && progress.progress

  const [effectivenessScore, setEffectivenessScore] = useState(0)
  const [timeScore, setTimeScore] = useState(0)
  const [budgetScore, setBudgetScore] = useState(0)

  useEffect(() => {
    setEffectivenessScore(currentEEScore)
    setTimeScore(currentETScore)
    setBudgetScore(currentBudgetScore)
  }, [currentEEScore, currentETScore, currentBudgetScore])

  // const phaseName =  progress.progress && progress.progress.phase_name
  const phaseName   = nextStep.nextPhaseStep && nextStep.nextPhaseStep.phase_name
  const description = nextStep?.nextPhaseStep?.description

  console.log("progress from desktop 1280 second screen", progress)

  return (

      <div className="container">
        <div className="game-board">

          <div>

            <div className="static">

              {columnSixSteps && columnSixSteps.map((step, index)=>
                  <div>
                    {index === 3 &&
                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        progressLastStep={progressLastStep}
                        divStyle={{ top: `80px` ,left: `40px`}}
                        tileStyle={{width: `55%`, marginTop: `-5px` }}
                        busStyle={{left:`-5px`, width: `35%`, marginTop: `0` }}
                        column={6}
                        number={index}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                    />
                    }

                    {index === 2 &&
                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        progressLastStep={progressLastStep}
                        divStyle={{top: `190px`, left: `40px`}}
                        tileStyle={{width: `55%`, marginTop: `2px`}}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `0` }}
                        column={6}
                        number={index}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />

                    }

                    {index === 1 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        progressLastStep={progressLastStep}
                        divStyle={{top: `300px`, left: `40px`}}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `0` }}
                        column={6}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />

                    }

                    {index === 0 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{top: `415px`, left: `40px`}}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `0` }}
                        column={6}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />
                    }

                  </div>
              )}

            </div>

            <div className="static">

              {columnSevenSteps && columnSevenSteps.map((step, index)=>
                  <div>
                    {index === 0 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `240px`, top: `78px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={7}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />

                    }

                    {index === 1 &&
                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `240px`, top: `190px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={7}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />
                    }

                    {index === 2 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `240px`, top: `300px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={7}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />
                    }

                    {index === 3 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `240px`, top: `415px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={7}
                        greenTickerStyle={{top: `-10px`,  left: `40px`, width: `12%`}}
                    />
                    }

                  </div>
              )}


            </div>

            <div className="static">

              {columnEightSteps && columnEightSteps.map((step, index)=>
                  <div>
                    {index === 3 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        progressLastStep={progressLastStep}
                        divStyle={{ left: `440px`, top: `78px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        number={index}
                        column={8}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />

                    }

                    {index === 2 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        progressLastStep={progressLastStep}
                        divStyle={{ left: `440px`, top: `190px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={8}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />

                    }

                    {index === 1 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        progressLastStep={progressLastStep}
                        divStyle={{ left: `440px`, top: `300px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-5px`, width: `35%`, marginTop: `10px` }}
                        number={index}
                        column={8}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />

                    }

                    {index === 0 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        progressLastStep={progressLastStep}
                        divStyle={{ left: `440px`, top: `415px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-5px`, width: `35%`, marginTop: `10px` }}
                        number={index}
                        column={8}
                        greenTickerStyle={{top: `0px`,  left: `30px`, width: `12%`}}
                    />
                    }

                  </div>
              )}

            </div>

            <div className="static">

              {columnNineSteps && columnNineSteps.map((step, index)=>
                  <div>
                    {index === 0 &&
                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `640px`, top: `80px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={9}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}

                    />
                    }

                    {index === 1 &&
                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `640px`, top: `190px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={9}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}

                    />
                    }

                    {index === 2 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `640px`, top: `300px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={9}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />

                    }

                    {index === 3 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `640px`, top: `415px` }}
                        tileStyle={{width: `55%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `35%`, marginTop: `10px` }}
                        column={9}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                    />
                    }

                  </div>
              )}

            </div>


            <div className="static">

              {columnTenSteps && columnTenSteps.map((step, index)=>
                  <div>
                    {index === 3 &&
                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `840px`, top: `84px` }}
                        tileStyle={{width: `74%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `40%`, top: `-10px` }}
                        column={10}
                        type={`AnchoringPhase`}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                    />
                    }

                    {index === 2 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `840px`, top: `190px` }}
                        tileStyle={{width: `74%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `50%`, marginTop: `10px` }}
                        column={10}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                    />

                    }

                    {index === 1 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `840px`, top: `300px` }}
                        tileStyle={{width: `74%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `50%`, marginTop: `10px` }}
                        column={10}
                        greenTickerStyle={{top: `-10px`,  left: `40px`, width: `18%`}}
                    />

                    }

                    {index === 0 &&

                    <SingleTile
                        step={step}
                        userLastStep={userLastStep}
                        divStyle={{ left: `840px`, top: `415px` }}
                        tileStyle={{width: `74%`, marginTop: `2px` }}
                        busStyle={{left:`-7px`, width: `50%`, marginTop: `10px` }}
                        column={10}
                        greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}

                    />
                    }

                  </div>
              )}

            </div>

            <div style={{
              position: `absolute`,
              top: `542px`,
              left: `345px`,
            }}>


              <div style={{
                border: `1px solid #000`,
                width: `36px`,
                position: `absolute`,
                background: `#000`,
                height: `22px` }}>
                <p style={{color: `white`,
                  marginTop: `-1px`,
                  marginLeft: `4px`
                }}>
                  {!isNaN(progressObject.actual_ee_score)? progressObject.actual_ee_score + 100 : 100}
                </p>

              </div>


              <div style={{
                border: `1px solid #000`,
                width: `36px`,
                background: `#000`,
                position: `relative`,
                left: `298px`,
                height: `22px` }}>
                <p style={{color: `white`, marginTop: `-1px`, marginLeft: `4px`}}>
                  {!isNaN(progressObject.actual_et_score)? progressObject.actual_et_score + 100 : 100}
                </p>
              </div>



              <div style={{
                border: `1px solid #000`,
                width: `36px`,
                position: `relative`,
                left: `600px`,
                top: `-22px`,
                background: `#000`,
                height: `22px` }}>
                <p style={{color: `white`, top: `-1px`, marginLeft: `4px`}}>
                  {!isNaN(progressObject.actual_budget_score) ? progressObject.actual_budget_score + 100  : 100}
                </p>
              </div>


            </div>

            <div style={{
              position: `absolute`,
              top: `550px`
            }}>

              <div className={`absolute` }
                   style={{ left: `${currentEEScore+20}px`}}>
                <img src={GreenIndicator}   alt="indicator" />
              </div>

              <div className={`absolute` } style={{ left: `${currentETScore+48}px`}}>
                <img src={GreenIndicator}  alt="indicator" />
              </div>

              <div
                  style={{
                    position: `relative`,
                    left: `${currentBudgetScore+79}px`
                  }}>
                <img src={GreenIndicator}  alt="indicator" />
              </div>

            </div>
          </div>


          <img src={SecondBoardDesign} width={1000} />

          {/*<button*/}
          {/*    onClick={() =>*/}
          {/*        navigate(-1)*/}
          {/*    }*/}
          {/*    className=" exit-game bg-gray-300 text-black font-bold py-2 px-4 rounded">*/}
          {/*  Exit game*/}
          {/*</button>*/}
        </div>
        <div className="instructions" >
          <Instructions />
        </div>
      </div>

  )

}