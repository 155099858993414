import { store, authActions } from '../_store';
import axios from "axios";

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    patch: request('PATCH'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }

        return axios({
            method: method,
            url: url,
            data: requestOptions.body,
            headers: requestOptions.headers
        }).then(handleResponse)

    }
}

// helper functions

function authHeader(url) {
    const token = authToken();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
        return { Authorization: `Bearer ${token}` };
}

function authToken() {
    const authState = store.getState().auth
    return authState.isAdmin === "true"? authState.adminUser?.token :  authState.user?.token;
}

function handleResponse(response) {
    console.log("Handle response", response)
    return response && response.data
}