import ContentEditable from "react-contenteditable";
import React, {useRef} from "react";

export const NewSingleStep = () => {

    const contentEditable = React.createRef()
    const orderEditable = React.createRef()
    const text = useRef('');
    const orderText = useRef('');

    const handleChange = evt => {
        text.current = evt.target.value;
    };

    const handleOrderNumberChange = evt => {
        orderText.current = evt.target.value;
    }

    return (
        <div className={`w-3/12 h-64 flex flex-col justify-between dark:bg-gray-800 bg-white  dark:border-gray-700 rounded-lg border border-gray-400 mb-6 py-5 px-4`}>

            <div>
                <h3 className="text-gray-800 dark:text-gray-100 leading-7 font-semibold w-11/12">
                    <ContentEditable
                        innerRef={contentEditable}
                        html={""}
                        disabled={false}
                        onChange={handleChange}
                        tagName='article'
                        className="p-2 div-editable"
                    />

                </h3>
            </div>

            <div>
                <div className="mb-3 flex items-center">
                    <div className="p-1 bg-gray-800 dark:bg-gray-100 rounded text-yellow-500">

                        <ContentEditable
                            innerRef={orderEditable}
                            html={`0`}
                            disabled={false}
                            onChange={handleOrderNumberChange}
                            tagName='div'
                            className="p-3 div-editable"
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between text-gray-800 dark:text-gray-100">
                    <p className="text-sm">{""}</p>
                    <div className="grid grid-cols-3 gap-1">
                    </div>
                </div>
            </div>

            <button className="bg-gray-800 dark:bg-gray-100 w-3/12 text-white rounded p-1">Save</button>
        </div>

    )
}