import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { teamActions } from "../_store";
import { toast, ToastContainer } from "react-toastify";
import {ProductNav} from "./ProductNav";
import SessionEndedModal from "./superAdmin/SessionEndedModal";
import EmptyAssessment from "./EmptyAssessment";
import AssessmentTable from "./AssessmentTable";
import * as XLSX from "xlsx";

function Assessments() {
    const dispatch = useDispatch();
    const teams = useSelector((x) => x.teams);
    const teamList = teams.list.teams;
    const { user, isLoggedOut } = useSelector((x) => x.auth);
    const { licenses, licensedEmails } = useSelector((x) => x.licenses);
    const [searchParams] = useSearchParams();
    const plan = searchParams.get("plan");
    const navigate = useNavigate();
    const emailsCount = licensedEmails && licensedEmails.length;
    const license = user && user.license;
    const { id } = useParams();

    useEffect(() => {
        dispatch(teamActions.getAll({ license: id }));
    }, []);

    const checkEmailCount = () => emailsCount > user.quantity - 1;

    const onNewTeamClicked = () => {
        if (checkEmailCount()) {
            toast.info(process.env.UPGRADE_NOTICE);
        } else {
            navigate("/new-team");
        }
    };

    const exportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(teamList);
        XLSX.utils.book_append_sheet(wb, ws, "Assessments");
        XLSX.writeFile(wb, "assessments.xlsx");
    };

    return (
        <>
            <ToastContainer />
            {isLoggedOut && <SessionEndedModal />}

            <div className="min-h-full">
                <ProductNav id={id} plan={plan} />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Assessments</h1>

                    </div>
                </header>

                <br />
                <br />
                <main>

                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">

                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                {teamList && teamList.length === 0 && (
                                    <EmptyAssessment id={id} plan={plan} />
                                )}

                                {teamList && teamList.length > 0 && (
                                    <>

                                        <AssessmentTable
                                            teamList={teamList}
                                            id={id}
                                            plan={plan}
                                            onNewTeamClicked={onNewTeamClicked}
                                            exportToExcel={exportToExcel}
                                        />
                                    </>

                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Assessments;
