import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'licenses';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,reducers, extraReducers });

// exports
export const licenseActions = { ...slice.actions, ...extraActions };
export const licensesReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    licenses: {},
    licensedEmails : [],
    userLicenses : [],
    licenseQuantity: 0
  }
}

function createReducers() {
  return {
    setLicensedEmails,
    setLicenses
  }

  function setLicensedEmails (state, action){
    state.licensedEmails = action.payload
  }

  function setLicenses (state, action){
    state.licenses = action.payload
  }
}

function createExtraActions() {
  const url = `${process.env.REACT_APP_API_BASE_URL}/licenses`;

  return {
    getUserLicenses: getUserLicenses(),
    getAll: getTeamLicenses(),
    updateStatus: updateUserStatus(),
    completeGame: completeGame(),
  }

  function getUserLicenses() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/licenses`
    return createAsyncThunk(
        `${name}/getUserLicenses`,
        async (body) => await fetchWrapper.post(url, body)
    );
  }

  function getTeamLicenses() {
    return createAsyncThunk(
        `${name}/getAll`,
        async (body) => await fetchWrapper.post(url, body)
    );
  }

  function updateUserStatus() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/update-user-licenses`;

    return createAsyncThunk(
        `${name}/updateUserStatus`,
        async (body) => await fetchWrapper.post(url, body)
    );
  }

  function completeGame() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/complete-game`;

    return createAsyncThunk(
        `${name}/completeGame`,
        async (body) => await fetchWrapper.post(url, body)
    )
  }
}

function createExtraReducers() {
  return {
    ...getUserLicenses(),
    ...getAll(),
    ...updateUserStatus(),
    ...completeGame()
  };

  function getUserLicenses() {
    const { pending, fulfilled, rejected } = extraActions.getUserLicenses;
    return {
      [pending]: (state) => {
        state.userLicenses = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.userLicenses = action.payload.data;
      },
      [rejected]: (state, action) => {
        state.userLicenses = { error: action.error };
      }
    };
  }


  function getAll() {
    const { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.licenses = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.licenses = action.payload.data;
        const quantity      = action.payload.licenseQuantity
        state.licenseQuantity = quantity
        state.licensedEmails = action.payload.data.map((licenseArray) => {
          return licenseArray.email
        })
      },
      [rejected]: (state, action) => {
        state.licenses = { error: action.error };
      }
    };
  }

  function updateUserStatus() {
    const { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.licenses = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.licenses = action.payload.data;
        state.licensedEmails = action.payload.data.map((licenseArray) => {
          return licenseArray.email
        })
      },
      [rejected]: (state, action) => {
        state.licenses = { error: action.error };
      }
    };
  }

  function completeGame() {
    const { pending, fulfilled, rejected } = extraActions.completeGame;
    return {
      [pending]: (state) => {
        // state.licenses = { loading: true }
      },
      [fulfilled]: (state, action) => {
        // state.licenses = action.payload.data
      },
      [rejected]: (state, action) => {
        // state.licenses = { error: action.error }
      }
    }
  }

}