import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import { teamActions, licenseActions } from "../../_store";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {SpinningButton} from "../button/Buttons";
import {toast, ToastContainer} from "react-toastify";
import {promiseToast} from "../../_helpers/alerts";
import {isValidEmail} from "../../_helpers/functions";
import {useParams, useSearchParams, useNavigate} from "react-router-dom";


export const TeamInfo = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { user, teams, licenses } = useSelector((x) => ({
        user: x.auth.user,
        teams: x.teams,
        licenses: x.licenses,
    }));
    const [searchParams] = useSearchParams();
    const plan = searchParams.get("plan");
    const { license_id } = useParams();

    const [maxExpiryDate, setMaxExpiryDate] = useState("");

    const validationSchema = Yup.object().shape({
        team_name: Yup.string().required("team name is required"),
        contact_email: Yup.string().required("contact email is required"),
        team_size: Yup.string().required("team size is required"),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const [licenseQuantity, setLicenseQuantity] = useState(0)
    const {licensedEmails} = useSelector( x=> x.licenses);



    useEffect(() => {
        const today = new Date();
        const maxDate = new Date(
            today.getFullYear(),
            today.getMonth() + 6,
            today.getDate()
        );
        setMaxExpiryDate(maxDate.toISOString().split("T")[0]);
    }, []);

    useEffect(() => {
        dispatch(licenseActions.getAll({administrator: user?.id})).then((res) => {
            console.log("Res", res)
            setLicenseQuantity(res.payload.licenseQuantity)
        })
    },[])

    const onSubmit = ({ team_name, contact_email, team_size, expiry_date }) => {

        if (!isValidEmail(contact_email)) {
            toast.error("Email must be valid!");
            return;
        }

        if (!teams.companyInfoCompleted) {
            toast.info("You need to complete the company profile first!");
            return;
        }

        // if ((licensedEmails.length + Number(team_size)) > licenseQuantity ){
        //     toast.info("You have exceeded your license limit!");
        //     return;
        // }

        const teamObject = teams.company;
        const companyObject = teams.companyObject;

        console.log("Onsubmit", `${team_name} - ${contact_email} - ${team_size} - ${expiry_date}`)
        // return

        dispatch(
            teamActions.setTeamObject({
                team_name,
                contact_email,
                team_size,
                teamObject,
                license: license_id,
                companyObject,
                expiry_date
            })
        );

        dispatch(teamActions.setIsTeamInfoCompleted(true));

        dispatch(teamActions.addTeam({
                team_name,
                contact_email,
                team_size,
                teamObject,
                license: license_id,
                companyObject,
                expiry_date
        })).then((response) => {
            if(response.type === "teams/addTeam/rejected"){
                promiseToast("Saving team", "Failed to save!");
                return
            }
        });

        promiseToast("Saving team", "Successfully saved team");

        setTimeout(() => {
            // navigate(`/products`)

            navigate(`/assessments/${license_id}`)
        },1500)
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}  method="POST">
                <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Name
                                </label>
                                <input
                                    placeholder="Team name"
                                    {...register("team_name")}
                                    type="text"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                    Team Coordinator
                                </label>
                                <input
                                    placeholder="Team's coordinator"
                                    {...register("contact_email")}
                                    name="contact_email"
                                    type="text"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Team Size
                                </label>
                                <input
                                    {...register("team_size")}
                                    disabled={!teams.companyInfoCompleted || teams.teamInfoCompleted}
                                    name="team_size"
                                    type="number"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />

                            </div>

                            <div className="col-span-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Expiry Date
                                </label>
                                <input
                                    {...register("expiry_date")}
                                    name="expiry_date"
                                    type="date"
                                    min={new Date().toISOString().split("T")[0]}
                                    max={maxExpiryDate}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">

                            {isSubmitting ? <SpinningButton />
                                : teams.teamInfoCompleted ? "Update" : "Add and Send email to coordinator" }

                        </button>
                    </div>
                    {/*}*/}
                </div>
            </form>

        </>
    )
}