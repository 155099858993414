import React, { useEffect, useRef, useState } from "react";
import { readString } from "papaparse";
import {toast} from "react-toastify";
import Modal from "react-modal";
import * as XLSX from "xlsx";
import {teamActions, licenseActions} from "../../_store";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {promiseToast} from "../../_helpers/alerts";
import { BsX } from "react-icons/bs";

Modal.setAppElement("#root");

const EmailTag = ({ email, onRemove }) => {
    return (
        <div className="inline-flex items-center bg-gray-500 text-white text-sm font-medium rounded-full px-3 py-1 m-1.5 shadow-md">
            <span>{email}</span>
            <button
                onClick={onRemove}
                className="ml-2 bg-gray-300 hover:bg-gray-400 focus:outline-none transition-colors duration-150 rounded-full p-1"
            >
                <BsX className="text-gray-800"/>
            </button>
        </div>
    );
};

const emailTemplate = (inputValue) =>  `
  <div>
             <p>Good day,&nbsp;</p>
        </div>
        
        <div>
            <p>Welcome to the <strong>ChangeVerve Change Management Game.</strong> 
            
            An interactive online platform that guides you through the common scenarios, events, and hurdles encountered during the implementation of significant organisational change initiatives.&nbsp;</p>
        </div>
     
        <p style="font-weight: 400;"> ${inputValue} </p>    
        <br/>
        
        <div>
            <p><strong>REGISTRATION AND&nbsp;LOGIN:&nbsp;</strong></p>
        </div>
        
        <div>
            <p>If you have a&nbsp;ChangeVerve&nbsp;account, please log&nbsp;in with your username and password to access the&nbsp;ChangeVerve&nbsp;Change Management Game.&nbsp;</p>
        </div>
        
        <div>
            <p>If you are a first-time user of ChangeVerve products, kindly follow the registration steps below.&nbsp;</p>
        </div>
     
        <p><strong>Steps to register for the game: </strong></p>
        <p><strong>Step 1</strong>: &nbsp;CLICK on the following link to access the game platform: &nbsp; https://changeverveacademy.com/invitee-register/{user} &nbsp;</p>
 
        <p><strong>Step 2</strong>: &nbsp;Enter your name, surname and email address in the fields provided. <span style="color: red">NB: Use the email address on which you received this email.</span></p>
             
        <p><strong>Step 3</strong>:  Create a password and CLICK on the “REGISTER” button.&nbsp;</p>
               
        <p><strong>Step 4</strong>:  Log in using your email and password on the login screen to go to your ChangeVerve Academy dashboard. &nbsp;</p>
             
        <p><strong>Step 5:</strong>  CLICK on the tile indicating the ChangeVerve Change Management Game for your team to start playing.</p>
                <br/>
       
        <p>For future login after registration, use the following link to access the game. https://changeverveacademy.com &nbsp;</p>
        
        <p>
        The game offers flexibility in terms of playtime, allowing for either a single extended session lasting approximately 6 to 8 hours, 
        or multiple shorter intervals that fit within your schedule. Your progress will be tracked and saved, 
        allowing you to resume play at the point where you last left off upon your return.
        </p>
   
        <p><strong>Your organisation requires you to complete the game by close of business {date}.</strong></p>
        <br/>
        <p>Should you have any difficulty logging in, kindly contact your Team Coordinator at {email}.</p>
        <br/>
        <p>Good luck and enjoy the experience!</p>
           <br/>
        <p>Sincerely,&nbsp;</p>
        <p>The ChangeVerve team</p>
`

export const MemberEmails = () => {
    const {team_id} = useParams()
    const [inputValue, setInputValue] = useState("");
    const {licensedEmails} = useSelector( x=> x.licenses);
    const [validatedEmails, setValidatedEmails] = useState([]);
    const [error, setError] = useState("");
    const [csvData, setCsvData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(0);
    const teams = useSelector(x => x.teams );
    const teamList = teams?.teamMembers?.data
    const [csvHeaders, setCsvHeaders] = useState([]);
    const dispatch = useDispatch()

    const [requiredInputValue, setRequiredInputValue] = useState("");
    const [grammarError, setGrammarError] = useState("");
    const [content, setContent] = useState("")
    const [submitted, setSubmitted] = useState(false)

    const [interval, setInterval] = useState('daily');
    const [time, setTime] = useState('');
    const [dayOfWeek, setDayOfWeek] = useState('1');
    const [secondDayOfWeek, setSecondDayOfWeek] = useState('2');
    const [dayOfMonth, setDayOfMonth] = useState('1');
    const [showModal, setShowModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [activeTab, setActiveTab] = useState('type');
    const teamsData = teams?.teamMembers?.data
    const licenseQuantity = teamsData && teamsData.length > 0 && teamsData[0]?.licenseQuantity
    const licenseId = teamsData && teamsData.length > 0 && teamsData[0]?.userLicense
    const [licenseCount, setLicenseCount] = useState(0)
    const [previousEmailsCount, setPreviousEmailsCount] = useState(0)
    const currentLicenseCount = (validatedEmails.length - previousEmailsCount) + Number(licenseCount)

    console.log("License quantity", licenseQuantity)
    console.log("Licenses", licensedEmails)
    console.log("current license count", currentLicenseCount)

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const validateEmail = (email) => {
        const re = /^[\w-]+(\+[\w-]+)?(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return re.test(email);
    };

    const handleInputBlur = () => {
        const emailArray = inputValue.split(/[ ,;:]+/).filter(Boolean);
        const validEmails = emailArray.filter((email) => validateEmail(email));
        setValidatedEmails([...new Set([...validatedEmails, ...validEmails])]);
        setSubmitted(false)

        const invalidEmails = emailArray.filter((email) => !validateEmail(email));
        setError(invalidEmails.length ? "Invalid email(s): " + invalidEmails.join(", ") : "");
        setInputValue("");
    };

    const handleRemoveEmail = (index) => {
        setValidatedEmails(validatedEmails.filter((_, i) => i !== index));
    };

    const clearInvalidEmails = () => {
        setError("");
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (evt) => {
            if (file.type === "text/csv") {
                const parsedData = readString(evt.target.result, { header: false });
                setCsvHeaders(parsedData.data[0]);
                setCsvData(parsedData.data.slice(1));
            } else if (
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type === "application/vnd.ms-excel"
            ) {
                const workbook = XLSX.read(evt.target.result, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                setCsvHeaders(data[0]);
                setCsvData(data.slice(1));
            } else {
                console.error("Unsupported file type");
                return;
            }

            setIsModalOpen(true);
        };
        reader.onerror = (evt) => {
            console.error("Error reading file:", evt);
        };

        if (file.type === "text/csv") {
            reader.readAsText(file);
        } else {
            reader.readAsBinaryString(file);
        }
    };

    const handleAddEmailsFromCsv = () => {
        const emails = csvData.map((row) => row[selectedColumn]);
        const validEmails = emails.filter((email) => validateEmail(email));
        setValidatedEmails([...new Set([...validatedEmails, ...validEmails])]);

        const invalidEmails = emails.filter((email) => !validateEmail(email));
        setError(invalidEmails.length ? "Invalid email(s): " + invalidEmails.join(", ") : "");

        setIsModalOpen(false);
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData("text");

        const emailArray = pastedText.split(/[ ,;:]+/).filter(Boolean);
        const validEmails = emailArray.filter((email) => validateEmail(email));
        setValidatedEmails([...new Set([...validatedEmails, ...validEmails])]);

        const invalidEmails = emailArray.filter((email) => !validateEmail(email));
        setError(invalidEmails.length ? "Invalid email(s): " + invalidEmails.join(", ") : "");
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleInputBlur();
        }
    };

    const handleContentChange = (e) =>  {
        setContent(e.target.value)
    }
    const checkForGrammarErrors = (text) => {
        const re = /\s{3,}/; // Checks for three or more consecutive spaces
        return re.test(text);
    };

    const handleSubmit = () => {
        if (validatedEmails.length === 0) {
            setError("At least 1 email is required");
            return;
        }
        // Call your API to submit the data here
        console.log("Submitting the following emails:", validatedEmails);

        if (checkForGrammarErrors(requiredInputValue)) {
            setGrammarError("Please fix the grammatical errors in the required field.");
            return;
        }

        setGrammarError("");

        setSubmitted(true)

        if (currentLicenseCount > licenseQuantity){
            setError("You have exceeded the number of emails your license allows!")
            setAlertModal(true);
            return;
        }

        dispatch(teamActions.addEmails({emails: validatedEmails, team_id, content}))
            .then((response) => {
                if (response.type === "rejected"){
                    setSubmitted(false)
                    return
                }

                toast.success("Successfully invited members!")
                setSubmitted(true)
        })
    };

    useEffect(() => {
        dispatch(teamActions.getTeamById({team_id}));
        dispatch(teamActions.getTeamMembers(team_id));
        dispatch(licenseActions.getUserLicenses({license: licenseId, team: team_id})).then((res) => {
            setPreviousEmailsCount(res?.payload?.data.length)
        })
    }, []);

    useEffect(() => {
        dispatch(licenseActions.getUserLicenses({license: licenseId})).then((res) => {
            console.log("Licenses from useEffect", res?.payload?.data?.length)
            setLicenseCount(res?.payload?.data?.length)
        })
    }, [licenseId]);

    const validateLicenseCount = () => {
        dispatch(licenseActions.getUserLicenses({license: licenseId})).then((res) => {
            console.log("Licenses from useEffect", res?.payload?.data?.length)
            setLicenseCount(res?.payload?.data?.length)
        })
    }

    useEffect(() => {
            if (Array.isArray(teamList)) {
                const mappedEmails = teamList.map((tm) => {
                    return tm.email;
                });

                // setValidatedEmails(mappedEmails);
                setValidatedEmails([...new Set([...validatedEmails, ...mappedEmails])]);

            } else {
                setValidatedEmails([]);
            }
    }, [teamList]);


    const closeModal = () => {
        setShowModal(false)
        setShowEmailModal(false)
    }

    // const handlePreviewClick = () => {
    //     const previewContent = content;
    //     // Replace ${content} in the email template with the previewContent
    //     const updatedEmailTemplate = emailTemplate?.replace('{content}', previewContent);
    //     // Display the updated email template in a desired location
    // };


    return (
        <div className="w-full">
            <h1 className="text-3xl font-semibold text-[#a70000] leading-tight mb-6">Add Team Members</h1>

            <div className="mb-2 text-sm text-red-500">{error}

                {error && (
                    <button
                        onClick={clearInvalidEmails}
                        className="ml-4 px-2 py-1 bg-red-500 text-white font-semibold rounded-md hover:bg-red-600 focus:outline-none"
                    >
                        Clear Invalid Emails
                    </button>
                )}
            </div>

            <div className="mb-4">
                {validatedEmails.map((email, index) => (
                    <EmailTag
                        key={index}
                        email={email}
                        onRemove={() => handleRemoveEmail(index)}
                    />
                ))}
            </div>


            <div>
                <div className="flex mb-4">
                    <button
                        className={`text-lg font-medium p-2 ${activeTab === 'type' ? 'border-b-2 border-blue-500' : ''}`}
                        onClick={() => setActiveTab('type')}
                    >
                        Type Email Addresses
                    </button>
                    <button
                        className={`text-lg font-medium p-2 ${activeTab === 'upload' ? 'border-b-2 border-blue-500' : ''}`}
                        onClick={() => setActiveTab('upload')}
                    >
                        Upload CSV
                    </button>
                </div>

                {activeTab === 'type' ? (
                    <div>
                        <label htmlFor="emailAddresses" className="block text-lg font-medium text-gray-700 mb-2">Email Addresses</label>
                        <textarea
                            id="emailAddresses"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            onPaste={handlePaste}
                            onKeyPress={handleKeyPress}
                            className="w-full h-40 border border-gray-300 rounded-md p-4 resize-none focus:ring-2 focus:ring-blue-200 focus:border-transparent shadow-md"
                            placeholder="Enter email addresses separated by space, colon, semi-colon, or comma"
                        />
                    </div>
                ) : (
                    <div>
                        <label htmlFor="fileUpload" className="block text-lg font-medium text-gray-700 mb-2">Upload CSV</label>
                        <div className="w-full h-12 border border-gray-300 rounded-md p-2 cursor-pointer flex items-center justify-center bg-white text-gray-700 hover:bg-gray-100 focus:ring-2 focus:ring-blue-200 focus:border-transparent shadow-md">
                            <div className="relative">
                                <input
                                    type="file"
                                    accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={handleFileUpload}
                                    id="fileUpload"
                                    className="absolute inset-0 opacity-0 cursor-pointer"
                                />
                                <span className="text-center">Click to select a Excel/CSV file</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <br/>
            <label htmlFor="emailContent" className="block text-lg font-medium text-gray-700 mb-2">Email Content</label>
            <textarea
                id="emailContent"
                onChange={handleContentChange}
                className="w-full h-40 border border-gray-300 rounded-md p-4 resize-none focus:ring-2 focus:ring-blue-200 focus:border-transparent shadow-md"
                placeholder="Add email content"
            />


            <div className="mt-2 text-sm text-red-500">{grammarError}</div>

            <br/>

            <button
                onClick={() => setShowModal(true)}
                className="mr-3 bg-[#a70000] text-white font-bold py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-[#a70000] focus:ring-opacity-50"
            >
                Set Reminder
            </button>

            <button
                onClick={() => setShowEmailModal(true)}
                className=" bg-[#a70000] text-white font-bold py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-[#a70000] focus:ring-opacity-50"
            >
                Preview Email
            </button>


            {showEmailModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                        <div
                            className="fixed inset-0 transition-opacity"
                            aria-hidden="true"
                            onClick={ () => closeModal()}
                        >
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="reminder-interval-input p-4 bg-white shadow-md rounded-md w-full max-w-md mx-auto">
                                    <div dangerouslySetInnerHTML={{ __html: emailTemplate(inputValue) }} />
                                </div>

                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    onClick={ () => closeModal()}
                                    className="mt-3 sm:mt-0 sm:ml-3 text-gray-700 font-semibold py-2 px-4 rounded hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <div
                        className="fixed inset-0 transition-opacity"
                        aria-hidden="true"
                        onClick={closeModal}
                    >
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>


                    <div
                        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">


                            <div className="reminder-interval-input p-4 bg-white shadow-md rounded-md w-full max-w-md mx-auto">
                                    <div className="flex flex-col">
                                        <label htmlFor="interval-select" className="text-lg font-semibold mb-2">Reminder interval:</label>
                                        <select
                                            id="interval-select"
                                            value={interval}
                                            onChange={(e) => setInterval(e.target.value)}
                                            className="p-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#a70000]"
                                        >
                                            <option value="daily">Daily</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="bi-weekly">Bi-Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </select>
                                    </div>

                                {interval === 'weekly' && (
                                    <div className="flex flex-col">
                                        <label htmlFor="dayOfWeek" className="text-lg font-semibold mb-2">
                                            Day of the week:
                                        </label>
                                        <select
                                            id="dayOfWeek"
                                            value={dayOfWeek}
                                            onChange={(e) => setDayOfWeek(e.target.value)}
                                            className="p-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#a70000]"
                                        >
                                            <option value="">Select a day</option>
                                            <option value="Sunday">Sunday</option>
                                            <option value="Monday">Monday</option>
                                            <option value="Tuesday">Tuesday</option>
                                            <option value="Wednesday">Wednesday</option>
                                            <option value="Thursday">Thursday</option>
                                            <option value="Friday">Friday</option>
                                            <option value="Saturday">Saturday</option>
                                        </select>
                                    </div>
                                )}

                                {interval === 'bi-weekly' && (
                                    <div className="flex flex-col">
                                        <label htmlFor="dayOfWeek" className="text-lg font-semibold mb-2">
                                            Choose day of first week:
                                        </label>
                                        <select
                                            id="dayOfWeek"
                                            value={dayOfWeek}
                                            onChange={(e) => setDayOfWeek(e.target.value)}
                                            className="p-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#a70000]"
                                        >
                                            <option value="">Select a day</option>
                                            <option value="1">Sunday</option>
                                            <option value="2">Monday</option>
                                            <option value="3">Tuesday</option>
                                            <option value="4">Wednesday</option>
                                            <option value="5">Thursday</option>
                                            <option value="6">Friday</option>
                                            <option value="7">Saturday</option>
                                        </select>
                                        <label htmlFor="secondDayOfWeek" className="text-lg font-semibold mb-2 mt-4">
                                            Choose day of second week:
                                        </label>
                                        <select
                                            id="secondDayOfWeek"
                                            value={secondDayOfWeek}
                                            onChange={(e) => setSecondDayOfWeek(e.target.value)}
                                            className="p-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#a70000]"
                                        >
                                            <option value="">Select a day</option>
                                            <option value="1">Sunday</option>
                                            <option value="2">Monday</option>
                                            <option value="3">Tuesday</option>
                                            <option value="4">Wednesday</option>
                                            <option value="5">Thursday</option>
                                            <option value="6">Friday</option>
                                            <option value="7">Saturday</option>
                                        </select>
                                    </div>
                                )}



                                {interval === 'monthly' && (
                                        <div className="flex flex-col">
                                            <label htmlFor="dayOfMonth" className="text-lg font-semibold mb-2">Day of the month:</label>
                                            <select
                                                id="dayOfWeek"
                                                value={dayOfWeek}
                                                onChange={(e) => setDayOfWeek(e.target.value)}
                                                className="p-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#a70000]"
                                            >
                                                <option value="">Select a day</option>
                                                <option value="1">Sunday</option>
                                                <option value="2">Monday</option>
                                                <option value="3">Tuesday</option>
                                                <option value="4">Wednesday</option>
                                                <option value="5">Thursday</option>
                                                <option value="6">Friday</option>
                                                <option value="7">Saturday</option>
                                            </select>
                                        </div>
                                    )}
                                    <div className="flex flex-col">
                                        <label htmlFor="time" className="text-lg font-semibold mb-2">Time:</label>
                                        <input
                                            type="time"
                                            id="time"
                                            value={time}
                                            onChange={(e) => setTime(e.target.value)}
                                            className="p-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#a70000]"
                                        />
                                    </div>

                            </div>



                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="submit"
                                onClick={ closeModal}
                                className="bg-[#a70000] text-white font-bold py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-[#a70000] focus:ring-opacity-50"
                            >
                                Set Reminder
                            </button>
                            <button
                                onClick={closeModal}
                                className="mt-3 sm:mt-0 sm:ml-3 text-gray-700 font-semibold py-2 px-4 rounded hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            )}

            <Modal
                isOpen={alertModal}
                onRequestClose={() => setAlertModal(false)}
                contentLabel="Transaction Verification"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#a70000', // Change the background color here
                        color: 'white', // Change the text color here
                        padding: '20px',
                        borderRadius: '10px'
                    }
                }}
            >
                <h2>You have exceeded the number of emails your license allows!</h2>

                <button
                    style={{
                        marginTop: '20px',
                        backgroundColor: 'white', // Button background color
                        color: '#a70000', // Button text color
                        border: 'none',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        borderRadius: '5px'
                    }}
                    onClick={() => setAlertModal(false)}
                >
                    Close
                </button>
            </Modal>


            <div className={`fixed z-10 inset-0 overflow-y-auto ${!isModalOpen && "hidden"}`}>
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Select Email Column</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">Please select the column containing the email addresses.</p>
                        </div>
                        <select
                            value={selectedColumn}
                            onChange={(e) => setSelectedColumn(Number(e.target.value))}
                            className="w-full mb-4 py-2 px-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-200 focus:border-transparent"
                        >
                            {csvHeaders.map((header, index) => (
                                <option key={index} value={index}>
                                    {header}
                                </option>
                            ))}
                        </select>
                        <div className="mt-5 sm:mt-6">
                            <button
                                type="button"
                                onClick={handleAddEmailsFromCsv}
                                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#a70000] text-base font-medium text-white hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                            >
                                Add Emails
                            </button>
                            <button
                                type="button"
                                onClick={() => setIsModalOpen(false)}
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-between items-center mt-4">
                <span className="text-lg font-semibold text-gray-700">Email count: {validatedEmails.length}</span>
                <button
                    disabled={submitted}
                    onClick={handleSubmit}
                    className={`ml-4 px-4 py-2 font-semibold text-lg rounded-md transition duration-200 ease-in-out ${
                        submitted
                            ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                            : "bg-[#a70000] text-white hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-[#a70000] focus:ring-opacity-50"
                    }`}
                >
                    Submit
                </button>
            </div>

        </div>
);
};
