import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
// import GameBoard from "../games/Example-1.png";
import GameBoard from "../games/Game-Board-one.png";



// create slice

const name = 'games';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,reducers, extraReducers });
// const slice = createSlice({ name, initialState, extraReducers });

// exports
export const gameActions = { ...slice.actions, ...extraActions };
export const gamesReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        list:  [],
        steps: [],
        game: {},
        isModalOpen: false,
        isMediaModalOpen: false,
        isResultModalOpen: false,
        isCorrectResultModalOpen: false,
        isWrongResultModalOpen: false,
        isVideoModalOpen: false,
        currentModalStep: {},
        phaseBackground: GameBoard,
        hoverModalOpen: false,
        hoverDescription: "",
        hasSupportedScreen: false,
        choiceOfAnswer: {},
        infoButtonModalOpened: false,
        assessmentIntroModalOpened: false
    }
}

function createReducers() {
    return {
        openModal,
        closeModal,
        openMediaModal,
        closeMediaModal,
        openCorrectResultModal,
        closeCorrectResultModal,
        openWrongResultModal,
        closeWrongResultModal,
        openVideoModal,
        closeVideoModal,
        setCurrentModalStep,
        setPhaseBackground,
        setHoverModal,
        clearHoverDescription,
        setHasSupportedScreen,
        setChoiceOfAnswer,
        setInfoButtonModalOpened,
        setAssessmentIntroModalOpened
    };

    function openModal (state, action){
        state.isModalOpen = true;
    }

    function openMediaModal (state, action){
        state.isMediaModalOpen = true
    }

    function closeMediaModal (state, action){
        state.isMediaModalOpen = false
    }

    function closeModal (state, action){
        state.isModalOpen = false;
    }

    function openCorrectResultModal(state, action){
        state.isCorrectResultModalOpen = true
    }

    function  closeCorrectResultModal(state, action){
        state.isCorrectResultModalOpen = false
    }

    function openWrongResultModal(state, action){
        state.isWrongResultModalOpen = true
    }

    function closeWrongResultModal(state, action){
        state.isWrongResultModalOpen = false
    }

    function openVideoModal(state, action){
        state.isVideoModalOpen = true
    }

    function closeVideoModal(state, action){
        state.isVideoModalOpen = false
    }

    function setCurrentModalStep(state, action){
        state.currentModalStep = null
        state.currentModalStep = action.payload
    }

    function setPhaseBackground(state, action){
        state.phaseBackground = action.payload
    }

    function setHoverModal(state, action){
        state.hoverModalOpen = action.payload.status
        state.hoverDescription = action.payload.description
    }

    function clearHoverDescription(state, action){
        state.hoverDescription = ""
    }

    function setHasSupportedScreen(state, action) {
        state.hasSupportedScreen = action.payload
    }

    function setChoiceOfAnswer(state, action) {
        state.choiceOfAnswer = action.payload
    }

    function setInfoButtonModalOpened(state, action){
        state.infoButtonModalOpened = action.payload
    }


    function setAssessmentIntroModalOpened(state, action){
        state.assessmentIntroModalOpened = action.payload
    }

}

function createExtraActions() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/games`;

    return {
        getAll: getAll(),
        getGame: getGame(),
        addGame: addGame(),
    };

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(url)
        );
    }

    function getGame() {
        return createAsyncThunk(
            `${name}/getGame`,
            async ({game}) => await fetchWrapper.get(`${url}/${game}`)
        );
    }


    function addGame() {
        return createAsyncThunk(
            `${name}/addGame`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll(),
        ...getGame(),
        ...addGame()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.list = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload
            },
            [rejected]: (state, action) => {
                state.list = { error: action.error };
            }
        };
    }

    function getGame() {
        const { pending, fulfilled, rejected } = extraActions.getGame;
        return {
            [pending]: (state) => {
                state.game = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.game = action.payload
            },
            [rejected]: (state, action) => {
                state.game = { error: action.error };
            }
        };
    }

    function addGame() {
        const { pending, fulfilled, rejected } = extraActions.addGame;
        return {
            [pending]: (state) => {
                state.game = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.game = action.payload;
            },
            [rejected]: (state, action) => {
                state.game = { error: action.error };
            }
        };
    }

}
