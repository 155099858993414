import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {userActions} from "../_store";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import Modal from "react-modal";

const ReturnURLPage = () => {
    const {token}  = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const [captchaValue, setCaptchaValue] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    const {user,error} = useSelector( x => x.users)

    console.log("User", user)
    console.log("Error object", error)

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required('first name is required'),
        email:  Yup.string().required('last name is required'),
        // password:  Yup.string().required('password is required'),
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, watch } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const handleCaptchaResponseChange = (response) => {
        setCaptchaValue(response);
    };

    function onSubmit({ email }) {
        dispatch(userActions.addAdministrator({ email, token, captchaValue})).then((resp) => {
        // dispatch(userActions.addAdministrator({name, email, password, company, token, captchaValue})).then((resp) => {
            console.log("Response", resp)

        if (resp.type !== "users/addAdministrator/rejected"){
            setTimeout(() => {
                setIsModalOpen(true)
            },1000)
        }

        })
    }

    return (
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div className="absolute inset-0 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl" style={{ backgroundImage: 'linear-gradient(to right, #a70000, #ff9900)' }}></div>
                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                        <div>
                            <h1 className="text-2xl font-semibold">Add administrator's info:</h1>
                            <p className="text-red-500 mt-2">Note: This administrator will be responsible  for creating teams and appointing coordinators to oversee members.</p>

                        </div>
                        <div className="divide-y divide-gray-200">
                            <form onSubmit={handleSubmit(onSubmit)} className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                {/*<div className="relative">*/}
                                {/*    <input*/}
                                {/*        autoComplete="off"*/}
                                {/*        {...register('name', { required: true })}*/}
                                {/*        placeholder="Administrator's Name"*/}
                                {/*        className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"*/}
                                {/*    />*/}
                                {/*    <label htmlFor="name" className="absolute left-0 -top-3.5 text-gray-600 text-sm light:text-gray-400 dark:text-gray-200 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Name</label>*/}
                                {/*</div>*/}

                                <div className="relative">
                                    <input
                                        autoComplete="off"
                                        {...register('email', { required: true })}
                                        placeholder="Administrator's Email"
                                        className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
                                    />
                                    <label htmlFor="email" className="absolute left-0 -top-3.5 text-gray-600 text-sm light:text-gray-400 dark:text-gray-200 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Email</label>
                                </div>

                                {/*<div className="relative">*/}
                                {/*    <input*/}
                                {/*        type="password"*/}
                                {/*        autoComplete="off"*/}
                                {/*        {...register('password', { required: true })}*/}
                                {/*        placeholder="Administrator's Password"*/}
                                {/*        className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"*/}
                                {/*    />*/}
                                {/*    <label htmlFor="password" className="absolute left-0 -top-3.5 text-gray-600 text-sm light:text-gray-400 dark:text-gray-200 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Password</label>*/}
                                {/*</div>*/}


                                {/*<div className="relative">*/}
                                {/*    <input*/}
                                {/*        autoComplete="off"*/}
                                {/*        {...register('company', { required: true })}*/}
                                {/*        placeholder="Company Name"*/}
                                {/*        className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"*/}
                                {/*    />*/}
                                {/*    <label htmlFor="company" className="absolute left-0 -top-3.5 text-gray-600 text-sm light:text-gray-400 dark:text-gray-200 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Company Name</label>*/}
                                {/*</div>*/}

                                <ReCAPTCHA
                                    sitekey="6LdTVP8lAAAAAPcD8BcFfAydHTnEQQbUl0kfamX8"
                                    onChange={handleCaptchaResponseChange}
                                />

                                <i className="text-red-500 text-sm">{error?.message}</i>

                                <button type="submit" className="mt-3 text-center w-full py-2.5 rounded-lg bg-[#a70000] text-white shadow transition-colors duration-300 hover:bg-deep-purple-accent-700">
                                    Add
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Transaction Verification"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#a70000', // Change the background color here
                        color: 'white', // Change the text color here
                        padding: '20px',
                        borderRadius: '10px'
                    }
                }}
            >
                <h2>You have successfully set up an administrator!</h2>
                <h3>An email has been sent to the Administrator.</h3>
                <button
                    style={{
                        marginTop: '20px',
                        backgroundColor: 'white', // Button background color
                        color: '#a70000', // Button text color
                        border: 'none',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        borderRadius: '5px'
                    }}
                    onClick={() => {
                        setIsModalOpen(false)
                        setTimeout(() => {
                            navigate("/")
                        },2000)
                    }}
                >
                    Close
                </button>
            </Modal>
        </div>

    );
};

export default ReturnURLPage;

