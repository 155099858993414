import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {
    confirmModalActions,
    gameActions,
    licenseActions,
    phaseActions,
    planActions, questionActions,
    stepActions,
    teamActions
} from "../../../_store";
import {modalActions} from "../../../_store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../../_helpers/alerts";
import {useNavigate, useParams} from "react-router";
import React, {useEffect} from "react";
import Select from "react-select";
import {useSearchParams} from "react-router-dom";
import {SpinningButton} from "../../button/Buttons";
import GreenLight from "../../../games/Green-Light.png"
import GameTile from "../../../games/Game-Tile-Plain.png";


export const StepInfo = () => {
    const dispatch = useDispatch();

    const games = useSelector(x => x.games )
    const phases = useSelector(x => x.phases )
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const step = searchParams.get("step")
    const game_id = searchParams.get("game_id")
    const column = searchParams.get("column")
    const gameList = games.list.games
    const phaseList = phases.list.phases
    const questions = useSelector(x => x.questions );
    const questionList = questions.questions.data && questions.questions.data


    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const questionOptions = questionList && questionList
        .map((item) =>{
        return { value: item.id, label: `${item.question} - ${item.description}`}
    })

    const gameOptions = gameList && gameList.map((item) =>{
        return { value: item.id, label: item.name}
    })

    const phaseOptions = phaseList && phaseList.map((item) =>{
        return { value: item.id, label: item.name}
    })

    useEffect(() => {
        dispatch(gameActions.getAll())
        dispatch(stepActions.getAll())
        dispatch(phaseActions.getAll())
        dispatch(questionActions.getAll())
    },[])

    function onSubmit({ name, description, question, enabled, phase }) {

        return dispatch(stepActions.addStep({ name, description, question, game: game_id, enabled, step, column, phase }))
            .then((response)=> {
                if (response.type === "steps/addStep/rejected"){
                    toast.error("OOps!, Problem saving step!")
                    return
                }
                promiseToast("Saving step", "Added a new step")
                navigate(-1)
            });
    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}  method="POST">
                <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <input
                                {...register("name")}
                                type="text"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Attach a question/scenario
                            </label>

                            <Controller
                                {...register('question')}
                                name="question"
                                control={control}
                                render={({ field }) => <Select
                                    {...field}
                                    options={questionOptions}
                                />}
                            />
                            <small className="bg-gray-100 pl-2">
                                <a href="/super-admin/new-question"> <i>Add new question</i>  </a></small>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <textarea
                                name={"description"}
                                {...register("description")}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3 h-16">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Phase
                            </label>

                            <Controller
                                {...register('phase')}
                                name="phase"
                                control={control}
                                render={({ field }) => <Select
                                    {...field}
                                    options={phaseOptions}
                                />}
                            />
                            <small className="bg-gray-100 pl-2"><a href="/super-admin/new-phase"> <i>Add new phase</i>  </a></small>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Type
                            </label>

                            <select
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                name={"type"}
                                {...register("type")}
                            >
                                <option value="tile">Tile button</option>
                                <option value="start">Start button </option>
                            </select>

                        </div>


                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Enabled
                            </label>

                            <select
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                name={"enabled"}
                                {...register("enabled")}
                            >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </select>

                        </div>

                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                {isSubmitting ? <SpinningButton /> : "Save" }
                            </button>
                    </div>
                </div>
            </form>

        </>
    )
}