import React, {useEffect, useState} from "react";
import {AddButton, AddUserButton, DeleteButton, EditButton, PlayIcon, ViewButton} from "../../button/Buttons";

function truncate(str, n){
    return str != null && (str.length > n) ? str.slice(0, n-1) + '...' : str;
}

export const SingleSubGame = ({number, id, description, question, date, questionObject}) => {
    const [show, setShow] = useState(null)

    return (
        <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 bg-white">

            <td className="pl-4">
                <p className="text-sm font-medium leading-none text-gray-800">{ number }</p>
            </td>

            <td className="pl-4 cursor-pointer">
                <div className="flex items-center">
                    <div className="pl-4">
                        <p className="font-medium">{description}</p>
                    </div>
                </div>
            </td>

            <td className="pl-4 cursor-pointer">
                <div className="flex items-center">
                    <div className="pl-4">
                        <p className="font-medium">{truncate(question, 70)}</p>
                    </div>
                </div>
            </td>

            <td className="pl-12">
                <p className="font-medium">
                    {questionObject.only_media ? "Image": "Video"}
                </p>
            </td>

            <td className="pl-12">
                <p className="font-medium">{date}</p>
            </td>

            <td className="pl-16">
                <div className="flex items-center mt-2 mb-2">
                    <div className="mr-3">
                        <a
                            href={`/super-admin/edit-question/${questionObject.id}`}
                            className="focus:outline-none pl-7">
                            {!questionObject.only_media &&
                            <EditButton />
                            }
                        </a>
                    </div>

                    <div>
                        <a onClick={() => {
                            alert('You are not allowed to delete!')
                        }}
                           href="#"
                           className="focus:outline-none pl-7">
                            <DeleteButton />
                        </a>
                    </div>
                </div>
            </td>

        </tr>
    )
}