import {BoxExample} from "../BoxExample";
import { Portal } from "react-portal"

export const SubGame = () => {

    return (
        <aside className='modal-container overflow-y-auto overflow-x-hidden'>
            <div className='extra-large-modal mt-12 '  >
                <BoxExample />
            </div>
        </aside>

    )
}