import {Desktop1536X864} from "../Desktop1536X864/Desktop1536X864";
import {Desktop1536X864ScreenTwo} from "../Desktop1536X864/Desktop1536X864ScreenTwo";
import {Desktop1536X864ScreenThree} from "../Desktop1536X864/Desktop1536X864ScreenThree";
import React, {useEffect} from "react";
import {screens} from "../../../_helpers/screens";
import {Desktop1280X720} from "./Desktop1280X720";
import {Desktop1280X720ScreenTwo} from "./Desktop1280X720ScreenTwo";
import {Desktop1280X720ScreenThree} from "./Desktop1280X720ScreenThree";
import {useDispatch} from "react-redux";
import {gameActions} from "../../../_store";


export const Render1280X720 = ({
                                   width,
                                   height,
                                 columnOneSteps,
                                 columnTwoSteps,
                                 columnThreeSteps,
                                 columnFourSteps,
                                 columnFiveSteps,
                                 progressLastStep,
                                 currentProgressOrder,
                                 userLastStep,
                                 columnSixSteps,
                                 columnSevenSteps,
                                 columnEightSteps,
                                 columnNineSteps,
                                 columnTenSteps,
                                 columnElevenSteps,
                                 columnTwelveSteps,
                                 columnThirteenSteps
                               }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(gameActions.setHasSupportedScreen(true))
  }, [])
  return (

      <>
        {currentProgressOrder < 20 &&
        <Desktop1280X720
            columnOneSteps={columnOneSteps}
            columnTwoSteps={columnTwoSteps}
            columnThreeSteps={columnThreeSteps}
            columnFourSteps={columnFourSteps}
            columnFiveSteps={columnFiveSteps}
            progressLastStep={progressLastStep}
            userLastStep={userLastStep}
        />
        }

        {currentProgressOrder >= 20 && currentProgressOrder < 40 &&
        <Desktop1280X720ScreenTwo
            columnSixSteps={columnSixSteps}
            columnSevenSteps={columnSevenSteps }
            columnEightSteps={columnEightSteps}
            columnNineSteps={columnNineSteps}
            columnTenSteps={columnTenSteps}
            progressLastStep={progressLastStep}
            userLastStep={userLastStep}
        />
        }

        {currentProgressOrder >= 40 &&
        <Desktop1280X720ScreenThree
            columnElevenSteps={columnElevenSteps}
            columnTwelveSteps={columnTwelveSteps}
            columnThirteenSteps={columnThirteenSteps}
            progressLastStep={progressLastStep}
            userLastStep={userLastStep}
        />
        }
      </>
  )
}