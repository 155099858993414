import {toast, ToastContainer} from "react-toastify"
import {useDispatch, useSelector} from "react-redux";
import {progressActions, questionActions, subgameActions} from "../_store";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

const CompletedQuizModal = () => {
  const dispatch = useDispatch()
  const {isCompletedQuizModalOpen,isQuizCorrect, quizAttempts} = useSelector(x => x.questions)
  const {progress,nextStep, progressOrder, kcTries} = useSelector(x => x.progress)
  const progressObject = progress.progress && progress.progress
  const {user} = useSelector(x=> x.auth)
  const {subgame} = useSelector(x => x.subgames)
  const nextProgressOrder = progressOrder;
  const { column } = progress.progress || {};
  const userLastStep =  nextStep?.nextPhaseStep?.id
  const [searchParams] = useSearchParams()
  const game_id = searchParams.get("game_id")

  const currentActualEEScore = progressObject ? Number(progressObject.actual_ee_score) + getQuizScore(isQuizCorrect, quizAttempts) : 0;
  const currentActualETScore = progressObject ? Number(progressObject.actual_et_score) + getQuizScore(isQuizCorrect, quizAttempts) : 0;
  const currentActualBudgetScore = progressObject ? Number(progressObject.actual_budget_score) + getQuizScore(isQuizCorrect, quizAttempts) : 0;
  const navigate = useNavigate()

  const nextStepObject = nextStep?.nextPhaseStep

  function getQuizScore(isQuizCorrect, quizAttempts) {
    if (isQuizCorrect) {
      switch (quizAttempts) {
        case 1:
          return 3
        case 2:
          return 2
        case 3:
          return 1
        default:
          return 0
      }
    }
    return 0
  }

  const tryAgain = () => {
    dispatch(questionActions.showCompletedQuizModal(false))
    // console.log("quiz counts from quiz", quizAttempts)
  }

  const continueToNextBlock = async () => {
    dispatch(questionActions.showCompletedQuizModal(false));

    try {
      await dispatch(progressActions.updateProgress({
        id: progressObject.id,
        user: user.id,
        kc_tries: quizAttempts,
        kc_answered: true
      }))
      await dispatch(progressActions.addProgress({
        phaseStep: userLastStep,
        user: user.id,
        order: nextProgressOrder,
        game: game_id,
        column,
        answered_question_only: false,
        actual_ee_score: currentActualEEScore,
        actual_et_score: currentActualETScore,
        actual_budget_score: currentActualBudgetScore,
      }))

      // await dispatch(progressActions.getProgress({ user: user.id }))
      await dispatch(progressActions.getNextStep({ order: nextProgressOrder }))

      navigate(0)
    } catch (error) {
      toast.error("Oops! Problem saving progress!")
    }
  }

  return (
      <>
        <ToastContainer />
        <aside className='modal-container'>
          <div className='modal'>

            <div>
              <h1 className="mb-2 underline italic">RESULTS</h1>
              <h3 className="mb-2 text-xs">
                <i></i>
              </h3>

              <div>
                {isQuizCorrect && quizAttempts < 3 ?
                    <h1>Good Job, your answers were correct!</h1>
                    :
                    <h2>Sorry, your answers were not correct!</h2>
                }

                {/*<h1 className="text-lg">Correct Answer</h1>*/}
                <br/>
                { isQuizCorrect || quizAttempts === 3 || quizAttempts > 3 ?
                    <>
                      <h2>See below for the correct answers</h2>
                      <img width={`780`} src={subgame?.data?.correct_image_link}/>
                    </>: <p></p>
                }
                {/*<img src={`https://changeverve-bucket.s3.eu-west-2.amazonaws.com/Block+20+-+KC+Resistance+Mgmt.JPG`}/> }*/}

              </div>

              <div className='btn-container'>
                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                  {
                    isQuizCorrect || quizAttempts === 3 || quizAttempts > 3?
                        <button
                            type="button"
                            className="mr-3 inline-block px-6 py-2.5 bg-[#a70000] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                            onClick={async () => {
                              await continueToNextBlock()
                            }}>
                          Continue to Next Block
                        </button>:

                        <button
                            type="button"
                            className="mr-3 inline-block px-6 py-2.5 bg-[#a70000] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                            onClick={ () => tryAgain()}>

                          Try again
                        </button>
                  }

                </div>
              </div>
            </div>
          </div>
        </aside>
      </>
  )
}
export default CompletedQuizModal

