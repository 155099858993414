import React, {useEffect, useState} from "react";
import {AddButton, AddUserButton, DeleteButton, EditButton, PlayIcon, ViewButton} from "../button/Buttons";


export const SingleVideo = ({number, video}) => {
    const [show, setShow] = useState(null)

    return (
        <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 bg-white">

            <td className="pl-4">
                <p className="text-sm font-medium leading-none text-gray-800">{ number }</p>
            </td>

            <td className="pl-4 cursor-pointer">
                <div className="flex items-center">
                    <div className="pl-4">
                        <p className="font-medium">{video.name}</p>
                    </div>
                </div>
            </td>

            {/*<td className="pl-12">*/}
            {/*    <p className="font-medium">*/}
            {/*        <a href="#">*/}
            {/*            <PlayIcon />*/}
            {/*        </a>*/}
            {/*    </p>*/}
            {/*</td>*/}

            <td className="pl-12">
                <p className="font-medium">
                    {video.size/1000000}
                </p>
            </td>

            <td className="pl-12">
                <p className="font-medium">{video.created_at}</p>
            </td>

            <td className="pl-16">
                <div className="flex items-center mt-2 mb-2">
                    <div className="mr-3">
                        <a
                            href="#"
                            className="focus:outline-none pl-7">
                            <EditButton />
                        </a>
                    </div>

                    <div>
                        <a
                            href="#"
                            className="focus:outline-none pl-7">
                            <DeleteButton />
                        </a>
                    </div>
                </div>
            </td>

        </tr>
    )
}