import {confirmModalActions, modalActions, teamActions} from "../../_store";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import DateTimePicker from "react-datetime-picker";
import React from "react";
import {toast} from "react-toastify";
import {promiseToast} from "../../_helpers/alerts";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

export const ScheduleInfo = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {emailContent, currentTeamId} = useSelector(x => x.teams)
    const {license} = useSelector(x => x.auth.user)
    const {id} = useParams()
    const team_id = id != null ? id : currentTeamId

    const validationSchema = Yup.object().shape({
        schedule_date: Yup.string().required('date is required'),
        hour: Yup.string().required('hour is required'),
        minute: Yup.string().required('minute is required'),
    })
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const onSubmit = ({ schedule_date, hour, minute, time_zone }) => {
       sendEmails({schedule_date, hour, minute, time_zone})
    }

    const sendEmails = ({schedule_date, hour, minute, time_zone}) =>{
        promiseToast("Sending", "Sending emails!")
        dispatch(teamActions.sendEmails(
            {
                 license,
                 team_id,
                 scheduled: true,
                 scheduled_at: `${schedule_date}T${hour}:${minute}:00`,
                 time_zone
            }))

        if (!isSubmitting){
            dispatch(confirmModalActions.closeModal())
            navigate("/assessments")
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} method="POST">
            <div className="shadow overflow-hidden sm:rounded-md">

            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                <div className="grid grid-cols-3 gap-1 ">
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Date and Time
                        </label>
                        <input
                            name="schedule_date"
                            {...register("schedule_date")}
                            type="date"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>

                    <div>
                        <div className="grid grid-cols-2 gap-1">

                            <div>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Hour
                                </label>

                                <select
                                    name="hour"
                                    {...register("hour")}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="00">00</option>
                                </select>
                            </div>

                            <div>
                                <label htmlFor="minute" className="block text-sm font-medium text-gray-700">
                                    Min
                                </label>
                                <select
                                    name="minute"
                                    {...register("minute")}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                    <option value="41">41</option>
                                    <option value="42">42</option>
                                    <option value="43">43</option>
                                    <option value="44">44</option>
                                    <option value="45">45</option>
                                    <option value="46">46</option>
                                    <option value="47">47</option>
                                    <option value="48">48</option>
                                    <option value="49">49</option>
                                    <option value="50">50</option>
                                    <option value="51">51</option>
                                    <option value="52">52</option>
                                    <option value="53">53</option>
                                    <option value="54">54</option>
                                    <option value="55">55</option>
                                    <option value="56">56</option>
                                    <option value="57">57</option>
                                    <option value="58">58</option>
                                    <option value="59">59</option>
                                    <option value="00">00</option>
                                </select>
                                {/*<input*/}
                                {/*    {...register("minute")}*/}
                                {/*    type="number"*/}
                                {/*    defaultValue="00"*/}
                                {/*    min="00"*/}
                                {/*    max="59"*/}
                                {/*    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"*/}
                                {/*/>*/}
                            </div>

                            {/*<div>*/}
                            {/*    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">*/}
                            {/*        AM/PM*/}
                            {/*    </label>*/}
                            {/*    <select*/}
                            {/*        {...register("team_name")}*/}
                            {/*        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">*/}
                            {/*        <option value="AM">AM</option>*/}
                            {/*        <option value="PM">PM</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}

                        </div>

                    </div>

                    <div>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Timezone
                        </label>

                        <select
                            name="time_zone"
                            {...register("time_zone")}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">

                            <option value="+0">GMT+0 (GMT)</option>
                            <option value="+1">GMT+1 (CET)</option>
                            <option value="+2">GMT+2 (EET)</option>
                            <option value="+3">GMT+3 (MSK)</option>
                            <option value="+4">GMT+4 (AMT)</option>
                            <option value="+5">GMT+5 (PKT)</option>
                            <option value="+6">GMT+6 (OMSK)</option>
                            <option value="+7">GMT+7 (KRAT)</option>
                            <option value="+8">GMT+8 (CST)</option>
                            <option value="+9">GMT+9 (JST)</option>
                            <option value="+10">GMT+10 (AEST)</option>
                            <option value="+11">GMT+11 (SAKT)</option>
                            <option value="+12">GMT+12 (NZST)</option>

                        </select>

                    </div>

                </div>

            </div>

            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                {
                    <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Schedule
                    </button>
                }
            </div>
        </div>
        </form>
    )
}