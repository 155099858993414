import React, {useEffect, useState} from "react";
import {Nav} from "../../Nav";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {gameActions, teamActions} from "../../../_store";
import {useParams} from "react-router";
import {SingleGame} from "./SingleGame";
import {SuperAdminNav} from "../SuperAdminNav";

function Games() {

    const dispatch = useDispatch()
    const {games} = useSelector(x => x.games.list );

    const params = useParams();
    // console.log("params", params.id);

    useEffect(() => {
        dispatch(gameActions.getAll())
    },[])

    return (

        <>
            {/*{isOpen && <Modal />}*/}
            <div className="min-h-full">
                <SuperAdminNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Games</h1>
                    </div>
                </header>

                <main>
                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="w-full sm:px-6">
                                    <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
                                        <div className="sm:flex items-center justify-between">
                                            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">List</p>
                                            <div>
                                                <NavLink className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-[#a70000] hover:bg-[#a70000] focus:outline-none rounded"
                                                         to="/super-admin/new-game">
                                                    <p className="text-sm font-medium leading-none text-white">Add Game</p>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">

                                        <table className="w-full whitespace-nowrap">
                                            <thead>
                                            <tr className="h-16 w-full text-sm leading-none text-gray-800">
                                                <th className="font-normal text-left pl-4">No.</th>
                                                <th className="font-normal text-left pl-12">Name</th>
                                                <th className="font-normal text-left pl-20">Steps count</th>
                                                <th className="font-normal text-left pl-12">Date Created</th>
                                                <th className="font-normal text-left pl-16">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="w-full">

                                            {games && games.map((tm, index) =>
                                                <SingleGame
                                                    key={tm.id}
                                                    number={index+1}
                                                    id={tm.id}
                                                    name={tm.name}
                                                    date={tm.created_at}
                                                    email={tm.contact_email}
                                                    numberOfMembers={tm.no_of_members}
                                                    license={tm.license}
                                                />
                                            )}

                                            </tbody>
                                        </table>
                                    </div>

                                    <br/>
                                    <br/>
                                    <br/>

                                </div>

                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </>
    );
}

export default Games;
