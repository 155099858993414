import {memo, useEffect, useState} from 'react'
import {Dustbin} from "./Dustbin"
import {Box} from "./Box"
// import {useDispatch, useSelector} from "react-redux"
import {progressActions, questionActions, subgameActions} from "../../_store"
import {useForm} from "react-hook-form"
import * as Yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import logo from "../../Images/logo.png"
import CopyrightLogo from "../../games/copyright-logo.jpg";
import {useSearchParams} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


export const Container = memo(function Container({data}) {
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const {nextStep, progress} = useSelector(x => x.progress)
  console.log("Next step from Container", progress)
  const nextStepObject = nextStep?.nextPhaseStep
  const {subgame} =  useSelector(x => x.subgames)
  const {user}    = useSelector( x => x.auth)
  const {selectedQuizValues, quizAttempts} = useSelector(x => x.questions)
  const sortedItems = [...selectedQuizValues].sort((a, b) => (a.id > b.id ? 1 : -1));
  const selectedNames = sortedItems?.map((val) => {
    return val.name.toString()
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const game_id = searchParams.get("game_id")


  const answerNames = [
    subgame?.data?.options.answer_1,
    subgame?.data?.options.answer_2,
    subgame?.data?.options.answer_3,
    subgame?.data?.options.answer_4,
    subgame?.data?.options.answer_5,
    subgame?.data?.options.answer_6,
  ]

  const shuffledAnswerNames = [
    subgame?.data?.options.answer_3,
    subgame?.data?.options.answer_4,
    subgame?.data?.options.answer_1,
    subgame?.data?.options.answer_5,
    subgame?.data?.options.answer_6,
    subgame?.data?.options.answer_2,
  ]

  const shuffledStatementNames = [
    subgame?.data?.options?.statement_3,
    subgame?.data?.options?.statement_4,
    subgame?.data?.options?.statement_1,
    subgame?.data?.options?.statement_5,
    subgame?.data?.options?.statement_6,
    subgame?.data?.options?.statement_2,
  ]

  const answerData = answerNames.filter(function(element) {
    return element !== undefined
  })

  const shuffledAnswerData = shuffledAnswerNames.filter(function(element) {
    return element !== undefined
  })

  const shuffledStatementsData = shuffledStatementNames.filter((element) => {
    return element !== undefined
  })

  const statementNames = [
    subgame?.data?.options?.statement_1,
    subgame?.data?.options?.statement_2,
    subgame?.data?.options?.statement_3,
    subgame?.data?.options?.statement_4,
    subgame?.data?.options?.statement_5,
    subgame?.data?.options?.statement_6,
  ]

  const statementData = statementNames.filter((element) => {
    return element !== undefined
  })

  const validationSchema = Yup.object().shape({
    // answer: string().required('answer is required')
  })

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, control } = useForm(formOptions);
  const compareSelectedAnswersAndValues = (array_1, array_2) => {
    return JSON.stringify(array_1) === JSON.stringify(array_2);
  }

  useEffect(() => {
    dispatch(progressActions.getKCTries({user: user.id})).then((res) => {
      dispatch(questionActions.incrementQuizAttempts(res?.payload?.progress))
    })

    // if (quizAttempts === 3 || quizAttempts > 3){
    // dispatch(questionActions.closeModal())
    // alert("You have completed this knowledge check!")
    // }
    //
  },[])

  const  onSubmit = async() => {
    const selectedNamesLowerCase = selectedNames.map((item) => {
      return item?.toString()?.toLowerCase().trim()
    })
    const answerDataLowerCase = answerData.map((item) => {
      return item?.toString()?.toLowerCase().trim()
    })

    const isCorrect = compareSelectedAnswersAndValues(selectedNamesLowerCase, answerDataLowerCase)
    const quizCounts = quizAttempts + 1

    dispatch(questionActions.incrementQuizAttempts(quizCounts))
    dispatch(progressActions.updateProgress({ user: user.id, kc_tries: quizCounts, kc_answered: isCorrect}))

    console.log("quiz counts", quizCounts)
    await dispatch(questionActions.showCompletedQuizModal({isCorrect: isCorrect, status: true}))
    return
  }

  function shuffle(array) {
    let currentIndex = array?.length,  randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  const uniqueIds = []
  const uniqueAnswers = (answerData) && (answerData).filter(element => {
    const isDuplicate = uniqueIds.includes(element?.toString()?.trim())
    if (!isDuplicate){
      uniqueIds.push(element)
      return true
    }
    return false
  })
  const uniqueShuffledIds = []
  const uniqueShuffledAnswers = (shuffledAnswerData) && (shuffledAnswerData).filter(element => {
    const isDuplicate = uniqueShuffledIds.includes(element?.toString()?.trim())
    if (!isDuplicate){
      uniqueShuffledIds.push(element)
      return true
    }
    return false
  })

  useEffect(() => {
    dispatch(subgameActions.getSubGame(nextStepObject.questionId))

  }, [])

  return (
      <div style={{marginTop: `1rem`}}>
        <div className="grid grid-cols-6">
          <div>
            <div style={{ marginBottom: `20px`, width: `150px`, color: `#ffffff`, marginLeft: `-1rem`, paddingLeft: `10px`, fontWeight: `bolder`, fontSize: `2em`, height: `60px`, float: "left"}}>
              <img src={logo} />
            </div>

            <div style={{width: `150px`, backgroundColor: "#a70000", color: `#ffffff`, marginLeft: `-1rem`, padding: `3px`, fontWeight: `bolder`, fontSize: `1em`, float: "left"}}>
              {nextStepObject?.description?.substr(0,nextStepObject?.description?.indexOf("-"))}
            </div>

          </div>

          <div className=" flex justify-center items-center md:col-span-5 bg-gray-200 text-sm border-red-500 text-[#000] font-semibold"
               style={{ marginRight: `20px`, border: `4px solid #a70000`, paddingTop: `10px`, paddingLeft:`5px`, paddingRight:`10px`, paddingBottom: `5px`}}>
                            <span className={`text-center`} style={{padding: `8px`}}>
                                {nextStepObject?.scenario}
                            </span>
          </div>
        </div>

        <br/>
        <br/>

        <div className="grid grid-cols-3 gap-3">
          <div className={`grid grid-rows-8 text-xs`}>
            {statementData?.map((item, index) => {
              return <div className="flex justify-center items-center" style={{padding: `4px`, border: `2px solid #a70000`,
                height: `100px`, marginBottom: `7px`, textAlign: `center`,
                borderRadius: `5px`, boxShadow: `5px 5px 5px 0px rgba(0,0,0,0.75)`}}>
                        <span className={`text-xs text-center`}>
                            {subgame["data"]["options"][`statement_${index+1}`]}
                        </span>
              </div>
            })}
          </div>

          <div className={`grid grid-rows-6 gap-2`}>
            {statementData?.map((item,index) => {
              return <Dustbin id={index}/>
            })}
          </div>

          <div className={` grid grid-rows-6 mt-16`}>
            {uniqueShuffledAnswers?.map((item, index) => {
              return <Box name={`${item}`} id={index}/>
            })}
          </div>

          {/*<div className={` grid grid-rows-6 mt-16`}>*/}
          {/*    {uniqueAnswers?.map((item, index) => {*/}
          {/*        return <Box name={`${item}`} id={index}/>*/}
          {/*    })}*/}
          {/*</div>*/}

          <img src={CopyrightLogo} width={100} className="float-left mt-5"  />

          <div style={{position: `relative`, top: `0px`, left: `30%`}} className="relative">

            <form method="POST" onSubmit={handleSubmit(onSubmit)}>

              <button
                  type="button"
                  className="mr-3 inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => {
                    dispatch(questionActions.closeModal())
                  }}>
                CLOSE
              </button>

              <button
                  onClick={() => {

                  }}
                  type="submit"
                  className="mr-3 inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-800 hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out">
                SUBMIT ANSWER
              </button>
            </form>
          </div>

        </div>

      </div>
  )
})