import {useDispatch, useSelector} from 'react-redux';
import {gameActions, progressActions, questionActions} from "../../_store";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {promiseToast} from "../../_helpers/alerts";
import {YoutubeEmbed} from "../Video/YoutubeEmbed";
import Video from "../Video";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import CopyrightLogo from "../../games/copyright-logo.jpg";

const GameModal = () => {
    const dispatch = useDispatch();
    const {currentModalStep} = useSelector(x => x.games)
    const {progress} = useSelector(x => x.progress)
    const currentActualEEScore = progress.progress && progress.progress.actual_ee_score
    const currentActualETScore = progress.progress && progress.progress.actual_et_score
    const currentActualBudgetScore = progress.progress && progress.progress.actual_budget_score

    const {user} = useSelector(x=> x.auth)
    const {answer} = useSelector(x => x.questions)
    const {choiceOfAnswer} = useSelector(x => x.games)
    const reason =  answer.replace("option","reason")
    const [searchParams] = useSearchParams()
    const game_id = searchParams.get("game_id")
    console.log("Current Modal Step", currentModalStep)
    const navigate = useNavigate()
    const validationSchema = Yup.object().shape({
        // answer: string().required('answer is required')
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const eeScore = choiceOfAnswer?.ee_score
    const newETScore = choiceOfAnswer?.et_score
    const newBudgetScore = choiceOfAnswer?.eb_score

    function onSubmit() {
        const phaseStep = currentModalStep.phase_step_id
        return dispatch(progressActions.addProgress({phaseStep, user: user.id}))
            .then((response)=> {
                if (response.type === "progress/addProgress/rejected"){
                    toast.error("OOps!, Problem saving progress!")
                    return
                }
                promiseToast("Saving progress", "saved your progress!")

                dispatch(progressActions.getProgress({user: user.id}))
                dispatch(gameActions.closeWrongResultModal());
            });
    }

    function replaceWithBr(text) {
        return text.replace(/\n/g, "<br />")
    }

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [ ])

    return (
        <aside className='modal-container overflow-y-auto scroll' >
            <div className='large-modal' style={{marginTop: `120px`}}>
                <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                    <div className="scroll">
                        <h1 className="mb-2">YOU ANSWERED INCORRECTLY</h1>
                        <h3 className="text-center">
                            {currentModalStep.reason}
                        </h3>

                        <div className={` text-center`}>
                            <strong>Why you answered incorrectly:</strong>
                            <p className="text-sm">
                                <p dangerouslySetInnerHTML={{__html: replaceWithBr(currentModalStep.options[reason])}} />
                            </p>
                        </div>

                        {/*<p className="text-sm"> <strong> Why you answered incorrectly:</strong>*/}
                        {/*    <p dangerouslySetInnerHTML={{__html: replaceWithBr(currentModalStep.options[reason])}} />*/}
                        {/*</p>*/}

                        <br/>
                        <i className="underline">
                            Please watch this video to learn more and proceed to the next bus stop when done.
                        </i>
                        <br/>
                        <br/>

                        <img src={CopyrightLogo} width={100} className="float-left mt-5" />

                        <div className='btn-container'>
                            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                                {/*<button*/}
                                {/*    type="button"*/}
                                {/*    className="mr-3 inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"*/}
                                {/*    data-bs-dismiss="modal"*/}
                                {/*    onClick={() => {*/}
                                {/*        dispatch(gameActions.closeWrongResultModal());*/}
                                {/*    }}>*/}
                                {/*    CLOSE*/}
                                {/*</button>*/}

                                <button
                                    onClick={() => {
                                        dispatch(progressActions.setEEScore(eeScore))
                                        dispatch(progressActions.setETScore(newETScore))
                                        dispatch(progressActions.setBudgetScore(newBudgetScore))
                                        dispatch(questionActions.setCorrect(false))
                                        dispatch(gameActions.closeWrongResultModal());
                                        dispatch(gameActions.openVideoModal())
                                    }}
                                    type="button"
                                    className="mr-3 inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-800 hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                                    // className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                    data-bs-dismiss="modal">
                                    WATCH VIDEO
                                </button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </aside>
    )
};
export default GameModal;


