import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice
const name = 'questions';
const initialState = createInitialState();
const extraActions = createExtraActions();
const reducers = createReducers();
const extraReducers = createExtraReducers();

const slice = createSlice({ name, initialState,reducers, extraReducers });

// exports
export const questionActions = { ...slice.actions, ...extraActions };
export const questionsReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        questions: {},
        answer: "",
        question: JSON.parse(localStorage.getItem('question')),
        isCorrect: false,
        isModalOpen: false,
        selectedQuizValues: [],
        isCompletedQuizModalOpen: false,
        isQuizCorrect : false,
        quizAttempts: 0

    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/questions`;

    return {
        getAll: getAll(),
        addQuestion: addQuestion(),
        addGame: addGame(),
        getQuestion: getQuestion(),
        updateQuestion: updateQuestion()
    }

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(baseUrl)
        );
    }

    function addQuestion() {
        return createAsyncThunk(
            `${name}/addQuestion`,
            async (body) => await fetchWrapper.post(baseUrl,body)
        );
    }

    function addGame() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/sub-game`;
        return createAsyncThunk(
            `${name}/addGame`,
            async (body) => await fetchWrapper.post(baseUrl,body)
        );
    }

    function updateQuestion() {
        return createAsyncThunk(
            `${name}/updateQuestion`,
            async (body) => await fetchWrapper.patch(baseUrl,body)
        );
    }

    function getQuestion() {
        return createAsyncThunk(
            `${name}/getQuestion`,
            async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }
}

function createReducers() {
    return {
        setAnswer,
        setQuestion,
        setCorrect,
        openModal,
        closeModal,
        setSelectedValues,
        showCompletedQuizModal,
        incrementQuizAttempts
    };

    function setAnswer (state, action){
        state.answer = action.payload;
    }

    function openModal (state, action){
        state.isModalOpen = true
    }

    function closeModal (state, action){
        state.isModalOpen = false
    }

    function setQuestion (state, action){
        localStorage.setItem("question", action.payload )
    }

    function setCorrect(state, action) {
        state.isCorrect = action.payload
    }

    function setSelectedValues(state, action){
        const index = state.selectedQuizValues.findIndex(object => {
            return object.id === action.payload.id;
        })

        if (index !== -1) {
            state.selectedQuizValues[index].name = action.payload.name;

        }else{
            state.selectedQuizValues.push({name: action.payload.name, id: action.payload.id})
        }

        console.log("selected values slice", state.selectedQuizValues)
    }

    function showCompletedQuizModal(state, action){
        state.isCompletedQuizModalOpen = action.payload.status
        state.isQuizCorrect    = action.payload.isCorrect
    }

    function incrementQuizAttempts(state, action){
        state.quizAttempts    = action.payload
    }
}

function createExtraReducers() {
    return {
        ...getAll(),
        ...addQuestion(),
        ...addGame(),
        ...getQuestion(),
        ...updateQuestion()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.questions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.questions = action.payload;
            },
            [rejected]: (state, action) => {
                state.questions = { error: action.error };
                console.log("questions error", action.error )
            }
        };
    }

    function addQuestion() {
        const { pending, fulfilled, rejected } = extraActions.addQuestion;
        return {
            [pending]: (state) => {
                state.questions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.questions = action.payload;
            },
            [rejected]: (state, action) => {
                state.questions = { error: action.error };
                console.log("questions error", action.error )
            }
        };
    }

    function addGame() {
        const { pending, fulfilled, rejected } = extraActions.addGame
        return {
            [pending]: (state) => {
                state.questions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.questions = action.payload;
            },
            [rejected]: (state, action) => {
                state.questions = { error: action.error };
                console.log("questions error", action.error )
            }
        };
    }

    function updateQuestion() {
        const { pending, fulfilled, rejected } = extraActions.updateQuestion;
        return {
            [pending]: (state) => {
                // state.questions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                // state.questions = action.payload;
            },
            [rejected]: (state, action) => {
                // state.questions = { error: action.error };
                // console.log("questions error", action.error )
            }
        };
    }

    function getQuestion() {
        const { pending, fulfilled, rejected } = extraActions.getQuestion;
        return {
            [pending]: (state) => {
                state.question = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.question = action.payload;
            },
            [rejected]: (state, action) => {
                state.question = { error: action.error };
                console.log("question error", action.error )
            }
        };
    }
}


