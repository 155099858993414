import React from "react";

function Pagination({ totalItems, pageSize, currentPage, onPageChange }) {
    const totalPages = Math.ceil(totalItems / pageSize);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <nav>
            <ul className="flex items-center justify-center gap-2">
                {pageNumbers.map((number) => (
                    <li key={number}>
                        <button
                            className={`px-4 py-2 text-sm font-semibold rounded ${
                                currentPage === number
                                    ? "bg-[#a70000] text-white"
                                    : "text-gray-700 hover:bg-gray-200"
                            }`}
                            onClick={() => onPageChange(number)}
                        >
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Pagination;