import {LockClosedIcon} from "@heroicons/react/solid";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {authActions, userActions} from "../../_store";
import {useNavigate, useParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import logo from "../../Images/logo.png";
import {SpinningButton} from "../button/Buttons";

export const EmailRegister = () => {

    const dispatch = useDispatch()
    const authUser = useSelector(x => x.auth.user)
    const auth = useSelector(x => x.auth)
    const authError = useSelector(x => x.auth.error)
    const navigate = useNavigate()
    const {user} = useParams()

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('first name is required'),
        lastName:  Yup.string().required('last name is required'),
        password:  Yup.string().required('password is required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, watch } = useForm(formOptions);
    const { errors, isSubmitting } = formState;


    function onSubmit({ email, firstName, lastName, password }) {
        dispatch(userActions.update({id: user, firstName, lastName, email, password})).then((resp) => {
            toast.success("Successfully registered!")
            setTimeout(() => {
                navigate("/")
            },1000)
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="wrapper">
                <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-md w-full space-y-8">
                        <div>
                            <img
                                className="mx-auto h-12 w-auto"
                                src={logo}
                                alt="logo"
                            />

                            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Registration</h2>
                        </div>

                        <div>
                            <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6" action="#" method="POST" autoComplete="off">
                                <input type="hidden" name="remember" defaultValue="true"/>
                                <div className="rounded-md shadow-sm -space-y-px">
                                    <div>
                                        <label htmlFor="first-name">
                                            First Name
                                        </label>
                                        <input
                                            autoComplete={false}
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            required
                                            className="mb-2 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#a70000] focus:border-[#a70000] focus:z-10 sm:text-sm"
                                            placeholder="First Name"
                                            {...register('firstName')}
                                        />
                                        <div className="invalid-feedback">{errors.firstName?.message}</div>

                                    </div>

                                    <div className="mb-5">
                                        <label htmlFor="last-name">
                                            Last Name
                                        </label>
                                        <input
                                            autoComplete={false}
                                            id="last-name"
                                            name="lastName"
                                            type="text"
                                            required
                                            className="mb-2 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#a70000] focus:border-[#a70000] focus:z-10 sm:text-sm"
                                            placeholder="Last Name"
                                            {...register('lastName')}
                                        />
                                        <div className="invalid-feedback">{errors.lastName?.message}</div>

                                    </div>

                                    <div>
                                        <label htmlFor="password">
                                            Password
                                        </label>
                                        <input
                                            autoComplete="off"
                                            id="password"
                                            name="password"
                                            type="password"
                                            required
                                            // className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#a70000] focus:border-[#a70000] focus:z-10 sm:text-sm"
                                            placeholder="Create password"
                                            {...register('password')}
                                        />
                                        <div className="invalid-feedback">{errors.password?.message}</div>

                                    </div>

                                    <div>
                                        <label htmlFor="confirm_password" >
                                            Password
                                        </label>
                                        <input
                                            autoComplete="off"
                                            id="confirm_password"
                                            name="confirm_password"
                                            type="password"
                                            required
                                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#a70000] focus:border-[#a70000] focus:z-10 sm:text-sm"
                                            placeholder="Confirm password"
                                            {...register('confirm_password')}
                                        />
                                        <div className="invalid-feedback">{errors.confirm_password?.message}</div>

                                    </div>

                                    <div className="text-red-900">
                                        {authError &&
                                        <div className="alert alert-danger mt-1 mb-0">{authError.message}</div>
                                        }
                                    </div>

                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                    </div>
                                </div>

                                <div>
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        // className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"
                                    >
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                      <LockClosedIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                                    </span>
                                        {isSubmitting ? <SpinningButton /> : isSubmitting ? "Saving" : "Register" }
                                    </button>

                                    {authError &&
                                    <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                                    }

                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}