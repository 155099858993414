import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'videos';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const videoActions = { ...slice.actions, ...extraActions };
export const videosReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        video: {},
        list : [],
        completed: false,
        isModalOpen: false,
        nextVideo : true,
        isIntroVideosWatched: false,
        maxScreenClicked: false,
        currentIntroVideo: ""
    }
}

function createExtraActions() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/videos`;

    return {
        addVideo: addVideo(),
        getById:  getVideoById(),
        getAll:   getVideos()
    }

    function addVideo() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/videos`;
        return createAsyncThunk(
            `${name}/addVideo`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function getVideos() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${url}`)
        );
    }

    function getVideoById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => await fetchWrapper.get(`${url}/${id}`)
        );
    }
}

function createReducers() {
    return {
        setCompleted,
        openModal,
        closeModal,
        setNextVideo,
        setIntroVideosWatched,
        setMaxScreenClicked,
        setCurrentIntroVideo
    }

    function setCompleted (state, action) {
        state.completed = action.payload
    }

    function openModal (state, action) {
        state.isModalOpen = true
    }

    function closeModal (state, action){
        state.isModalOpen = false
    }

    function setNextVideo (state, action){
        state.nextVideo = action.payload
    }

    function setIntroVideosWatched (state, action){
        state.isIntroVideosWatched = action.payload
    }

    function setMaxScreenClicked (state, action){
        state.maxScreenClicked = action.payload
    }

    function setCurrentIntroVideo (state, action) {
        state.currentIntroVideo = action.payload
    }
}

function createExtraReducers() {
    return {
        ...addVideo(),
        ...getById(),
        ...getVideos()
    };

    function addVideo() {
        const { pending, fulfilled, rejected } = extraActions.addVideo;
        return {
            [pending]: (state) => {
                state.video = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.video = action.payload;
            },
            [rejected]: (state, action) => {
                state.video = { error: action.error };
            }
        };
    }

    function getVideos() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.list = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload;
            },
            [rejected]: (state, action) => {
                state.list = { error: action.error };
            }
        };
    }

    function getById() {
        const { pending, fulfilled, rejected } = extraActions.getById;
        return {
            [pending]: (state) => {
                state.plan = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.plan = action.payload;
                // console.log("Plans from slice", action.payload)
            },
            [rejected]: (state, action) => {
                state.plan = { error: action.error };
            }
        };
    }

}
