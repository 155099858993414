import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SingleTile} from "./SingleTile";
import GreenIndicator from "../../../games/Yellow-Indicator-B.png";
import infoButton from "../../../games/Info-Button.png";
import DemoImage from "../../../games/resized-image-Promo.jpeg";
import AnimatedNumbers from "react-animated-numbers";
import {useNavigate} from "react-router";
import {AnimateNumber} from "../../AnimateNumber";
import {screens} from "../../../_helpers/screens";
import {gameActions} from "../../../_store";
import "./css/custom.css"
import {GameTile} from "../assets";
import {Instructions} from "./Instructions";

export const Desktop1280X720 = ({
                                  columnOneSteps,
                                  columnTwoSteps,
                                  columnThreeSteps,
                                  columnFourSteps,
                                  columnFiveSteps,
                                  userLastStep,
                                  progressLastStep}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {phaseBackground}  = useSelector(x => x.games)
  const {nextStep,progress, et_score, ee_score, budget_score} = useSelector(x => x.progress)
  const progressObject     = progress.progress && progress.progress
  const currentEEScore     =   screens.desktopFive.eeIndicator + (progressObject.actual_ee_score || 0)
  const currentETScore     =   screens.desktopFive.etIndicator + (progressObject.actual_et_score || 0)
  const currentBudgetScore =   screens.desktopFive.budgetIndicator + (progressObject.actual_budget_score || 0)

  const phaseName          = nextStep.nextPhaseStep && nextStep.nextPhaseStep.phase_name
  const description          = nextStep?.nextPhaseStep?.description
  const columnTwoLeft      = `452px`
  const columnThreeLeft      = `681px`

  console.log("Phase background", phaseBackground)

  return (
      <>
        <div className="container">
          <div className="game-board">
            <div>
              <div>
                {columnOneSteps && columnOneSteps.map((step, index)=>
                    <div>
                      {index === 0 &&
                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          progressLastStep={progressLastStep}
                          divStyle={{ top: `85px` ,left: `48px`}}
                          tileStyle={{width: `60%`, marginTop: `-5px` }}
                          busStyle={{left:`-25px`, width: `40%`, top: `-13px` }}
                          // busStyle={{left:`-15px`, width: `40%`, marginTop: `-13px` }}
                          column={1}
                          number={index}
                          type={`changeLandscape`}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                      />
                      }

                      {index === 1 &&
                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          progressLastStep={progressLastStep}
                          divStyle={{top: `190px`, left: `40px`}}
                          tileStyle={{width: `55%`, marginTop: `2px`}}
                          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}
                          column={1}
                          number={index}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />

                      }

                      {index === 2 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          progressLastStep={progressLastStep}
                          divStyle={{top: `300px`, left: `40px`}}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}
                          column={1}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />

                      }

                      {index === 3 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{top: `415px`, left: `40px`}}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, top: `0px`}}
                          column={1}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />
                      }

                    </div>
                )}

              </div>

              <div className="static">

                {columnTwoSteps && columnTwoSteps.map((step, index)=>
                    <div>
                      {index === 3 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `240px`, top: `80px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, top: `0px`}}
                          column={2}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />

                      }

                      {index === 2 &&
                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `240px`, top: `190px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, top: `0px`}}
                          column={2}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />
                      }

                      {index === 1 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `240px`, top: `300px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-15px`, width: `40%`, top: `0px`}}
                          column={2}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />
                      }

                      {index === 0 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `245px`, top: `425px` }}
                          tileStyle={{width: `60%`, marginTop: `2px` }}
                          busStyle={{left: `-15px`, width: `40%`, top: `-10px`}}
                          column={2}
                          type={`startUp`}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                      />
                      }

                    </div>
                )}

              </div>

              <div className="static">

                {columnThreeSteps && columnThreeSteps.map((step, index)=>
                    <div>
                      {index === 0 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          progressLastStep={progressLastStep}
                          divStyle={{ left: `440px`, top: `80px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}
                          number={index}
                          column={3}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />

                      }

                      {index === 1 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          progressLastStep={progressLastStep}
                          divStyle={{ left: `440px`, top: `190px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}
                          column={3}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />

                      }

                      {index === 2 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          progressLastStep={progressLastStep}
                          divStyle={{ left: `440px`, top: `300px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}
                          number={index}
                          column={3}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />

                      }

                      {index === 3 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          progressLastStep={progressLastStep}
                          divStyle={{ left: `440px`, top: `415px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-15px`, width: `30%`, marginTop: `0px`}}
                          number={index}
                          column={3}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />
                      }

                    </div>
                )}

              </div>

              <div className="static">

                {columnFourSteps && columnFourSteps.map((step, index)=>
                    <div>
                      {index === 3 &&
                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `640px`, top: `80px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}
                          column={4}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}

                      />
                      }

                      {index === 2 &&
                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          type={`implementationPhase`}
                          divStyle={{ left: `650px`, top: `200px` }}
                          tileStyle={{width: `60%`, marginTop: `2px` }}
                          busStyle={{left: `-25px`, width: `38%`, top: `-10px`}}
                          column={4}
                          greenTickerStyle={{top: `-10px`,  left: `40px`, width: `15%`}}
                      />

                      }

                      {index === 1 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `640px`, top: `300px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}
                          column={4}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />

                      }

                      {index === 0 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `640px`, top: `415px` }}
                          tileStyle={{width: `55%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}
                          column={4}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}
                      />
                      }

                    </div>
                )}

              </div>

              <div className="static">

                {columnFiveSteps && columnFiveSteps.map((step, index)=>
                    <div>
                      {index === 0 &&
                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `840px`, top: `80px` }}
                          tileStyle={{width: `74%`, marginTop: `2px` }}
                          busStyle={{left: `-15px`, width: `40%`, marginTop: `0px`}}
                          column={5}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                      />
                      }

                      {index === 1 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `840px`, top: `190px` }}
                          tileStyle={{width: `74%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `40%`, marginTop: `0px`}}
                          column={5}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                      />

                      }

                      {index === 2 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `840px`, top: `300px` }}
                          tileStyle={{width: `74%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `40%`, marginTop: `0px`}}
                          column={5}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                      />

                      }

                      {index === 3 &&

                      <SingleTile
                          step={step}
                          userLastStep={userLastStep}
                          divStyle={{ left: `840px`, top: `415px` }}
                          tileStyle={{width: `74%`, marginTop: `2px` }}
                          busStyle={{left: `-9px`, width: `40%`, marginTop: `0px`}}
                          column={5}
                          greenTickerStyle={{top: `0px`,  left: `40px`, width: `18%`}}
                      />
                      }

                    </div>
                )}


                {/*{columnFiveSteps && columnFiveSteps.map((step, index)=>*/}
                {/*    <div>*/}
                {/*      {index === 0 &&*/}
                {/*      <SingleTile*/}
                {/*          step={step}*/}
                {/*          userLastStep={userLastStep}*/}
                {/*          divStyle={{ left: `762px`, top: `74px` }}*/}
                {/*          tileStyle={{width: `50%`, marginTop: `2px` }}*/}
                {/*          busStyle={{left: `0px`, width: `30%`, marginTop: `0px`}}*/}
                {/*          column={5}*/}
                {/*          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}*/}
                {/*      />*/}
                {/*      }*/}

                {/*      {index === 1 &&*/}

                {/*      <SingleTile*/}
                {/*          step={step}*/}
                {/*          userLastStep={userLastStep}*/}
                {/*          divStyle={{ left: `762px`, top: `176px` }}*/}
                {/*          tileStyle={{width: `50%`, marginTop: `2px` }}*/}
                {/*          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}*/}
                {/*          column={5}*/}
                {/*          greenTickerStyle={{top: `0px`,  left: `40px`, width: `12%`}}*/}
                {/*      />*/}

                {/*      }*/}

                {/*      {index === 2 &&*/}

                {/*      <SingleTile*/}
                {/*          step={step}*/}
                {/*          userLastStep={userLastStep}*/}
                {/*          divStyle={{ left: `762px`, top: `275px` }}*/}
                {/*          tileStyle={{width: `50%`, marginTop: `2px` }}*/}
                {/*          busStyle={{left: `0px`, width: `30%`, top: `0px`}}*/}
                {/*          column={5}*/}
                {/*          greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}*/}
                {/*      />*/}

                {/*      }*/}

                {/*      {index === 3 &&*/}

                {/*      <SingleTile*/}
                {/*          step={step}*/}
                {/*          userLastStep={userLastStep}*/}
                {/*          divStyle={{ left: `767px`, top: `377px` }}*/}
                {/*          tileStyle={{width: `50%`, marginTop: `2px` }}*/}
                {/*          busStyle={{left: `-9px`, width: `30%`, marginTop: `0px`}}*/}
                {/*          column={5}*/}
                {/*          greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}*/}
                {/*      />*/}
                {/*      }*/}

                {/*    </div>*/}
                {/*)}*/}

              </div>

              <div style={{
                position: `absolute`,
                top: `542px`,
                left: `345px`,
              }}>


                <div style={{
                  border: `1px solid #000`,
                  width: `36px`,
                  position: `absolute`,
                  background: `#000`,
                  height: `22px` }}>
                  <p style={{color: `white`,
                    marginTop: `-1px`,
                    marginLeft: `4px`
                  }}>
                    {!isNaN(progressObject.actual_ee_score)? progressObject.actual_ee_score + 100 : 100}
                  </p>

                </div>


                <div style={{
                  border: `1px solid #000`,
                  width: `36px`,
                  background: `#000`,
                  position: `relative`,
                  left: `298px`,
                  height: `22px` }}>
                  <p style={{color: `white`, marginTop: `-1px`, marginLeft: `4px`}}>
                    {!isNaN(progressObject.actual_et_score)? progressObject.actual_et_score + 100 : 100}
                  </p>
                </div>



                <div style={{
                  border: `1px solid #000`,
                  width: `36px`,
                  position: `relative`,
                  left: `600px`,
                  top: `-22px`,
                  background: `#000`,
                  height: `22px` }}>
                  <p style={{color: `white`, top: `-1px`, marginLeft: `4px`}}>
                    {!isNaN(progressObject.actual_budget_score) ? progressObject.actual_budget_score + 100  : 100}
                  </p>
                </div>


              </div>

              <div style={{
                position: `absolute`,
                top: `550px`
              }}>

                <div className={`absolute` }
                     style={{ left: `${currentEEScore+20}px`}}>
                  <img src={GreenIndicator}   alt="indicator" />
                </div>

                <div className={`absolute` } style={{ left: `${currentETScore+48}px`}}>
                  <img src={GreenIndicator}  alt="indicator" />
                </div>

                <div
                     style={{
                       position: `relative`,
                       left: `${currentBudgetScore+79}px`
                     }}>
                  <img src={GreenIndicator}  alt="indicator" />
                </div>

              </div>

              {/*<div className={`absolute` } style={{top: `485px`, left: `60px`}}*/}
              {/*     onClick={ () => {*/}
              {/*       dispatch(gameActions.setInfoButtonModalOpened(true))*/}
              {/*     }}>*/}
              {/*  <img src={infoButton}  alt="indicator" width="27" />*/}
              {/*</div>*/}


              {/*<div className={`absolute` } style={{top: `645px`, left: `220px`}}*/}
              {/*     onClick={ () => {*/}
              {/*       dispatch(gameActions.setInfoButtonModalOpened(true))*/}
              {/*     }}>*/}
              {/*  <img src={infoButton}  alt="indicator" width="30"*/}
              {/*       style={{*/}
              {/*         position: `relative`,*/}
              {/*         left: `-145px`*/}
              {/*       }} />*/}
              {/*</div>*/}

            </div>
            <img src={phaseBackground} width={1000} />

            {/*<button*/}
            {/*    onClick={() =>*/}
            {/*        navigate(-1)*/}
            {/*    }*/}
            {/*    className=" exit-game bg-gray-300 text-black font-bold py-2 px-4 rounded">*/}
            {/*  Exit game*/}
            {/*</button>*/}

          </div>

          <div className="instructions" >

            <Instructions />
          </div>

        </div>
      </>

  )
}
