import {ToastContainer} from "react-toastify";
import {Nav} from "./Nav";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {authActions, planActions, teamActions} from "../_store";
import {useNavigate} from "react-router";

export const CoordinatorDashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authUser = useSelector(x => x.auth.user)
    const {list} = useSelector(x => x.teams );
    console.log("Coordinator teams ", list)

    useEffect(() => {
        dispatch(teamActions.getCoordinatorTeams({user: authUser?.id}))
    },[])

    return (
        <>
            <ToastContainer limit={1} />

            <div className="min-h-full">
                {/*<Nav />*/}
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Coordinator Dashboard</h1>
                        <a
                            onClick={() => {
                                dispatch(authActions.logout())
                            }}
                            href="#" className="block px-4 py-2  font-bold float-right mt-0 pt-0 "
                            role="menuitem" tabIndex="-1" id="user-menu-item-2">Sign out</a>

                    </div>
                </header>

                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <h1 className="text-4xl">Teams</h1>
                            <br/>

                            <div className="grid grid-cols-4 gap-1">
                                {list?.teams?.map((tm) =>
                                        <div className="border-4 border-dashed border-gray-200 rounded-lg h-auto p-6 bg-white shadow-lg">
                                            <div>
                                                <div className="text-slate-700">
                                                    <h1 className="text-2xl font-bold mb-4">{tm?.company_name}</h1>
                                                    <h2 className="text-xl font-semibold">{tm?.name}</h2>
                                                    <h3 className="text-sm pt-2">Add/Manage team members</h3>
                                                    <div className="flex items-center space-x-4 mt-6">
                                                        <a
                                                            href={`/coordinator-team-setup/${tm.id}`}
                                                            className="bg-green-500 text-white font-bold py-2 px-4 rounded-full inline-flex items-center text-sm"
                                                        >
                                                            <svg
                                                                className="w-4 h-4 mr-2"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M12 5c.6 0 1 .4 1 1v5h5c.6 0 1 .4 1 1s-.4 1-1 1h-5v5c0 .6-.4 1-1 1s-1-.4-1-1v-5H6c-.6 0-1-.4-1-1s.4-1 1-1h5V6c0-.6.4-1 1-1z"
                                                                ></path>
                                                            </svg>
                                                            <span>Add</span>
                                                        </a>
                                                        <a
                                                            href={`/coordinator-team-users/${tm?.id}`}
                                                            className="bg-[#a70000] text-white font-bold py-2 px-4 rounded-full inline-flex items-center text-sm"
                                                        >
                                                            <svg
                                                                className="w-4 h-4 mr-2"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M12 21.2L2.6 12 12 2.8l9.4 9.2-9.4 9.2z"
                                                                ></path>
                                                            </svg>
                                                            <span>View</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    //                               <div className="border-4 border-dashed border-gray-200 rounded-lg h-auto">
          //                                   <div>
          //                                       <a >
          //                                           <div className={`report bg-slate-700 hover:grow text-white`}>
          //                                               <h1 className="h1">{tm?.company_name}</h1>
          //                                               <br />
          //                                               <br />
          //                                               <div>
          //                                                   <h2 className="text-2xl">
          //                                                       <strong>{tm?.name}</strong>
          //                                                   </h2>
          //                                                   <div className="flex items-center space-x-4 ml-2 pt-5">
          //                                                       <a
          //                                                           href={`/coordinator-team-setup/${tm.id}`}
          //                                                           className="bg-green-500 text-white font-bold py-1 px-4 rounded-full inline-flex items-center text-sm"
          //                                                       >
          //                                                           <svg
          //                                                               className="w-4 h-4 mr-2"
          //                                                               viewBox="0 0 24 24"
          //                                                               xmlns="http://www.w3.org/2000/svg"
          //                                                           >
          //                                                               <path
          //                                                                   fill="currentColor"
          //                                                                   d="M12 5c.6 0 1 .4 1 1v5h5c.6 0 1 .4 1 1s-.4 1-1 1h-5v5c0 .6-.4 1-1 1s-1-.4-1-1v-5H6c-.6 0-1-.4-1-1s.4-1 1-1h5V6c0-.6.4-1 1-1z"
          //                                                               ></path>
          //                                                           </svg>
          //                                                           <span>Add</span>
          //                                                       </a>
          //                                                       <a
          //                                                           href={`/coordinator-team-users/${tm?.id}`}
          //                                                           className="bg-[#a70000] text-white font-bold py-1 px-4 rounded-full inline-flex items-center text-sm "
          //                                                       >
          //                                                           <svg
          //                                                               className="w-4 h-4 mr-2"
          //                                                               viewBox="0 0 24 24"
          //                                                               xmlns="http://www.w3.org/2000/svg"
          //                                                           >
          //                                                               <path
          //                                                                   fill="currentColor"
          //                                                                   d="M12 21.2L2.6 12 12 2.8l9.4 9.2-9.4 9.2z"
          //                                                               ></path>
          //                                                           </svg>
          //                                                           <span>View</span>
          //                                                       </a>
          //                                                   </div>
          //                                                   {/*<span>*/}
          //   {/*<i>view members</i>*/}
          // {/*</span>*/}
          //                                               </div>
          //                                           </div>
          //                                       </a>
          //                                   </div>
          //                               </div>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>

    )
}