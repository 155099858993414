import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";
import { teamActions, userActions, videoActions } from "../_store";
import Video from "./Video";
import { toast } from "react-toastify";
import IntroVideo from "../Videos/Change Game Intro.mp4";
import SessionEndedModal from "./superAdmin/SessionEndedModal";

export default function IntroContent({ game, user, license }) {
  const dispatch = useDispatch();
  const videoRef = useRef();

  const { team } = useSelector((x) => x.teams.team);
  console.log("Auth user from intro content", user);

  useEffect(() => {
    dispatch(teamActions.getTeam({ license }));
    if (user.hasWatchedIntro) {
      dispatch(videoActions.setIntroVideosWatched(true));
    }
  }, []);

  const onEnded = () => {
    dispatch(videoActions.setIntroVideosWatched(true));
    dispatch(userActions.updateUser({ id: user.id, hasWatchedIntro: true }));
    toast.success("Good Job! You can now proceed to your game");
  };

  const restartVideo = () => {
    toast.info("You are required to watch the full video!");
    videoRef.current.load();
    videoRef.current.play();
  };

  return (
      <>
        {/*{isLoggedOut && <SessionEndedModal/>}*/}

        <main
            style={{
              fontFamily: "Calibri, sans-serif",
              padding: "2rem",
              color: "#333",
              lineHeight: "1.6",
              background: "linear-gradient(135deg, #a70000, #ff9999)",
            }}
            id="content"
            role="main"
        >
          <div style={{ backgroundColor: "#fff", padding: "2rem", borderRadius: "10px" }}>
            <h1 style={{ color: "#a70000" }}>{game && game.name}</h1>
            <br />
            <div>
              <p>
                Welcome to the ChangeVerve Change Management Game. The purpose of the game is to provide the player with an experience detailing the complexity, wide reach, and impact of Change Management in organisations.
              </p>
              <p>&nbsp;</p>
              <p>
                During the game, you will be presented with various scenarios, choices, events, and Change Management best practice requirements that occur in a typical large project. You will need to make decisions for each scenario to manage the change and complete the project successfully.
              </p>
              <p>&nbsp;</p>
              <p>
                Before the game starts, you are required to watch 8 videos (38 Minutes in total duration):
              </p>
              <p style={{ marginLeft: "36.0pt", color: "#a70000" }}>
                Part 1: Understand the objectives of the game (1:23 Minutes),
              </p>
              <p style={{ marginLeft: "36.0pt", color: "#a70000" }}>Part 2: Introduction to Change Management</p>
              <ul style={{ paddingLeft: "80px", color: "#a70000" }}>
                <li>2a. Why Change Matters (3:05 Minutes)</li>
                <li>2b. Types of Change (3:21 Minutes)</li>
                <li>2c. Why Change Management Fails (5:55 Minutes)</li>
                <li>2d. ISO Requirements (4:46 Minutes)</li>
                <li>2e. Human Behaviour (4:45 Minutes)</li>
              </ul>
              <p style={{ marginLeft: "36.0pt", color: "#a70000" }}>Part 3: The Business Case (5:09 Minutes),</p>
              <p style={{ marginLeft: "36.0pt", color: "#a70000" }}>Part 4: How to play the game (9:32 Minutes).</p>
              <p>&nbsp;</p>
              <p>
                All the videos must be watched in sequence before proceeding to play the game. Videos can be replayed but not skipped backward or forward. The game can be exited at any time and will continue from that position once you resume playing.
              </p>
              <p>&nbsp;</p>
              <p>
                Please be advised that the <em>ChangeVerve Change Management Game</em>, its content, and all related materials are protected by copyright and trademark laws. Reproduction or distribution of the game or its contents without the express written consent of ChangeVerve is strictly prohibited.
              </p>
              <p>&nbsp;</p>
              <p>
                Click on the video link below to Start your ChangeVerve Change Management Game journey!
              </p>
            </div>
          </div>
        </main>

      </>
  );
}
