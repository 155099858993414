import {useDispatch, useSelector} from "react-redux";
import {saleActions} from "../../_store";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {promiseToast} from "../../_helpers/alerts";
import {useNavigate} from "react-router-dom";
import {SpinningButton} from "../button/Buttons"
import {Invoice} from "./Sales/Invoice";

const ConfirmSaleModal = ({formatter, price, plan, qty, user, email, emailInvoice, region, override, paymentMode}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [submitting, setSubmitting] = useState(false)
    const [url, setUrl] = useState("")
    const [completed, setCompleted] = useState(false)

    const onSend = () => {
        const items = [{
            id: plan.value && plan.value,
            name: plan.label && plan.label,
            qty
        }]

        const formattedPrice = formatter.format(price*qty)

        if (paymentMode === "paypal") {
            processPaypalPayment(items, user, emailInvoice, formattedPrice)
        }

        if (paymentMode === "payfast"){
            processPayFastPayment(items, user, email, emailInvoice, formattedPrice)
        }
    }

    const processPayFastPayment = (items, user,email, emailInvoice, formattedPrice) => {
        return dispatch(saleActions.addSale({items, email: email,
            user: user.value, emailInvoice, formattedPrice })) .then((response)=> {
            console.log("response", response)
            if (response.type === "sales/addSale/rejected") {
                toast.error("OOps, problem completing purchase!")
                setSubmitting(false)
                return
            }
            promiseToast("Saving...", "successfully created and sent an invoice!")

            setTimeout(() => {
                navigate(0)
            },2000)

        //     const transaction_number = response.payload.invoice.transaction_number
        //     const base_url = window.location.origin;
        //
        //     setTimeout(() => {
        //         setSubmitting(false)
        //         setCompleted(true)
        //         setUrl(`${base_url}/card-payments/${transaction_number}`)
        //     }, 2000)
        });
    }

    const processPaypalPayment = (items, user, emailInvoice, formattedPrice) => {
        return dispatch(saleActions.addPaypalSale({items, email:user.email,
            user: user.value, emailInvoice, formattedPrice }))
            .then((response)=> {
                console.log("response", response)
                if (response.type === "sales/addSale/rejected") {
                    toast.error("OOps, problem completing purchase!")
                    setSubmitting(false)
                    return
                }
                promiseToast("Saving...", "successfully created an invoice!")

                setTimeout(() => {
                    setSubmitting(false)
                    setCompleted(true)
                    const link = response.payload.link
                    setUrl(link)
                }, 2000)
            });
    }

    const copyLink = (text) => {
        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            toast.info("copied")
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        })
    }

    return (

    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                        <section className=" bg-black">
                            <div className="max-w-5xl mx-auto bg-white">

                                {/*<Invoice />*/}

                            </div>
                        </section>

                    </div>

                    <div className="bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                        {!completed &&
                        <button
                            disabled={submitting}
                            onClick={()=> {
                                setSubmitting(true)
                                onSend()
                            }}

                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                            {submitting ? <SpinningButton />  : "Create invoice" }
                        </button>
                        }

                        {completed &&

                            <>
                                <button
                                    disabled={submitting}
                                    onClick={(e)=> {
                                        copyLink(url)
                                    }}

                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-300 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                                    Copy payment link
                                </button>

                                <button
                                    disabled={submitting}
                                    onClick={(e)=> {
                                        copyLink(url)
                                    }}

                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#a70000b3] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                                    Send link
                                </button>
                            </>
                        }

                        <button
                            onClick={() => {
                                dispatch(saleActions.closeModal())
                                navigate(0)
                            }}
                            type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Close</button>

                    </div>

                </div>
            </div>
        </div>
    </div>

)
}
export default ConfirmSaleModal


