import {SuperAdminNav} from "./SuperAdminNav";
import {PlanInfo} from "./PlanInfo";
import {ToastContainer} from "react-toastify";
import React from "react";
import {VideoInfo} from "./VideoInfo";

function NewVideo() {

    return (

        <>
            {/*{isOpen && <Modal />}*/}
            <ToastContainer />

            <div className="min-h-full">
                <SuperAdminNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">New Media</h1>
                    </div>
                </header>

                <main className="w-full">

                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">
                        <nav className="ml-8 rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href="/super-admin/media" className="text-blue-600 hover:text-blue-700">Media</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">New Media</li>
                            </ol>
                        </nav>
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="w-2/3 sm:px-6">
                                    <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
                                        <div className="sm:flex items-center justify-between">
                                            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">New Video</p>

                                        </div>
                                    </div>

                                    <VideoInfo />

                                    <br/>
                                    <br/>
                                    <br/>

                                </div>

                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </>

    );
}

export default NewVideo
