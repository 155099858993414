import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {questionActions} from "../../../_store";
import {SuperAdminNav} from "../SuperAdminNav";
import { SingleSubGame} from "./SingleSubGame";
import ButtonDropDown from "../Questions/ButtonDropDown";


function SubGames() {

    const dispatch = useDispatch()
    const {data} = useSelector(x => x.questions.questions )

    useEffect(() => {
        dispatch(questionActions.getAll())
    },[])

    return (

        <>
            <div className="min-h-full">
                <SuperAdminNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Sub Games</h1>
                    </div>
                </header>

                <main>
                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                {data  && data.length === 0 &&
                                <div className="card-body cart">
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <div className="mx-auto">
                                        </div>

                                        <h3><strong>Your sub games page is empty </strong></h3>
                                        <a
                                            href={`/super-admin/new-sub-game`}
                                            className="mx-auto mt-5 group relative w-2/12 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"
                                        >
                                            Add New game
                                        </a>

                                    </div>
                                </div>
                                }

                                { data && data.length > 0 &&

                                <div className="w-full sm:px-6">
                                    <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
                                        <div className="sm:flex items-center justify-between">
                                            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Sub games</p>
                                            <div>
                                                <ButtonDropDown link={`/super-admin/new-sub-game`} name={`New Game`} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">

                                        {data && data.length > 0 &&
                                        <table className="w-full whitespace-nowrap">
                                            <thead>
                                            <tr className="h-16 w-full text-sm leading-none text-gray-800">
                                                <th className="font-normal text-left pl-4">No.</th>
                                                <th className="font-normal text-left pl-4">Description</th>
                                                <th className="font-normal text-left pl-12">Question</th>
                                                <th className="font-normal text-left pl-12">Type</th>
                                                {/*<th className="font-normal text-left pl-12">Wrong Answer Video</th>*/}
                                                <th className="font-normal text-left pl-12">Date</th>
                                                <th className="font-normal text-left pl-16">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="w-full">

                                            {Array.isArray(data) > 0 && data.map((tm, index) =>
                                                <SingleSubGame
                                                    key={tm.id}
                                                    number={index+1}
                                                    id={tm.id}
                                                    description={tm.description}
                                                    question={tm.question}
                                                    date={tm.created_at}
                                                    questionObject={tm}
                                                />
                                            )}

                                            </tbody>
                                        </table>

                                        }
                                    </div>

                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </>
    )
}

export default SubGames
