import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice
const name = 'functions';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports
export const functionActions = { ...slice.actions, ...extraActions };
export const functionsReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        functions: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/functions`;

    return {
        getAll: getAll()
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(baseUrl)
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.functions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.functions = action.payload;
            },
            [rejected]: (state, action) => {
                state.functions = { error: action.error };
                console.log("functions error", action.error )
            }
        };
    }
}
