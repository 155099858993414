import {gameActions, progressActions, questionActions, subgameActions, videoActions} from "../../_store";
import GreenBus from "../../games/Bus-Green.png";
import YellowBus from "../../games/Bus-Yellow.png";
import GreenLight from "../../games/Green-Light.png";
import GameTile from "../../games/Game-Tile-Plain.png";
import PhaseOneSign from "../../games/Change-Landscape-Phase.png";
import PhaseTwoSign from "../../games/Start-up-Phase.png";
import PhaseThreeSign from "../../games/Implementation-Phase.png";
import PhaseFourSign from "../../games/Anchoring-Stage.png";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Delayed from "../Delayed"
import GameBoardOne from "../../games/Game-Board-one.png";
import GameBoardTwo from "../../games/Game-Board-two.png";
// import GameBoardThree from "../../games/Example-3.png";
import GameBoardThree from "../../games/Game-Board-three.png"
import GameBoardFour from "../../games/Game-Board-four.png"
import GreenThumb from "../../games/green_thumb_tack_angled_right_1600_clr_1947.png"
import {InfoIcon} from "../button/Buttons";
import "../../custom.css"

export const SingleTile = ({step, userLastStep,divStyle, tileStyle, busStyle, type="tile", number, column, progressLastStep, greenTickerStyle}) => {
    const dispatch = useDispatch()
    const {progress,nextStep, progressOrder, gameCompletedStatus} = useSelector(x => x.progress)
    // const {progress, nextStep, progressOrder, et_score, gameCompleted ,gameCompletedStatus} = useSelector(x => x.progress)

    const LAST_BLOCK = 52
    const {user} = useSelector( x => x.auth)
    const stepOrder  = progress.progress && progress.progress.order
    const progressObject = progress.progress && progress.progress
    const nextStepObject = nextStep?.nextPhaseStep
    const userColumn = nextStepObject?.column
    const nextOrder  = nextStepObject?.order
    const phase_name = nextStep?.nextPhaseStep?.phase_name
    const answeredQuestionOnly = progressObject?.answered_question_only

    const typeToImage = {
        tile: GameTile,
        changeLandscape: PhaseOneSign,
        startUp: PhaseTwoSign,
        implementationPhase: PhaseThreeSign,
        AnchoringPhase: PhaseFourSign,
    }

    // const imageSrc = typeToImage[type] ?? GreenLight;

    const excludedSteps = ["1", "5", "15", "40"];

    return (
        <>
            <div
                key={step.id}
                onLoad={()=> {
                    dispatch(progressActions.setProgressOrder(stepOrder))

                    if (phase_name === "Change Landscape" ){
                        dispatch(gameActions.setPhaseBackground(GameBoardOne))
                    } else  if (phase_name === "Start up" ){
                        dispatch(gameActions.setPhaseBackground(GameBoardTwo))
                    } else if (phase_name === "Implementation" ){
                        dispatch(gameActions.setPhaseBackground(GameBoardThree))
                    }else if (phase_name === "Anchoring" ){
                        dispatch(gameActions.setPhaseBackground(GameBoardFour))
                    }

                }}
                onClick={() => {
                    if (step.order === LAST_BLOCK){
                        dispatch(progressActions.setGameCompletedStatusText("scores-card"))
                        return;
                    }

                    if (nextStepObject?.description?.includes("Knowledge Check")  && progressObject.kc_tries < 3 && !progressObject.kc_answered  ){
                        dispatch(questionActions.openModal())
                        return
                    }

                    if (nextStepObject?.description?.includes("Knowledge Check") && progressObject.kc_answered ){
                        dispatch(progressActions.getKCTries({user: user.id})).then((res) => {
                            dispatch(questionActions.incrementQuizAttempts(res?.payload?.progress))
                        })
                        dispatch(subgameActions.getSubGame(nextStepObject.questionId))

                        dispatch(questionActions.showCompletedQuizModal({isCorrect: true, status: true}))
                        return
                    }

                    if (nextStepObject?.description?.includes("Knowledge Check")  && (progressObject.kc_tries >= 3 )  ){
                        dispatch(progressActions.getKCTries({user: user.id})).then((res) => {
                            dispatch(questionActions.incrementQuizAttempts(res?.payload?.progress))
                        })
                        dispatch(subgameActions.getSubGame(nextStepObject.questionId))

                        dispatch(questionActions.showCompletedQuizModal({isCorrect: false, status: true}))
                        return
                    }

                    if (answeredQuestionOnly && step.phase_step_id === userLastStep){
                        dispatch(gameActions.setCurrentModalStep(step))
                        dispatch(gameActions.openVideoModal())
                        return
                    }

                    if (step.phase_step_id === userLastStep){
                        dispatch(gameActions.setCurrentModalStep(step))
                        if (step.only_media){
                            dispatch(gameActions.openMediaModal(true))
                            return
                        }
                        dispatch(gameActions.openModal(true))
                    }
                }}
                className={" absolute top-8 left-56 z-10"} style={divStyle} >
                {/*className={" absolute top-8 left-56 z-10"} style={divStyle} >*/}

                { Number(userColumn) === Number(column) && step.phase_step_id === userLastStep &&

                <Delayed>
                    <img className={`z-30 absolute`} src={YellowBus} alt="Yellow Bus"  style={busStyle} />
                </Delayed>
                }

                {step.order < nextOrder &&
                <>
                    <Delayed>
                        {/*<img className={`z-30 absolute`} src={GreenThumb} alt="Green thumb"  style={greenTickerStyle} />*/}
                        <img className={`z-30 absolute`} src={GreenThumb} alt="Green thumb"  style={greenTickerStyle} />
                    </Delayed>

                    <p className={" absolute z-30 text-sm text-white font-medium font"}
                       style={{marginTop: `35px`, left: `75px`,fontSize: `10px`, paddingTop: `2px`, paddingLeft: `2px`}}>

                        {type === "changeLandscape" || type === "startUp" || type === "implementationPhase" || type === "AnchoringPhase"  ?
                            <div onClick={() => {
                                dispatch(gameActions.setHoverModal({status: true, description: `${step.phase_step_name} - ${step.videoDescription}`}))
                            }}
                                 style={{marginTop: `-15px`, marginLeft: `28px`}}>
                            </div>:
                            <div onClick={() => {
                                dispatch(gameActions.setHoverModal({status: true, description: `${step.phase_step_name} - ${step.videoDescription}`}))
                            }}
                                 style={{marginTop: `-15px`, marginLeft: `28px`}}>

                                <InfoIcon />
                            </div>
                        }
                    </p>
                </>

                }

                { userLastStep == null && number === 0 && column === 0  && <img className={`z-30 absolute`} src={GreenBus} alt="Green Bus"  style={busStyle} />}

                {!excludedSteps.includes(step.name) && (
                    <p className="absolute z-30 text-sm text-white font-medium font" style={{marginTop: `35px`, left: `75px`, fontSize: `17px`, paddingTop: `2px`, paddingLeft: `2px`}}>
                        <div className="mr-10">{step.name}</div>
                    </p>
                )}

                <div className={`single-tile`}>
                    {<img src={typeToImage[type]} alt="Green tile" style={tileStyle} />}
                </div>
            </div>
        </>

    )
}