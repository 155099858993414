import {useDispatch, useSelector} from 'react-redux';
import {gameActions, progressActions, questionActions, subgameActions, videoActions} from "../../_store";
import * as Yup from "yup"
import {string} from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import {useForm} from "react-hook-form"
import {toast, ToastContainer} from "react-toastify"
import {useSearchParams} from "react-router-dom"
import Video from "../Video"
import {useEffect, useState} from "react"
import CopyrightLogo from "../../games/copyright-logo.jpg"

const GameModal = () => {
  const dispatch = useDispatch();
  const {currentModalStep} = useSelector(x => x.games)
  const {user} = useSelector(x => x.auth)
  const {completed, isModalOpen} = useSelector(x => x.videos)
  const [searchParams] = useSearchParams()
  const game_id = searchParams.get("game_id")
  const {progress, nextStep, progressOrder, ee_score, et_score, budget_score} = useSelector(x => x.progress)
  const {subgame} = useSelector(x => x.subgames)
  console.log("Sub game", subgame)
  const userLastStep = nextStep.nextPhaseStep && nextStep.nextPhaseStep.id
  const recapLink = progress?.progress?.link
  console.log("Progress Link", progress?.progress)
  const question = progress?.progress?.question
  const questionId = progress?.progress?.questionId
  const [loading, setLoading] = useState(false);

  const [recap, setRecap] = useState(false)
  const validationSchema = Yup.object().shape({
    answer: string().required('answer is required')
  })

  const formOptions = {resolver: yupResolver(validationSchema)};
  const {register, handleSubmit, formState, control} = useForm(formOptions);

  console.log("Progress from Game Media Modal", progress)

  const progressObject = progress?.progress
  const nextStepObject = nextStep?.nextPhaseStep

  const {
    option_a_ee_score: correctEEScore,
    option_a_et_score: correctETScore,
    option_a_eb_score: correctBudgetScore
  } = nextStepObject?.questionOptions || {};

  const currentActualEEScore = (progressObject?.actual_ee_score ?? 0) + (isNaN(correctEEScore) ? (nextStepObject?.questionOptions?.option_a_ee_score ?? 0) : correctEEScore);
  const currentActualETScore = (progressObject?.actual_et_score ?? 0) + (isNaN(correctETScore) ? (nextStepObject?.questionOptions?.option_a_et_score ?? 0) : correctETScore);
  const currentActualBudgetScore = (progressObject?.actual_budget_score ?? 0) + (isNaN(correctBudgetScore) ? (nextStepObject?.questionOptions?.option_a_eb_score ?? 0) : correctBudgetScore);


  async function onSubmit() {
    setLoading(true);

    const nextProgressOrder = progressOrder;
    const column = progress.progress && progress.progress.column


    // Optimistic update
    dispatch(progressActions.getNextStep({ order: nextProgressOrder }));
    dispatch(gameActions.closeVideoModal());

    try {
      await dispatch(progressActions.addProgress(
          {
            phaseStep: userLastStep, user: user.id, order: nextProgressOrder, game: game_id, column,
            ee_score,
            et_score,
            budget_score,
            answered_question_only: false,
            actual_ee_score: currentActualEEScore,
            actual_et_score: currentActualETScore,
            actual_budget_score: currentActualBudgetScore
          }))

      dispatch(progressActions.getProgress({ user: user.id }));
      dispatch(progressActions.getNextStep({ order: nextProgressOrder }));
    } catch (error) {
      toast.error("Oops! Problem saving progress!");
    } finally {
      setLoading(false);
    }
  }

  // function onSubmit() {
  //   const nextProgressOrder = progressOrder
  //   dispatch(progressActions.getNextStep({order: nextProgressOrder}))
  //   const column = progress.progress && progress.progress.column
  //   dispatch(gameActions.closeVideoModal());
  //
  //   return dispatch(progressActions.addProgress(
  //       {
  //         phaseStep: userLastStep, user: user.id, order: nextProgressOrder, game: game_id, column,
  //         ee_score,
  //         et_score,
  //         budget_score,
  //         answered_question_only: false,
  //         actual_ee_score: currentActualEEScore,
  //         actual_et_score: currentActualETScore,
  //         actual_budget_score: currentActualBudgetScore
  //       }))
  //       .then((response) => {
  //         if (response.type === "progress/addProgress/rejected") {
  //           toast.error("OOps!, Problem saving progress!")
  //           return
  //         }
  //
  //         dispatch(progressActions.getProgress({user: user.id}))
  //         dispatch(progressActions.getNextStep({order: nextProgressOrder}))
  //       });
  // }

  useEffect(() => {
    dispatch(videoActions.setCompleted(false))
  }, [])

  function replaceWithBr(text) {
    return text?.replace(/\n/g, "<br /> <br/>")
  }

  useEffect(() => {
    dispatch(subgameActions.getSubGame(questionId))
  }, [])



  return (
      <>
        <ToastContainer/>
        <aside className='modal-container' onLoad={() => dispatch(videoActions.setCompleted(false))}>
          <div className='modal'>

            {loading && <div className="loading-indicator">Loading...</div>}

            {recap &&
            <div>
              <h1 className="mb-2">RECAP</h1>
              <h3 className="mb-2 text-xs"><i>

                {subgame?.data?.correct_image_link == null &&
                <p dangerouslySetInnerHTML={{__html: replaceWithBr(question)}}/>
                }
                {/*<p dangerouslySetInnerHTML={{__html: replaceWithBr(question)}} />*/}
                {/*{question}*/}
              </i>
              </h3>
              <div>
                {/*{recapLink != null && recapLink.includes(".mp4") && <Video url={recapLink} /> }*/}
                {recapLink != null && recapLink.includes(".mp4") ? <Video url={recapLink}/> : recapLink !== null &&
                    <img width={`780`} src={recapLink}/>}

                {subgame?.data?.correct_image_link != null &&
                <img width={`780`} src={`${subgame?.data?.correct_image_link}`}/>}
              </div>

              <img src={CopyrightLogo} width={100} className="float-left mt-5"/>

              <div className='btn-container'>
                <div
                    className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                  {recap &&
                  <button
                      type="button"
                      className="mr-3 inline-block px-6 py-2.5 bg-[#a70000] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setRecap(false)
                      }}>
                    Continue
                  </button>
                  }

                </div>
              </div>
            </div>
            }

            {!recap &&
            <form method="POST" onSubmit={handleSubmit(onSubmit)}>
              <div>

                {console.log("Game Media Modal Type", currentModalStep)}
                {currentModalStep.mime_type === "application/octet-stream" ?
                    <div>
                      <h1>{currentModalStep.question}</h1>
                      <Video url={currentModalStep.link}/>
                    </div>
                    :
                    <img src={currentModalStep.link} width={"780"} alt={`media`}/>
                }

                <div>
                  {currentModalStep.mime_type === "application/octet-stream" &&
                  <div className="grid grid-cols-3 font-bold">
                    <div className={`text-lg`}>Effectiveness
                      Score: {nextStepObject?.questionOptions?.option_a_ee_score}</div>
                    <div className={`text-lg`}>Time Score: {nextStepObject?.questionOptions?.option_a_et_score}</div>
                    <div className={`text-lg`}>Budget Score: {nextStepObject?.questionOptions?.option_a_eb_score}</div>
                  </div>
                  }

                </div>

                <img src={CopyrightLogo} width={100} className="float-left mt-5"/>

                <div className='btn-container'>
                  <div
                      className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                    {progressOrder !== 0 && currentModalStep.order > 2 &&
                    <button
                        type="button"
                        className="mr-3 inline-block px-6 py-2.5 bg-[#a70000] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                        onClick={() => {
                          setRecap(true)
                        }}>
                      Recap
                    </button>
                    }

                    {currentModalStep.mime_type !== "application/octet-stream" &&
                    <button
                        type="submit"
                        className="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-800 hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                        // data-bs-dismiss="modal"
                        onClick={() => {
                          onSubmit()
                          dispatch(gameActions.closeMediaModal());
                        }}
                    >
                      Next
                    </button>
                    }

                    {currentModalStep.mime_type === "application/octet-stream" && completed &&
                    <button
                        type="submit"
                        className="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-800 hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                        // data-bs-dismiss="modal"
                        onClick={() => {
                          onSubmit()
                          dispatch(gameActions.closeMediaModal());
                        }}
                    >
                      Next
                    </button>
                    }

                  </div>
                </div>

              </div>
            </form>
            }
          </div>
        </aside>
      </>
  )
}
export default GameModal

