import React, {useEffect, useState} from "react";
import {AddUserButton, DeleteButton, EditButton, SpinningReloadButton, ViewButton} from "../../button/Buttons";
import {toast, ToastContainer} from "react-toastify";
import {useDispatch} from "react-redux";
import {string} from "yup";
import {saleActions} from "../../../_store";


export const SingleSale = ({ number, sale}) => {
    // const [show, setShow] = useState(null)
    const dispatch = useDispatch()
    const [copied, setCopied] = useState(false)
    const [generated, setGenerated] = useState(false)
    const [generating, setGenerating] = useState()

    const regenerateLink = (transaction_number) => {
        setGenerating(true)
        dispatch(saleActions.regenerateLink(transaction_number)).then(()=> {
            setGenerated(true)
            setTimeout(()=>{
                setGenerating(null)
                toast.info("Generated a new payment link!")
            },2000)
        })
    }

    const copyLink = (text) => {
        const base_url = window.location.origin;
        navigator.clipboard.writeText(`${base_url}/card-payments/${text}`).then(function() {
            toast.info("copied!")
            setCopied(true)
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        })
    }

    return (
        <>

        <tr className={`h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 
            ${sale.paid ? "bg-green-400 text-white" : "bg-red-300 text-white"}"bg-white" rounded`}>

            <td className="">
                <p className="text-sm font-medium leading-none text-gray-800 pl-5 rounded">{ number }</p>
            </td>

            <td className="pl-2">
                <p className="text-sm font-medium leading-none text-gray-800">{ sale.transaction_number }</p>
            </td>

            <td className="pl-8">
                <p className="text-sm font-medium leading-none text-gray-800">{ sale.email }</p>
            </td>

            <td className="pl-8">
                <p className="text-sm font-medium leading-none text-gray-800">{ sale.quantity }</p>
            </td>

            <td className="pl-8">
                <p className="text-sm font-medium leading-none text-gray-800">{ sale.formatted_total }</p>
            </td>

            <td className="pl-8">
                <p className="text-sm font-medium leading-none text-gray-800">{ sale.paid ? "Paid" : "Unpaid"} </p>
            </td>


            <td className="pl-16 cursor-pointer">
                <div className="flex items-center">
                    <div className="">
                        <p className="font-medium">{ sale.created_at }</p>
                        <p className="text-xs leading-3 text-gray-600 pt-2"> </p>
                    </div>
                </div>
            </td>

            <td className="pl-16">

                <div className="flex items-center mt-2 mb-2">

                    <div className={`mr-1 hover:grow hover:text-[#a70000]`}>
                        <a
                            href="#"
                            className="focus:outline-none pl-7">
                            <ViewButton />
                        </a>
                    </div>

                    <div className={`mr-1 hover:grow hover:text-[#a70000] ${copied && 'text-[#a70000]'}`}>
                        <a

                            onClick={(e)=> {
                                copyLink(sale.transaction_number)
                            }}

                            className="focus:outline-none pl-7">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                            </svg>
                        </a>
                    </div>

                    {!sale.paid &&
                        <div className={`mr-1 hover:grow hover:text-[#a70000] ${generated && 'text-[#a70000]'}`}>
                        <a
                            onClick={(e)=> {
                                regenerateLink(sale.transaction_number)
                            }}
                            className="focus:outline-none pl-7">
                            {generating ? <SpinningReloadButton />  :

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            }

                        </a>
                    </div>
                    }

                </div>
            </td>

        </tr>
        </>


    )
}