import IntroContent from "./IntroContent";
import {
    authActions,
    gameActions,
    licenseActions,
    planActions,
    progressActions,
    subscriptionActions,
    teamActions
} from "../_store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import { ModalAlertSpinner} from "./Alert/ModalAlert";
import {useSearchParams} from "react-router-dom";
import AssessmentIntroModal from "./AssessmentIntroModal";
import logo from "../Images/logo.png";
import "../index.css"
import {screens} from "../_helpers/screens";

const { screen, innerWidth, innerHeight, document } = window;


export default function AssessmentIntroPage() {
    const dispatch = useDispatch()
    const authUser = useSelector(x => x.auth.user)
    const isCompletedId = `completed-${authUser?.id}`
    const {subscription} = useSelector(x => x.subscriptions)
    const {isIntroVideosWatched, nextVideo } = useSelector(x => x.videos)
    const {plan} = useSelector(x => x.plans)
    const subscriptionPlan = subscription.subscription && subscription.subscription.subscription_plan
    // const game_id = plan && plan.game
    const {games} = useSelector(x => x.games.game)
    const {assessmentIntroModalOpened} = useSelector(x => x.games)
    const navigate = useNavigate()
    const [submitting, setSubmitting] = useState(false)
    const [searchParams] = useSearchParams()
    const license_id = searchParams.get("license_id")
    const game_id = searchParams.get("game")
    const isCompleted = useState(localStorage.getItem(isCompletedId) || false)

    const emailArray = [
        "freddy100dollar@gmail.com",
        "frederickankamah988+1@gmail.com",
        "frederickankamah988@gmail.com",
        "moniquevw.consulting@gmail.com",
        "birikate@gmail.com",
        "ilze@changeverve.co.za",
        "ilzes6@gmail.com",
        "lizelle@changeverve.co.za"
    ];

    const gotoGame = () => {
        if (!submitting) {
            setSubmitting(true)
        }

        setTimeout(() => {
            dispatch(licenseActions.updateStatus({user: authUser.id, license: license_id}))
            navigate(`/game-board/${subscriptionPlan}?game_id=${game_id}`)
            // navigate(`/game-board/${subscriptionPlan}?game_id=adf81d1c-b66e-41c1-a487-0983e5027ea1`)
        },1000)
    }

    const clearProgress = () => {
        dispatch(progressActions.clearProgress({user: authUser.id}))
        alert("Successfully cleared your progress")
    }

    const goToNextVideo = () => {
        if (!submitting) {
            setSubmitting(true)
        }
    }

    useEffect(() => {
        dispatch(subscriptionActions.getSingleSubscription({license: license_id}))
        dispatch(teamActions.getAll({license: license_id}))
        dispatch(progressActions.setGameCompleteStatus(false))

    },[])

    useEffect(() => {
        dispatch(planActions.getById(subscriptionPlan))
        // dispatch(gameActions.getGame({game: game_id}))
    },[subscriptionPlan])

    useEffect(() => {
        if (game_id !== null ){
            console.log("game id from Assessment intro page ", game_id)
            dispatch(gameActions.getGame({game: game_id}))
        }
    },[game_id])

    console.log("auth user email", authUser?.email)

    return (
        <>
            <ToastContainer />
            {assessmentIntroModalOpened && <AssessmentIntroModal/> }
            {
                submitting && <ModalAlertSpinner message="Description" description="Description" />
            }

            <div className="bg-[#a70000] min-h-screen overflow-hidden">
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                        <div className="flex items-center">
                            <img className="h-12 w-auto" src={logo} alt="logo" />
                            <h1 className="text-3xl font-bold text-gray-900 ml-4">Introduction</h1>
                        </div>
                        <a
                            onClick={() => {
                                dispatch(authActions.logout());
                            }}
                            href="#"
                            className="px-4 py-2 font-bold"
                            role="menuitem"
                            tabIndex="-1"
                            id="user-menu-item-2"
                        >
                            Sign out
                        </a>
                    </div>
                </header>

                <main className="bg-[#a70000]">
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <div
                                className="bg-white shadow-xl rounded-lg overflow-hidden"
                                style={{ marginBottom: '-120px' }}>
                                <IntroContent game={games} user={authUser} license={license_id} />

                                <div className="bg-[#a70000] p-6 flex justify-between">
                                    {/*{!isCompleted &&*/}

                                    {/*<button*/}

                                    {/*    onClick={() =>*/}
                                    {/*        dispatch(gameActions.setAssessmentIntroModalOpened(true))*/}
                                    {/*    }*/}

                                    {/*    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 border border-[#a70000] disabled:border-[#FFCCCB]  disabled:bg-[#FFCCCB] rounded"*/}
                                    {/*>*/}
                                    {/*    Watch Intro Videos*/}
                                    {/*    <br/>*/}
                                    {/*</button>*/}

                                    {/*}*/}

                                    {/*{isCompleted &&*/}
                                    <button
                                        onClick={() => {
                                            dispatch(gameActions.setAssessmentIntroModalOpened(false));
                                            gotoGame()
                                        }}
                                        className="bg-green-500 hover:bg-gray-700 text-white font-bold py-2 px-4 border border-[#a70000] disabled:border-[#FFCCCB]  disabled:bg-[#FFCCCB] rounded"
                                    >
                                        Play game
                                        <br />
                                    </button>
                                    {/*}*/}


                                    {emailArray.includes(authUser?.email) && (
                                        <button
                                            onClick={() => {
                                                clearProgress();
                                            }}
                                            className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 border disabled:border-[#FFCCCB]  disabled:bg-[#FFCCCB] rounded"
                                        >
                                            Clear Progress
                                            <br />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>
                </main>
                <br />
                <br />
            </div>

        </>
    )
}