import {useDispatch, useSelector} from 'react-redux';

import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router";
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import WinnerCup from "../../games/congrats-cup.jpg"
import {AnimateNumber} from "../AnimateNumber";
import {authActions, licenseActions, userActions} from "../../_store";
import {useSearchParams} from "react-router-dom";
import CopyrightLogo from "../../games/copyright-logo.jpg";

const CompleteGameModal = ({game_id}) => {
    const dispatch = useDispatch();
    const {currentModalStep} = useSelector(x => x.games)
    const {progress} = useSelector(x => x.progress)
    const {user} = useSelector(x=> x.auth)
    const navigate = useNavigate()

    const currentActualEEScore = progress.progress && progress.progress.actual_ee_score + 100
    const currentActualETScore = progress.progress && progress.progress.actual_et_score + 100
    const currentActualBudgetScore = progress.progress && progress.progress.actual_budget_score + 100
    const overallScore = Math.floor((currentActualEEScore+currentActualETScore+currentActualBudgetScore)/3)


    console.log("User License actions", user?.license)
    console.log("User progress game", progress)

    return (
        <>
            <ToastContainer />

            <aside className='modal-container'>

                <div className='modal'>
                    <form method="POST">
                        {/*<Confetti />*/}

                        <h2 className="mb-2 mt-6">
                            <span className=" mb-3">
                               You have reached the end of the Project Phoenix change journey.
                                <br/>
                                Your results will be emailed to you.
                                <br/>
                                Thank you for playing!

                            </span>
                        </h2>
                        <i><a target="_blank" href="https://forms.office.com/r/N7m330pA4T" className="underline font-bold">
                            We want to hear from you. Please click here to complete a feedback form.
                        </a></i>
                        <br/>
                        <br/>

                        <img src={CopyrightLogo} width={100} className="float-left mt-5" />

                        <div>
                            <div className='btn-container'>
                                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                                    <button
                                        onClick={() => {
                                            dispatch(licenseActions.completeGame({user: user?.id, license: user?.license,completed: true, game_id: progress?.progress?.game})).then(() => {
                                                dispatch(authActions.logout())
                                            })
                                        }}

                                        type="button"
                                        className="mr-3 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                        data-bs-dismiss="modal">
                                        END GAME
                                    </button>
                                </div>
                            </div>
                        </div>


                    </form>
                </div>
            </aside>

        </>
    )
}
export default CompleteGameModal


