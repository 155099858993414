import React, {useEffect, useState} from "react";
import { AddUserButton, DeleteButton, EditButton, ViewButton} from "../button/Buttons";


export const SinglePlan = ({ number, game}) => {
    const [show, setShow] = useState(null)

    return (
        <tr
            className={`h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 
            "bg-white" `}>

            <td className="">
                <p className="text-sm font-medium leading-none text-gray-800">{ number }</p>
            </td>

            <td className="pl-2">
                <p className="text-sm font-medium leading-none text-gray-800">{ game.name }</p>
            </td>

            <td className="pl-8">
                <p className="text-sm font-medium leading-none text-gray-800">{ game.price_per_person }</p>
            </td>

            <td className="pl-8">
                <p className="text-sm font-medium leading-none text-gray-800">{ game.price_per_person_europe }</p>
            </td>


            <td className="pl-16 cursor-pointer">
                <div className="flex items-center">
                    <div className="">
                        <p className="font-medium">{ game.duration_in_days }</p>
                        <p className="text-xs leading-3 text-gray-600 pt-2"> </p>
                    </div>
                </div>
            </td>
            <td className="pl-24">
                <p className="text-sm font-medium leading-none text-gray-800">{game.game_name}</p>
            </td>

            <td className="pl-16">
                <p className="font-medium">{game.created_at}</p>
            </td>

            <td className="pl-16">

                <div className="flex items-center mt-2 mb-2">

                    <div className="mr-3">
                        <a
                            href={`/assessments/${game.id}`}
                            className="focus:outline-none pl-7">
                            <ViewButton />
                        </a>
                    </div>

                    <div>
                        <a
                            href=""
                            className="focus:outline-none pl-7">
                            <DeleteButton />
                        </a>
                    </div>
                </div>
            </td>

        </tr>

    )
}