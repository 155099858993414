import {useDispatch, useSelector} from 'react-redux';
import { closeModal } from '../features/modal/modalSlice';

const Modal = () => {
  const dispatch = useDispatch();
  const {emailContent} = useSelector(x => x.teams)

  console.log("Email Content", emailContent.content)

  return (
    <aside className='modal-container'>
      <div className='modal'>
        <div>


          <div className="text-left">
            <strong>
              From:
            </strong>
            <span className="pl-6"> ChangeVerve Change Management Game (no-reply@changevervacademy.com)</span>
            <p>
              <strong>To: </strong>
              <span className="pl-11"> [Team members] </span>
            </p>

            {/*<p>*/}
            {/*  <strong>Subject: </strong>*/}
            {/*  <span className="pl-2"> { emailContent && emailContent.subject} </span>*/}
            {/*</p>*/}
            <hr className="h-50 bg-indigo-700"/>
            <br/>
            <p>Good day,</p>

            <div>
              <p>Welcome to the&nbsp;<strong>ChangeVerve Change Management Game.</strong> An interactive online platform that guides you through the common scenarios, events, and hurdles encountered during the implementation of significant organisational change initiatives.&nbsp;</p>
            </div>

            <p className="mt-3">
              {emailContent && emailContent.content}
            </p>

            <br/>

            <div>

              <div className="mb-2">
                <p><strong>REGISTRATION AND LOGIN:&nbsp;</strong></p>
              </div>

              <div  className="mb-2">
                <p>If you have a ChangeVerve account, please log in with your username and password to access the ChangeVerve Change Management Game.&nbsp;</p>
              </div>

              <div className="mb-2">
                <p>If you are a first-time user of ChangeVerve products, kindly follow the registration steps below.&nbsp;</p>
              </div>

              <p  className="mb-2"><strong>Steps to register for the game:</strong></p>

              <p  className="mb-2"><strong>Step 1</strong>: &nbsp;CLICK on the following link to access the game platform: &nbsp;&nbsp;<a href="https://changeverveacademy.com/invitee-register/id" target="_blank">https://changeverveacademy.com/invitee-register/id</a> &nbsp;</p>
              <p  className="mb-2"><strong>Step 2</strong>: &nbsp;Enter your name, surname and email address in the fields provided.&nbsp;NB: Use the email address on which you received this email.</p>
              <p  className="mb-2"><strong>Step 3</strong>: Create a password and CLICK on the &ldquo;REGISTER&rdquo; button.&nbsp;</p>
              <p  className="mb-2"><strong>Step 4</strong>: Log in using your email and password on the login screen to go to your ChangeVerve Academy dashboard. &nbsp;</p>
              <p  className="mb-2"><strong>Step 5:</strong> CLICK on the tile indicating the ChangeVerve Change Management Game for your team to start playing.</p>

              <p className="mb-2">For future login after registration, use the following link to access the game.&nbsp;<a href="https://changeverveacademy.com/" target="_blank">https://changeverveacademy.com</a>&nbsp;</p>
              <div>
                <br/>
              </div>
              <p>The game offers flexibility in terms of playtime, allowing for either a single extended session lasting approximately 6 to 8 hours, or multiple shorter intervals that fit within your schedule. Your progress will be tracked and saved, allowing you to resume play at the point where you last left off upon your return.</p>
              <div>
                <br/>
              </div>
              <p><strong>Your organisation requires you to complete the game by close of business&nbsp;(expiry date).</strong></p>
              <div>
                <br/>
              </div>
              <p>Should you have any difficulty logging in, kindly contact your Team Coordinator at&nbsp;<a href="mailto:info@changeverveacademy.com" target="_blank">info@changeverveacademy.com</a>.</p>
              <div>
                <br/></div>
              <p>Good luck and enjoy the experience!</p>
              <div><br/></div>

              {/*<p className="mb-5">Before completing, please keep in mind the following:</p>*/}

              {/*<ul>*/}
              {/*    <li className="mb-2">1.*/}
              {/*        Take the team assessment as soon as possible.*/}
              {/*    </li>*/}

              {/*    <li className="mb-2">*/}
              {/*        2.  The assessment is designed to be completed in approximately 10 minutes.*/}
              {/*        Be thoughtful about your responses, but agonize over each response. Your initial*/}
              {/*        gut feel is usually best.*/}
              {/*    </li>*/}

              {/*    <li className="mb-2">*/}
              {/*        3.*/}
              {/*        Be honest. The team needs your candid feedback. To ensure confidentiality,*/}
              {/*        your results will be tabulated by the ChangeVerve Group and presented in aggregate form.*/}
              {/*    </li>*/}
              {/*</ul>*/}

            </div>



          </div>
          <div className='btn-container'>

            {/*<button*/}
            {/*    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
            {/*    type='button'*/}
            {/*    onClick={() => {*/}
            {/*      dispatch(closeModal());*/}
            {/*    }}>*/}
            {/*  cancel*/}
            {/*</button>*/}

            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                  data-bs-dismiss="modal"
                  onClick={() =>
                     {
                       dispatch(closeModal());
                      }}>
                Close
              </button>
              {/*<button*/}
              {/*    type="button"*/}
              {/*    className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"*/}
              {/*>*/}
              {/*  Save changes*/}
              {/*</button>*/}
            </div>

          </div>


        </div>
      </div>
    </aside>
  );
};
export default Modal;


