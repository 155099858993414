import { LockClosedIcon } from '@heroicons/react/solid'
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../_store";
import {AssessmentLogin} from "./AssessmentLogin";
import {AdminLogin} from "./AdminLogin"
import logo from "../../Images/logo.png"

export default function Login() {
  const dispatch = useDispatch();
  const auth = useSelector(x => x.auth);

  return (
      <>
        <div className="login-wrapper-container mt-12">
          <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 -mt-6">
            <div className="max-w-md w-full space-y-8">
              <div>
                <img
                    className="mx-auto h-12 w-auto"
                    src={logo}
                    alt="logo"
                />
                <br/>
              </div>

              <div>
                <button
                    onClick={() =>{
                      dispatch(authActions.openAssessmentLogin())}}
                    type="button"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"
                >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {/*<LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />*/}
                </span>
                  User Login
                </button>

              </div>

              <div>
                <button
                    onClick={() =>{
                      dispatch(authActions.openAdminLogin())
                    }}
                    type="button"
                    // className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"
                >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {/*<LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />*/}
                </span>
                  Coordinator/Administrator Login
                </button>

              </div>

              {auth.isAdminLoginOpen &&
              <AdminLogin />
              }

              {auth.isAssessmentLoginOpen &&
              <AssessmentLogin />
              }

            </div>
          </div>
        </div>
      </>
  )
}