import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'plans';
const initialState = createInitialState();
// const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const planActions = { ...slice.actions, ...extraActions };
export const plansReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        plan: {},
        list : []
    }
}

function createExtraActions() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/plans`;

    return {
        addPlan: addPlan(),
        getById: getPlanById(),
        getAll: getPlans(),
    }

    function addPlan() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/plans`;
        return createAsyncThunk(
            `${name}/addPlan`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function getPlans() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${url}`)
        );
    }

    function getPlanById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => await fetchWrapper.get(`${url}/${id}`)
        );
    }

}

function createExtraReducers() {
    return {
        ...addPlan(),
        ...getById(),
        ...getPlans()
    };

    function addPlan() {
        const { pending, fulfilled, rejected } = extraActions.addPlan;
        return {
            [pending]: (state) => {
                state.plan = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.plan = action.payload;
            },
            [rejected]: (state, action) => {
                state.plan = { error: action.error };
            }
        };
    }

    function getPlans() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.list = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload;
            },
            [rejected]: (state, action) => {
                state.list = { error: action.error };
            }
        };
    }

    function getById() {
        const { pending, fulfilled, rejected } = extraActions.getById;
        return {
            [pending]: (state) => {
                state.plan = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.plan = action.payload;
                // console.log("Plans from slice", action.payload)
            },
            [rejected]: (state, action) => {
                state.plan = { error: action.error };
            }
        };
    }

}
