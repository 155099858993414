import {useDispatch, useSelector} from "react-redux";
// import {logout} from "../features/user/userSlice";
import TeamSetup from "./TeamSetUp";
import Modal from '../Modal'
import {Nav} from "../Nav";
import React, {useEffect} from "react";
import {licenseActions, teamActions} from "../../_store";
import {ProductNav} from "../ProductNav";

const NewTeam = () => {

    return (
        <>

            <div className="min-h-full">
                <ProductNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Team Setup</h1>
                    </div>
                </header>
                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

                        <nav className="rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href="/assessments" className="text-blue-600 hover:text-blue-700">Assessments</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">Add New Team</li>
                            </ol>
                        </nav>

                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <TeamSetup />

                            </div>
                        </div>
                    </div>
                </main>
                <br/>
                <br/>
            </div>
        </>
    )
}

export default NewTeam