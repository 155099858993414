import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {
    questionActions, videoActions,
} from "../../../_store";
import {modalActions} from "../../../_store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../../_helpers/alerts";
import {useNavigate, useParams} from "react-router";
import React, {useEffect, useRef, useState} from "react";
import {object, string} from "yup";
import Select from "react-select";
import {SpinningButton} from "../../button/Buttons";



export const EditQuestionInfo = () => {
    const {id} = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {videos} = useSelector(x => x.videos.list)
    const {question} = useSelector(x => x.questions)
    const ref = useRef()
    console.log("Current value", ref.current)
    const [questionObject, setQuestionObject] = useState()

    const [type, setType] = useState("question")

    const videoOptions = videos && videos
        .map((item) =>{
            return { value: item.id, label: item.name}
        })

    const validationSchema = Yup.object().shape({
        // question: type === "question" && Yup.string().required('Question is required'),
        // options_answer: type === "question" && string().required('answer is required'),
        // video: type === "question" && object().required('Video is required')
    })

    const formOptions = { resolver: yupResolver(validationSchema) }
    const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm(formOptions)
    const { errors, isSubmitting } = formState

    useEffect(() => {
        dispatch(videoActions.getAll())
        // dispatch(questionActions.getQuestion(id))
    },[])

    useEffect(() => {
        const fields = ['id','question','options_answer',
            'option_one',   'reason_one',   'option_a_ee_score', 'option_a_et_score', 'option_a_eb_score',
            'option_two',   'reason_two',   'option_b_ee_score','option_b_et_score', 'option_b_eb_score',
            'option_three', 'reason_three', 'option_c_ee_score','option_c_et_score', 'option_c_eb_score',
            'option_four',  'reason_four',  'option_d_ee_score','option_d_et_score', 'option_d_eb_score'
        ];
        dispatch(questionActions.getQuestion(id)).then((ques)=> {
            fields.forEach(field => {
                switch (field){
                    case "question":
                        setValue(field, ques.payload?.data?.question )
                        break

                    case "option_one":
                        setValue(field, ques.payload?.data?.options?.option_one )
                        break

                    case "reason_one":
                        setValue(field, ques.payload?.data?.options?.reason_one )
                        break

                    case "option_a_ee_score":
                        setValue(field, ques.payload?.data?.options?.option_a_ee_score )
                        break

                    case "option_a_et_score":
                        setValue(field, ques.payload?.data?.options?.option_a_et_score )
                        break

                    case "option_a_eb_score":
                        setValue(field, ques.payload?.data?.options?.option_a_eb_score )
                        break


                    case "option_two":
                        setValue(field, ques.payload?.data?.options?.option_two )
                        break

                    case "reason_two":
                        setValue(field, ques.payload?.data?.options?.reason_two )
                        break

                    case "option_b_ee_score":
                        setValue(field, ques.payload?.data?.options?.option_b_ee_score )
                        break

                    case "option_b_et_score":
                        setValue(field, ques.payload?.data?.options?.option_b_et_score )
                        break

                    case "option_b_eb_score":
                        setValue(field, ques.payload?.data?.options?.option_b_eb_score )
                        break


                    case "option_three":
                        setValue(field, ques.payload?.data?.options?.option_three )
                        break

                    case "reason_three":
                        setValue(field, ques.payload?.data?.options?.reason_three )
                        break

                    case "option_c_ee_score":
                        setValue(field, ques.payload?.data?.options?.option_c_ee_score )
                        break

                    case "option_c_et_score":
                        setValue(field, ques.payload?.data?.options?.option_c_et_score )
                        break

                    case "option_c_eb_score":
                        setValue(field, ques.payload?.data?.options?.option_c_eb_score )
                        break

                    case "option_four":
                        setValue(field, ques.payload?.data?.options?.option_four )
                        break

                    case "reason_four":
                        setValue(field, ques.payload?.data?.options?.reason_four )
                        break

                    case "option_d_ee_score":
                        setValue(field, ques.payload?.data?.options?.option_d_ee_score )
                        break

                    case "option_d_et_score":
                        setValue(field, ques.payload?.data?.options?.option_d_et_score )
                        break

                    case "option_d_eb_score":
                        setValue(field, ques.payload?.data?.options?.option_d_eb_score )
                        break

                    case "options_answer":
                        setValue(field, ques.payload?.data?.options?.options_answer )
                        break

                    // case "id":
                    //     setValue(field, ques.payload?.data?.id )
                    //     break
                }
            });
        })
        },[])

       function onSubmit(fields){
        const formFields = {
            ...fields,
            id
        }
        return dispatch(questionActions.updateQuestion(formFields))
            .then(async(response)=> {
                if (response.type === "questions/updateQuestion/rejected"){
                    toast.error("OOps!, Problem saving question!")
                    return
                }
                await promiseToast("Updating question", "Successfully updated!")

                setTimeout(() => {
                    navigate("/super-admin/questions")
                }, 2000)
            });
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}  method="POST" onReset={reset}>
                <div className="shadow overflow-hidden sm:rounded-md">

                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <br/>

                        {type === "question" &&
                            <div className="col-span-6 sm:col-span-3">

                                <div className="col-span-6 sm:col-span-3 mb-3">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-3">
                                        Question
                                    </label>

                                    <textarea
                                        ref={register}
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({question: e.target.value}))
                                        }}
                                        {...register("question")}
                                        rows="10"
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    >

                                    </textarea>

                                </div>

                            <div className="grid">

                                <p className={`font-semi-bold mb-3`}>Option A.</p>

                                <div className="border-2 border-grey-800 rounded px-3 pt-3 pb-3">

                                     <textarea
                                         ref={register}
                                         rows={`12`}
                                         // defaultValue={question?.data?.options?.option_one}

                                         onChange={(e) => {
                                             dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                         }}

                                         {...register("option_one")}
                                         placeholder="Type answer option here..."
                                         className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                     />

                                    <textarea
                                        ref={register}
                                        // defaultValue={question?.data?.options?.reason_one}
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({reason_one: e.target.value}))
                                        }}

                                        rows="7"
                                        placeholder="Type reason here..."
                                        name="reason_one"
                                        {...register("reason_one")}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    >
                            </textarea>

                                    <br/>

                                    <div className={`grid grid-cols-3 gap-2`}>

                                        <div>
                                            <input

                                                ref={register}

                                                // defaultValue={question?.data?.options?.option_a_ee_score}

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_a_ee_score: e.target.value}))
                                                }}

                                                {...register("option_a_ee_score")}
                                                type="number"
                                                placeholder="Type option A Effectiveness score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                ref={register}

                                                // defaultValue={question?.data?.options?.option_a_et_score}

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_a_et_score: e.target.value}))
                                                }}

                                                {...register("option_a_et_score")}
                                                type="number"
                                                placeholder="Type option A Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                ref={register}
                                                // defaultValue={question?.data?.options?.option_a_eb_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_a_eb_score: e.target.value}))
                                                }}

                                                {...register("option_a_eb_score")}
                                                type="number"
                                                placeholder="Type option A Time score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="grid mt-5">
                                <p className={`font-semi-bold mb-3`}>Option B.</p>

                                <div className="border-2 border-grey-800 rounded px-3 pt-3 pb-3">

                                       <textarea
                                           rows={`12`}
                                           ref={register}
                                           // defaultValue={question?.data?.options?.option_two}
                                           onChange={(e) => {
                                               dispatch(questionActions.setQuestion({option_two: e.target.value}))
                                           }}

                                           {...register("option_two")}
                                           placeholder="Type answer option here..."
                                           className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                       />

                                    <textarea
                                        ref={register}

                                        // defaultValue={question?.data?.options?.reason_two}

                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({reason_two: e.target.value}))
                                        }}
                                        rows="7"
                                        placeholder="Type reason here..."
                                        name="reason_two"
                                        {...register("reason_two")}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <br/>

                                    <div className={`grid grid-cols-3 gap-2`}>

                                        <div>
                                            <input
                                                ref={register}

                                                // defaultValue={question?.data?.options?.option_b_ee_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_b_ee_score: e.target.value}))
                                                }}

                                                {...register("option_b_ee_score")}
                                                type="number"
                                                placeholder="Type option B Effectiveness score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input
                                                ref={register}
                                                // defaultValue={question?.data?.options?.option_b_et_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_b_et_score: e.target.value}))
                                                }}

                                                {...register("option_b_et_score")}
                                                type="number"
                                                placeholder="Type option B Time score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input
                                                ref={register}
                                                // defaultValue={question?.data?.options?.option_b_eb_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_b_eb_score: e.target.value}))
                                                }}

                                                {...register("option_b_eb_score")}
                                                type="number"
                                                placeholder="Type option B Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>
                                <br/>

                            <div className="grid">
                                <p className={`font-semi-bold`}>Option C.</p>
                                <div className="border-2 border-grey-800 rounded px-3 pt-3 pb-3 mt-5">
                                    <textarea
                                        rows={10}
                                        ref={register}

                                        // defaultValue={question?.data?.options?.option_three}

                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({option_three: e.target.value}))
                                        }}
                                        {...register("option_three")}
                                        placeholder="Type answer option here..."
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <textarea
                                        ref={register}

                                        // defaultValue={question?.data?.options?.reason_three}

                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({reason_three: e.target.value}))
                                        }}
                                        rows="7"
                                        placeholder="Type reason here..."
                                        name="reason_three"
                                        {...register("reason_three")}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <br/>
                                    <div className={`grid grid-cols-3 gap-2`}>

                                        <div>
                                            <input
                                                ref={register}

                                                // defaultValue={question?.data?.options?.option_c_ee_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_c_ee_score: e.target.value}))
                                                }}

                                                {...register("option_c_ee_score")}
                                                type="number"
                                                placeholder="Type option C Effectiveness score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input
                                                ref={register}

                                                // defaultValue={question?.data?.options?.option_c_et_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_c_et_score: e.target.value}))
                                                }}

                                                {...register("option_c_et_score")}
                                                type="number"
                                                placeholder="Type option C Time score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input
                                                ref={register}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_c_eb_score: e.target.value}))
                                                }}

                                                {...register("option_c_eb_score")}
                                                type="number"
                                                placeholder="Type option C Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>
                                <br/>
                            <div className="grid">
                                <p className={`font-semi-bold`}>Option D.</p>
                                <div className="border-2 border-grey-800 rounded px-3 pt-3 pb-3 mt-5">
                                    <textarea
                                        rows={12}
                                        ref={register}
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({option_four: e.target.value}))
                                        }}
                                        {...register("option_four")}
                                        placeholder="Type answer option here..."
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <textarea
                                        ref={register}
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({reason_four: e.target.value}))
                                        }}
                                        rows="7"
                                        placeholder="Type reason here..."
                                        name="reason_four"
                                        {...register("reason_four")}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <br/>

                                    <div className={`grid grid-cols-3 gap-2`}>

                                        <div>
                                            <input
                                                ref={register}
                                                // defaultValue={question?.data?.options?.option_d_ee_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_d_ee_score: e.target.value}))
                                                }}

                                                {...register("option_d_ee_score")}
                                                type="number"
                                                placeholder="Type option D Effectiveness score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input
                                                ref={register}
                                                // defaultValue={question?.data?.options?.option_d_et_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_d_et_score: e.target.value}))
                                                }}

                                                {...register("option_d_et_score")}
                                                type="number"
                                                placeholder="Type option D Time score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input
                                                ref={register}
                                                // defaultValue={question?.data?.options?.option_d_eb_score}
                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_d_eb_score: e.target.value}))
                                                }}

                                                {...register("option_d_eb_score")}
                                                type="number"
                                                placeholder="Type option D Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <p>Choose the correct answer from options</p>

                                <div className={`grid grid-cols-4`}>

                                    <div className="2xl mt-3" >

                                        <div className="3xl mt-0" >
                                            <label className={`mr-3`}>A</label>
                                            <input
                                                ref={register}
                                                // checked={question?.data?.options?.options_answer === "option_one"}
                                                value="option_one" type="radio" {...register("options_answer")}/>
                                        </div>
                                    </div>

                                    <div className="2xl mt-3" >
                                        <div className="3xl mt-0" >
                                            <label className={`mr-3`}>B</label>
                                            <input
                                                ref={register}
                                                // checked={question?.data?.options?.options_answer === "option_two"}
                                                value="option_two" type="radio" {...register("options_answer")}/>
                                        </div>
                                    </div>

                                    <div className="2xl mt-3" >

                                        <div className="3xl mt-0" >
                                            <label className={`mr-3`}>C</label>
                                            <input
                                                ref={register}
                                                // checked={question?.data?.options?.options_answer === "option_three"}
                                                value="option_three" type="radio" {...register("options_answer")}/>
                                        </div>
                                    </div>

                                    <div className="2xl mt-3" >

                                        <div className="3xl mt-0" >
                                            <label className={`mr-3`}>D</label>
                                            <input
                                                ref={register}
                                                // checked={question?.data?.options?.options_answer === "option_four"}
                                                value="option_four" type="radio" {...register("options_answer")}/>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        }
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                            {isSubmitting ? <SpinningButton />  : "Update Question" }
                        </button>
                    </div>
                </div>
            </form>

        </>
    )
}