import {useDispatch, useSelector} from 'react-redux';
import {gameActions, progressActions, questionActions, videoActions} from "../_store";
const VideoSeekModal = () => {
    const dispatch = useDispatch();

    return (
        <aside className='modal-container z-50'>
            <div className='modal'>
                <form method="POST">
                    <div>
                        <h1 className="mb-2">VIDEO</h1>

                        <div>
                            You are required to watch full video
                        </div>

                        <div className='btn-container'>

                            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                                <button
                                    type="button"
                                    className="mr-3 inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        dispatch(videoActions.closeModal());
                                    }}>
                                    RESTART VIDEO
                                </button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </aside>
    )
};
export default VideoSeekModal


