import {Profile} from "../Profile";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {authActions, profileActions} from "../../_store";

import Logo from "../../Images/Logo-Round-V2.png"
import UserIcon from "../../Images/user-icon.jpg"
import {useEffect} from "react";

export const SuperAdminNav = () => {
    const dispatch = useDispatch()
    const profile = useSelector(x => x.profile)

    useEffect(() => {
        dispatch(profileActions.close())
    }, [])

    return (
        <nav className="bg-[#a70000]">

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">

                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <img className="h-8 w-8"
                                 src={Logo}
                                 alt="Workflow"/>

                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">

                                    <>
                                        <NavLink to='/super-admin/dashboard'
                                                 className="text-white hover:bg-[#ffffff] hover:text-black px-3 py-2 rounded-md text-sm font-medium"
                                                 aria-current="page">Dashboard</NavLink>

                                        <NavLink to='/super-admin/sales' className="text-white hover:bg-[#ffffff] hover:text-black px-3 py-2 rounded-md text-sm font-medium">Invoices</NavLink>

                                        <NavLink to='/super-admin/subscriptions' className="text-white hover:bg-[#ffffff] hover:text-black px-3 py-2 rounded-md text-sm font-medium">Subscriptions</NavLink>

                                        <NavLink to='/super-admin/plans'      className="text-white hover:bg-[#ffffff] hover:text-black px-3 py-2 rounded-md text-sm font-medium">Product Plans</NavLink>

                                        <NavLink to='/super-admin/questions'  className="text-white hover:bg-[#ffffff] hover:text-black px-3 py-2 rounded-md text-sm font-medium">Scenarios/Questions</NavLink>

                                        <NavLink to='/super-admin/media'     className="text-white hover:bg-[#ffffff] hover:text-black px-3 py-2 rounded-md text-sm font-medium">Media</NavLink>

                                        <NavLink to='/super-admin/games'      className="text-white hover:bg-[#ffffff] hover:text-black px-3 py-2 rounded-md text-sm font-medium">Games</NavLink>

                                        <NavLink to='/super-admin/sub-games'      className="text-white hover:bg-[#ffffff] hover:text-black px-3 py-2 rounded-md text-sm font-medium">Sub Games</NavLink>
                                    </>

                                }



                            </div>
                        </div>
                    </div>

                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            <button type="button"
                                    className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">View notifications</span>
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                     aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                                </svg>

                            </button>

                            <div className="ml-3 relative">
                                <div>
                                    <button type="button"
                                            onClick={() => {
                                                dispatch(profileActions.open())
                                            }}

                                            className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                            id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                        <span className="sr-only">Open user menu</span>
                                        <img className="h-8 w-8 rounded-full"
                                             // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                             src={UserIcon}
                                             alt=""/>
                                    </button>
                                </div>

                                {profile.isOpen && <Profile />}

                                {/*<Profile />*/}

                            </div>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button type="button"
                                className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                aria-controls="mobile-menu" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>

                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>

                            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className="md:hidden" id="mobile-menu">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    <a href="#"
                       className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                       aria-current="page">Dashboard</a>

                    <a href="#"
                       className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a>


                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                            <img className="h-10 w-10 rounded-full"
                                 src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                 alt=""/>
                        </div>
                        <div className="ml-3">
                            <div className="text-base font-medium leading-none text-white">Tom Cook</div>
                            <div className="text-sm font-medium leading-none text-gray-400">tom@example.com
                            </div>
                        </div>
                        <button type="button"
                                className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">View notifications</span>
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                            </svg>
                        </button>
                    </div>
                    <div className="mt-3 px-2 space-y-1">
                        <a href="#"
                           className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Your
                            Profile</a>

                        <a href="#"
                           className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Settings</a>

                        <a
                            onClick={() => {
                                dispatch(profileActions.close())
                                dispatch(authActions.logout())
                            }}
                            href="#"
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Sign
                            out</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}