import { LockClosedIcon } from '@heroicons/react/solid'
import logo from "../../Images/logo.png";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {authActions} from "../../_store";

export default function ForgotPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [validEmail, setValidEmail] = useState(false)

    function validateEmail(email) {
        // Basic email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(email)
    }

    return (
        <>
          <div className="wrapper">
              <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                  <div className="max-w-md w-full space-y-8">
                      <div>
                          <img
                              className="mx-auto h-12 w-auto"
                              src={logo}
                              alt="logo"
                          />
                          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot password</h2>
                          <p className="mt-2 text-center text-sm text-gray-600">
                          </p>
                      </div>
                      <form className="mt-8 space-y-6" action="#" method="POST">
                          <input type="hidden" name="remember" defaultValue="true" />
                          <div className="rounded-md shadow-sm -space-y-px">
                              <div>
                                  <label htmlFor="email-address" className="sr-only">
                                      Email address
                                  </label>
                                  <input
                                      id="email-address"
                                      name="email"
                                      type="email"
                                      autoComplete="email"
                                      required
                                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                      placeholder="Email address"
                                      onChange={(e) => {
                                          setEmail(e.target.value)
                                          setValidEmail(validateEmail(e.target.value))
                                      }}
                                  />
                              </div>
                          </div>

                          <div>
                              <button
                                  disabled={!validEmail || submitted}
                                  onClick={() => {
                                      setSubmitted(true)

                                      dispatch(authActions.resetEmail({email})).then(() => {
                                          alert("Successfully sent a reset email.")
                                          setTimeout(() => {
                                              navigate("/login")
                                          },1000)
                                      })
                                  }}
                                  type="button"
                                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                                  Send Reset Link
                              </button>
                          </div>
                      </form>

                      <div className="text-sm">
                          <a href="/login" className="font-medium text-[#a70000]">
                              Return to login
                          </a>
                      </div>

                  </div>
              </div>
          </div>
        </>
    )
}