import {useDispatch, useSelector} from "react-redux";
import {Nav} from "../Nav";
import {useEffect} from "react";
import {teamActions} from "../../_store";
import {SuperAdminNav} from "./SuperAdminNav";

const SuperAdminDashboard = () => {

    const teams = useSelector(x => x.teams)
    const dispatch = useDispatch()

    useEffect(() => {

    },[])

    console.log("teams list", teams.list.teams)

    return (
        <>

            <div className="min-h-full text-[#a70000]">
              <SuperAdminNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 ">
                        <h1 className="text-3xl font-bold text-[#000]">Dashboard</h1>
                    </div>
                </header>

                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <h1 className="text-4xl text-[#000]">Assessments Statistics</h1>
                                <br/>
                                <div className="grid grid-cols-4 gap-4">
                                        <div className="report text-[#000]">
                                            <h1 className="h1">
                                                Teams
                                            </h1>

                                            <br/>
                                            <div>
                                                <h2 className="text-4xl"> <strong>0</strong> </h2>
                                                <p><strong>+0</strong></p>
                                                <span>last month</span>
                                            </div>
                                        </div>
                                        <div className="report text-[#000]">
                                            <h1 className="h1">
                                                Members
                                            </h1>

                                            <br/>
                                            <div>
                                                <h2 className="text-4xl"> <strong>0</strong> </h2>
                                                <p><strong>+0</strong></p>
                                                <span>last month</span>
                                            </div>
                                        </div>
                                        <div className="report text-[#000]">
                                            <h1 className="h1">
                                                Started
                                            </h1>
                                            <br/>
                                            <div>
                                                <h2 className="text-4xl"> <strong>0</strong> </h2>
                                                <p><strong>+0</strong></p>
                                                <span>last month</span>
                                            </div>
                                        </div>
                                        <div className="report text-[#000]">
                                            <h1 className="h1">
                                                Completed
                                            </h1>

                                            <br/>
                                            <div>
                                                <h2 className="text-4xl"> <strong>0</strong> </h2>
                                                <p><strong>+0</strong></p>
                                                <span>last month</span>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
 }

 export default SuperAdminDashboard