import React, {useEffect, useState} from "react";
import { AddUserButton, DeleteButton, EditButton, ViewButton} from "../button/Buttons";
import {useDispatch, useSelector} from "react-redux";
import {teamActions} from "../../_store";
import {toast} from "react-toastify";
import {promiseToast} from "../../_helpers/alerts";


export const TeamMember = ({number, id, email, isInvited, isStarted, isCompleted, date, team}) => {
    const {user} = useSelector(  x => x.auth)
    const dispatch = useDispatch()
    const [show, setShow] = useState(null)
    const [percentage, setPercentage] = useState(0)
    const [resend, setResent] = useState(false)

    useEffect(() => {
        setPercentage ( Number(team.order/52 * 100))
        console.log("Team Order", team.order)
    }, [])

    console.log("Team from Team member", team)
    console.log("User from Team member", user)

    const actual_ee_score = team?.actual_ee_score !== undefined ? 100 + team.actual_ee_score : 100;
    const actual_et_score = team?.actual_et_score !== undefined ? 100 + team.actual_et_score : 100;
    const actual_budget_score = team?.actual_budget_score !== undefined ? 100 + team.actual_budget_score : 100;

    const resendEmail = () => {
        const team_id = team?.id
        const license_id = team?.userLicense
        const user_id = team?.user

        promiseToast('sending', 'Successfully sent!')


        dispatch(teamActions.resendEmail({team_id, license_id, user_id}))
        setResent(true)

    }

    const downloadResults = () => {
        const user_id = team?.user
        const license_id = team?.userLicense
        const game_id = team?.game

        const url = `https://backend.changeverveacademy.com/api/v1/download-results?user_id=${user_id}&license_id=${license_id}&game_id=${game_id}`
        window.location.href = url
    }

    return (
        <tr
            key={id}
            // className={`h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 ${isInvited ? "bg-green-200 text-white": isStarted ? "bg-green-500 text-white" : "bg-white" }`}>
            className={`h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 bg-white }`}>

            <td className="">
                <p className="text-sm font-medium leading-none text-gray-800">{ number }</p>
            </td>

            <td className="pl-4">
                <p className="text-sm font-medium leading-none text-gray-800">{ email }</p>
            </td>

            <td className="pl-16 cursor-pointer">
                <div className="flex items-center">
                    <div className="">
                        <p className="font-medium">{ isInvited ? "Yes": "No" }</p>
                        <p className="text-xs leading-3 text-gray-600 pt-2"> </p>
                    </div>
                </div>
            </td>

            <td className="pl-24">
                <p className="text-sm font-medium leading-none text-gray-800">{ isStarted ? "Yes": "No" }</p>
            </td>

            <td className="pl-28">
                <p className="font-medium">{isCompleted ? "Yes" : "No"}</p>
                {/*<p className="text-xs leading-3 text-gray-600 mt-2">5 tasks pending</p>*/}
            </td>

            <td className="pl-12">
                <p className="font-medium">{date}</p>
            </td>

            <td className="pl-6">
                <div className="font-medium">
                    {percentage?.toFixed(2)}%
                </div>
            </td>

            <td className="pl-6">
                <div className="font-medium">
                    EE: {actual_ee_score} | T: {actual_et_score} | B: {actual_budget_score}
                </div>

            </td>

            <td>
                <div>
                    <a
                        onClick={() => {
                            resendEmail()
                        } }
                        className="focus:outline-none pr-15 cursor-pointer">
                        {resend ? "email sent" : "Resend invitation"}

                    </a>
                </div>

                <div className="mt-3">
                    <a
                        onClick={() => {
                            downloadResults()
                        }}

                        className="focus:outline-none pr-15 cursor-pointer">
                        Download results
                    </a>
                </div>
            </td>

        </tr>

    )
}