import {SuperAdminNav} from "./SuperAdminNav";
import {NavLink, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {gameActions, phaseActions, questionActions, stepActions} from "../../_store";
import {NewSingleStep} from "../NewSingleStep";
import {SingleStep} from "../SingleStep";
import {EmptySingleStep} from "./Steps/EmptySingleStep"


const Steps = () => {
    const dispatch = useDispatch();
    const steps = useSelector(x => x.steps)
    const phases = useSelector(x => x.phases )
    const phaseList = phases.list.phases
    const [searchParams] = useSearchParams()
    const step = searchParams.get("step")

    const {id} = useParams()

    const phaseOptions = phaseList && phaseList.map((item) =>{
        return { value: item.id, label: item.name}
    })


    useEffect(() => {
        dispatch(stepActions.getStepsByGame(id))
        dispatch(phaseActions.getAll())

    },[])

    const stepsList = steps && steps.list.steps;

    const columns = {};
    for (let i = 1; i <= 13; i++) {
        const stepsInColumn = stepsList && stepsList.filter((element) => element.column === i);
        columns[i] = {
            steps: stepsInColumn,
            count: stepsInColumn ? stepsInColumn.length : 0,
        };
    }

    return (
        <>
            <div className="min-h-full">
                <SuperAdminNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Game Steps</h1>
                    </div>
                </header>

                <main>
                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">

                        <nav className="ml-8 rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href="/super-admin/games" className="text-blue-600 hover:text-blue-700">Games</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">Steps</li>
                            </ol>
                        </nav>


                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="w-full sm:px-6">
                                    <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded">
                                        <div className="sm:flex items-center justify-between">
                                            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Game phases</p>
                                            <div>

                                                <div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="grid sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6 mt-2 gap-2 ">
                                            {phaseList && phaseList.map((item) => {
                                                return (
                                                    <a href={`/super-admin/steps/${item.id}`} className="tag bg-gray-800 dark:bg-gray-100 rounded text-white font-extrabold">{item.name}</a>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <div className="mx-auto container py-20 px-6">
                                            <div> {steps && steps.addNewStatus && <NewSingleStep />}

                                                <div className="flex flex-row gap-2">

                                                    {Object.entries(columns).map(([column, {steps, count}]) => (
                                                        <div className="flex flex-col gap-2">
                                                            <div> Step </div>
                                                            {steps?.map((step) => <SingleStep step={step} />)}
                                                            {<EmptySingleStep columnStepsCount={count} number={column} game_id={id} />}
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default Steps;

