import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {confirmModalActions, licenseActions, teamActions} from "../../_store";
import {modalActions} from "../../_store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../_helpers/alerts";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ExpiryDateInput} from "./ExpiryDateInput";


export const EmailInfo = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const teams = useSelector(x => x.teams )
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")

    const [expiryDate, setExpiryDate] = useState('');
    const maxExpiryDate = new Date();
    maxExpiryDate.setMonth(maxExpiryDate.getMonth() + 6); // set max expiry date to 6 months from today

    const handleExpiryDateChange = (event) => {
        const selectedDate = new Date(event.target.value);
        if (selectedDate > maxExpiryDate) {
            setExpiryDate(maxExpiryDate.toISOString().slice(0, 10)); // set to max expiry date if selected date is greater than 6 months
        } else {
            setExpiryDate(event.target.value);
        }
    };

    useEffect(() =>{
        if (id){
            dispatch(teamActions.getTeamById({team_id: id}))
        }
    },[])

    console.log("teams from email", teams)

    // const contactEmail =

    const validationSchema = Yup.object().shape({
        // email_subject: Yup.string().required('Subject is required'),
        email_message: Yup.string().required('Message is required'),
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email_message, sign_off, reminder_interval, expiry_date }) {
        const teamObject = teams

        if (!teamObject?.teamInfoCompleted && !teamObject?.companyInfoCompleted){
            toast.info("You need to complete the company profile first!")
            return
        }

        const team_id = id != null ? id : teams?.currentTeamId

        return dispatch(teamActions.addEmailInfo({email_message, sign_off, team_id, reminder_interval, expiry_date}))
            .then((response)=> {
                if (response.type === "teams/addEmailInfo/rejected"){
                    toast.error("OOps!, Problem saving your email!")
                    return
                }
                 promiseToast("Saving email", "You set up your team!")

                dispatch(confirmModalActions.openModal())
            });
    }

    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)}  method="POST">

                <div className="shadow overflow-hidden sm:rounded-md">

                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                        <p>Good day,</p>

                        <div>
                            <p>Welcome to the&nbsp;<strong>ChangeVerve Change Management Game.</strong> An interactive online platform that guides you through the common scenarios, events, and hurdles encountered during the implementation of significant organisational change initiatives.&nbsp;</p>
                        </div>

                        <div>
                            <div className="mt-1">
                      <textarea
                          {...register("email_message")}
                          onChange={(e) => dispatch(teamActions.setEmailContent(e.target.value))}
                          rows={5}
                          className="shadow-sm focus:ring-[#a70000] focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"

                      />
                            </div>

                            <br/>
                            <div>

                                <div className="mb-2">
                                    <p><strong>REGISTRATION AND LOGIN:&nbsp;</strong></p>
                                </div>

                                <div  className="mb-2">
                                    <p>If you have a ChangeVerve account, please log in with your username and password to access the ChangeVerve Change Management Game.&nbsp;</p>
                                </div>

                                <div className="mb-2">
                                    <p>If you are a first-time user of ChangeVerve products, kindly follow the registration steps below.&nbsp;</p>
                                </div>

                                <p  className="mb-2"><strong>Steps to register for the game:</strong></p>

                                <p  className="mb-2"><strong>Step 1</strong>: &nbsp;CLICK on the following link to access the game platform: &nbsp;&nbsp;<a href="https://changeverveacademy.com/invitee-register/id" target="_blank">https://changeverveacademy.com/invitee-register/id</a> &nbsp;</p>
                                <p  className="mb-2"><strong>Step 2</strong>: &nbsp;Enter your name, surname and email address in the fields provided.&nbsp;NB: Use the email address on which you received this email.</p>
                                <p  className="mb-2"><strong>Step 3</strong>: Create a password and CLICK on the &ldquo;REGISTER&rdquo; button.&nbsp;</p>
                                <p  className="mb-2"><strong>Step 4</strong>: Log in using your email and password on the login screen to go to your ChangeVerve Academy dashboard. &nbsp;</p>
                                <p  className="mb-2"><strong>Step 5:</strong> CLICK on the tile indicating the ChangeVerve Change Management Game for your team to start playing.</p>


                                <p className="mb-2">For future login after registration, use the following link to access the game.&nbsp;<a href="https://changeverveacademy.com/" target="_blank">https://changeverveacademy.com</a>&nbsp;</p>
                                <div>
                                    <br/>
                                </div>
                                <p>The game offers flexibility in terms of playtime, allowing for either a single extended session lasting approximately 6 to 8 hours, or multiple shorter intervals that fit within your schedule. Your progress will be tracked and saved, allowing you to resume play at the point where you last left off upon your return.</p>
                                <div>
                                    <br/>
                                </div>
                                <p><strong>Your organisation requires you to complete the game by close of business&nbsp;(expiry date).</strong></p>
                                <div>
                                    <br/>
                                </div>
                                <p>Should you have any difficulty logging in, kindly contact your Team Coordinator at&nbsp;<a href={`${teams?.team?.team?.contact_email}`} target="_blank">{teams?.team?.team?.contact_email}</a>.</p>
                                <div>
                                    <br/></div>
                                <p>Good luck and enjoy the experience!</p>
                                <div><br/></div>

                            </div>

                            <br/>

                            <div className="mt-1">

                      <textarea
                          {...register("sign_off")}
                          onChange={(e) => dispatch(teamActions.setSignOff(e.target.value))}
                          rows={4}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          defaultValue={'Sincerely, '}
                      />
                            </div>


                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                dispatch(modalActions.openModal())
                            }}
                            className="bg-[#a70000] hover:bg-[#a70000] text-white font-bold py-2 px-4 border border-[#a70000] rounded mt-2">
                            Preview Email
                        </button>

                        <div>
                            <label htmlFor="expiry-date">Expiry Date:</label>
                            <input
                                {...register("expiry_date")}
                                className={`shadow-sm focus:ring-[#a70000] focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md`}
                                type="date"
                                id="expiry-date"
                                name="expiry-date"
                                value={expiryDate}
                                min={new Date().toISOString().slice(0, 10)} // set min date to today's date
                                max={maxExpiryDate.toISOString().slice(0, 10)} // set max date to 6 months from today
                                onChange={handleExpiryDateChange}
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3 mb-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 mb-2">
                                How often should reminders be sent?
                            </label>

                            <div>
                                <div className="float-left mr-3" >
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Daily
                                    </label>
                                    <input
                                        defaultChecked={true}
                                        {...register("reminder_interval")}
                                        value="daily"
                                        type="radio"
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block  shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>

                                <div className="float-left mr-3">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Weekly
                                    </label>
                                    <input
                                        value="weekly"
                                        {...register("reminder_interval")}
                                        type="radio"
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block  shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>

                                <div className="float-left mr-3">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        Monthly
                                    </label>
                                    <input
                                        value="monthly"
                                        {...register("reminder_interval")}
                                        type="radio"
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block  shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                        </div>


                        <br/>
                        <br/>



                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                        <button
                            onClick={() => {
                                navigate(-1)
                            }}
                            className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                            Close
                        </button>

                        {
                            teams.teamInfoCompleted &&
                            <button
                                disabled={!teams.teamInfoCompleted}
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500  focus:outline-none focus:ring-2 focus:ring-offset-2">
                                Save
                            </button>
                        }


                    </div>
                </div>
            </form>



        </>
    )
}