import {useDispatch, useSelector} from 'react-redux';

import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router";
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import WinnerCup from "../../games/congrats-cup.jpg"
import {AnimateNumber} from "../AnimateNumber";

const CompletedGameModal = () => {
    const dispatch = useDispatch();
    const {currentModalStep} = useSelector(x => x.games)
    const {progress} = useSelector(x => x.progress)
    const {user} = useSelector(x=> x.auth)
    const navigate = useNavigate()

    const currentActualEEScore = progress.progress && progress.progress.actual_ee_score
    const currentActualETScore = progress.progress && progress.progress.actual_et_score
    const currentActualBudgetScore = progress.progress && progress.progress.actual_budget_score

    const getEffectivenessAssessment = () => {
        if(currentActualEEScore >= 200) {
            return  "Excelling"
        }else if(currentActualEEScore < 200 && currentActualEEScore >= 150 ){
            return "Very Effective"
        }else if (currentActualEEScore < 150 && currentActualEEScore >= 100 ) {
            return "Effective"
        }else if (currentActualEEScore < 100 && currentActualEEScore >= 50 ) {
            return "Ineffective"
        }
    }

    const getTimeAssessment = () => {
        if(currentActualETScore >= 200) {
            return  "Ahead of Schedule"
        }else if(currentActualETScore < 200 && currentActualETScore >= 150 ){
            return "Fast Tracking"
        }else if (currentActualETScore < 150 && currentActualETScore >= 100 ) {
            return "On Schedule"
        }else if (currentActualETScore < 100 && currentActualETScore >= 50 ) {
            return "Falling Behind Schedule"
        }
    }

    const getBudgetAssessment = () => {
        if(currentActualETScore >= 200) {
            return  "Surplus"
        }else if(currentActualBudgetScore < 200 && currentActualBudgetScore >= 150 ){
            return "Under Budget"
        }else if (currentActualBudgetScore < 150 && currentActualBudgetScore >= 100 ) {
            return "On Budget"
        }else if (currentActualBudgetScore < 100 && currentActualBudgetScore >= 50 ) {
            return "Over Budget"
        }
    }

    return (
        <>
            <ToastContainer />
            <aside className='modal-container'>
                <div className='modal'>
                    <form method="POST">

                        <Confetti />

                        <h1 className="mb-2 mt-6">HURRAY!! YOU COMPLETED THE CHANGE MANAGEMENT GAME!</h1>

                        <div>
                            {/*<img className={`w-1/3 mx-auto`} src={WinnerCup} />*/}
                            <h1>Your Scores:</h1>
                            <h2 className={`font-bold`}>
                                Effectiveness: <i className={`text-green-500`}>
                                {currentActualEEScore} - {getEffectivenessAssessment()}
                            </i>
                                <br/>
                                Time: <i className={`text-green-500`}>{currentActualETScore} - {getTimeAssessment()} </i><br/>
                                Budget: <i className={`text-green-500`}> {currentActualBudgetScore} - {getBudgetAssessment()}</i><br/>
                            </h2>
                            <br/>
                            <br/>
                            <br/>

                            <div className='btn-container'>
                                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                                    <button
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                        type="button"
                                        className="mr-3 inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-800 hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                                        // className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                        data-bs-dismiss="modal">
                                        GO BACK
                                    </button>

                                    <button
                                        onClick={() => {
                                            // navigate(-1)
                                        }}
                                        type="button"
                                        className="mr-3 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                        data-bs-dismiss="modal">
                                        OTHER GAMES
                                    </button>
                                </div>
                            </div>
                        </div>


                    </form>
                </div>
            </aside>

        </>
    )
}
export default CompletedGameModal


