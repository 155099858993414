import React from 'react';
import { useLocation } from 'react-router-dom';

const CancelURLPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    return (
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div className="absolute inset-0 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl" style={{ backgroundImage: 'linear-gradient(to right, #a70000, #ff9900)' }}></div>
                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                        <div>
                            <h1 className="text-2xl font-semibold">Payment Cancelled</h1>
                            <p className="text-red-500 mt-2">You have opted to cancel your payment. If you wish to try making the payment again, please use the link provided in your email.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CancelURLPage;
