import React from "react";

const EmptyAssessment = ({ id, plan }) => {
    return (
        <div className="card-body cart">
            <div className="col-sm-12 empty-cart-cls text-center">
                <div className="mx-auto"></div>

                <h3>
                    <strong>Your Assessments page is empty</strong>
                </h3>
                <h4>Add something to make me happy :)</h4>
                <a
                    href={`/new-team/${id}?plan=${plan}`}
                    className="mx-auto mt-5 group relative w-2/12 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]"
                >
                    Add New Team
                </a>
            </div>
        </div>
    );
};

export default EmptyAssessment;
