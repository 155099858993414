import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {useEffect} from "react";
import {authActions, userActions} from "../../_store"
export { PrivateRoute };

function PrivateRoute({ children }) {
    const { user: authUser } = useSelector(x => x.auth);
    const dispatch = useDispatch()
    const {user} = useSelector(x => x.users)
    const navigate = useNavigate()

    console.log("from private routes", authUser)
    console.log("user private routes", user)

    useEffect(()=> {
        dispatch(userActions.getUser()) .then((response)=> {
            console.log("response", response)
            if (response.type === "users/getUser/rejected"){
                // toast.error("OOps, session ended!")
                dispatch(authActions.setLogout(true))
                localStorage.clear()
                // navigate("/login")
                return
            }
        })
    },[])

    if (!authUser) {
         navigate("/login")
        return
    }

    return <Outlet />
}