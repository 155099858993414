import React from "react";
import {SingleAssessment} from "./SingleAssessment";
import * as XLSX from "xlsx";

const AssessmentTable = ({ teamList, id, plan, onNewTeamClicked, exportToExcel }) => {

    return (
        <div className="w-full sm:px-6">
            <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">

                <button
                    onClick={exportToExcel}
                    className="bg-[#a70000] text-white font-bold py-2 px-4 rounded"
                >
                    Export to Excel
                </button>

                <div className="sm:flex items-center justify-between">
                    <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                        List
                    </p>

                    <div>
                        <a
                            onClick={() => onNewTeamClicked()}
                            className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-[#a70000] hover:bg-[#a70000] focus:outline-none rounded"
                            href={`/new-team/${id}?plan=${plan}`}
                        >
                            <p className="text-sm font-medium leading-none text-white">
                                New Team Setup
                            </p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
                <table className="w-full whitespace-nowrap">
                    <thead>
                    <tr className="h-16 w-full text-sm leading-none text-gray-800">
                        <th className="font-normal text-left pl-4">Status</th>
                        <th className="font-normal text-left pl-4">Team Name</th>
                        <th className="font-normal text-left pl-12">Company</th>
                        <th className="font-normal text-left pl-12">Date Created</th>
                        <th className="font-normal text-left pl-20">Coordinator</th>
                        <th className="font-normal text-left pl-20">Assigned</th>
                        <th className="font-normal text-left pl-20">No. of Members</th>
                        <th className="font-normal text-left pl-16">Action</th>
                    </tr>
                    </thead>
                    <tbody className="w-full">
                    {teamList &&
                    teamList.map((tm) => (
                        <SingleAssessment
                            key={tm.id}
                            id={tm.id}
                            name={tm.name}
                            company={tm.company_name}
                            industry={tm.industry_name}
                            date={tm.created_at}
                            email={tm.contact_email}
                            numberOfMembers={tm.no_of_members}
                            license={tm.license}
                            product_id={id}
                            company_id={tm.company_id}
                            plan={plan}
                        />
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AssessmentTable;
