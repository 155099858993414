import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { industriesReducer } from './industries.slice';
import { questionsReducer } from './questions.slice';
import { functionsReducer } from './functionList.slice';
import {teamsReducer} from "./teams.slice";
import {licensesReducer} from "./licenses.slice";
import {modalReducer} from "./modal.slice";
import {profileReducer} from "./profile.slice";
import {gamesReducer} from "./games.slice";
import {stepsReducer} from "./steps.slice";
import {phasesReducer} from "./phases.slice";
import {plansReducer} from "./plans.slice";
import {confirmModalReducer} from "./confirmModal.slice";
import {progressReducer} from "./progress.slice";
import {videosReducer} from "./videos.slice";
import {salesReducer} from "./sales.slice";
import {subscriptionsReducer} from "./subscriptions.slice";
import {subgamesReducer} from "./subgames.slice";

export * from './auth.slice';
export * from './users.slice';
export * from './industries.slice';
export * from './questions.slice';
export * from './functionList.slice';
export * from './teams.slice';
export * from './licenses.slice';
export * from './modal.slice';
export * from './profile.slice';
export * from './games.slice';
export * from './steps.slice';
export * from './phases.slice';
export * from './plans.slice';
export * from './confirmModal.slice';
export * from './progress.slice';
export * from './videos.slice';
export * from './sales.slice';
export * from './subscriptions.slice';
export * from './subgames.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        industries: industriesReducer,
        questions: questionsReducer,
        functions: functionsReducer,
        teams: teamsReducer,
        games: gamesReducer,
        steps: stepsReducer,
        phases: phasesReducer,
        licenses: licensesReducer,
        plans: plansReducer,
        modal:modalReducer,
        confirmModal:confirmModalReducer,
        profile: profileReducer,
        progress: progressReducer,
        videos: videosReducer,
        sales: salesReducer,
        subscriptions: subscriptionsReducer,
        subgames: subgamesReducer
    },
});