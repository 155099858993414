import { useDrag } from 'react-dnd'
import { ItemTypes } from './ItemTypes.js'
const style = {
    border: '1px dashed gray',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    cursor: 'move',
    float: 'left',
}

export const Box = function Box({ name, id }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.BOX,
        item: { name, id },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                // alert(`You dropped ${item.name} into ${id}!`)
                console.log("Drop Result", dropResult)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))
    const opacity = isDragging ? 0.4 : 1
    return (

        <div className="text-sm flex justify-center items-center"
            ref={drag} data-testid={`box`}
             style={{marginBottom: `-25px`, height:`70px`, backgroundColor: `#a70000`, color:`#fff`, textAlign: `center` }}>
            <span style={{textAlign: `center`}}> {name} </span>
        </div>
    )
}
