import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SingleTile} from "../SingleTile";
import GreenIndicator from "../../../games/Yellow-Indicator-B.png";
import AnimatedNumbers from "react-animated-numbers";
import {useNavigate} from "react-router"
import ThirdBoardDesign from "../../../games/Board-Design-Frame-Phase-4-B.png"
import {screens} from "../../../_helpers/screens";
import {gameActions, videoActions} from "../../../_store";
import infoButton from "../../../games/Info-Button.png";
import SecondBoardDesign from "../../../games/Board-Design-Frame-Phase-3-B.png";

export const Desktop1536X864ScreenThree = ({columnElevenSteps,columnTwelveSteps, columnThirteenSteps,
                                               userLastStep, progressLastStep}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {phaseBackground} = useSelector(x => x.games)
    const {nextStep,progress, et_score, ee_score, budget_score} = useSelector(x => x.progress)

    const currentActualEEScore = progress.progress && progress.progress.actual_ee_score
    const currentActualETScore = progress.progress && progress.progress.actual_et_score
    const currentActualBudgetScore = progress.progress && progress.progress.actual_budget_score

    const progressObject     = progress.progress && progress.progress

    const currentEEScore     =   screens.desktopOne.eeIndicator + (progressObject.actual_ee_score  || 0)
    const currentETScore     =   screens.desktopOne.etIndicator + (progressObject.actual_et_score ||  0)
    const currentBudgetScore =   screens.desktopOne.budgetIndicator +  (progressObject.actual_budget_score || 0);

    const [effectivenessScore, setEffectivenessScore] = useState(0)
    const [timeScore, setTimeScore] = useState(0)
    const [budgetScore, setBudgetScore] = useState(0)

    useEffect(() => {
        setEffectivenessScore(currentEEScore)
        setTimeScore(currentETScore)
        setBudgetScore(currentBudgetScore)
    }, [currentEEScore, currentETScore, currentBudgetScore])

    const phaseName  = nextStep?.nextPhaseStep?.phase_name
    const description  = nextStep?.nextPhaseStep?.description

    const [isLoading, setLoading] = useState(true);

    return (

        <div className="container">
            <div className="game-board">

                <div style={{backgroundColor: `#E5E7EB`}}>
                    <div className="static">

                        {columnElevenSteps && columnElevenSteps.map((step, index)=>
                            <div>
                                {index === 0 &&
                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    progressLastStep={progressLastStep}
                                    divStyle={{ top: `90px` ,left: `40px`}}
                                    tileStyle={{width: `70%`, marginTop: `-5px` }}
                                    column={11}
                                    number={index}
                                    busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />
                                }

                                {index === 1 &&
                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    progressLastStep={progressLastStep}
                                    divStyle={{top: `225px`, left: `40px`}}
                                    tileStyle={{width: `70%`, marginTop: `2px`}}
                                    column={11}
                                    number={index}

                                    busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />

                                }

                                {index === 2 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    progressLastStep={progressLastStep}
                                    divStyle={{top: `355px`, left: `40px`}}
                                    tileStyle={{width: `70%`, marginTop: `2px` }}
                                    column={11}
                                    busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />

                                }

                                {index === 3 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    divStyle={{top: `490px`, left: `40px`}}
                                    tileStyle={{width: `70%`, marginTop: `2px` }}
                                    column={11}
                                    busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />
                                }

                            </div>
                        )}

                    </div>

                    <div className="static">

                        {columnTwelveSteps && columnTwelveSteps.map((step, index)=>
                            <div>
                                {index === 3 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    divStyle={{ left: `520px`, top: `90px` }}
                                    tileStyle={{width: `70%`, marginTop: `2px` }}
                                    column={12}
                                    busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />

                                }

                                {index === 2 &&
                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    divStyle={{ left: `520px`, top: `225px` }}
                                    tileStyle={{width: `70%`, marginTop: `2px` }}
                                    column={12}
                                    busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />
                                }

                                {index === 1 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    divStyle={{ left: `520px`, top: `355px` }}
                                    tileStyle={{width: `70%`, marginTop: `2px` }}
                                    column={12}
                                    busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />
                                }

                                {index === 0 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    divStyle={{ left: `520px`, top: `490px` }}
                                    tileStyle={{width: `70%`, marginTop: `2px` }}
                                    column={12}
                                    busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />
                                }

                            </div>
                        )}
                    </div>

                    <div className="static">

                        {columnThirteenSteps && columnThirteenSteps.map((step, index)=>
                            <div>
                                {index === 0 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    progressLastStep={progressLastStep}
                                    divStyle={{ left: `1010px`, top: `90px` }}
                                    tileStyle={{width: `80%`, marginTop: `2px` }}
                                    number={index}
                                    column={13}
                                    busStyle={{left:`-15px`, width: `50%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />

                                }

                                {index === 1 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    progressLastStep={progressLastStep}
                                    divStyle={{ left: `1010px`, top: `225px` }}
                                    tileStyle={{width: `80%`, marginTop: `2px` }}
                                    column={13}
                                    busStyle={{left:`-15px`, width: `50%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />

                                }

                                {index === 2 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    progressLastStep={progressLastStep}
                                    divStyle={{ left: `1010px`, top: `355px` }}
                                    tileStyle={{width: `80%`, marginTop: `2px` }}
                                    busStyle={{left:`-15px`, width: `50%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                    number={index}
                                    column={13}
                                />
                                }

                                {index === 3 &&

                                <SingleTile
                                    step={step}
                                    userLastStep={userLastStep}
                                    progressLastStep={progressLastStep}
                                    divStyle={{ left: `1010px`, top: `490px` }}
                                    tileStyle={{width: `80%`, marginTop: `2px` }}
                                    // busStyle={{width: `30%`, marginTop: `2px` }}

                                    number={index}
                                    column={13}
                                    busStyle={{left:`-15px`, width: `50%`, marginTop: `0px` }}
                                    greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                                />
                                }

                            </div>
                        )}

                    </div>


                    <div style={{
                        border: `1px solid #000`,
                        width: `40px`,
                        position: `absolute`,
                        top: `645px`,
                        left: `405px`,
                        background: `#000`,
                        height: `27px` }}>
                        <p style={{color: `white`,
                            marginTop: `-1px`,
                            marginLeft: `4px`
                        }}>

                            {/*<AnimateNumber number={!isNaN(progressObject.actual_ee_score)? progressObject.actual_ee_score : 100} />*/}
                            {!isNaN(progressObject.actual_ee_score)? progressObject.actual_ee_score + 100 : 100}
                            {/*<AnimateNumber number={cachedValue} />*/}

                        </p>

                    </div>

                    <div style={{
                        border: `1px solid #000`,
                        width: `40px`,
                        position: `absolute`, top: `645px`,
                        left: `767px`,
                        background: `#000`,
                        height: `27px` }}>
                        <p style={{color: `white`, marginTop: `-1px`, marginLeft: `4px`}}>
                            {/*<AnimateNumber number={!isNaN(progressObject.actual_et_score)? progressObject.actual_et_score : 100} />*/}
                            {!isNaN(progressObject.actual_et_score)? progressObject.actual_et_score + 100 : 100}
                        </p>
                    </div>

                    <div style={{
                        border: `1px solid #000`,
                        width: `40px`,
                        position: `absolute`, top: `645px`,
                        left: `1128px`,
                        background: `#000`,
                        height: `27px` }}>
                        <p style={{color: `white`, top: `-1px`, marginLeft: `4px`}}>
                            {/*<AnimateNumber number={!isNaN(progressObject.actual_budget_score) ? progressObject.actual_budget_score : 100} />*/}
                            {!isNaN(progressObject.actual_budget_score) ? progressObject.actual_budget_score + 100  : 100}
                        </p>
                    </div>

                    <div className={`absolute` } style={{top: `650px`, left: `${currentEEScore}px`}}>
                        <img src={GreenIndicator}   alt="indicator" />
                    </div>

                    <div className={`absolute` } style={{top: `650px`, left: `${currentETScore}px`}}>
                        <img src={GreenIndicator}  alt="indicator" />
                    </div>

                    <div className={`absolute` } style={{top: `650px`, left: `${currentBudgetScore}px`}}>
                        <img src={GreenIndicator}  alt="indicator" />
                    </div>

                {/*    <div className={`absolute` } style={{top: `645px`, left: `220px`}}*/}
                {/*         onClick={ () => {*/}
                {/*             dispatch(gameActions.setInfoButtonModalOpened(true))*/}
                {/*         }}>*/}

                {/*        <img src={infoButton}  alt="indicator" width="30"*/}
                {/*             style={{*/}
                {/*                 position: `relative`,*/}
                {/*                 left: `-145px`*/}
                {/*             }} />*/}
                {/*</div>*/}

                <img src={ThirdBoardDesign} width={1200} />
                </div>
            </div>

            <div className="instructions" >
                {/*<h4>Current Stage: <strong>{phaseName}</strong></h4>*/}
                <br/>

                {/*<p>Stop {description?.replace(/\D+/g, '')}: Change Management Plan</p>*/}
                {/*<br/>*/}

                <h3><strong>Information:</strong> </h3>
                {/*<br/>*/}
                <ol>

                    <li>
                        <button
                            onClick={() =>{
                                dispatch(videoActions.setCurrentIntroVideo("business-objective"))
                                dispatch(gameActions.setInfoButtonModalOpened(true))
                            }
                            }
                            className="exit-game w-full">Objectives of the Game</button>
                    </li>

                    <li>
                        <button onClick={() => {
                            dispatch(videoActions.setCurrentIntroVideo("business-case-image"))
                            dispatch(gameActions.setInfoButtonModalOpened(true))
                        }}
                                className="exit-game w-full">Business Case Summary</button>
                    </li>

                    <li>
                        <button onClick={() => {
                            dispatch(videoActions.setCurrentIntroVideo("business-case"))
                            dispatch(gameActions.setInfoButtonModalOpened(true))
                        }}
                                className="exit-game w-full">Business Case</button>
                    </li>

                    <li>
                        <button
                            onClick={() => {
                                dispatch(videoActions.setCurrentIntroVideo("how-to-play"))
                                dispatch(gameActions.setInfoButtonModalOpened(true))
                            }}
                            className="exit-game w-full">How to play the Game</button>
                    </li>

                    <br/>
                    <br/>
                    <li>

                        <button
                            onClick={() =>
                                navigate(-1)
                            }
                            className=" w-full bg-gray-300 text-black font-bold py-2 px-4 rounded">
                            Exit game
                        </button>

                    </li>
                </ol>

                <br/>


            </div>

        </div>

    )

}