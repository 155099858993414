import {CompanyInfo} from "./team/CompanyInfo";
import {Nav} from "./Nav";
import {NavLink} from "react-router-dom";
import {SingleAssessment} from "./SingleAssessment";
import React from "react";
import {ProfileInfo} from "./ProfileInfo";

export default function ProfilePage() {
    return (


        <>

            <div className="min-h-full">
                <Nav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Profile</h1>
                    </div>
                </header>
                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div>
                                    <div className="md:grid md:grid-cols-3 md:gap-6">
                                        <div className="md:col-span-1">
                                            <div className="px-4 sm:px-0">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3>
                                                <p className="mt-1 text-sm text-gray-600">
                                                    This information will be displayed publicly so be careful what you share.
                                                </p>
                                            </div>
                                        </div>

                                        <ProfileInfo />

                                    </div>
                                </div>

                                <div className="hidden sm:block" aria-hidden="true">
                                    <div className="py-5">
                                        <div className="border-t border-gray-200" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}
