import React, {useEffect, useState} from "react";
import {Nav} from "../Nav";

import {useDispatch, useSelector} from "react-redux";
import {planActions, teamActions} from "../../_store";
import {SinglePlan} from "./SinglePlan";
import {useParams} from "react-router";
import {SuperAdminNav} from "./SuperAdminNav";
import {NavLink} from "react-router-dom";

function Plans() {

    const dispatch = useDispatch()
    const teams = useSelector(x => x.teams );
    const plans = useSelector(x => x.plans );
    const planList = plans.list && plans.list.plans
    console.log("Plans ", plans.list.plans)


    const teamList = teams.teamMembers.data

    const params = useParams();

    // console.log("params", params.id); // 👉️ {userId: '4200'}
    //
    // console.log("Teams from team assessment ", teams)

    useEffect(() => {
        dispatch(planActions.getAll())
    },[])

    return (

        <>
            {/*{isOpen && <Modal />}*/}
            <div className="min-h-full">
                <SuperAdminNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Product Plans</h1>
                    </div>
                </header>

                <main className="w-full">

                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">
                        <nav className="ml-8 rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href="/super-admin/plans" className="text-blue-600 hover:text-blue-700">Dashboard</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">Product Plans</li>
                            </ol>
                        </nav>
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="w-full sm:px-6">
                                    <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
                                        <div className="sm:flex items-center justify-between">
                                            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">List</p>
                                            <div>
                                                <NavLink className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-[#a70000] hover:bg-[#a70000] focus:outline-none rounded"
                                                         to="/super-admin/new-plan">
                                                    <p className="text-sm font-medium leading-none text-white">Add New Plan</p>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">

                                        <table className="w-full whitespace-nowrap">
                                            <thead>
                                            <tr className="h-16 w-full text-sm leading-none text-gray-800">
                                                <th className="font-normal text-left pl-4">No.</th>
                                                <th className="font-normal text-left pl-4">Name</th>
                                                <th className="font-normal text-left pl-4">Price (SADC - Rand)</th>
                                                <th className="font-normal text-left pl-4">Price (Europe - Euro)</th>
                                                <th className="font-normal text-left pl-12">Duration(days)</th>
                                                <th className="font-normal text-left pl-20">Game</th>
                                                <th className="font-normal text-left pl-20">Date</th>
                                                <th className="font-normal text-left pl-16">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="w-full">

                                            {planList && planList.map((tm, index) =>
                                                <SinglePlan
                                                    key={tm.id}
                                                    number={index+1}
                                                    game={tm}
                                                />
                                            )}

                                            </tbody>
                                        </table>
                                    </div>

                                    <br/>
                                    <br/>
                                    <br/>

                                </div>

                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </>

    );
}

export default Plans;
