import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'steps';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,reducers, extraReducers });

// exports
export const stepActions = { ...slice.actions, ...extraActions };
export const stepsReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        list:  [],
        steps: [],
        step: {},
        phases:[],
        addNewStatus: false,
        phaseStep: {}
    }
}

function createReducers() {
    return {
        setAddNewStatus,
    };

    function setAddNewStatus(state, action){
        state.addNewStatus =  action.payload
    }

}

function createExtraActions() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/steps`;

    return {
        getAll: getAll(),
        getStepsByGame: getStepsByGame(),
        addStep: addStep(),
        getStep: getStep(),
        getPhaseStep: getPhaseStep(),
        updatePhaseStep: updatePhaseStep()
    };

    function getAll() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/steps`;
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${url}`)
        );
    }

    function getStepsByGameIdAndColumn() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/steps`;
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${url}`)
        );
    }

    function getStepsByGame() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/steps/game`;
        return createAsyncThunk(
            `${name}/getStepsByGame`,
            async (game_id) => await fetchWrapper.get(`${url}/${game_id}`)
        );
    }

    function addStep() {
        return createAsyncThunk(
            `${name}/addStep`,
            async (body ) => await fetchWrapper.post(`${url}`, body )
        );
    }

    function getStep() {
        return createAsyncThunk(
            `${name}/getStep`,
            async ({id}) => await fetchWrapper.get(`${url}/${id}` )
        );
    }

    function getPhaseStep() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/phase-steps`;
        return createAsyncThunk(
            `${name}/getPhaseStep`,
            async ({id}) => await fetchWrapper.get(`${url}/${id}` )
        );
    }

    function updatePhaseStep() {
        const url = `${process.env.REACT_APP_API_BASE_URL}/phase-steps`;
        return createAsyncThunk(
            `${name}/updatePhaseStep`,
            async ({body}) => await fetchWrapper.post(`${url}/${body.id}`, body )
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll(),
        ...getStepsByGame(),
        ...addStep(),
        ...getStep(),
        ...getPhaseStep(),
        ...updatePhaseStep()
    }

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.list = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload
            },
            [rejected]: (state, action) => {
                state.list = { error: action.error };
            }
        };
    }

    function getStepsByGame() {
        const { pending, fulfilled, rejected } = extraActions.getStepsByGame;
        return {
            [pending]: (state) => {
                state.list = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.list = action.payload
            },
            [rejected]: (state, action) => {
                state.list = { error: action.error };
            }
        };
    }

    function addStep() {
        const { pending, fulfilled, rejected } = extraActions.addStep;
        return {
            [pending]: (state) => {
                state.step = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.step = action.payload;
            },
            [rejected]: (state, action) => {
                state.step = { error: action.error };
            }
        };
    }

    function getStep() {
        const { pending, fulfilled, rejected } = extraActions.getStep;
        return {
            [pending]: (state) => {
                state.step = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.step = action.payload;
            },
            [rejected]: (state, action) => {
                state.step = { error: action.error };
            }
        };
    }

    function getPhaseStep() {
        const { pending, fulfilled, rejected } = extraActions.getPhaseStep
        return {
            [pending]: (state) => {
                state.phaseStep = { loading: true }
            },
            [fulfilled]: (state, action) => {
                state.phaseStep = action.payload
            },
            [rejected]: (state, action) => {
                state.phaseStep = { error: action.error }
            }
        }
    }

    function updatePhaseStep() {
        const { pending, fulfilled, rejected } = extraActions.updatePhaseStep
        return {
            [pending]: (state) => {
                state.phaseStep = { loading: true }
            },
            [fulfilled]: (state, action) => {
                state.phaseStep = action.payload
            },
            [rejected]: (state, action) => {
                state.phaseStep = { error: action.error }
            }
        }
    }

}
