// components/PieChart.js
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart } from "chart.js";
import { ArcElement, PieController, Tooltip, Legend } from "chart.js";

// Register the required controllers, elements, and plugins
Chart.register(PieController, ArcElement, Tooltip, Legend);

const PieChart = ({ data, options }) => {
    return (
        <div className="w-full h-full flex items-center justify-center">
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default PieChart;
