import {useDispatch, useSelector} from "react-redux";
import {videoActions} from "../_store";
import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";

const Video = ({url, handleClick}) => {
  const dispatch = useDispatch()
  const [currentVideoTime, setCurrentVideoTime] = useState(0)
  const [previousVideoTime, setPreviousVideoTime] = useState(0)

  const onEnded = () => {
    dispatch(videoActions.setCompleted(true))
    toast.success("Hurray!! You can click NEXT to continue!")
  }

  const videoRef = useRef(null);

  const restartVideo = () => {
    toast.info("You are required to watch the full video!")
    videoRef.current.load()
    videoRef.current.play()
    console.log("Video Ref Current Play ", videoRef)
  }

  const replayVideo = () => {
    handleClick()
    videoRef.current.currentTime = 0
    videoRef.current.play()
  }

  useEffect(() => {
    const video = videoRef.current;

    function handleFullscreenChange() {
      if (window.document.fullscreenElement === video) {
        console.log('Maximize button clicked!');
        dispatch(videoActions.setMaxScreenClicked(true))
      }
    }

    video.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      video.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);


  useEffect(() => {
    const video = videoRef.current;

    const handleFullScreenChange = () => {
      if (document.fullscreenElement === video) {
        console.log("Entered full screen mode")
          dispatch(videoActions.setMaxScreenClicked(true))
      } else {
        console.log("Exited full screen mode")
      }
    }

    video.addEventListener("fullscreenchange", handleFullScreenChange);
    video.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    video.addEventListener("mozfullscreenchange", handleFullScreenChange);

    return () => {
      video.removeEventListener("fullscreenchange", handleFullScreenChange);
      video.removeEventListener("webkitfullscreenchange", handleFullScreenChange);
      video.removeEventListener("mozfullscreenchange", handleFullScreenChange);
    };
  }, []);

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const SKIP_TIME = 10;

  const handleTimeUpdate = () => {
    if (videoRef.current.currentTime < previousVideoTime) {
      videoRef.current.currentTime = previousVideoTime;
    } else {
      setPreviousVideoTime(videoRef.current.currentTime);
    }
  };

  const timeUpdate = () => {
    const duration  = videoRef.current.duration
    setCurrentVideoTime(videoRef.current.currentTime);
    if (currentVideoTime >= duration - 15) {
      dispatch(videoActions.setCompleted(true))
    }
  }

  return (
      <video
          ref={videoRef}

          onTimeUpdate={timeUpdate}
          onSeeked={ (e) => {
            restartVideo()
          }}

          onSeeking={ (e) => {
            setCurrentVideoTime(videoRef.current.currentTime)
          }}

          onEnded={(e) => {
            onEnded()
          }} controls
          controlsList="nodownload pip"
          width="100%"
          disablePictureInPicture
          playsInline // Add this attribute
      >
        <source src={url} type="video/mp4"/>
        Sorry, your browser doesn't support videos.
      </video>
  )
}


export default Video;
