import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {
    questionActions, subgameActions, videoActions,
} from "../../../_store";
import {modalActions} from "../../../_store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../../_helpers/alerts";
import {useNavigate, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {SpinningButton} from "../../button/Buttons";


export const EditGameInfo = () => {
    const dispatch = useDispatch();

    const {id}   = useParams()
    const navigate = useNavigate()
    const {question} = useSelector(x => x.questions)

    console.log("Question object", question)

    const [type, setType] = useState("question")

    const validationSchema = Yup.object().shape({
        // question: type === "question" && Yup.string().required('Question is required'),
        // options_answer: type === "question" && string().required('answer is required'),
        // video: type === "question" && object().required('Video is required')
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control,setValue } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({
                          statement_1, statement_2, statement_3, statement_4, statement_5, statement_6,
                          answer_1, answer_2, answer_3, answer_4, answer_5, answer_6,
                      }) {
        alert("hit")

        return dispatch(subgameActions.addSubGame({
            question: question?.data?.id,
            statement_1, statement_2, statement_3, statement_4, statement_5, statement_6,
            answer_1, answer_2, answer_3, answer_4, answer_5, answer_6
        }))
            .then(async (response)=> {
                if (response.type === "questions/addSubGame/rejected"){
                    toast.error("OOps!, Problem saving game!")
                    return
                }
                await promiseToast("Saving game", "Added a new game!")
                // setTimeout(() => {
                //     navigate("/super-admin/sub-games")
                // }, 2000)
            })
    }

    useEffect(() => {

        dispatch(questionActions.getQuestion(id))

        const fields = ['id','question',
            'statement_1', 'statement_2', 'statement_3', 'statement_4', 'statement_5', 'statement_6',
            'answer_1', 'answer_2', 'answer_3', 'answer_4', 'answer_5', 'answer_6',
        ]
        dispatch(subgameActions.getSubGame(id)).then((ques)=> {
            fields.forEach(field => {
                switch (field){

                    case "statement_1":
                        setValue(field, ques.payload?.data?.options?.statement_1 )
                        break

                    case "statement_2":
                        setValue(field, ques.payload?.data?.options?.statement_2 )
                        break

                    case "statement_3":
                        setValue(field, ques.payload?.data?.options?.statement_3 )
                        break

                    case "statement_4":
                        setValue(field, ques.payload?.data?.options?.statement_4 )
                        break

                    case "statement_5":
                        setValue(field, ques.payload?.data?.options?.statement_5 )
                        break

                    case "statement_6":
                        setValue(field, ques.payload?.data?.options?.statement_6 )
                        break

                    case "answer_1":
                        setValue(field, ques.payload?.data?.options?.answer_1 )
                        break

                    case "answer_2":
                        setValue(field, ques.payload?.data?.options?.answer_2 )
                        break

                    case "answer_3":
                        setValue(field, ques.payload?.data?.options?.answer_3 )
                        break

                    case "answer_4":
                        setValue(field, ques.payload?.data?.options?.answer_4 )
                        break

                    case "answer_5":
                        setValue(field, ques.payload?.data?.options?.answer_5 )
                        break

                    case "answer_6":
                        setValue(field, ques.payload?.data?.options?.answer_6 )
                        break
                }
            });
        })
    },[])

    return (
        <>
            <br/>
            <div>

                <form onSubmit={handleSubmit(onSubmit)} method="POST">


                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Scenario/Question</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                Scenario or question.
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:mt-0 md:col-span-2">
                        {/*<form onSubmit={handleSubmit(onSubmit)} method="POST">*/}
                            <div className="shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Knowledge Check Scenario
                                        </label>
                                        <textarea
                                            defaultValue={question?.data?.question}
                                            value={question?.data?.question}
                                            onChange={(e) => {
                                                // dispatch(questionActions.setQuestion({question: e.target.value}))
                                            }}
                                            {...register("question")}
                                            rows="5"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        >

                                        </textarea>
                                    </div>

                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    {/*<div className="alert alert-danger mt-3 mb-0">{teamError.message}</div>*/}
                                </div>
                            </div>
                        {/*</form>*/}
                    </div>

                </div>

                <br/>

                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Statements</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                Statements in order.
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:mt-0 md:col-span-2">
                        {/*<form onSubmit={handleSubmit(onSubmit)} method="POST">*/}
                            <div className="shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Statement 1
                                        </label>
                                        <input
                                            placeholder="Type  statement"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('statement_1')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Statement 2
                                        </label>
                                        <input
                                            placeholder="Type  statement"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('statement_2')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Statement 3
                                        </label>
                                        <input
                                            placeholder="Type  statement"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('statement_3')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Statement 4
                                        </label>
                                        <input
                                            placeholder="Type  statement"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('statement_4')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Statement 5
                                        </label>
                                        <input
                                            placeholder="Type  statement"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('statement_5')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Statement 6
                                        </label>
                                        <input
                                            placeholder="Type  statement"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('statement_6')}
                                        />
                                    </div>

                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    {/*<div className="alert alert-danger mt-3 mb-0">{teamError.message}</div>*/}
                                </div>
                            </div>
                        {/*</form>*/}
                    </div>

                </div>

                <br/>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Answers</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                Answers the in order you want it to appear.
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Answer 1
                                        </label>
                                        <input
                                            placeholder="Type  answer"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('answer_1')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Answer 2
                                        </label>
                                        <input
                                            placeholder="Type  answer"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('answer_2')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Answer 3
                                        </label>
                                        <input
                                            placeholder="Type  answer"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('answer_3')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Answer 4
                                        </label>
                                        <input
                                            placeholder="Type  answer"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('answer_4')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Answer 5
                                        </label>
                                        <input
                                            placeholder="Type  answer"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('answer_5')}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                            Answer 6
                                        </label>
                                        <input
                                            placeholder="Type  answer"
                                            type="text"
                                            name="name"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('answer_6')}
                                        />
                                    </div>

                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                                </div>
                            </div>

                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                            <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                                {isSubmitting ?<>  <SpinningButton />  Saved </> : "Save" }
                            </button>

                            {/*<div className="alert alert-danger mt-3 mb-0">{teamError.message}</div>*/}

                        </div>
                    </div>

                </div>

                </form>
            </div>
        </>
    )
}