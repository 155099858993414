import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice
const name = 'subscriptions';
const initialState = createInitialState();
const extraActions = createExtraActions();
const reducers = createReducers();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const subscriptionActions = { ...slice.actions, ...extraActions };
export const subscriptionsReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        subscription: {},
        subscriptions: {},
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/subscriptions`;

    return {
        getAll: getAll(),
        getSubscriptions: getSubscriptions(),
        getSubscription: getSubscription(),
        getSingleSubscription: getSingleSubscription(),
        addSubscriptions: addSubscriptions(),
        extend: extendSubscription()
    };

    function getSubscriptions() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/all-subscriptions`;
        return createAsyncThunk(
            `${name}/getSubscriptions`,
            async (obj) => {
                const query = new URLSearchParams(obj).toString()
                return await fetchWrapper.get(`${baseUrl}?${query}`)
            }
        )
    }

    function getSubscription() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/subscriptions`;
        return createAsyncThunk(
            `${name}/getSubscription`,
            async (obj) => {
                const query = new URLSearchParams(obj).toString()
                return await fetchWrapper.get(`${baseUrl}/${obj.subscription_plan}?${query}`)
            }
        )
    }

    function getSingleSubscription() {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/subscriptions`;
        return createAsyncThunk(
            `${name}/getSingleSubscription`,
            async (body) => {
                return await fetchWrapper.post(baseUrl, body)
            }
        )
    }


    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async ({user}) => await fetchWrapper.get(`${baseUrl}?user=${user}`)
        )
    }

    function addSubscriptions() {
        return createAsyncThunk(
            `${name}/addSale`,
            async (body) => await fetchWrapper.post(baseUrl,body)
        )
    }

    function  extendSubscription () {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/extend-subscription`;
        return createAsyncThunk(
            `${name}/extend`,
            async(body) => await fetchWrapper.post(baseUrl, body)
        )
    }
}

function createReducers () {
    return {
        setSubscription,

    }

    function setSubscription(state, action){
        state.subscription = action.payload
    }
}


function createExtraReducers() {
    return {
        ...getAll(),
        ...getSubscriptions(),
        ...getSubscription(),
        ...getSingleSubscription(),
        ...addSubscriptions(),
        ...extendSubscription()
    };

    function getSubscriptions() {
        const { pending, fulfilled, rejected } = extraActions.getSubscriptions;
        return {
            [pending]: (state) => {
                state.subscriptions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subscriptions = action.payload;
            },
            [rejected]: (state, action) => {
                state.subscriptions = { error: action.error };
            }
        };
    }

    function getSubscription() {
        const { pending, fulfilled, rejected } = extraActions.getSubscription;
        return {
            [pending]: (state) => {
                state.subscription = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subscription = action.payload;
            },
            [rejected]: (state, action) => {
                state.subscription = { error: action.error };
            }
        };
    }

    function getSingleSubscription() {
        const { pending, fulfilled, rejected } = extraActions.getSingleSubscription;
        return {
            [pending]: (state) => {
                state.subscription = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subscription = action.payload;
            },
            [rejected]: (state, action) => {
                state.subscription = { error: action.error };
            }
        };
    }

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.subscriptions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subscriptions = action.payload;
            },
            [rejected]: (state, action) => {
                state.subscriptions = { error: action.error };
            }
        };
    }

    function addSubscriptions() {
        const { pending, fulfilled, rejected } = extraActions.addSubscriptions;
        return {
            [pending]: (state) => {
                state.subscription = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subscription = action.payload;
            },
            [rejected]: (state, action) => {
                state.subscription = { error: action.error };
            }
        };
    }

    function extendSubscription() {
        const { pending, fulfilled, rejected } = extraActions.extend;
        return {
            [pending]: (state) => {
                state.subscription = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subscription = action.payload;
            },
            [rejected]: (state, action) => {
                state.subscription = { error: action.error };
            }
        };
    }

}
