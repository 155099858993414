import {MemberEmails} from "./MemberEmails";
import {CompanyInfo} from "./CompanyInfo";
import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";
import {EmailInfo} from "./EmailInfo";
import {licenseActions, teamActions} from "../../_store";
import {useDispatch, useSelector} from "react-redux";
import {Nav} from "../Nav";
import TeamSetup from "./TeamSetUp";
import {useParams, useSearchParams} from "react-router-dom";
import Modal from "../Modal";
import ConfirmModal from "../ConfirmModal";
import SessionEndedModal from "../superAdmin/SessionEndedModal";
import {ProductNav} from "../ProductNav";

export const AddEmail = () => {

    const dispatch = useDispatch()
    const modal  = useSelector(x => x.modal )
    const confirmModal = useSelector(x => x.confirmModal)
    const {user, isLoggedOut} = useSelector(x => x.auth)
    const licenses = useSelector(x => x.licenses)
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")
    const plan = searchParams.get("plan")
    const {license_id} = useParams()

    useEffect(() => {
        const license = user.license
        dispatch(licenseActions.setLicenses(null))
        dispatch(licenseActions.setLicensedEmails(null))

        dispatch(licenseActions.getAll({license:license_id}))
        dispatch(teamActions.setIsTeamInfoCompleted(true))
    },[])

    return (
        <>
            <ToastContainer />
            {modal.isOpen && <Modal />}
            {confirmModal.isOpen && <ConfirmModal />}
            {isLoggedOut && <SessionEndedModal />}

            <div className="min-h-full">
                <ProductNav id={license_id} plan={plan} />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Add Members to team</h1>
                    </div>
                </header>
                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

                        <nav className="rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href={`/assessments/${license_id}`} className="text-blue-600 hover:text-blue-700">Assessments</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">Add members</li>
                            </ol>
                        </nav>

                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="max-w-5xl content-center">
                                    <div className="hidden sm:block" aria-hidden="true">
                                        <div className="py-5">
                                            <div className="border-t border-gray-200" />
                                        </div>
                                    </div>
                                    <div className=" sm:mt-0">
                                        <div className="md:grid md:grid-cols-1 md:gap-6">
                                            <div className=" md:mt-0 md:col-span-2">
                                                <MemberEmails />

                                                <br/>
                                                <br/>

                                                <EmailInfo />

                                                <br/>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
                <br/>
                <br/>
            </div>


        </>
    )
}