import {SuperAdminNav} from "../SuperAdminNav";
import {PlanInfo} from "../PlanInfo";
import {ToastContainer} from "react-toastify";
import React, {useEffect} from "react";
import {StepInfo} from "./StepInfo";
import {useSearchParams} from "react-router-dom";
import {EditStepInfo} from "./EditStepInfo";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {stepActions} from "../../../_store";

function EditStep() {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const game_id = searchParams.get("game_id")
    const {id}   = useParams()

    return (

        <>
            <ToastContainer />

            <div className="min-h-full">
                <SuperAdminNav />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Edit Step</h1>
                    </div>
                </header>

                <main className="w-full">

                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">
                        <nav className="ml-8 rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href={`/super-admin/steps/${game_id}`} className="text-blue-600 hover:text-blue-700">Steps</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">Edit Step</li>
                            </ol>
                        </nav>

                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="w-2/3 sm:px-6">
                                    <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
                                        <div className="sm:flex items-center justify-between">
                                            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Edit Step</p>

                                        </div>
                                    </div>
                                        {/*<StepInfo />*/}
                                        <EditStepInfo />
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </>

    );
}

export default EditStep;
