import React, {useEffect, useState} from "react";
import { ViewButton} from "./button/Buttons";

export const SingleAssessment = ({product_id, id, name, company, date, email, numberOfMembers, industry, license, company_id, plan}) => {

    return (
        <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 transition-colors duration-150">
            <td className="pl-4 py-4">
                <div className="w-full bg-gray-200 h-4 rounded-full">
                    <div className="bg-[#a70000] h-4" style={{ width: 25 + "%" }}></div>
                </div>
            </td>
            <td className="pl-4 cursor-pointer py-4">
                <div className="flex items-center">
                    <div className="pl-4">
                        <p className="font-semibold text-base text-gray-800">{name}</p>
                        <p className="text-xs leading-3 text-gray-600 pt-1">{industry}</p>
                    </div>
                </div>
            </td>
            <td className="pl-12 py-4">
                <p className="text-base font-semibold leading-none text-gray-800">{company}</p>
                <div className="w-24 h-2 bg-gray-100 rounded-full mt-2">
                    <div className="w-20 h-2 bg-green-500 rounded-full" />
                </div>
            </td>
            <td className="pl-12 py-4">
                <p className="font-semibold text-base">{(new Date(date)).toLocaleDateString()}</p>
            </td>
            <td className="pl-20 py-4">
                <p className="font-semibold text-base">{email}</p>
            </td>
            <td className="pl-20 py-4">
                <p className="font-semibold text-base">0</p>
            </td>
            <td className="pl-20 py-4">
                <p className="font-semibold text-base">{numberOfMembers}</p>
            </td>
            <td className="pl-16 py-4">
                <div className="flex items-center">
                    <div className="mr-3">
                        <a
                            href={`/assessments/members/${id}?license=${license}&plan=${plan}`}
                            className="inline-flex items-center px-3 py-1 text-sm font-medium text-white bg-[#a70000] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2">
                            <ViewButton />
                            <span className="ml-2">View</span>
                        </a>
                    </div>
                </div>
            </td>
            <td className="px-7 2xl:px-0"></td>
        </tr>


    )
}