import {useDispatch, useSelector} from 'react-redux'
import {gameActions, progressActions, questionActions, subgameActions} from "../../_store"
import {useNavigate} from "react-router";
import * as Yup from "yup"
import {string} from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import {useForm} from "react-hook-form"
import Video from "../Video"
import {useEffect, useState} from "react"
import CopyrightLogo from "../../games/copyright-logo.jpg"
import {useSearchParams} from "react-router-dom";

const GameModal = () => {
  const dispatch = useDispatch();
  const {currentModalStep} = useSelector(x => x.games)
  const { progress, nextStep,progressOrder} = useSelector(x => x.progress)
  const recapLink =  progress?.progress?.link
  const question =  progress?.progress?.question
  const [recap, setRecap] = useState(false)
  const progressObject = progress?.progress;
  const nextStepObject = nextStep.nextPhaseStep && nextStep.nextPhaseStep
  const [searchParams] = useSearchParams()
  const {user} = useSelector(x=> x.auth)
  const nextProgressOrder = progressOrder
  const game_id = searchParams.get("game_id")
  const validationSchema = Yup.object().shape({answer: string().required('answer is required')})
  const {subgame} = useSelector(x => x.subgames)
  const questionId = progress?.progress?.questionId

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, control } = useForm(formOptions)

  const column = progressObject?.column
  const userLastStep =  nextStep.nextPhaseStep && nextStep.nextPhaseStep.id

  useEffect(() => {
    dispatch(subgameActions.getSubGame(questionId))
  }, [])

  function onSubmit({ answer}) {
    dispatch(gameActions.closeModal())
    dispatch(questionActions.setAnswer(answer))

    switch (answer) {
      case "option_one":
        console.log("nextStepObject a", nextStepObject)
        dispatch(gameActions.setChoiceOfAnswer({
          ee_score: nextStepObject?.questionOptions?.option_a_ee_score,
          et_score: nextStepObject?.questionOptions?.option_a_et_score,
          eb_score: nextStepObject?.questionOptions?.option_a_eb_score,
        }))

        dispatch(progressActions.addProgress(
            {
              phaseStep: userLastStep,
              user: user.id,
              order: nextProgressOrder,
              game: game_id,
              column,
              answered_question_only: true,
              actual_ee_score: progressObject && Number(progressObject.actual_ee_score) + nextStepObject?.questionOptions?.option_a_ee_score,
              actual_et_score: progressObject && Number(progressObject.actual_et_score) + nextStepObject?.questionOptions?.option_a_et_score,
              actual_budget_score: progressObject && Number(progressObject.actual_budget_score) + nextStepObject?.questionOptions?.option_a_eb_score
            }))

        break

      case "option_two":
        console.log("nextStepObject b", nextStepObject)
        dispatch(gameActions.setChoiceOfAnswer({
          ee_score: nextStepObject?.questionOptions?.option_b_ee_score,
          et_score: nextStepObject?.questionOptions?.option_b_et_score,
          eb_score: nextStepObject?.questionOptions?.option_b_eb_score,
        }))

        dispatch(progressActions.addProgress(
            {
              phaseStep: userLastStep,
              user: user.id,
              order: nextProgressOrder,
              game: game_id,
              column,
              answered_question_only: true,
              actual_ee_score: progressObject && Number(progressObject.actual_ee_score) + nextStepObject?.questionOptions?.option_b_ee_score,
              actual_et_score: progressObject && Number(progressObject.actual_et_score) + nextStepObject?.questionOptions?.option_b_et_score,
              actual_budget_score: progressObject && Number(progressObject.actual_budget_score) + nextStepObject?.questionOptions?.option_b_eb_score
            }))
        break

      case "option_three":
        console.log("nextStepObject c", nextStepObject)
        dispatch(gameActions.setChoiceOfAnswer({
          ee_score: nextStepObject?.questionOptions?.option_c_ee_score,
          et_score: nextStepObject?.questionOptions?.option_c_et_score,
          eb_score: nextStepObject?.questionOptions?.option_c_eb_score,
        }))

        dispatch(progressActions.addProgress(
            {
              phaseStep: userLastStep,
              user: user.id,
              order: nextProgressOrder,
              game: game_id,
              column,
              answered_question_only: true,
              actual_ee_score: progressObject && Number(progressObject.actual_ee_score) + nextStepObject?.questionOptions?.option_c_ee_score,
              actual_et_score: progressObject && Number(progressObject.actual_et_score) + nextStepObject?.questionOptions?.option_c_et_score,
              actual_budget_score: progressObject && Number(progressObject.actual_budget_score) + nextStepObject?.questionOptions?.option_c_eb_score
            }))

        break

      case "option_four":
        console.log("nextStepObject d", nextStepObject)
        dispatch(gameActions.setChoiceOfAnswer({
          ee_score: nextStepObject?.questionOptions?.option_d_ee_score,
          et_score: nextStepObject?.questionOptions?.option_d_et_score,
          eb_score: nextStepObject?.questionOptions?.option_d_eb_score,
        }))

        dispatch(progressActions.addProgress(
            {
              phaseStep: userLastStep,
              user: user.id,
              order: nextProgressOrder,
              game: game_id,
              column,
              answered_question_only: true,
              actual_ee_score: progressObject && Number(progressObject.actual_ee_score) + nextStepObject?.questionOptions?.option_d_ee_score,
              actual_et_score: progressObject && Number(progressObject.actual_et_score) + nextStepObject?.questionOptions?.option_d_et_score,
              actual_budget_score: progressObject && Number(progressObject.actual_budget_score) + nextStepObject?.questionOptions?.option_d_eb_score
            }))

        break

      default:

        dispatch(gameActions.setChoiceOfAnswer({
          ee_score: nextStepObject?.questionOptions?.option_a_ee_score,
          et_score: nextStepObject?.questionOptions?.option_a_et_score,
          eb_score: nextStepObject?.questionOptions?.option_a_eb_score,
        }))

        dispatch(progressActions.addProgress(
            {
              phaseStep: userLastStep,
              user: user.id,
              order: nextProgressOrder,
              game: game_id,
              column,
              answered_question_only: true,
              actual_ee_score: progressObject && Number(progressObject.actual_ee_score) + nextStepObject?.questionOptions?.option_a_ee_score,
              actual_et_score: progressObject && Number(progressObject.actual_et_score) + nextStepObject?.questionOptions?.option_a_et_score,
              actual_budget_score: progressObject && Number(progressObject.actual_budget_score) + nextStepObject?.questionOptions?.option_a_eb_score
            }))

        break
    }

    if (answer === currentModalStep.options.options_answer) {
      dispatch(gameActions.openCorrectResultModal())
    }else{
      dispatch(gameActions.openWrongResultModal())
    }
  }

  function replaceWithBr(text) {
    return text?.replace(/\n/g, "<br /> <br/>")
  }

    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        const modal = document.querySelector('.modal-container');
        if (!document.fullscreenElement) {
            modal.requestFullscreen().catch(console.error);
            setIsFullScreen(true);
        } else {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

  return (
      <aside className='modal-container scroll'>
          {/*<aside className={`modal-container scroll ${isFullScreen ? 'fullscreen' : ''}`}>*/}
        <div className={`modal scroll ${isFullScreen ? 'fullscreen text-2xl' : ''}`}>
          <form method="POST" onSubmit={handleSubmit(onSubmit)}>

            {recap &&
            <div>
              <h3 className="mb-2 text-2xl">RECAP</h3>
              <h3 className="mb-2 text-xs">
                <i>
                  {subgame?.data?.correct_image_link == null &&
                  <p dangerouslySetInnerHTML={{__html: replaceWithBr(question)}} />
                  }

                  {/*{question}*/}
                </i>
              </h3>

              <div>
                {/*{recapLink != null && recapLink.includes(".mp4") ? <Video url={recapLink} /> :<img src={recapLink}/> }*/}
                {recapLink != null && recapLink.includes(".mp4") ? <Video url={recapLink} /> : recapLink !== null && <img width={`780`} src={recapLink}/> }
                {subgame?.data?.correct_image_link != null && <img width={`780`} src={`${subgame?.data?.correct_image_link}`} /> }

              </div>

              <div className='btn-container'>
                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                  {recap &&
                  <button
                      type="button"
                      className="mr-3 inline-block px-6 py-2.5 bg-[#a70000] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setRecap(false)
                      }}>
                    Continue
                  </button>
                  }

                </div>
              </div>
            </div>
            }

            {!recap &&
            <div className={`mt-7`}>
              <h2 className={`font-bold`}>Scenario/Question:</h2>
              <br/>
              <h3 className={`${isFullScreen ? 'text-xl' :'text-center text-[#000000] italic font-medium text-sm'}`}>
                {/*{currentModalStep.question}*/}
                <p dangerouslySetInnerHTML={{__html: replaceWithBr(currentModalStep.question)}} />

              </h3>
              <br/>
              <i className="underline"> Please select the correct answer.</i>
              <br/>
              <br/>

              <div className="grid grid-cols-2 gap-2 font-medium text-sm">
                <div>
                  <input {...register("answer")} type="radio" name="answer" value="option_one" className="mr-2" />
                    <p className={`${isFullScreen ? 'text-lg' : ''}`}>{currentModalStep.options.option_one} </p>
                </div>
                <div>
                  <input {...register("answer")} type="radio" name="answer" value="option_two" className="mr-2" />
                    <p className={`${isFullScreen ? 'text-lg' : ''}`}>{currentModalStep.options.option_two} </p>
                </div>
              </div>
              <br/>

              <div className="grid grid-cols-2 gap-2 font-medium text-sm">
                <div>
                  <input {...register("answer")} type="radio" name="answer" value="option_three" className="mr-2" />
                    <p className={`${isFullScreen ? 'text-lg' : ''}`}>{currentModalStep.options.option_three} </p>
                </div>
                <div>
                  <input {...register("answer")} type="radio" name="answer" value="option_four" className="mr-2" />
                    <p className={`${isFullScreen ? 'text-lg' : ''}`}>{currentModalStep.options.option_four} </p>
                </div>
              </div>
              <br/>

              <img src={CopyrightLogo} width={100} className="float-left mt-5"  />
              {/*<img src={CopyrightLogo} width={100} srcSet={"image@2x.jpg 2x, image@3x.jpg 3x"} className="float-left mt-5" />*/}

              <div className='btn-container'>
                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                    <button
                        type="button"
                        className="mr-2 inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
                        onClick={toggleFullScreen}
                    >
                        {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
                    </button>

                  {progressOrder !== 0 && currentModalStep.order > 1 &&
                  <button
                      type="button"
                      className="mr-3 inline-block px-6 py-2.5 bg-[#a70000] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                      onClick={() =>{
                        setRecap(true)
                      }}>
                    Recap
                  </button>
                  }


                  <button
                      type="submit"
                      className="mr-3 inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-800 hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                      data-bs-dismiss="modal"
                      onClick={() =>
                      {}}>
                    Submit
                  </button>

                  <button
                      type="button"
                      className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                      data-bs-dismiss="modal"
                      onClick={() =>
                      {
                        dispatch(gameActions.closeModal());
                      }}>
                    Close
                  </button>


                </div>
              </div>
            </div>

            }

          </form>

        </div>
      </aside>
  )
}
export default GameModal

