import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../_helpers/alerts";
import {useNavigate, useParams} from "react-router";
import {SpinningButton} from "../button/Buttons";
import {useEffect} from "react";
import {gameActions, phaseActions, questionActions, stepActions} from "../../_store";


export const VideoInfo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required('Name is required'),
        file: Yup.mixed().required('File is required')
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

        const onSubmit = async (data) => {
            const formData = new FormData();
            formData.append("file", data.file[0]);
            const url = `${process.env.REACT_APP_API_BASE_URL}/videos`;
             await fetch(url, {
                method: "POST",
                body: formData,
            }).then((res) => {
                promiseToast("Saving media", "You have Successfully added a new media!")

                 if (!isSubmitting){
                     // navigate("/super-admin/media")
                 }

                return res.json();
            });
        }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}  method="POST" encType="multipart/form-data">

                <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="file" className="mb-2 block text-sm font-medium text-gray-700">
                                Click below to add media
                            </label>

                            <input
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                {...register("file")}
                                required
                                type='file'
                                name='file' />
                            {errors.file && <span>This field is required</span>}
                        </div>

                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {isSubmitting ? <SpinningButton />  : "Add Media" }
                        </button>

                        {/*<button*/}
                        {/*    disabled={!isSubmitting}*/}
                        {/*    type="submit"*/}
                        {/*    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*/}
                        {/*    Add Video*/}
                        {/*</button>*/}
                    </div>
                </div>
            </form>
        </>
    )
}