import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice
const name = 'users';
const initialState = createInitialState();
const extraActions = createExtraActions();
const reducers = createReducers();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation
function createInitialState() {
  return {
    user: {},
    users: {},
    checkoutUser: null,
    createUser: false,
    error: null
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;

  return {
    getAll: getAll(),
    getUser: getUser(),
    addUser: addUser(),
    addAdministrator: addAdministrator(),
    updateUser: updateUser(),
    update: update(),
  };

  function getAll() {
    return createAsyncThunk(
        `${name}/getAll`,
        async () => await fetchWrapper.get(baseUrl)
    );
  }

  function addUser() {
    return createAsyncThunk(
        `${name}/addUser`,
        async (body) => await fetchWrapper.post(baseUrl,body)
    );
  }

  function addAdministrator() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/add-administrator`;

    return createAsyncThunk(
        `${name}/addAdministrator`,
        async (body) => await fetchWrapper.post(baseUrl,body)
    );
  }

  function updateUser() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;

    return createAsyncThunk(
        `${name}/updateUser`,
        async ({id, hasWatchedIntro }) => await fetchWrapper.patch(baseUrl,{id, hasWatchedIntro})
    );
  }

  function update() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;

    return createAsyncThunk(
        `${name}/update`,
        async (body) => await fetchWrapper.patch(baseUrl,body)
    );
  }

  function getUser() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/user`;
    return createAsyncThunk(
        `${name}/getUser`,
        async (body) => await fetchWrapper.get(baseUrl)
    );
  }
}

function createReducers() {
  return {
    setCheckoutUser,
    setCreateUser
  }

  function setCheckoutUser(state, action){
    state.checkoutUser = action.payload
  }

  function setCreateUser(state, action){
    state.createUser = action.payload
  }

}

function createExtraReducers() {
  return {
    ...getAll(),
    ...getUser(),
    ...addUser(),
    ...addAdministrator(),
    ...update(),
  };

  function getAll() {
    const { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.users = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.users = action.payload;
      },
      [rejected]: (state, action) => {
        state.users = { error: action.error };
      }
    };
  }

  function getUser() {
    const { pending, fulfilled, rejected } = extraActions.getUser;
    return {
      [pending]: (state) => {
        state.user = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.user = action.payload;
      },
      [rejected]: (state, action) => {
        state.user = { error: action.error };
      }
    }
  }

  function addUser() {
    const { pending, fulfilled, rejected } = extraActions.addUser;
    return {
      [pending]: (state) => {
        state.user = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.user = action.payload;
      },
      [rejected]: (state, action) => {
        state.user = { error: action.error };
      }
    }
  }

  function addAdministrator() {
    const { pending, fulfilled, rejected } = extraActions.addAdministrator;
    return {
      [pending]: (state) => {
        state.user = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.user = action.payload;
      },
      [rejected]: (state, action) => {
        console.log("Action", action)
        // state.user = { error: action.error };
        if (action.error.message.includes("409")){
          state.error = {message : "This email already exists or Admin has been set up!" }
          state.user = {message : "This email already exists or Admin has been set up!" }
          return
        }else if (action.error.message.includes("404")){
          state.error = {message : "Transaction does not exist or not paid!" }
          state.user = {message : "Transaction does not exist or not paid!" }
          return
        }else if (action.error.message.includes("400")) {
          state.error = {message: "All inputs are required!"}
          state.user = {message: "All inputs are required!"}
          return
        }

        state.user = action.error;
        state.error = action.error;
        console.log("error object", action)
      }
    }
  }

  function update() {
    const { pending, fulfilled, rejected } = extraActions.update;
    return {
      [pending]: (state) => {
        state.user = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.user = action.payload;
      },
      [rejected]: (state, action) => {
        state.user = { error: action.error };
      }
    }
  }
}