import {useDispatch, useSelector} from 'react-redux'
import {toast, ToastContainer} from "react-toastify"
import {useNavigate} from "react-router"
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import WinnerCup from "../../games/congrats-cup.jpg"
import {AnimateNumber} from "../AnimateNumber"
import {authActions, gameActions, licenseActions, progressActions} from "../../_store"
import CopyrightLogo from "../../games/copyright-logo.jpg";

const CompletedGameModalFour = () => {
  const dispatch = useDispatch();
  const {currentModalStep} = useSelector(x => x.games)
  const {clickedTeamId}  = useSelector(x => x.teams)

  const {progress} = useSelector(x => x.progress)
  const {user} = useSelector(x=> x.auth)
  const navigate = useNavigate()

  const currentActualEEScore = progress.progress && progress.progress.actual_ee_score + 100
  const currentActualETScore = progress.progress && progress.progress.actual_et_score + 100
  const currentActualBudgetScore = progress.progress && progress.progress.actual_budget_score + 100
  const overallScore = Math.floor((currentActualEEScore+currentActualETScore+currentActualBudgetScore)/3)

  const getOverAllAssessment = () => {
    let message;
    if (overallScore >= 201 && overallScore < 232) {
      message = `You appear to be an accomplished and highly experienced change manager and may possibly be a subject matter expert. You seem to have a deep understanding of Change Management principles and methodologies and most likely successfully led a wide range of change initiatives across different industries and/or organisations in complex and challenging environments. You are likely to have a strong ability to anticipate, manage, and identify causes of resistance to change, effectively use Change Management tools, methodologies, and techniques, as well as develop new methods, tools, and tailored interventions to suit specific change scenarios. You may also have the ability to establish and foster a culture of change within organisations. Your leadership, communication, and stakeholder management skills, emotional intelligence, and ability to inspire and motivate others to embrace change are likely exceptional, making you a legend in the field of Change Management.
If you are interested in further enhancing your skills, exploring new practical tools in Change Management, and/or developing Change Management capacity within your organisation, we recommend reaching out to the appropriate organisational representative/manager or the ChangeVerve support team. They can provide information on various practical tools and training programmes that are available to support your development or your organisation in this area.`
    } else if (overallScore >= 170 && overallScore < 201) {
      message = `
            It appears that you have significant experience and a good understanding of Change Management, including a broad range of knowledge and skills. You may have a proven track record of successfully leading a wide range of change initiatives. As an experienced Change Manager/Leader/Champion, you likely have the ability to not only anticipate and manage resistance to change but also identify and address the root causes of resistance. It appears that your experience includes the application of different methodologies and tools and possibly developing tailored interventions to specific change scenarios. 
            If you are interested in further enhancing your skills or developing Change Management capacity within your organisation, we recommend reaching out to the appropriate organisational representative/manager or the ChangeVerve support team. They can provide information on various practical tools and training programmes that are available to support your development or your organisation in this area.
            `
    } else if (overallScore >= 139 && overallScore < 170){
      message = `
                It seems that you have some experience in Change Management, and are capable of managing change initiatives of simple to moderate complexity. You appear to have a good understanding of Change Management principles, methodologies, tools, and techniques. It is probable that you have the competence to recognise the change requirements, create and carry out a change plan, and efficiently communicate and coordinate with stakeholders for such simple to moderately complex projects. With more learning and experience in complex change, you will be able to deepen your understanding through the results of practical application. You may need guidance from specialists or more experienced colleagues to identify and manage the complexities and nuances that arise from more multifaceted change. 
                If you are interested in further enhancing your skills to manage more complex change, we recommend reaching out to the appropriate representative/manager within your organisation or the ChangeVerve support team. They can provide information on various practical tools and training programmes that are available to support your development in this area.
            `

    } else if (overallScore >= 107 && overallScore < 139) {
      message = `It appears that you have a good understanding of the concepts, principles, and methodologies of Change Management. It is likely that you have gained this knowledge through formal education or self-study. However, you may have limited experience in applying your knowledge to large-scale, more complex change projects such as Gold Corporation's Project Phoenix. With more learning and experience, you will be able to deepen your understanding through the results of practical application. You may need guidance from specialists or more experienced colleagues to identify and manage the complexities and nuances that arise from more multifaceted change.
        If you are interested in further developing your skills to practically implement change and manage more complex change, we recommend reaching out to the appropriate representative/manager within your organisation or the ChangeVerve support team. They can provide information on various practical tools and training programmes that are available to support your development in this area.`

    } else if (overallScore >= 67 && overallScore < 107 ) {
      message = `It appears that you have basic knowledge of the principles and processes of Change Management. However, it seems that you may not have had the opportunity to apply your knowledge in a real-world, large-scale change project as complex as the Gold Corporation's Project Phoenix. It is likely that you have gained your knowledge through formal education or self-study, but you may have some uncertainty regarding certain aspects of the Change Management process and may need some guidance from experienced colleagues to put the knowledge into practice. 
                        If you are interested in further enhancing your skills in Change Management, we recommend reaching out to the appropriate representative/manager within your organisation or the ChangeVerve support team. They can provide information on various practical tools and training programmes that are available to support your development in this area.`
    } else if (overallScore >= 0 && overallScore < 67 ){
      message = `It seems that you may be new to Change Management for large-scale change projects, and have limited experience and understanding of the complexity of the full Change Management process. Common challenges when people start out in Change Management include difficulty with setting clear and measurable goals for the change, the uncertainty of how to effectively communicate and implement changes, aligning stakeholders, dealing with resistance, tracking actual progress, and managing change risk. Managing all these elements to ensure that a change initiative is completed successfully on time and within budget requires specialised skills. If you are interested in further developing your skills in Change Management, we recommend reaching out to the appropriate representative/manager within your organisation or the ChangeVerve support team. They can provide information on various practical tools and training programmes that are available to support your development in this area.`

    }

    return <p>{message}</p>;
  }

  return (
      <>
        <ToastContainer />

        <aside className='modal-container'>

          <div className='modal'>
            <form method="POST">
              {/*<Confetti />*/}

              <h1 className="mb-2 mt-6">
                            <span className="font-bold mb-3">
                                 Explanation of your Overall score:
                            </span>
              </h1>
              <br/>

              <h1 className="font-bold text-lg"> Overall average score = { overallScore}</h1>
              <br/>

              <p className="text-lg">
                {getOverAllAssessment()}
              </p>

              <img src={CopyrightLogo} width={100} className="float-left mt-5" />

              <div>
                <div className='btn-container'>
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                    <button
                        onClick={() => {
                          dispatch(licenseActions.completeGame({user: user?.id, license: user?.license,completed: true, game_id: progress?.progress?.game, email_only: true, team_id: clickedTeamId})).then(() => {
                            dispatch(progressActions.setGameCompletedStatusText("end-game"))
                          })
                        }}
                        type="button"
                        className="mr-3 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                        data-bs-dismiss="modal">
                      NEXT
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </aside>
      </>
  )
}

export default CompletedGameModalFour

