import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SingleTile} from "../SingleTile";
import GreenIndicator from "../../../games/Yellow-Indicator-B.png";
import AnimatedNumbers from "react-animated-numbers";
import {useNavigate} from "react-router"
import SecondBoardDesign from "../../../games/Board-Design-Frame-Phase-3-B.png"
import {screens} from "../../../_helpers/screens";
import infoButton from "../../../games/Info-Button.png";
import {gameActions, videoActions} from "../../../_store";
import "./css/custom.css"
import {position} from "tailwindcss/src/util/dataTypes";

export const Desktop1536X864ScreenTwo = ({columnSixSteps, columnSevenSteps, columnEightSteps,
                                             columnNineSteps,columnTenSteps,
                                             userLastStep, progressLastStep}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {phaseBackground} = useSelector(x => x.games)
    const {nextStep,progress, et_score, ee_score, budget_score} = useSelector(x => x.progress)
    // const currentEEScore =  progress.progress && progress.progress.ee_score !== 0 ? progress.progress.ee_score: ee_score
    // const currentETScore =  progress.progress && progress.progress.et_score !== 0 ? progress.progress.et_score: et_score
    // const currentBudgetScore =  progress.progress && progress.progress.budget_score !== 0 ? progress.progress.budget_score: budget_score
    const currentActualEEScore = progress.progress && progress.progress.actual_ee_score
    const currentActualETScore = progress.progress && progress.progress.actual_et_score
    const currentActualBudgetScore = progress.progress && progress.progress.actual_budget_score

    const progressObject     = progress.progress && progress.progress

    const currentEEScore     =   screens.desktopOne.eeIndicator + (progressObject.actual_ee_score  || 0)
    const currentETScore     =   screens.desktopOne.etIndicator + (progressObject.actual_et_score ||  0)
    const currentBudgetScore =   screens.desktopOne.budgetIndicator +  (progressObject.actual_budget_score || 0);


    const [effectivenessScore, setEffectivenessScore] = useState(0)
    const [timeScore, setTimeScore] = useState(0)
    const [budgetScore, setBudgetScore] = useState(0)

    useEffect(() => {
        setEffectivenessScore(currentEEScore)
        setTimeScore(currentETScore)
        setBudgetScore(currentBudgetScore)
    }, [currentEEScore, currentETScore, currentBudgetScore])

    // const phaseName =  progress.progress && progress.progress.phase_name
    // const phaseName  = nextStep.nextPhaseStep && nextStep.nextPhaseStep.phase_name
    const phaseName  =  nextStep?.nextPhaseStep?.phase_name
    const description  =  nextStep?.nextPhaseStep?.description

    console.log("progress from desktop 1536 second screen", progress)

    return (

    <div className="container bg-white">
        <div className="game-board">
            <div>
                <div className="static">

                    {columnSixSteps && columnSixSteps.map((step, index)=>
                        <div>
                            {index === 3 &&
                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                progressLastStep={progressLastStep}
                                divStyle={{ top: `90px` ,left: `40px`}}
                                // divStyle={{ top: `110px` ,left: `222px`}}
                                tileStyle={{width: `70%`, marginTop: `-5px` }}
                                column={6}
                                number={index}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                            {index === 2 &&
                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                progressLastStep={progressLastStep}
                                divStyle={{top: `220px`, left: `40px`}}
                                tileStyle={{width: `70%`, marginTop: `2px`}}
                                column={6}
                                number={index}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />

                            }

                            {index === 1 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                progressLastStep={progressLastStep}
                                divStyle={{top: `360px`, left: `40px`}}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                column={6}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />

                            }

                            {index === 0 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{top: `490px`, left: `40px`}}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                column={6}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                        </div>
                    )}

                </div>
                <div className="static">
                    {columnSevenSteps && columnSevenSteps.map((step, index)=>
                        <div>
                            {index === 0 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `280px`, top: `90px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={7}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />

                            }

                            {index === 1 &&
                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `280px`, top: `220px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={7}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                            {index === 2 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `280px`, top: `355px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={7}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                            {index === 3 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `280px`, top: `490px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={7}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                        </div>
                    )}


                </div>

                <div className="static">

                    {columnEightSteps && columnEightSteps.map((step, index)=>
                        <div>
                            {index === 3 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                progressLastStep={progressLastStep}
                                divStyle={{ left: `520px`, top: `90px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                number={index}
                                column={8}
                                greenTickerStyle={{top: `10px`,  left: `40px`, width: `15%`}}
                            />

                            }

                            {index === 2 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                progressLastStep={progressLastStep}
                                divStyle={{ left: `520px`, top: `225px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={8}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />

                            }

                            {index === 1 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                progressLastStep={progressLastStep}
                                divStyle={{ left: `525px`, top: `355px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                number={index}
                                column={8}
                                greenTickerStyle={{top: `0px`,  left: `30px`, width: `15%`}}
                            />

                            }

                            {index === 0 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                progressLastStep={progressLastStep}
                                divStyle={{ left: `525px`, top: `490px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                number={index}
                                column={8}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                        </div>
                    )}

                </div>

                <div className="static">

                    {columnNineSteps && columnNineSteps.map((step, index)=>
                        <div>
                            {index === 0 &&
                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `760px`, top: `90px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={9}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}

                            />
                            }

                            {index === 1 &&
                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `760px`, top: `225px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={9}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}

                            />
                            }

                            {index === 2 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `760px`, top: `355px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={9}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />

                            }

                            {index === 3 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `760px`, top: `490px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={9}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                        </div>
                    )}

                </div>

                <div className="static">

                    {columnTenSteps && columnTenSteps.map((step, index)=>
                        <div>
                            {index === 3 &&
                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `1020px`, top: `105px` }}
                                tileStyle={{width: `70%`, marginTop: `2px` }}
                                busStyle={{ width: `45%`, marginTop: `2px` }}
                                column={10}
                                type={`AnchoringPhase`}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                            {index === 2 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `1010px`, top: `225px` }}
                                tileStyle={{width: `80%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={10}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />

                            }

                            {index === 1 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `1010px`, top: `355px` }}
                                tileStyle={{width: `80%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={10}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />

                            }

                            {index === 0 &&

                            <SingleTile
                                step={step}
                                userLastStep={userLastStep}
                                divStyle={{ left: `1010px`, top: `490px` }}
                                tileStyle={{width: `80%`, marginTop: `2px` }}
                                busStyle={{left:`-15px`, width: `40%`, marginTop: `0px` }}
                                column={10}
                                greenTickerStyle={{top: `0px`,  left: `40px`, width: `15%`}}
                            />
                            }

                        </div>
                    )}

                </div>

                <div style={{
                    border: `1px solid #000`,
                    width: `40px`,
                    position: `absolute`,
                    top: `645px`,
                    left: `405px`,
                    background: `#000`,
                    height: `27px` }}>
                    <p style={{color: `white`,
                        marginTop: `-1px`,
                        marginLeft: `4px`
                    }}>

                        {/*<AnimateNumber number={!isNaN(progressObject.actual_ee_score)? progressObject.actual_ee_score : 100} />*/}
                        {!isNaN(progressObject.actual_ee_score)? progressObject.actual_ee_score + 100 : 100}
                        {/*<AnimateNumber number={cachedValue} />*/}

                    </p>

                </div>

                <div style={{
                    border: `1px solid #000`,
                    width: `40px`,
                    position: `absolute`, top: `645px`,
                    left: `767px`,
                    background: `#000`,
                    height: `27px` }}>
                    <p style={{color: `white`, marginTop: `-1px`, marginLeft: `4px`}}>
                        {/*<AnimateNumber number={!isNaN(progressObject.actual_et_score)? progressObject.actual_et_score : 100} />*/}
                        {!isNaN(progressObject.actual_et_score)? progressObject.actual_et_score + 100 : 100}
                    </p>
                </div>

                <div style={{
                    border: `1px solid #000`,
                    width: `40px`,
                    position: `absolute`, top: `645px`,
                    left: `1128px`,
                    background: `#000`,
                    height: `27px` }}>
                    <p style={{color: `white`, top: `-1px`, marginLeft: `4px`}}>
                        {/*<AnimateNumber number={!isNaN(progressObject.actual_budget_score) ? progressObject.actual_budget_score : 100} />*/}
                        {!isNaN(progressObject.actual_budget_score) ? progressObject.actual_budget_score + 100  : 100}
                    </p>
                </div>

                <div className={`absolute` } style={{top: `650px`, left: `${currentEEScore}px`}}>
                    <img src={GreenIndicator}   alt="indicator" />
                </div>

                <div className={`absolute` } style={{top: `650px`, left: `${currentETScore}px`}}>
                    <img src={GreenIndicator}  alt="indicator" />
                </div>

                <div className={`absolute` } style={{top: `650px`, left: `${currentBudgetScore}px`}}>
                    <img src={GreenIndicator}  alt="indicator" />
                </div>

                {/*<div className={`absolute` } style={{top: `645px`, left: `220px`}}*/}
                {/*     onClick={ () => {*/}
                {/*         dispatch(gameActions.setInfoButtonModalOpened(true))*/}
                {/*     }}>*/}

                {/*    <img src={infoButton}  alt="indicator" width="30"*/}
                {/*         style={{*/}
                {/*             position: `relative`,*/}
                {/*             left: `-145px`*/}
                {/*         }} />*/}
                {/*</div>*/}

            </div>
            <img src={SecondBoardDesign} width={1200} />
        </div>

        <div className="instructions h-full" >
            {/*<h4>Current Stage: <strong>{phaseName}</strong></h4>*/}
            <br/>

            {/*<p>Stop {description?.replace(/\D+/g, '')}: Change Management Plan</p>*/}
            {/*<br/>*/}

            <h3><strong>Information:</strong> </h3>
            {/*<br/>*/}
            <ol>
                <li>
                    <button
                        onClick={() =>{
                            dispatch(videoActions.setCurrentIntroVideo("business-objective"))
                            dispatch(gameActions.setInfoButtonModalOpened(true))
                        }
                        }
                        className="exit-game w-full">Objectives of the Game</button>
                </li>

                <li>
                    <button onClick={() => {
                        dispatch(videoActions.setCurrentIntroVideo("business-case-image"))
                        dispatch(gameActions.setInfoButtonModalOpened(true))
                    }}
                            className="exit-game w-full">Business Case Summary</button>
                </li>

                <li>
                    <button onClick={() => {
                        dispatch(videoActions.setCurrentIntroVideo("business-case"))
                        dispatch(gameActions.setInfoButtonModalOpened(true))
                    }}
                            className="exit-game w-full">Business Case</button>
                </li>

                <li>
                    <button
                        onClick={() => {
                            dispatch(videoActions.setCurrentIntroVideo("how-to-play"))
                            dispatch(gameActions.setInfoButtonModalOpened(true))
                        }}
                        className="exit-game w-full">How to play the Game</button>
                </li>
                <br/>
                <br/>
                <li>

                    <button
                        onClick={() =>
                            navigate(-1)
                        }
                        className=" w-full bg-gray-300 text-black font-bold py-2 px-4 rounded">
                        Exit game
                    </button>

                </li>
            </ol>

            <br/>


        </div>

    </div>
    )

}