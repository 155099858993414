import {Desktop1536X864} from "../Desktop1536X864/Desktop1536X864";
import {Desktop1536X864ScreenTwo} from "../Desktop1536X864/Desktop1536X864ScreenTwo";
import {Desktop1536X864ScreenThree} from "../Desktop1536X864/Desktop1536X864ScreenThree";
import React, {useEffect} from "react";
import {gameActions} from "../../../_store";
import {useDispatch} from "react-redux";

export const Render1536X864 = ({
                                 screens,
                                 width,
                                 height,
                                 columnOneSteps,
                                 columnTwoSteps,
                                 columnThreeSteps,
                                 columnFourSteps,
                                 columnFiveSteps,
                                 progressLastStep,
                                 currentProgressOrder,
                                 userLastStep,
                                 columnSixSteps,
                                 columnSevenSteps,
                                 columnEightSteps,
                                 columnNineSteps,
                                 columnTenSteps,
                                 columnElevenSteps,
                                 columnTwelveSteps,
                                 columnThirteenSteps
                               }) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(gameActions.setHasSupportedScreen(true))
  }, [])


  return (

      <>

        <div className="test-container">
          <div className="content">
        {/*{screens.desktopOne.width === width && screens.desktopOne.height === height &&*/}
        {currentProgressOrder < 20 &&
        <Desktop1536X864
            columnOneSteps={columnOneSteps}
            columnTwoSteps={columnTwoSteps}
            columnThreeSteps={columnThreeSteps}
            columnFourSteps={columnFourSteps}
            columnFiveSteps={columnFiveSteps}
            progressLastStep={progressLastStep}
            userLastStep={userLastStep}
        />
        }

        {
            // screens.desktopOne.width === width && screens.desktopOne.height === height &&
        currentProgressOrder >= 20 && currentProgressOrder < 40 &&
        <Desktop1536X864ScreenTwo
            columnSixSteps={columnSixSteps}
            columnSevenSteps={columnSevenSteps }
            columnEightSteps={columnEightSteps}
            columnNineSteps={columnNineSteps}
            columnTenSteps={columnTenSteps}
            progressLastStep={progressLastStep}
            userLastStep={userLastStep}
        />
        }

        {
            // screens.desktopOne.width === width && screens.desktopOne.height === height &&
        currentProgressOrder >= 40 &&
        <Desktop1536X864ScreenThree
            columnElevenSteps={columnElevenSteps}
            columnTwelveSteps={columnTwelveSteps}
            columnThirteenSteps={columnThirteenSteps}
            progressLastStep={progressLastStep}
            userLastStep={userLastStep}
        />
        }
          </div>
        </div>
      </>
  )
}