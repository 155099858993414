import {useDispatch, useSelector} from 'react-redux';
import {gameActions, progressActions, questionActions} from "../../_store";
import * as Yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import {useForm} from "react-hook-form"
import {useEffect} from "react"
import CopyrightLogo from "../../games/copyright-logo.jpg"

const GameModal = () => {
    const dispatch = useDispatch();
    const {currentModalStep, choiceOfAnswer} = useSelector(x => x.games)
    const {progress} = useSelector(x => x.progress)

    const currentActualEEScore = progress.progress && progress.progress.actual_ee_score
    const currentActualETScore = progress.progress && progress.progress.actual_et_score
    const currentActualBudgetScore = progress.progress && progress.progress.actual_budget_score

    const {answer} = useSelector(x => x.questions)
    // const {progress, ee_score, et_score, budget_score} = useSelector(x => x.progress)

    const reason =  answer.replace("option","reason")
    const validationSchema = Yup.object().shape({
        // answer: string().required('answer is required')
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit } = useForm(formOptions);
    const eeScore = choiceOfAnswer?.ee_score
    const newETScore = choiceOfAnswer?.et_score
    const newBudgetScore = choiceOfAnswer?.eb_score

    const actualEEScore = currentActualEEScore +  choiceOfAnswer?.ee_score
    const actualETScore = currentActualETScore + choiceOfAnswer?.et_score
    const actualBudgetScore = currentActualBudgetScore + choiceOfAnswer?.eb_score

    function onSubmit() {
        // dispatch(gameActions.closeCorrectResultModal())
        console.log("Actual ET Score from correct answer modal", actualETScore)
    }

    function replaceWithBr(text) {
        return text.replace(/\n/g, "<br />")
    }

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [ ])

    return (

        <aside className='modal-container overflow-y-auto scroll' >
            <div className='large-modal' style={{marginTop: `110px`}}>
          <form method="POST" onSubmit={handleSubmit(onSubmit)}>
              <div className='overflow-y-auto scroll'>
                  <h1 className="mb-2 ">YOU ARE CORRECT!</h1>

                  <div className={` text-center font-semi-bold mt-5`}>
                      <strong>Why you answered correctly</strong>:
                      <p className="text-sm">
                          <p dangerouslySetInnerHTML={{__html: replaceWithBr(currentModalStep.options[reason])}} />
                      </p>
                  </div>

                  <br/>
                  <i className="underline"> Good job. Please watch this video and proceed to the next bus stop when done.</i>
                  <br/>
                  <br/>

                  <img src={CopyrightLogo} width={100} className="float-left mt-5" />

                  <div className='btn-container'>
                      <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                          <button
                              onClick={() => {
                                  dispatch(progressActions.setEEScore(eeScore))
                                  dispatch(progressActions.setETScore(newETScore))
                                  dispatch(progressActions.setBudgetScore(newBudgetScore))

                                  dispatch(questionActions.setCorrect(true))

                                  dispatch(gameActions.closeCorrectResultModal());
                                  dispatch(gameActions.openVideoModal())

                              }}
                              type="button"
                              className="mr-3 inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-800 hover:shadow-lg focus:bg-green-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                              // className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                              data-bs-dismiss="modal">
                              WATCH VIDEO
                          </button>
                      </div>
                  </div>
              </div>
          </form>
      </div>
    </aside>
  )
}
export default GameModal


