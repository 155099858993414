// import {logout} from "../features/user/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {authActions} from "../_store";

export const Profile = () => {
    const dispatch = useDispatch()

    const authUser = useSelector(x => x.auth.user);

    const navigate = useNavigate()

    // useEffect(() => {
    //     // redirect to home if already logged in
    //     if (!authUser) {
    //         navigate('/');
    //     }
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [navigate, authUser]);

    return (
        <div
            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button"
            tabIndex="-1">

            <a href="/profile" className="block px-4 py-2 text-sm text-gray-700"
               role="menuitem" tabIndex="-1" id="user-menu-item-0">Your Profile</a>

            <a href="#" className="block px-4 py-2 text-sm text-gray-700"
               role="menuitem" tabIndex="-1" id="user-menu-item-1">Settings</a>

            <a
                onClick={() => {
                    dispatch(authActions.logout())
                }}
                href="#" className="block px-4 py-2 text-sm text-gray-700"
                role="menuitem" tabIndex="-1" id="user-menu-item-2">Sign out</a>
        </div>
    )
}