export const screens = {
    desktopOne:   {
        width: 1536,
        height: 864,
        innerScreenHeight: 714,
        eeIndicator: 237,
        etIndicator: 597,
        budgetIndicator:960
    },
    desktopTwo:   {width: 1920, height: 1080, eeIndicator: 505, etIndicator: 938, budgetIndicator:1367},
    desktopThree: {width: 1366, height: 768, eeIndicator: 350, etIndicator: 659, budgetIndicator:967},
    desktopFour:  {width: 1440, height: 900, eeIndicator: 370, etIndicator: 697, budgetIndicator:1022},
    desktopFive:  {width: 1280, height: 720, eeIndicator: 154, etIndicator: 428, budgetIndicator:700},
    IPadPro    :  {width: 1024, height: 1366, eeIndicator: 505, etIndicator: 938, budgetIndicator:1367},
}