import {useDispatch, useSelector} from 'react-redux';
import {gameActions, licenseActions, progressActions, questionActions, videoActions} from "../_store";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useSearchParams} from "react-router-dom"
import React, {useEffect, useRef, useState} from "react";
import Video from "./Video";
import {ModalAlertSpinner} from "./Alert/ModalAlert";
import {screens} from "../_helpers/screens";
const { screen, innerWidth, innerHeight, document } = window;

const AssessmentIntroModal = () => {
    const dispatch = useDispatch();
    const [currentMedia, setCurrentMedia] = useState("objectives")
    const {completed,isModalOpen} = useSelector(x=> x.videos)
    const { progress, nextStep,progressOrder} = useSelector(x => x.progress)
    const authUser = useSelector(x => x.auth.user)
    const [searchParams] = useSearchParams()
    const license_id = searchParams.get("license_id")
    const game_id = searchParams.get("game")
    const validationSchema = Yup.object().shape({
        // answer: string().required('answer is required')
    })
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit } = useForm(formOptions)
    const videoRef = useRef(null);
    const {subscription} = useSelector(x => x.subscriptions)
    const subscriptionPlan = subscription.subscription && subscription.subscription.subscription_plan
    const navigate = useNavigate()
    const [submitting, setSubmitting] = useState(false)
    const currentVideoIndexId = `currentVideoIndex-${authUser?.id}`
    const currentTimeId = `currentTime-${authUser?.id}`
    const isCompletedId = `completed-${authUser?.id}`
    const [currentVideoIndex, setCurrentVideoIndex] = useState(parseInt(localStorage.getItem(currentVideoIndexId)) || 0);
    const [watched, setWatched] = useState(false);
    const [currentTime, setCurrentTime] = useState(parseInt(localStorage.getItem(currentTimeId)) || 0);
    const [isCompleted, setIsCompleted] = useState(localStorage.getItem(isCompletedId) || false)
    // const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    // const [watched, setWatched] = useState(false);

    useEffect(() => {
        const zoomOut = () => {
            if(screen.width === screens.desktopThree.width || screen.width < screens.desktopThree.width  ){
                document.body.style.zoom = '85%';
            }
        };
        zoomOut();
    }, []);


    useEffect(() => {
        // const handleContextmenu = e => {
        //     e.preventDefault()
        // }
        // document.addEventListener('contextmenu', handleContextmenu)
        // return function cleanup() {
        //     document.removeEventListener('contextmenu', handleContextmenu)
        // }
    }, [])

    useEffect(() => {
        // localStorage.clear()
        videoRef.current.currentTime = currentTime;
    }, [currentVideoIndex]);


    const replayVideo = () => {
        videoRef.current.currentTime = 0;
        setWatched(false)
        setCurrentTime(0)
    }

    const videos = [
        {
            src: "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo%2B1%2B-%2BObjectives.mp4",
            title: "1. Objectives of the Game (1 of 8)",
            duration: 1.23 * 60
        },
        {
            src: "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo%2B2a%2B-%2BWhy%2BCM%2BMatters.mp4",
            title: "2a. Why Change Management Matters(2 of 8)",
            duration: 3.05 * 60
        },
        {
            src: "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo%2B2b%2B-%2BTypes%2Bof%2BChange.mp4",
            title: "2b. Types of Change (3 of 8)",
            duration: 3.21 * 60

        },
        {
            // src: "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo+2c++-+Why+CM+Fails.mp4_1080_H264",
            src: "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo%2B2c%2B%2B-%2BWhy%2BCM%2BFails.mp4",
            title: "2c. Why Change Fails(4 of 8)",
            duration: 5.55 * 60

        },
        {
            // src:  "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo+2d+-+ISO+Req.mp4_1080_H264",
            src:  "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo%2B2d%2B-%2BISO%2BReq.mp4",
            title: "2d.  ISO Requirements Video (5 of 8)",
            duration: 4.45 * 60

        } ,
        {
            // src:   "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo+2e+-+Human+Behaviour.mp4_1080_H264",
            src:   "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo%2B2e%2B-%2BHuman%2BBehaviour.mp4",
            title: "2e. Human Behaviour (6 of 8)",
            duration: 4.45 * 60

        },
        {
            src:  "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo%2B3%2B-%2BBusiness%2BCase.mp4",
            // src:  "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo+3+-+Business+Case.mp4_1080_H264",
            title: "3. Business Case (7 of 8)",
            duration: 5.09 * 60

        },

        {
            // src:  "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo+4+-+How+to+Play+the+Game.mp4_1080_H264",
            src:  "https://changeverve-bucket.s3.eu-west-2.amazonaws.com/IVideo%2B4%2B-%2BHow%2Bto%2BPlay%2Bthe%2BGame.mp4",
            title: "4. How to Play (8 of 8)",
            duration: 11.35 * 60 // 1 minute in seconds


        }
    ]

    const gotoGame = () => {
        if (!submitting) {
            setSubmitting(true)
        }

        setTimeout(() => {
            dispatch(licenseActions.updateStatus({user: authUser.id, license: license_id}))
            navigate(`/game-board/${subscriptionPlan}?game_id=${game_id}`)
            // navigate(`/game-board/${subscriptionPlan}?game_id=adf81d1c-b66e-41c1-a487-0983e5027ea1`)
        },1000)
    }

    function handleNext() {
        if (watched) {
            if (currentVideoIndex < videos.length - 1) {
                setCurrentVideoIndex(currentVideoIndex + 1);
                localStorage.setItem(currentVideoIndexId, currentVideoIndex+1);
                setWatched(false);
                setCurrentTime(0);
                videoRef.current.load();
            } else {
                alert("You've reached the end of the videos!");
            }
        } else {
            alert("Please watch the entire video before proceeding.");
        }
    }

    function handleVideoEnd() {
        setWatched(true);
        setCurrentTime(0);
        localStorage.setItem(currentTimeId, 0);
        if (currentVideoIndex < videos.length - 1) {
            setCurrentVideoIndex(currentVideoIndex);
            localStorage.setItem(currentVideoIndexId, currentVideoIndex);
            videoRef.current.load();
        } else {
            setIsCompleted(true)
            localStorage.setItem(isCompletedId, "true");
            alert("You've reached the end of the videos!");
        }
    }

    function handleTimeUpdate() {
        setCurrentTime(videoRef.current.currentTime);
        localStorage.setItem(currentTimeId, videoRef.current.currentTime);
    }

    return (
        <>
            {
                submitting && <ModalAlertSpinner message="Description" description="Description" />
            }
            <aside className='modal-container' onLoad={() => dispatch(videoActions.setCompleted(false))}>
                <div className='modal'>
                    <form method="POST">
                        <div>

                            <div className="grid grid-cols-4 gap-2 text-sm">
                                <div className="col-span-4">
                                    <button
                                        className="h-24 bg-[#a70000] text-white font-bold py-2 pr-8 pl-8 rounded w-full">
                                        {/*1. Objectives of the Game (1 of 7)*/}
                                        <h2>{videos[currentVideoIndex].title}</h2>
                                    </button>
                                </div>

                            </div>

                            <br/>

                            <div>
                                <video
                                    controlsList="nodownload pip"
                                    onEnded={handleVideoEnd}
                                    onTimeUpdate={handleTimeUpdate}
                                    width="100%"
                                    ref={videoRef}
                                    controls src={videos[currentVideoIndex].src}
                                    playsInline
                                    webkit-playsinline
                                    disablePictureInPicture
                                />
                                <p>{watched || isCompleted ? "You've watched this video." : "Please watch the entire video before proceeding."}</p>
                                {/*<h2>{videos[currentVideoIndex].title}</h2>*/}
                                {/*<button onClick={handleNext}>Next</button>*/}
                            </div>


                            <div className='btn-container'>

                                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                                    <button
                                        type="button"
                                        className="mr-3 inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                                        data-bs-dismiss="modal"
                                        onClick={replayVideo}>
                                        Replay
                                    </button>

                                    {isCompleted?
                                        <button
                                            type="button"
                                            className="mr-3 inline-block px-6 py-2.5 bg-[#a70000] text-white font-medium text-xs leading-tight uppercase rounded shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                dispatch(gameActions.setAssessmentIntroModalOpened(false));
                                                gotoGame()
                                            }}>
                                            PLAY GAME
                                        </button> :

                                        <button
                                            type="button"
                                            className="mr-3 inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                                            data-bs-dismiss="modal"
                                            onClick={handleNext}>
                                            Next
                                        </button>
                                    }

                                    <button
                                        type="button"
                                        className="mr-3 inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            dispatch(gameActions.setAssessmentIntroModalOpened(false));
                                        }}>
                                        CLOSE
                                    </button>

                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </aside>
        </>
    )
};
export default AssessmentIntroModal

