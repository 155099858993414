import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'industries';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const industryActions = { ...slice.actions, ...extraActions };
export const industriesReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        industries: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/industries`;

    return {
        getAll: getAll()
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(baseUrl)
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.industries = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.industries = action.payload;
            },
            [rejected]: (state, action) => {
                state.industries = { error: action.error };
                console.log("industries error", action.error )
            }
        };
    }
}
