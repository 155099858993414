import React, {useEffect, useState} from "react";
import {AddUserButton, DeleteButton, EditButton, SpinningReloadButton, ViewButton} from "../../button/Buttons";
import {toast, ToastContainer} from "react-toastify";
import {useDispatch} from "react-redux";
import {string} from "yup";
import {saleActions, subscriptionActions} from "../../../_store";
import moment from "moment";


export const SingleSubscription = ({ number, subscription}) => {
    // const [show, setShow] = useState(null)
    const dispatch = useDispatch()

    const expired = () => {
        const  today = (moment(Date.now()).format('YYYY-MM-DDTHH:mm:ssZ'))
        const  diff  = (moment(subscription.expires_at)).diff(today)
        return diff < 0
    }

    return (
        <>

            <tr className={`h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 
             ${expired() ? "bg-red-300" : "bg-green-400" } text-white rounded`}>

                <td className="">
                    <p className="text-sm font-medium leading-none text-gray-800 pl-5 rounded">{ number }</p>
                </td>

                <td className="pl-2">
                    <p className="text-sm font-medium leading-none text-gray-800">{ subscription.email }</p>
                </td>

                <td className="pl-8">
                    <p className="text-sm font-medium leading-none text-gray-800">{ subscription.name }</p>
                </td>

                <td className="pl-8">
                    <p className="text-sm font-medium leading-none text-gray-800">{ subscription.quantity }</p>
                </td>

                <td className="pl-8">
                    <p className="text-sm font-medium leading-none text-gray-800">{ subscription.expires_at }</p>
                </td>

                <td className="pl-8">
                    <p className="text-sm font-medium leading-none text-gray-800">{ subscription.created_at} </p>
                </td>


                {/*<td className="pl-16 cursor-pointer">*/}
                {/*    <div className="flex items-center">*/}
                {/*        <div className="">*/}
                {/*            <p className="font-medium">{ subscription.created_at }</p>*/}
                {/*            <p className="text-xs leading-3 text-gray-600 pt-2"> </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</td>*/}

                <td className="pl-16">

                    <div className="flex items-center mt-2 mb-2">

                        <div className={`mr-1 hover:grow hover:text-[#a70000]`}>
                            <a
                                href={`#`}
                                className="focus:outline-none pl-7">
                                <ViewButton />
                            </a>
                        </div>

                        <div className={`mr-1 hover:grow hover:text-[#a70000]`}>
                            <a
                                onClick={(e)=> {
                                    dispatch(saleActions.openExtendExpiryDateModal())
                                    dispatch(subscriptionActions.setSubscription(subscription))

                                }}
                                className="focus:outline-none pl-7">

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>


                            </a>
                        </div>

                    </div>
                </td>

            </tr>
        </>


    )
}