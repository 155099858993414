import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {saleActions} from "../_store";
import Modal from 'react-modal';


const TransactionStatusPage = () => {
    const {verified} = useSelector(x => x.sales)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dispatch = useDispatch()
    const [counter, setCounter] = useState(60);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        const timer =
            counter > 0 &&
            setInterval(() => setCounter(counter - 1), 1000);
        if (counter === 0) {
            setButtonVisible(true);
        }
        return () => clearInterval(timer);
    }, [counter]);

    const handleButtonClick = () => {
        dispatch(saleActions.checkTransactionStatus("8132-375749-2921")).then((resp) => {
            // if (verified.verified === true){
                setIsModalOpen(true);
            // }
            console.log("verified", verified)
        })
    };

    return (
        // <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12" style={{backgroundColor: '#a70000'}}>
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div className="absolute inset-0 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl" style={{ backgroundImage: 'linear-gradient(to right, #a70000, #ff9900)' }}></div>

                {/*<div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>*/}
                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                        <div>
                            <h1 className="text-2xl font-semibold">Transaction Status Check</h1>
                            <p className="text-red-500 mt-2">Please wait {counter} seconds before confirming payment.</p>
                            {buttonVisible &&
                            <button className="mt-4 bg-[#a70000] text-white font-bold py-2 px-4 rounded" onClick={handleButtonClick}>
                                I have paid
                            </button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Transaction Verification"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#a70000', // Change the background color here
                        color: 'white', // Change the text color here
                        padding: '20px',
                        borderRadius: '10px'
                    }
                }}
            >
                <h2>Transaction has been Verified!</h2>
                <h3>An email has been sent to create an Administrator.</h3>
                <button
                    style={{
                        marginTop: '20px',
                        backgroundColor: 'white', // Button background color
                        color: '#a70000', // Button text color
                        border: 'none',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        borderRadius: '5px'
                    }}
                    onClick={() => setIsModalOpen(false)}
                >
                    Close
                </button>
            </Modal>

        </div>
    );
};

export default TransactionStatusPage;

