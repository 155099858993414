
import {ToastContainer} from "react-toastify";
import React from "react";

function Interactive() {

    return (

        <>
            {/*{isOpen && <Modal />}*/}
            <ToastContainer />

        </>

    );
}

export default Interactive;
