import React, {useEffect, useState} from "react";
import {Nav} from "../Nav";
import {SingleAssessment} from "../SingleAssessment";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {teamActions} from "../../_store";
import {TeamMember} from "./TeamMember";
// import {useParams} from "react-router";
import {ProductNav} from "../ProductNav";
import {useParams, useSearchParams} from "react-router-dom";


function TeamAssessment() {

    const dispatch = useDispatch()
    const teams = useSelector(x => x.teams );

    const teamList = teams.teamMembers.data
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams()
    const license = searchParams.get("license")


    useEffect(() => {
        dispatch(teamActions.getTeamMembers(params.id))
    },[])

    return (

        <>
            {/*{isOpen && <Modal />}*/}
            <div className="min-h-full">
                <ProductNav id={license} />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Team Members</h1>
                    </div>
                </header>

                <main className="w-full">

                    <div className="w-full mx-auto py-6 sm:px-6 lg:px-8">
                        <nav className="ml-8 rounded-md w-full">
                            <ol className="list-reset flex">
                                <li><a href={`/assessments/${license}`} className="text-blue-600 hover:text-blue-700">Assessments</a></li>
                                <li><span className="text-gray-500 mx-2">/</span></li>
                                <li className="text-gray-500">Team Members</li>
                            </ol>
                        </nav>
                        <div className="px-4 py-6 sm:px-0">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">

                                <div className="w-full sm:px-6">
                                    <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
                                        <div className="sm:flex items-center justify-between">
                                            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">List</p>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">

                                        <table className="w-full whitespace-nowrap">
                                            <thead>
                                            <tr className="h-16 w-full text-sm leading-none text-gray-800">
                                                <th className="font-normal text-left pl-4">No.</th>
                                                <th className="font-normal text-left pl-4">Email</th>
                                                <th className="font-normal text-left pl-12">Invited</th>
                                                <th className="font-normal text-left pl-20">Started</th>
                                                <th className="font-normal text-left pl-20">Completed</th>
                                                <th className="font-normal text-left pl-12">Date Created</th>
                                                <th className="font-normal text-left pl-4">Progress</th>
                                                <th className="font-normal text-left pl-4">Scores</th>
                                                <th className="font-normal text-left pl-16">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="w-full">

                                            {teamList && teamList.map((tm, index) =>
                                                <TeamMember
                                                    key={tm.id}
                                                    number={index+1}
                                                    id={tm.id}
                                                    email={tm.email}
                                                    isInvited={tm.isInvited}
                                                    isStarted={tm.isStarted}
                                                    isCompleted={tm.isCompleted}
                                                    date={tm.created_at}
                                                    team = {tm}
                                                />
                                            )}

                                            </tbody>
                                        </table>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </>

    );
}

export default TeamAssessment;