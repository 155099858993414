import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {
    questionActions, videoActions,
} from "../../../_store";
import {modalActions} from "../../../_store";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import {promiseToast} from "../../../_helpers/alerts";
import {useNavigate, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {object, string} from "yup";
import Select from "react-select";
import {SpinningButton} from "../../button/Buttons";



export const QuestionInfo = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate()
    const {videos} = useSelector(x => x.videos.list)
    const question = useSelector( x => x.questions)
    console.log("Question from Questions Info", question)
    const [type, setType] = useState("question")

    const videoOptions = videos && videos
        .map((item) =>{
            return { value: item.id, label: item.name}
        })

    const validationSchema = Yup.object().shape({
        question: type === "question" && Yup.string().required('Question is required'),
        options_answer: type === "question" && string().required('answer is required'),
        // video: type === "question" && object().required('Video is required')
    })

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, control } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        dispatch(videoActions.getAll())
    },[])

    function onSubmit({   question,
                          option_one,
                          option_two,
                          option_three,
                          option_four,
                          options_answer,
                          video,
                          wrong_video_url,
                          reason_one,
                          reason_two,
                          reason_three,
                          reason_four,
                          option_a_ee_score, option_a_et_score,option_a_eb_score,
                          option_b_ee_score, option_b_et_score, option_b_eb_score,
                          option_c_ee_score, option_c_et_score, option_c_eb_score,
                          option_d_ee_score, option_d_et_score, option_d_eb_score,
                      }) {

        const only_media = type === "media"
        return dispatch(questionActions.addQuestion({ question,option_one,option_two,option_three,
                                                        option_four, options_answer,video,
                                                        wrong_video_url, reason_one, reason_two,
                                                        reason_three, reason_four, only_media,
                                            option_a_ee_score, option_a_et_score,option_a_eb_score,
                                            option_b_ee_score, option_b_et_score, option_b_eb_score,
                                            option_c_ee_score, option_c_et_score, option_c_eb_score,
                                            option_d_ee_score, option_d_et_score, option_d_eb_score,
        }))
            .then(async (response)=> {
                if (response.type === "questions/addQuestion/rejected"){
                    toast.error("OOps!, Problem saving question!")
                    return
                }
                await promiseToast("Saving question", "Added a new question!")

                setTimeout(() => {
                    navigate("/super-admin/questions")
                }, 2000)
            });
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}  method="POST">
                <div className="shadow overflow-hidden sm:rounded-md">

                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
                                <h3> Pop Up Type </h3>
                            </label>

                            <br/>

                            <div className="float-left mr-10">
                                <label className="mr-3"> With Question</label>
                                <input type="radio" name="popup-type" value="question"
                                       checked={type === "question"}
                                       onChange={(e) => {
                                    if (e.target.checked){
                                        setType(e.target.value)
                                    }

                                }}  />
                            </div>

                            <div className="float-left ">
                                <label className="mr-3"> Only Media</label>
                                <input type="radio" name="popup-type" value="media"
                                       checked={type === "media"}
                                       onChange={(e) => {
                                    if (e.target.checked){
                                        setType(e.target.value)
                                    }
                                }}/>
                            </div>

                            <div className="float-left ml-3 ">
                                <label className="mr-3"> Sub game</label>
                                <input type="radio" name="popup-type" value="sub-game"
                                       checked={type === "sub-game"}
                                       onChange={(e) => {
                                    if (e.target.checked){
                                        setType(e.target.value)
                                    }
                                }}/>
                            </div>

                        </div>
                        <br/>

                        {type === "media" &&
                            <div className="col-span-6 sm:col-span-3 mb-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-3">
                                Name
                            </label>

                            <input
                                type="text"
                                {...register("question")}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />

                        </div>
                        }

                        {type === "question"  &&
                            <div className="col-span-6 sm:col-span-3">

                                <div className="col-span-6 sm:col-span-3 mb-3">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-3">
                                        Question
                                    </label>

                                    <textarea
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({question: e.target.value}))
                                        }}
                                        {...register("question")}
                                        rows="5"
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    >

                                    </textarea>

                                </div>

                                {/*<label htmlFor="name" className="block text-sm font-medium text-gray-700">*/}
                                {/*Answer Options*/}
                                {/*<br/><small> <i>Choose the correct answer from the radio options.</i> </small>*/}
                            {/*</label>*/}

                            <div className="grid">

                                <p className={`font-semi-bold mb-3`}>Option A.</p>

                                <div className="border-2 border-grey-800 rounded px-3 pt-3 pb-3">
                                    <input
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                        }}

                                        {...register("option_one")}
                                        type="text"
                                        placeholder="Type answer option here..."
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <textarea

                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({reason_one: e.target.value}))
                                        }}

                                        rows="7"
                                        placeholder="Type reason here..."
                                        name="reason_one"
                                        {...register("reason_one")}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    >
                            </textarea>

                                    <br/>

                                    <div className={`grid grid-cols-3 gap-2`}>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_a_ee_score")}
                                                type="number"
                                                placeholder="Type option A Effectiveness score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_a_et_score")}
                                                type="number"
                                                placeholder="Type option A Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_a_eb_score")}
                                                type="number"
                                                placeholder="Type option A Time score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="grid mt-5">
                                <p className={`font-semi-bold mb-3`}>Option B.</p>

                                <div className="border-2 border-grey-800 rounded px-3 pt-3 pb-3">
                                    <input
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({option_two: e.target.value}))
                                        }}
                                        placeholder="Type answer option here..."
                                        {...register("option_two")}
                                        type="text"
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <textarea
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({reason_two: e.target.value}))
                                        }}
                                        rows="7"
                                        placeholder="Type reason here..."
                                        name="reason_two"
                                        {...register("reason_two")}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <br/>

                                    <div className={`grid grid-cols-3 gap-2`}>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_b_ee_score")}
                                                type="number"
                                                placeholder="Type option B Effectiveness score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_b_et_score")}
                                                type="number"
                                                placeholder="Type option B Time score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_b_eb_score")}
                                                type="number"
                                                placeholder="Type option B Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>
                                <br/>

                            <div className="grid">
                                <p className={`font-semi-bold`}>Option C.</p>
                                <div className="border-2 border-grey-800 rounded px-3 pt-3 pb-3 mt-5">
                                    <input
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({option_three: e.target.value}))
                                        }}
                                        {...register("option_three")}
                                        type="text"
                                        placeholder="Type answer option here..."
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <textarea
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({reason_three: e.target.value}))
                                        }}
                                        rows="7"
                                        placeholder="Type reason here..."
                                        name="reason_three"
                                        {...register("reason_three")}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <br/>
                                    <div className={`grid grid-cols-3 gap-2`}>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_c_ee_score")}
                                                type="number"
                                                placeholder="Type option C Effectiveness score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_c_et_score")}
                                                type="number"
                                                placeholder="Type option C Time score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_c_eb_score")}
                                                type="number"
                                                placeholder="Type option C Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>
                                <br/>
                            <div className="grid">
                                <p className={`font-semi-bold`}>Option D.</p>
                                <div className="border-2 border-grey-800 rounded px-3 pt-3 pb-3 mt-5">
                                    <input
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({option_four: e.target.value}))
                                        }}
                                        {...register("option_four")}
                                        type="text"
                                        placeholder="Type answer option here..."
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <textarea
                                        onChange={(e) => {
                                            dispatch(questionActions.setQuestion({reason_four: e.target.value}))
                                        }}
                                        rows="7"
                                        placeholder="Type reason here..."
                                        name="reason_four"
                                        {...register("reason_four")}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />

                                    <br/>

                                    <div className={`grid grid-cols-3 gap-2`}>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_d_ee_score")}
                                                type="number"
                                                placeholder="Type option D Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_d_et_score")}
                                                type="number"
                                                placeholder="Type option D Time score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div>
                                            <input

                                                onChange={(e) => {
                                                    dispatch(questionActions.setQuestion({option_one: e.target.value}))
                                                }}

                                                {...register("option_d_eb_score")}
                                                type="number"
                                                placeholder="Type option D Budget score here..."
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>

                                <p>Choose the correct answer from options</p>

                                <div className={`grid grid-cols-4`}>

                                    <div className="2xl mt-3" >

                                        <div className="3xl mt-0" >
                                            <label className={`mr-3`}>A</label>
                                            <input value="option_one" type="radio" {...register("options_answer")}/>
                                        </div>
                                    </div>

                                    <div className="2xl mt-3" >
                                        <div className="3xl mt-0" >
                                            <label className={`mr-3`}>B</label>
                                            <input value="option_two" type="radio" {...register("options_answer")}/>
                                        </div>
                                    </div>

                                    <div className="2xl mt-3" >

                                        <div className="3xl mt-0" >
                                            <label className={`mr-3`}>C</label>
                                            <input value="option_three" type="radio" {...register("options_answer")}/>
                                        </div>
                                    </div>

                                    <div className="2xl mt-3" >

                                        <div className="3xl mt-0" >
                                            <label className={`mr-3`}>D</label>
                                            <input value="option_four" type="radio" {...register("options_answer")}/>
                                        </div>
                                    </div>

                                </div>

                        </div>
                        }

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Attach Media (<small><i>You can add media on Media page</i></small>) <i onClick={() =>
                                    dispatch(videoActions.getAll())
                            }> <a href={`#`}>Reload video options</a></i>
                            </label>
                            <Controller
                                {...register('video')}
                                name="video"
                                control={control}
                                render={({ field }) => <Select
                                    {...field}
                                    options={videoOptions}
                                />}
                            />

                            {errors.correct_video_url && <span className="text-red-500">This field is required</span>}

                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Enabled
                            </label>

                            <select
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                name={"enabled"}
                                {...register("enabled")}
                            >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </select>

                        </div>

                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            {/*<button*/}
                            {/*    type="submit"*/}
                            {/*    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*/}
                            {/*    Add Question*/}
                            {/*</button>*/}


                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#a70000] hover:bg-[#a70000] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#a70000]">
                            {isSubmitting ? <SpinningButton />  : "Add Question" }
                        </button>

                    </div>
                </div>
            </form>

        </>
    )
}