import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Example from "./Example"

export const BoxExample = ({data}) => {

    return (
        <div className="App">
            <DndProvider backend={HTML5Backend}>
                <Example data={data} />
            </DndProvider>
        </div>
    )
}